import React, { useState } from 'react'
import style from './style.module.scss'
import TextField from 'newui/Generic/Inputs/TextField';
import { isTextMin5Char } from 'newui/Utils/uiUtil';
import { useIntl } from 'react-intl';
import { getImageUrl } from 'newui/Utils/uiUtil';

export const DATA_MOBILECONFIRMATION_CODE = 'code';

function MobileConfirmation(props) {
  const intl = useIntl();
  const sourceData = props.value;
  const sourceCode = sourceData.fields.get(DATA_MOBILECONFIRMATION_CODE) ? sourceData.fields.get(DATA_MOBILECONFIRMATION_CODE) : {};

  const [code, setCode] = useState(sourceCode.value ? sourceCode.value : '');
  const [codeValid, setCodeValid] = useState((sourceCode.valid !== undefined) ? sourceCode.valid : null);

  function codeValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_MOBILECONFIRMATION_CODE, { value: value, valid: isValid });
    setCodeValid(isValid);
    setCode(value)
    props.validatorInformer((isValid), data);
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            <div className={style.test3}>
              <img src={getImageUrl('newui/register/IMG_New-Notification.png')} alt='' />
            </div>
            <div className={style.test2}>
              <TextField maxLength='6' type='digit' autofocus='true' values={[code, codeValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.hint', defaultMessage: 'Type your code here' })} label={intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.label', defaultMessage: 'Code' })} validator={isTextMin5Char} validatorInformer={codeValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.label', defaultMessage: 'Invalid Code' })} />
            </div>
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default MobileConfirmation