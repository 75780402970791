import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl'

import { copyToClipboard, displayErrorNotificationIntl, displayGenericErrorNotificationIntl, displayInfoNotificationIntl, getCoinValue, isEmail, isNumericAndNot } from 'newui/Utils/uiUtil';

import Button from 'newui/Generic/Inputs/Button';
import { setTheme } from 'newui/Utils/themeUtils';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import TextField from 'newui/Generic/Inputs/TextField';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import ApiPersistence from 'newui/Utils/ApiPersistence';
import { convertToMoney } from 'newui/Utils/converterUtil';
import { getImageUrl } from 'newui/Utils/uiUtil';
import DivClick from 'newui/Generic/Inputs/DivClick';

function StreamerActions(props) {
    const [copied, setCopied] = useState(false);
    const [streamerDeal, setStreamerDeal] = useState(null);
    const [giveaway, setGiveaway] = useState(null);
    const [vault, setVault] = useState(null);
    const [giveAwayAmount, setGiveAwayAmount] = useState(0);
    const [giveAwayEmail, setGiveAwayEmail] = useState('');
    const [giveAwayEmailValid, setGiveAwayEmailValid] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [displayVault, setDisplayVault] = useState(false);

    var user = ApiPersistence.getInstance().getUser();

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    useEffect(() => {
        loadData();
    }, // eslint-disable-next-line
        []);

    function loadData() {
        jwtBackendService.getStreamingInfo(successGetStreamingInfoJWT, failureGetStreamingInfoJWT, navigate, location.pathname);
    }

    function successGetStreamingInfoJWT(data) {
        setCurrency(data.ga.remainingBalance.currency)
        setStreamerDeal(data.sd);
        setGiveaway(data.ga);
        setVault(data.vault);
    }

    function failureGetStreamingInfoJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }


    function getWager() {
        jwtBackendService.getBalance(successGetBalanceJWT, failureGetBalanceJWT, navigate, location.pathname);
    }


    function successGetBalanceJWT(data) {
        if (data.status === 'failure') {
            displayErrorNotificationIntl(intl, 'newcasino.giveaways.failure.wager')
        }
        else {
            displayInfoNotificationIntl(intl, 'newcasino.giveaways.success.wager')
            if (props.forceReloadTopMenu) {
                props.forceReloadTopMenu();
            }
            loadData();
        }

    }

    function failureGetBalanceJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }


    function addGiveAway() {
        giveAwayMoneyJWT()
    }

    function toggleDisplayVault() {
        setDisplayVault(!displayVault);
    }

    function giveAwayMoneyJWT() {
        jwtBackendService.giveAwayMoney(giveAwayEmail, giveAwayAmount, successGiveAwayMoneyJWT, failureGiveAwayMoneyJWT, navigate, location.pathname);
    }

    function successGiveAwayMoneyJWT(data) {

        if ((data.status && data.status === 'INVALID_EMAIL')) {
            displayErrorNotificationIntl(intl, 'newcasino.giveaways.failure.email')
        } else if ((data.status && data.status === 'NO_MONEY')) {
            displayErrorNotificationIntl(intl, 'newcasino.giveaways.failure.amount')
        } else {
            displayInfoNotificationIntl(intl, 'newcasino.giveaways.success.message')
            loadData();
        }
    }

    function failureGiveAwayMoneyJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function isGiveawayActive() {
        let toReturn = false;

        if (isEmail(giveAwayEmail) && isNumericAndNot(giveAwayAmount, 0)) {
            toReturn = giveaway.remainingBalance.balance > 0 && (giveAwayAmount <= giveaway.remainingBalance.balance);
        }
        return toReturn;
    }

    function emailValidCallBack(isValid, value) {
        setGiveAwayEmailValid(isValid);
        setGiveAwayEmail(value)
    }

    function buildReferralURL() {
        return window.location.protocol + '//' + window.location.host + '/streamer/' + user.referralId;
    }

    function copyInClipboard() {
        copyToClipboard(document, buildReferralURL())
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    if (streamerDeal) {
        return (
            <>
                <div className={style.master_page_container}>
                    <div className={style.master_grid}>
                        <div>
                            <div className={style.title_txt}>
                                <FormattedMessage id="newcasino.streamer.title" defaultMessage="Streamer" />
                            </div>
                            <img className={style.separator} alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))}></img>
                            <div>
                                <div className={style.span_spacer}>
                                    <FormattedMessage
                                        id='newcasino.streamer.info.part1' />
                                </div>

                                <div className={style.sameLineAlignment}>
                                    <div>
                                        <FormattedMessage
                                            id="newcasino.streamer.invite"
                                            defaultMessage="Invite your {viewers}"
                                            values={{
                                                viewers: (
                                                    <span className={style.txt_yellow_bold} >
                                                        <FormattedMessage
                                                            id='newcasino.streamer.invite.part1'
                                                            defaultMessage="viewers"
                                                        />
                                                    </span>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Link Line for Desktop */}
                                <div className={style.desktop}>
                                    <div className={style.bubble_dark}>
                                        <div className={style.grid_link}>
                                            <div className={style.divLink}>
                                                <span className={style.rafLink_span}>
                                                    <a href={buildReferralURL()} className={style.rafLink}>{buildReferralURL()}</a>
                                                </span>
                                            </div>
                                            <div className={style.divLink}>
                                                <Button iconTextAlign='left' colorStyle='light' icon='fa fa-clone' onClick={copyInClipboard} value={copied ? intl.formatMessage({ id: 'newcasino.streamer.copied', defaultMessage: 'Copied' }) : intl.formatMessage({ id: 'newcasino.streamer.copy', defaultMessage: 'Copy Link' })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Link Line for Mobile */}
                                <div className={style.mobile}>
                                    <div className={style.bubble_dark}>
                                        <div className={style.grid_link}>
                                            <div className={style.divLink}>
                                                <span className={style.rafLink_span}>
                                                    <a href={buildReferralURL()} className={style.rafLink}>{buildReferralURL()}</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>

                                        <div className={style.divButtonLink}>
                                            <div><Button colorStyle='light' icon='fa fa-clone' onClick={copyInClipboard} value={copied ? intl.formatMessage({ id: 'newcasino.streamer.copied', defaultMessage: 'Copied' }) : intl.formatMessage({ id: 'newcasino.streamer.copy', defaultMessage: 'Copy Link' })} /></div>
                                        </div>

                                    </div>
                                </div>
                                <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))} alt=''></img>
                                {/* Claim Line for Desktop */}
                                <div className={style.desktop}>
                                    <div className={style.sameLineAlignment}>
                                        <FormattedMessage
                                            id="newcasino.streamer.deal"
                                            defaultMessage="Your deal: {amount} - {xTime}x, {perWeek} per week"
                                            values={{
                                                amount: (
                                                    <span className={style.txt_yellow_bold} >
                                                        {convertToMoney(streamerDeal.balance, currency)}
                                                    </span>
                                                ),
                                                xTime: (
                                                    <span className={style.txt_yellow_bold} >
                                                        {streamerDeal.wager}
                                                    </span>
                                                ),
                                                perWeek: (
                                                    <span className={style.txt_yellow_bold} >
                                                        {streamerDeal.streamPerWeek}
                                                    </span>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className={style.bubble_light}>
                                        <div className={style.grid_claim}>
                                            <div className={style.divClaimLogo}>
                                                <img className={style.spinLogo} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/spin.svg"))} alt='' />
                                            </div>
                                            <div className={style.divClaim}>
                                                <div className={style.txt_yellow_bold}>
                                                    <FormattedMessage id='newcasino.streamer.money.to.claim' defaultMessage="Availaible Deals" />
                                                </div>
                                            </div>
                                            <div className={style.divClaim}>
                                                <p className={style.txt_big_number}>{streamerDeal.streamPerWeekLeft}</p>
                                            </div>
                                            <div className={style.divClaim}>
                                                <div className={style.vertLine_dark} />
                                            </div>
                                            <div className={style.divClaim}>
                                                <Button width='240px' colorStyle='light' icon='fa fa-smile-o' active={streamerDeal.streamPerWeekLeft > 0 ? true : false} onClick={getWager} value={intl.formatMessage({ id: 'newcasino.streamer.claim', defaultMessage: 'Get a wager' })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Claim Line for Mobile */}
                                <div className={style.mobile}>
                                    <div className={style.sameLineAlignment}>
                                        <FormattedMessage
                                            id="newcasino.streamer.deal"
                                            defaultMessage="Your deal: {amount} - {xTime}x, {perWeek} per week"
                                            values={{
                                                amount: (
                                                    <span className={style.txt_yellow_bold} >
                                                        {convertToMoney(streamerDeal.balance, currency)}
                                                    </span>
                                                ),
                                                xTime: (
                                                    <span className={style.txt_yellow_bold} >
                                                        {streamerDeal.wager}
                                                    </span>
                                                ),
                                                perWeek: (
                                                    <span className={style.txt_yellow_bold} >
                                                        {streamerDeal.streamPerWeek}
                                                    </span>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className={style.bubble_light}>
                                        <div className={style.grid_claim}>
                                            <div className={style.divClaimLogo}>
                                                <img className={style.spinLogo} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/spin.svg"))} alt='' />
                                            </div>
                                            <div className={style.divClaim}>
                                                <div className={style.txt_yellow_bold}>
                                                    <FormattedMessage id='newcasino.streamer.money.to.claim' defaultMessage="Availaible Deals" />
                                                </div>
                                            </div>
                                            <div className={style.divClaim}>
                                                <p className={style.txt_big_number}>{streamerDeal.streamPerWeekLeft}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={style.grid_claim_mobile}>

                                            <div className={style.divButtonLink}>
                                                <Button width='240px' colorStyle='light' icon='fa fa-smile-o' active={streamerDeal.streamPerWeekLeft > 0 ? true : false} onClick={getWager} value={intl.formatMessage({ id: 'newcasino.streamer.claim', defaultMessage: 'CLAIM MY WAGERS' })} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))} alt=''></img>

                                <div className={style.sameLineAlignment}>
                                    <FormattedMessage
                                        id="newcasino.streamer.giveaway"
                                        defaultMessage="Gift for you viewers: Available: {availableGift}"
                                        values={{
                                            availableGift: (
                                                <span className={style.txt_yellow_bold} >
                                                    {convertToMoney(giveaway.remainingBalance.balance, currency)}
                                                </span>
                                            )
                                        }}
                                    />
                                </div>
                                <div className={style.bubble_light}>
                                    <div className={style.grid_stat}>
                                        <div className={style.divStatLogo}>
                                            <img className={style.spinLogo} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/spin.svg"))} alt='' />
                                        </div>
                                        <div className={style.divStat}>
                                            <div className={style.divStatGrid2}>
                                                <div className={style.emailSize}>
                                                    <TextField maxLength='100' autofocus='false' values={[giveAwayEmail, giveAwayEmailValid]} hint={intl.formatMessage({ id: 'newcasino.giveaways.email.hint', defaultMessage: '' })} label={intl.formatMessage({ id: 'newcasino.giveaways.email.label', defaultMessage: 'Email' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.giveaways.email.error', defaultMessage: 'Invalid Email' })} />
                                                </div>
                                                <div className={style.amountTop}>
                                                    <TextField maxLength='100' autofocus='false' type='digit' values={[giveAwayAmount]} hint={intl.formatMessage({ id: 'newcasino.giveaways.amount.hint', defaultMessage: 'Amount' })} label={intl.formatMessage({ id: 'newcasino.giveaways.amount.label', defaultMessage: 'Amount' })} onChange={setGiveAwayAmount} />
                                                </div>
                                                <div className={style.buttonTop}>
                                                    <Button width='40px' active={isGiveawayActive()} colorStyle='light' value='+' onClick={addGiveAway} />
                                                </div>
                                            </div>
                                            {(giveaway && giveaway.giveAway && giveaway.giveAway.length > 0) &&
                                                <div className={style.pending}> <FormattedMessage id='newcasino.streamer.pending' defaultMessage="Pending for approval" />:</div>
                                            }
                                            {giveaway.giveAway.map((item, index) => (
                                                <div key={'receiver_' + index} className={style.divStatGrid}>
                                                    <div className={style.txt_yellow_bold}>
                                                        {item.winnerEmail}
                                                    </div>
                                                    <div className={style.info_result}>{convertToMoney(item.amount, currency)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))} alt=''></img>

                                <div className={style.sameLineAlignment2}>
                                    <FormattedMessage
                                        id="newcasino.streamer.vault"
                                        defaultMessage="Your Vault"
                                    />
                                    <div className={style.chevron}>
                                        <DivClick onClick={toggleDisplayVault}>
                                            <i className={displayVault ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true"></i>
                                        </DivClick></div>
                                </div>

                                {displayVault &&
                                    <div className={style.bubble_light}>
                                        <div className={style.grid_stat}>
                                            <div className={style.divStatLogo}>
                                                <img className={style.spinLogo} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/spin.svg"))} alt='' />
                                            </div>
                                            <div className={style.divStat}>
                                                <div className={style.divStatGrid3}>
                                                    <div className={style.txt_yellow_bold2}><FormattedMessage id='newcasino.streamer.vault.amount' defaultMessage="Your vault contains" /> </div>
                                                    <div className={style.txt_big_number}>{getCoinValue('signo', ApiPersistence.getInstance().getUser().coin)} {parseFloat(vault.vaultBalance).toFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div >
            </>
        );
    }
}

export default StreamerActions;