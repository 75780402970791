import React, { useState } from 'react'
import style from './style.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import TextField from 'newui/Generic/Inputs/TextField';
import Button from 'newui/Generic/Inputs/Button';

import { displayErrorNotification, displayInfoNotification, isEmail } from 'newui/Utils/uiUtil';
import { useIntl } from 'react-intl';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';

function ForgetPassword(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  var defaultEmail = ''

  const [email, setEmail] = useState(defaultEmail);
  const [emailValid, setEmailValid] = useState(null);

  function processResetPassword() {
    if (canReset) {
      props.spinner(true);
      jwtBackendService.passwordReset(email, callbackLoginSuccess, callbackLoginFailure, navigate, location.pathname);
    }
  }

  function callbackLoginSuccess(data) {
    props.spinner(false);
    displayInfoNotification(intl, 'newcasino.aff.auth.forget.success')
    props.showLogin();
  }

  function callbackLoginFailure(data) {
    props.spinner(false);
    displayErrorNotification(intl, 'newcasino.aff.auth.forget.fail')
  }

  function emailValidCallBack(isValid, value) {
    setEmailValid(isValid);
    setEmail(value)
  }

  function canReset() {
    return (emailValid)
  }

  try {
    return (
      <div className={style.login_mainGrid}>
        <div className={style.login_title}>{intl.formatMessage({ id: 'newcasino.aff.auth.forget.title', defaultMessage: 'Your forget your password?' })}</div>
        <div className={style.login_spacer} />

        <div className={style.login_text}>
          {intl.formatMessage({ id: 'newcasino.aff.auth.forget.sub.title', defaultMessage: 'No problem, type your email and you will receive an email to reset it.' })}
        </div>
        <div className={style.login_input}>
          <TextField maxLength='50' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' autofocus='true' topTitleStyle={style.inputTitle} values={[email, emailValid]} hint={intl.formatMessage({ id: 'newcasino.aff.auth.login.email.hint', defaultMessage: 'Type your email here' })} label={intl.formatMessage({ id: 'newcasino.aff.auth.login.email', defaultMessage: 'Email address' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.login.email.err', defaultMessage: 'Invalid Email' })} />
        </div>
        <div className={style.login_grid50}>
          <div className={style.login_buttonLine}>
            <Button width='180px' height='32px' paddingTop='8px' font='normal normal 500 12px/15px Montserrat' onClick={props.showLogin} value={intl.formatMessage({ id: 'newcasino.aff.auth.forget.button.back', defaultMessage: 'Back' })} colorStyle='white' />
          </div>
          <div className={style.login_buttonLineRight}>
            <Button width='180px' height='32px' paddingTop='8px' font='normal normal 500 12px/15px Montserrat' active={canReset()} value={intl.formatMessage({ id: 'newcasino.aff.auth.forget.button.reset', defaultMessage: 'Reset Password' })} colorStyle='light' onClick={() => processResetPassword()} />
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}
export default ForgetPassword