import React, { useEffect, useState } from 'react'
import * as constants from 'newui/constants.js'
import * as backEndService from 'newui/Services/Backend/backendService';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { canAccess } from 'newui/Utils/authorizationUtils'
import { setTheme, saveMapToLocalSetting, getMapFromLocalSetting, createBorderMenuDescription } from 'newui/Utils/uiUtil'
import { displayPage } from 'newui/Utils/routerUtil'
import { useNavigate, useLocation } from 'react-router-dom'
import ApiPersistence from 'newui/Utils/ApiPersistence';
import DivClick from 'newui/Generic/Inputs/DivClick';
import ApiCache from 'newui/Utils/ApiCache';
import Switch from 'newui/Generic/Inputs/Switch';
import ComboBox from 'newui/Generic/Inputs/ComboBox';

function BorderMenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const [currentLanguage, setCurrentLanguage] = useState(ApiPersistence.getInstance().getLanguage());
  const [menuCategories, setMenuCategories] = useState(null);
  const [selectionMap, setSelectionMap] = useState(new Map());
  const [categoryClosed, setCategoryClosed] = useState((getMapFromLocalSetting("BORDERMENU_STATE") !== null) ? getMapFromLocalSetting("BORDERMENU_STATE") : new Map());
  var showStyle = style.show;
  const methodCalls = new Map();
  const user = ApiPersistence.getInstance().getUser()

  methodCalls.set('switchLanguage', switchLanguage)


  if (!props.display) {
    showStyle = style.noShow;
  }


  useEffect(() => {
    getAllGamesInformation()
  },// eslint-disable-next-line
    [])



  function figureOutShowedTab(currentRoute, definition) {

    var toReturn = new Map();

    for (let i = 0; i < definition.categories.length; i++) {
      var currentCat = definition.categories[i];
      for (let j = 0; j < currentCat.elements.length; j++) {
        var currentElem = currentCat.elements[j];
        if (currentElem.action === currentRoute) {
          toReturn.set(i.toString(), j.toString())
        }
      }
    }
    return toReturn;

  }

  function onSuccessGetAllGamesInformation(newDefinition) {

    var baseDefinition = JSON.parse(JSON.stringify(props.definition));

    let data = {
      gamescategory: newDefinition[1],
      games: newDefinition[2],
      providers: newDefinition[3],
      gameTypes: newDefinition[4]
    }
    backEndService.setInCache(backEndService.CACHE_CASINO_DATA, newDefinition, ApiCache.getInstance())
    var g = createBorderMenuDescription(data, baseDefinition);
    setMenuCategories(g.categories);
    setSelectionMap(figureOutShowedTab(location.pathname, g))

  }

  function onFailureGetAllGamesInformation(error) {
    displayPage(constants.ROUTE_500, navigate);
  }

  function getAllGamesInformation() {
    return backEndService.getBorderMenuGamesInformation(ApiPersistence.getInstance().isLogged(), props.definition, onSuccessGetAllGamesInformation, onFailureGetAllGamesInformation, navigate, location.pathname)
  }

  function getImageToShow(catIndex, elemIndex, elementObj) {
    var toReturn = elementObj.images.notSelected;

    if (isSelected(catIndex, elemIndex)) {
      toReturn = elementObj.images.selected;
    }

    return setTheme(toReturn);

  }

  function newSelection(catIndexParam, elemIndexParam, elementObj) {
    const catIndex = catIndexParam.toString();
    const elemIndex = elemIndexParam.toString();

    if (props.toggleUserMenu) {
      props.toggleUserMenu(false);
    }

    var newMap = new Map()
    newMap.set(catIndex, elemIndex)
    setSelectionMap(newMap)

    if (elementObj.action) {
      if (elementObj.action.type === 'MODAL_MESSAGE') {
        const title = intl.formatMessage({
          id: elementObj.action.title.intl,
          defaultMessage: elementObj.action.title.default
        })

        const msg = intl.formatMessage({
          id: elementObj.action.intl,
          defaultMessage: elementObj.action.default
        })

        props.displayModalMsg(false, title, msg, null);
      } else if (elementObj.action.type === 'DYNAMIC_FUNCTION') {
        methodCalls.get(elementObj.action.function)();

      } else if (elementObj.action.type === 'HOME_PAGE_SPECIFIC') {
        props.requestHomeSpecificDisplay("BORDER_MENU", elementObj);
        displayPage(constants.ROUTE_HOME, navigate, location.pathname);
        if (props.isMobile) {
          props.toggleLeftMenu(false)
        }
      } else {

        displayPage(elementObj.action, navigate);
        if (props.isMobile) {
          props.toggleLeftMenu(false)
        }
      }
    }
  }

  function isSelected(catIndexParam, elemIndexParam) {
    const catIndex = catIndexParam.toString();
    const elemIndex = elemIndexParam.toString();

    var toReturn = false;
    if (selectionMap.get(catIndex) !== null && selectionMap.get(catIndex) === elemIndex) {
      toReturn = true;
    }

    return toReturn
  }

  function userSearchFocus(event) {
    if (event.currentTarget.value.trim().length > 0) {
      userSearch(event);
    }
  }

  function userSearch(event) {
    var title1 = intl.formatMessage({
      id: 'newcasino.search.result.title1',
      defaultMessage: 'Search Results -'
    })
    var title2 = intl.formatMessage({
      id: 'newcasino.search.result.title2',
      defaultMessage: 'item(s)'
    })

    props.displaySearchResultModal(title1, title2, event.currentTarget.value, null)
  }

  function getElementMapStyle(catIndex, elemIndex) {
    var toReturn = [style.borderMenu_elemBar_color_notSelected, style.borderMenu_elemBar_title_notSelected];

    if (isSelected(catIndex, elemIndex)) {
      toReturn = [style.borderMenu_elemBar_color_selected, style.borderMenu_elemBar_title_selected];
    }

    return toReturn;
  }

  function isCategoryClosed(index) {
    var toReturn = false;
    try {
      const catIndex = index.toString();

      if (categoryClosed.get(catIndex)) {
        toReturn = true;
      }

    } catch (error) {
      setCategoryClosed(new Map())
    }

    return toReturn;
  }

  function mustDisplay(item) {
    var toReturn = true;

    if (item.specificDisplay) {
      if (item.specificDisplay === 'mobile') {
        toReturn = props.isMobile;
      }
    }

    return toReturn;
  }


  function haveSubMenu(theUser, item) {

    var toReturn = false;
    for (let i = 0; i < item.elements.length; i++) {
      var currentElement = item.elements[i];
      if (mustDisplay(currentElement) && canAccess(theUser, currentElement)) {
        toReturn = true;
        break;
      }
    }

    return toReturn;
  }

  function toggleCategory(index) {
    const catIndex = index.toString();
    var newMap = new Map(categoryClosed)
    if (isCategoryClosed(catIndex)) {
      newMap.delete(catIndex)
    } else {
      newMap.set(catIndex, catIndex);
    }

    saveMapToLocalSetting("BORDERMENU_STATE", newMap);
    setSelectionMap(newMap);

    setCategoryClosed(newMap);
  }

  function darkMode(value) {
    if (value) {
      ApiPersistence.getInstance().setTheme('dark');
    }
    else {
      ApiPersistence.getInstance().setTheme('normal');
    }
    window.location.reload();
  }

  function getLanguageList(item) {
    let theList = [];


    for (let i = 0; i < item.type.languages.length; i++) {
      theList.push({ key: item.type.languages[i].code, value: intl.formatMessage({ id: item.type.languages[i].intl, defaultMessage: item.type.languages[i].default }) });
    }

    return { list: theList, key: 'key', value: 'value' }
  }

  function switchLanguage(item) {
    setCurrentLanguage(item)
    props.setLanguage(-1, item);

  }

  try {

    if (props.forceHide === false) {
      if (menuCategories) {
        return (
          <div className={`${style.borderMenu_div} ${showStyle}`}>
            {/* DARK MODE SWITCH */}
            <div className={`${style.borderMenu_row}`}>
              <div className={style.switchGrid}>
                <div className={style.switchText}>{intl.formatMessage({ id: 'newcasino.menuleft.darkmode', defaultMessage: 'Dark Mode' })}</div>
                <Switch onClick={darkMode} onValue='ON' offValue='OFF' initialValue={ApiPersistence.getInstance().getTheme() === 'dark'} />
              </div>
            </div>
            {/* SEARCH BAR */}
            <div className={`${style.borderMenu_row}`}>
              <div className={`${style.borderMenu_searchBar} ${style.borderMenu_search_grid}`}>
                <div className={`${style.borderMenu_searchBar_input}`}>
                  <input maxLength={50} className={`${style.borderMenu_searchBar_input_tag}`} placeholder={intl.formatMessage({ id: 'newcasino.homegame.search', defaultMessage: 'Search Casino' })} onFocus={(e) => userSearchFocus(e)} onChange={(e) => userSearch(e)} />
                </div>
                <div className={`${style.borderMenu_searchBar_search}`}><i className="fa fa-search" aria-hidden="true"></i></div>
              </div>
            </div>
            <div className={`${style.scrollableBorder}`}>
              {/* CATEGORY TITLE */}
              {menuCategories.map((item, index) => (haveSubMenu(user, item)) ? (
                <div key={'borderMenuCat_' + index}>
                  <div className={`${style.borderMenu_row}`}>
                    <div className={`${style.borderMenu_catBar} ${style.borderMenu_cat_grid}`}>
                      <div className={`${style.borderMenu_catBar_title}`}>
                        <FormattedMessage
                          id={item.intl}
                          defaultMessage={item.default}
                        />
                      </div>
                      {isCategoryClosed(index) &&
                        <DivClick className={`${style.borderMenu_catBar_arrow}`} onClick={() => toggleCategory(index)}><i className="fa fa-chevron-down" aria-hidden="true"></i></DivClick>
                      }
                      {!isCategoryClosed(index) &&
                        <DivClick className={`${style.borderMenu_catBar_arrow}`} onClick={() => toggleCategory(index)}><i className="fa fa-chevron-up" aria-hidden="true"></i></DivClick>
                      }
                    </div>
                  </div>
                  {/* CATEGORY ELEMENT NORMAL ELEMENT*/}
                  {(!isCategoryClosed(index)) &&
                    menuCategories[index].elements.map((itemElem, indexElem) => (mustDisplay(itemElem) && (canAccess(user, itemElem)) ? (
                      <DivClick key={'borderMenuCat_' + index + 'Elem_' + indexElem} className={`${((itemElem.type) && itemElem.type.name === 'LANGUAGE_SWITCHER') ? style.borderMenuElem_rowCombo : style.borderMenuElem_row} ${getElementMapStyle(index, indexElem)[0]}`} onClick={(itemElem.onClick === false) ? null : () => newSelection(index, indexElem, itemElem)}>
                        <div className={`${style.borderMenu_elemBar} ${style.borderMenu_elem_grid}`}>
                          <div className={`${style.borderMenu_elemBar_image}`}>
                            <div className={`${((itemElem.type) && itemElem.type.name === 'LANGUAGE_SWITCHER') ? style.image_transCombo : style.image_trans}`} style={{
                              backgroundImage: getImageToShow(index, indexElem, itemElem)
                            }} />
                          </div>
                          <div className={`${getElementMapStyle(index, indexElem)[1]}`}>
                            {(!(itemElem.type)) &&
                              <FormattedMessage
                                id={itemElem.intl}
                                defaultMessage={itemElem.default}
                              />
                            }
                            {((itemElem.type) && (itemElem.type.name === 'LANGUAGE_SWITCHER')) &&
                              <ComboBox values={[currentLanguage]} valueList={getLanguageList(itemElem)} onChange={switchLanguage} />
                            }

                          </div>
                        </div>
                      </DivClick>

                    ) : ''
                    )
                    )}
                </div>

              ) : '')}</div>
          </div>
        );
      }
    } else {
      return (
        <></>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default BorderMenu
