import { useEffect } from 'react'



function Slash(props) {
  useEffect(() => {
    //window.location.href = '/';
  },
    [])

}

export default Slash
