/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'

function ProgressBar(props) {

    const intl = useIntl()

    function getPercentage() {
        let perc = props.percentage * 100;

        if (perc > 100) {
            return '100%';
        }

        if (perc <= 0) {
            return '0%';
        }

        return perc + '%';
    }

    return (
        <>
            <div className={style.bg}>
                <div className={style.line} style={{ width: getPercentage() }}>
                    <div className={style.pointpad}>
                        {(props.percentage > 0) &&
                            <div className={style.point} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProgressBar;
