import { useEffect } from 'react';
import { useIntl } from 'react-intl'
import { displayErrorNotificationIntl } from 'newui/Utils/uiUtil';

function System500(props) {
    const intl = useIntl()

    useEffect(() => {
        displayErrorNotificationIntl(intl, 'newcasino.error.500');
    });
}

export default System500;