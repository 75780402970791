
import ApiPersistence from './ApiPersistence';
import * as constants from 'newui/constants';
import { createIntl, createIntlCache } from "react-intl";
import english from 'newui/locales/en-US'
import french from 'newui/locales/fr-FR'
import portuguese from 'newui/locales/pt-BR'
import { enqueueSnackbar } from 'notistack';

export function getImageUrl(path) {
    return 'https://playnumbersgame.com/images/general/' + path;
}

export function isOverEighteen(day, month, year) {
    var now = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
    var dob = year * 10000 + month * 100 + day * 1; // Coerces strings to integers

    return now - dob > 180000;
}

export function isInFuture(day, month, year) {
    var now = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
    var date = year * 10000 + month * 100 + day * 1; // Coerces strings to integers

    return now < date;
}

export function inNotMinusOne(value) {
    return value !== '-1';
}

export function isHourHHMM(field) {
    var toReturn = false;
    if (isText(field)) {
        var reg = new RegExp(/^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/)
        //var reg = new RegExp(/(0[0-9]|[1][0-9]|2[0-3])[: /.](0[0-9]|[1][0-9]|2[0-3])[: /.](0[0-9]|[1][0-9]|2[0-3])\d\d/)
        toReturn = reg.test(field);
    }

    return toReturn;
}

export function isDateDDMMYYYY(field) {
    var toReturn = false;
    if (isText(field)) {

        var reg = new RegExp(/(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/)
        toReturn = reg.test(field);
    }

    return toReturn;
}

export function isDateString(field) {
    var toReturn = false;
    if (isText(field)) {

        var reg = new RegExp(/(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/)
        toReturn = reg.test(field);
    }

    return toReturn;
}

export function isDateStringInFuture(field) {
    var toReturn = isDateString(field);

    if (toReturn) {
        let values = field.split('/')
        toReturn = isInFuture(parseInt(values[0]), parseInt(values[1]), parseInt(values[2]));
    }

    return toReturn;
}

export function isDateStringOver18(field) {
    var toReturn = isDateString(field);

    if (toReturn) {
        let values = field.split('/')
        toReturn = isOverEighteen(parseInt(values[0]), parseInt(values[1]), parseInt(values[2]));
    }

    return toReturn;
}

export function isTextMin2Char(field) {
    var toReturn = false;

    if (isText(field) && field.trim().length > 1) {
        toReturn = true;
    }
    return toReturn;
}

export function isTextMin5Char(field) {
    var toReturn = false;

    if (isText(field) && field.trim().length > 4) {
        toReturn = true;
    }
    return toReturn;
}

export function isTextMin1Char(field) {
    var toReturn = false;

    if (isText(field) && field.trim().length > 0) {
        toReturn = true;
    }
    return toReturn;
}

export function isPasswordOk(field) {
    const MINCHAR = 8;

    if (field === undefined || field === null) {
        return false;
    }

    if (field.trim().length < MINCHAR) {
        return false;
    }

    if (field.toUpperCase() === field) {
        return false;
    }

    if (field.toLowerCase() === field) {
        return false;
    }

    return true;
}

export function isEmail(field) {
    var toReturn = isText(field)

    if (toReturn) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(field)) {
            toReturn = true;
        } else {
            toReturn = false;
        }
    }

    return toReturn;
}

export function isNumericAndNot(field, value) {

    if (!isNumeric(field)) {
        return false;
    }

    return (parseInt(field) !== value);

}
export function isNumeric(field) {
    try {
        if (field === undefined || field === null) {
            return false;
        }

        var data = ('' + field).trim();
        if (data.length === 0 || isNaN(data)) {
            return false;
        }

    } catch (error) {
        return false;
    }

    return true;
}

export function nullToEmpty(value) {
    let toReturn = value;
    if (value === null || value === undefined) {
        toReturn = '';
    }

    return toReturn;
}

export function nullToMinusOne(value) {
    let toReturn = value;
    if (value === null || value === undefined) {
        toReturn = -1;
    }

    return toReturn;
}


export function isText(field) {
    try {
        if (field === undefined || field === null) {
            field = '';
        }
        var data = ('' + field).trim();
        if (data.length === 0) {
            return false;
        }
    } catch (error) {
        return false;
    }

    return true;
}

export function saveMapToLocalSetting(name, theMap) {
    saveToLocalSetting(name, JSON.stringify(Array.from(theMap.entries())));
}

export function saveToLocalSetting(name, value) {
    try {
        if (name !== null && name !== undefined) {
            if (value !== null && value !== undefined) {
                localStorage.setItem(name, value);
            } else {
                deleteFromLocalSetting(name);
            }
        }
    } catch (error) { }
}

export function getMapFromLocalSetting(name) {
    try {
        return new Map(JSON.parse(getFromLocalSetting(name)));
    } catch (error) {
        return new Map();
    }
}


export function getFromLocalSetting(name) {
    var toReturn = null;
    try {

        if (name !== null && name !== undefined) {
            toReturn = localStorage.getItem(name);
        }
    } catch (error) {
        deleteFromLocalSetting(name);
    }
    return toReturn;
}

export function txToText(txId, intl) {
    let toReturn = intl.formatMessage({ id: ('newcasino.wallet.tx.type.' + txId), defaultMessage: '??' });
    return toReturn;
}

export function getColor(text) {
    let arraycolors = [
        { color: 'red', texto: 'failed' },
        { color: 'blue', texto: 'created' },
        { color: 'cyan', texto: 'pending' },
        { color: 'green', texto: 'success' },
        { color: 'red', texto: 'withdrawal btc' },
        { color: 'gold', texto: 'Lottery Ticket' },
        { color: 'green', texto: 'BTC Deposit' },
        { color: 'MediumSeaGreen', texto: 'Credit Card Deposit' },
        { color: 'red', texto: 'Credit Card Withdrawal' },
        { color: 'MediumAquaMarine', texto: 'confirmed' },
    ]

    let colorselected = arraycolors.filter(item => {
        return item.texto === text
    })

    if (colorselected[0] === undefined) {
        return 'cyan'
    }

    return colorselected[0].color
}

export function listToCSV(theList) {
    return theList.replace(/\n/g, ",");
}

export function getAmountTxtWithCoin(amount, coin) {
    var txt = '' + amount;
    if (isNumeric(txt)) {
        return getCoin('signo', coin) + ' ' + parseFloat(txt).toFixed(2);
    }

    return '';
}

export function getAmountTxt(amount) {
    var txt = '' + amount;
    if (isNumeric(txt)) {
        return getCoin('signo') + ' ' + parseFloat(txt).toFixed(2);
    }

    return '';
}

export function copyToClipboard(doc, data) {
    const dummy = doc.createElement('input');
    doc.body.appendChild(dummy);
    dummy.value = data;
    dummy.select();
    doc.execCommand('copy');
    doc.body.removeChild(dummy);
}

export function getLength(data) {
    if (data === null || data === undefined) {
        return 0;
    }

    return (data + '').length;
}

export function getCoin(type, specificCoin) {
    return getCoinValue(type, specificCoin ? specificCoin : ApiPersistence.getInstance().getUser().coin);
}

export function getCoinValue(type, coin) {
    //type: signo, texto
    try {
        let selected = coin;
        let coins = [
            { signo: '€', texto: 'EUR' },
            { signo: '$', texto: 'USD' },
            { signo: 'R$', texto: 'BRL' },
            { signo: '$', texto: 'CAD' },
            { signo: 'A$', texto: 'AUD' },
        ]

        let coinselected = coins.filter(item => {
            return item.texto === selected
        })

        if (type === 'signo') return coinselected[0].signo
        else return coinselected[0].texto
    } catch (error) {
        console.log('error signo');
    }
    return '';
}

export function deleteFromLocalSetting(name) {
    if (name !== null && name !== undefined) {
        localStorage.removeItem(name);
    }
}

export function getInterText(key, defaultValue) {
    var toReturn = defaultValue;

    try {

        var lang = 'en-US';
        const locales = {
            'en-US': english,
            'fr-FR': french,
            'pt-BR': portuguese,
        }

        try {
            lang = ApiPersistence.getInstance().getLanguage()
        } catch (errr) { }

        var currentLocale = locales[lang];

        const cache = createIntlCache()
        const intl = createIntl({
            locale: lang,
            messages: currentLocale.messages
        }, cache)
        toReturn = intl.formatMessage({
            id: key,
            defaultMessage: defaultValue,
        })

    } catch (error) { }

    return toReturn;
}


export function displayGenericErrorNotificationIntl(intl) {
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title' }), intl.formatMessage({ id: 'newcasino.generic.error.msg' }));
}

export function displayErrorNotificationIntl(intl, key) {
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title' }), intl.formatMessage({ id: key }));
}

export function displayInfoNotificationIntl(intl, key) {
    displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title' }), intl.formatMessage({ id: key }));
}

function displayNotification(type, title, theText) {

    var jsonOption = {
        autoHideDuration: 10000,
        preventDuplicate: true,
        variant: type,
        anchorOrigin: {
            horizontal: 'center',
            vertical: 'top'
        }
    }

    enqueueSnackbar(theText, jsonOption)
}

export function displayErrorNotification(title, theText) {
    displayNotification('error', title, theText);
}

export function displayInfoNotification(title, theText) {
    displayNotification('info', title, theText);
}

export function getScreenWidth() {
    return window.innerWidth;
}

export function isMobile() {
    return window.innerWidth <= constants.MOBILE_SCREEN_SIZE_WIDTH_PIXEL;
}

export function isSafariBrowser() {
    return navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
}

export function setTheme(stringToCheck) {
    var theme = document.documentElement.getAttribute('data-theme');

    if (!theme) {
        theme = 'normal';
    }

    return stringToCheck.replace("{THEME}", theme);
}

export function urlToBackgroundUrl(url) {
    return "url('" + url + "')";
}

export function urlToBackgroundNoFoundHandling(noimage, url) {
    return "url('" + noimage + "'), url('" + url + "')";
}

function getFilteredGames(data, searchData) {
    const field = searchData.field;
    const operation = searchData.operation;
    const value = searchData.value;
    const returnType = searchData.returnType;


    var toReturn = new Map();

    var isMap = true
    if (returnType === 'ARRAY') {
        isMap = false;
    }

    data.games.forEach((game, index) => {
        var mapIndex = game[field] + '';
        if (!isMap) {
            mapIndex = 'ALL';
        }



        var currentField = game[field] + '';
        var currentGameId = game.game_uuid + '';
        var needAccountToPlay = (game.needAccountToPlay === 1) ? true : false
        var freeSpinAllowed = (game.freeSpinAllowed === 1) ? true : false
        var currentGameImageUrl = game.game_image_url;

        if (currentField && currentGameId && currentGameImageUrl) {

            var addIt = true;

            if (operation === 'CONTAINS') {
                addIt = currentField.trim().toUpperCase().includes(value.trim().toUpperCase());
            }

            if (operation === 'EQUALS') {
                var operationField = searchData.operationField;
                var operationValue = searchData.operationValue;

                addIt = game[operationField] === operationValue;
            }



            if (addIt) {
                var gameListForField = toReturn.get(mapIndex);
                if (!gameListForField) {
                    gameListForField = [];
                    toReturn.set(mapIndex, gameListForField);
                }



                gameListForField.push(
                    {
                        gameId: currentGameId,
                        image: currentGameImageUrl,
                        needAccountToPlay: needAccountToPlay,
                        freeSpinAllowed: freeSpinAllowed
                    }
                );
            }
        }
    });

    if (returnType === 'ARRAY') {
        var theArray = toReturn.get('ALL');
        if (!theArray) {
            theArray = [];
        }

        toReturn = theArray;
    }
    return toReturn;
}


function getGamesByName(data, keyword) {

    return getFilteredGames(data,
        {
            field: 'name',
            operation: 'CONTAINS',
            value: keyword,
            returnType: 'ARRAY'
        }
    )
}

function getNewGamesByTypes(data, field) {

    return getFilteredGames(data,
        {
            field: field,
            operation: 'EQUALS',
            operationField: 'isNew',
            operationValue: 1,
            returnType: 'MAP'
        }
    )
}

function getGamesByFieldMap(data, field) {

    return getFilteredGames(data,
        {
            field: field,
            returnType: 'MAP'
        }
    )
}

function getArrayByField(data, field) {
    var found = new Map();
    var toReturn = [];
    data.forEach((cat, index) => {
        var id = cat[field] + '';

        if (!(found.get(id))) {
            found.set(id, id);
            toReturn.push(cat);
        }

    });

    return toReturn;
}


function getMapByField(data, field) {
    var toReturn = new Map();
    data.forEach((cat, index) => {
        var key = cat[field] + '';
        toReturn.set(key, cat);
    });

    return toReturn;
}

export function createManagePageGamesDescription(data) {
    /*
    gamesCategory: item[0],
    gamesTypes: item[1],
    gamesSubTypes: item[2],
    games: item[3],
    providers: item[4],
    subTypePossiblities: null
*/
    var theMap = new Map();
    data.gamesSubTypes.forEach((subType, index) => {
        var theList = theMap.get((subType.type + ''));
        if (!theList) {
            theList = [];
        }
        theList.push(subType);
        theMap.set((subType.type + ''), theList);
    });

    data.subTypePossiblities = theMap;
    return data;
}


export function createHomePageGamesDescription(data, baseDefinition) {
    //Games
    var categoryMap = getMapByField(data.gamescategory, 'id');
    var categoryArray = getArrayByField(data.gamescategory, 'id');
    var gamesByCategoriesMap = getGamesByFieldMap(data, 'category_id');


    categoryArray.forEach((category, index) => {
        var categoryId = '' + category.id
        if (gamesByCategoriesMap.get(categoryId)) {
            var newConfigCategory = {
                intl: ('newcasino.games.category.code_' + categoryMap.get(categoryId).code),
                code: categoryMap.get(categoryId).code,
                default: "Games",
                images: {
                    'selected': ("url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_CATEGORY_" + categoryMap.get(categoryId).code + "-sel.png')"),
                    'notSelected': ("url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_CATEGORY_" + categoryMap.get(categoryId).code + ".png')"),
                },
                list: gamesByCategoriesMap.get(categoryId)
            };
            baseDefinition.games.push(newConfigCategory);
        }
    })

    //Providers
    var providerMap = getMapByField(data.providers, 'id');
    var gamesByProvidersMap = getGamesByFieldMap(data, 'provider_id');

    var providerKeys = Array.from(gamesByProvidersMap.keys());

    providerKeys.forEach((providerId, index) => {

        if (providerMap.get(providerId)) {
            var currentProvider = providerMap.get(providerId);
            var newConfigProviders = {
                intl: ('newcasino.games.provider.code_' + currentProvider.provider_id),
                default: currentProvider.name,

                image: getImageUrl("newui/providers/logo_provider_" + currentProvider.provider_id + ".png"),
                images: {
                    'selected': ("url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_PROVIDER-sel.png')"),
                    'notSelected': ("url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_PROVIDER-sel.png')")
                },
                action: {
                    "type": "HOME_PAGE_SPECIFIC",
                    "gameList": gamesByProvidersMap.get(providerId)
                }
            };
            baseDefinition.providers.list.push(newConfigProviders);
        }
    })

    return baseDefinition;
}

export function createBorderMenuDescription(data, baseDefinition) {
    var typeMap = getMapByField(data.gameTypes, 'typeId');
    //var subTypeMap = getMapByField(data.gameTypes, 'subTypeId');

    var subTypeArray = getArrayByField(data.gameTypes, 'subTypeId');
    var typeArray = getArrayByField(data.gameTypes, 'typeId');
    var gamesBySubTypeMap = getGamesByFieldMap(data, 'subType');
    var typeContent = new Map();

    //subTypeKeys.forEach((subTypeId, index) => {
    subTypeArray.forEach((subType, index) => {

        var typeId = subTypeArray[index].typeId + '';
        var subTypeId = subTypeArray[index].subTypeId + '';
        if (gamesBySubTypeMap.get(subTypeId)) {
            var newConfigSubType = {
                intl: ('newcasino.games.subtype.code_' + subTypeArray[index].subTypeCode),
                default: (subTypeArray[index].subTypeName),
                authorization: {
                    "access": "public",
                    "typeAccount": [],
                    "rols": []
                },
                images: {
                    'selected': ("url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_SUBTYPE_" + subTypeArray[index].subTypeCode + "-sel.png')"),
                    'notSelected': ("url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_SUBTYPE_" + subTypeArray[index].subTypeCode + ".png')"),
                },
                action: {
                    "type": "HOME_PAGE_SPECIFIC",
                    "gameList": gamesBySubTypeMap.get(subTypeId)
                }
            };

            var children = typeContent.get(typeId);
            if (!children) {
                children = [];
            }

            children.push(newConfigSubType);
            typeContent.set(typeId, children);
        }
    })


    //var typeContentKeys = Array.from(typeContent.keys());

    //typeContentKeys.forEach((typeId, index) => {
    typeArray.reverse().forEach((type, index) => {
        var typeId = typeArray[index].typeId + '';

        var stringTypeId = typeId + '';

        if (typeContent.get(stringTypeId)) {
            //Check For New Games
            var newGamesByTypeMap = getNewGamesByTypes(data, 'typeId');

            var newGamesForType = newGamesByTypeMap.get(stringTypeId);

            if (newGamesForType && newGamesForType.length > 0) {

                var newGames = {
                    intl: ('newcasino.games.newgame'),
                    default: 'New Games',
                    authorization: {
                        "access": "public",
                        "typeAccount": [],
                        "rols": []
                    },
                    images: {
                        'selected': "url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_Stars-sel.png')",
                        'notSelected': "url('" + getImageUrl('newui') + "/themes/{THEME}/BorderMenu/IMG_Stars.png')"
                    },
                    action: {
                        "type": "HOME_PAGE_SPECIFIC",
                        "gameList": newGamesForType
                    }
                };

                typeContent.get(stringTypeId).unshift(newGames)
                //newConfigCategory.unshift(newGames)

            }

            var newConfigCategory = {
                intl: ('newcasino.games.type.code_' + typeMap.get(stringTypeId).typeCode),
                default: typeMap.get(stringTypeId).typeName,
                images: "url('" + getImageUrl('newui') + "/themes/{THEME}/MobileBottomMenu/IMG_Slot-Machine-sel.png')",
                elements: typeContent.get(stringTypeId)
            };
            baseDefinition.categories.unshift(newConfigCategory);
        }
    })


    /////////////////////////////////


    return baseDefinition;

}

export function searchGames(data, keyword) {
    if (keyword.trim().length === 0) {
        return [];
    }

    return getGamesByName(data, keyword);
}


export function getCashierColorPerTheme(color) {



    if (ApiPersistence.getInstance().getTheme() === 'dark') {
        if (color === '422C6C') {
            return '#1A1A1A'
        }

        if (color === 'FBBA20') {
            return '#FBBA20'
        }

        if (color === '614D86') {
            return '#262626'
        }
    }
    return '#' + color;

}

export function getCashierCSS(isMobile, lang) {
    let toReturn = '';
    //Present on Main Page of paymentIQ
    const paymentData1 = 'data-v-dca9ba5c'; //OK
    const paymentData2 = 'data-v-1899b53e'; //OK
    const transactionTypeData = 'data-v-cfcf5d5a' //OK
    const paiementMethodHeaderData = 'data-v-efccc034' //OK
    const amountButtonData = 'data-v-4cdae88e' //'data-v-2513890a' //OK
    //Once on click on crypto
    const cryptoDropBoxData = 'data-v-5ec42852' //OK

    //On crypto page with QR Code
    const cryptoData = 'data-v-3b99674a';   //OK


    const pendingTransactionData = 'data-v-186064a9';        //YES    
    const pendingTransactionNoticeData = 'data-v-6b1622cd' //NO
    const pendingTransactionSummaryRowData = 'data-v-4666080b' //NO
    const pendingTransactionReceipData = 'data-v-e15371d8'; //'data-v-be7199b6' //NO
    const separatorMiddleData = 'data-v-99fbf274' //NO
    const providerRedirectData = 'data-v-35cacf9b' //NO

    /**********************************
     * Amount Buttons Modification
     *********************************/
    toReturn = toReturn + `\n\n
    .set-amount .predefinedvalues button[`+ amountButtonData + `]{
      background: `+ getCashierColorPerTheme('422C6C') + ` 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      color: `+ getCashierColorPerTheme('FBBA20') + `;
    }

    .set-amount .predefinedvalues button.active[`+ amountButtonData + `]{
      background: `+ getCashierColorPerTheme('FBBA20') + ` 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      color: `+ getCashierColorPerTheme('422C6C') + `;
    }`

    /**********************************
     * Transaction Types
     *********************************/
    toReturn = toReturn + `\n\n
    .tabs .tab[`+ transactionTypeData + `]:after {
      display: none;
    }

    .tabs.horizontal-mode-tab[`+ transactionTypeData + `] {
      margin-top: 0px;
    }

    #cashier div[`+ transactionTypeData + `] h3 {
        font-family: "Montserrat", sans-serif !important;
        letter-spacing: 0px;
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
        opacity: 1;
        font-style: normal;
        font-variant: normal;
        letter-spacing: 0px;
    }

    .tabs .tab.active[`+ transactionTypeData + `] {
        font-family: "Montserrat", sans-serif !important;
        font-weight: bold !important;
        font-size: 14px;
        color: `+ getCashierColorPerTheme('FBBA20') + ` !important;
        opacity: 1;
    }
  `

    /**********************************
    * Payment Methods
    *********************************/
    toReturn = toReturn + `\n\n
      .flex-row {
        flex-direction: row-reverse;
    }

    .payment-method-details-header[`+ paiementMethodHeaderData + `] {
        display: none;
    }

    .account .logo-container[`+ paymentData2 + `], .logo-container.list-view-logo-container[` + paymentData2 + `] {
        justify-content: flex-start;
    }

    .list-has-radio.app_default .payment-method.active .payment-method-header[`+ paymentData1 + `]:after {
        display: none;
    }

    .payment-method-label {
        font-family: "Montserrat", sans-serif !important;
        font-weight: bold !important;
        font-size: 16px !important;
        color: #FFFFFF !important;
        opacity: 1;
    }

    .payment-method{
        background: `+ getCashierColorPerTheme('422C6C') + ` 0% 0% no-repeat padding-box;
        border-radius: 12px;
        opacity: 1;
        margin-bottom: 16px;
    }

    .payment-method.active{
        background: `+ getCashierColorPerTheme('614D86') + ` 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 16px #0000001F;
        border: 2px solid `+ getCashierColorPerTheme('FBBA20') + `;
        border-radius: 12px;
        opacity: 1;
    }

    .layout-horizontal .payment-method-header[`+ paymentData1 + `]:before, .list-has-radio .payment-method-header[` + paymentData1 + `]:before {
        content: "";
        min-width: 24px;
        width: 24px;
        height: 24px;
        position: relative;
        border: 0px;
        background: transparent url('https://playnumbersgame.com/images/general/paymentiq/IMG_Circle.png') 0% 0% no-repeat padding-box;
    }

    .list-has-radio.app_default .payment-method.active .payment-method-header[`+ paymentData1 + `]:before {
        background: transparent url('https://playnumbersgame.com/images/general/paymentiq/IMG_Circle-Check.svg') 0% 0% no-repeat padding-box;
    }
    `

    /**********************************
    * Crypto Currency -> Background for dropdown Fix
    *********************************/
    toReturn = toReturn + `\n\n
    .dropdown-toggler[`+ cryptoDropBoxData + `] {
        background-color: white;
    }

    .dropdown-container[`+ cryptoDropBoxData + `] {
        background-color: `+ getCashierColorPerTheme('614D86') + `;
    }
    `

    /**********************************
    * Interact Copyright message (French or English)
    *********************************/
    if (lang === 'fr-FR') {
        toReturn = toReturn + `\n\n
            #interactCustom {
                display: none;
                color: #FFFFFF !important;
            }

            #interactCustom_pt {
                display: none;
                color: #FFFFFF !important;
            }

            #interactCustom_fr {
                display: block;
                color: #FFFFFF !important;
            }
    `
    } else if (lang === 'pt-BR') {
        toReturn = toReturn + `\n\n
        #interactCustom {
            display: none;
            color: #FFFFFF !important;
            font-size: 12px;
        }

        #interactCustom_pt {
            display: block;
            color: #FFFFFF !important;
        }

        #interactCustom_fr {
            display: none;
            color: #FFFFFF !important;
            font-size: 12px;
        }
        `
    } else {
        toReturn = toReturn + `\n\n
            #interactCustom {
                display: block;
                color: #FFFFFF !important;
                font-size: 12px;
            }

            #interactCustom_pt {
                display: none;
                color: #FFFFFF !important;
            }

            #interactCustom_fr {
                display: none;
                color: #FFFFFF !important;
                font-size: 12px;
            }
    `}

    /**********************************
    * EcoPayz ugly logo Rounded
    *********************************/
    toReturn = toReturn + `\n\n
            .ecopayz-custom-template .img-container{
                background: white 0% 0% no-repeat padding-box;
                box-shadow: 0px 8px 16px #0000001F;
                border: 2px solid white;
                border-radius: 12px;
                width: 100px;
                padding-top: 8px;
                padding-bottom: 1px;
                padding-left: 8px;
                opacity: 1;
        }

        .ecopayz-custom-template div p{
            color: #FFFFFF;
    }
        `

    /**********************************
   * Muchbetter ugly logo Rounded
   *********************************/
    toReturn = toReturn + `\n\n
         .muchbetter-custom-template .img-container{
             background: white 0% 0% no-repeat padding-box;
             box-shadow: 0px 8px 16px #0000001F;
             border: 2px solid white;
             border-radius: 12px;
             width: 105px;
             padding-top: 8px;
             padding-bottom: 1px;
             padding-left: 8px;
             opacity: 1;
     }

     .muchbetter-custom-template div p{
        color: #FFFFFF;
}
     `

    /**********************************
    * Pending Transactions
    const pendingTransactionData = 'data-v-186064a9';        //YES    
    const pendingTransactionNoticeData = 'data-v-6b1622cd' //NO
    const pendingTransactionSummaryRowData = 'data-v-4666080b' //NO
    const pendingTransactionReceipData = 'data-v-be7199b6' //NO
    const separatorMiddleData = 'data-v-99fbf274' //NO
    const providerRedirectData = 'data-v-35cacf9b' //NO
    *********************************/
    toReturn = toReturn + `\n\n
     .cashier-section-header{
         color: #FBBA20 !important;
     }
 
     .show-pending-transactions .header-container span[`+ pendingTransactionData + `] {
         color: #FFFFFF !important;
     }
 
     .show-pending-transactions[`+ pendingTransactionData + `] span{
         color: #FFFFFF;
     }
 
     .cancel-all-pending{
         color:red !important;
     }
 
     .cancel-pending-transaction{
         color:red !important;
     }
 
     .show-pending-transactions .transaction-details-container[`+ pendingTransactionData + `] {
         border: 2px solid #FBBA20;
         border-radius: 12px;
     }
 
     .notice-icon[`+ pendingTransactionNoticeData + `], .notice-text[` + pendingTransactionNoticeData + `] {
         color: #FFFFFF !important;
     }
 
     #cashier .h1, #cashier .h2, #cashier .h3, #cashier .h4, #cashier h1:not(.ecommerce-amount), #cashier h2, #cashier h3:not(.notice-text), #cashier h4 {
         color: #FBBA20;
     }
 
     .receipt p[`+ pendingTransactionReceipData + `] {
         color: #FFFFFF;
     }
 
     .receipt .details-container .success-message-label[`+ pendingTransactionReceipData + `], .receipt .details-container .success-message-value[` + pendingTransactionReceipData + `] {
         color: #FFFFFF !important;
     }
 
     .summary-row[`+ pendingTransactionSummaryRowData + `] {
         color: #FFFFFF !important;
     }
 
     #cashier .label, #cashier h5, #cashier label:not(.input-label-float) {
         color: #FFFFFF !important;
     }
 
     #cashier .label, #cashier h5, #cashier label:not(.input-label-float) {
         color: #FFFFFF !important;
     }
 
     #cashier .make-new-payment-text {
         color: #FFFFFF !important;
     }
 
     .seperator-middle[`+ separatorMiddleData + `] {
         color: #FFFFFF !important;
     }
 
     .seperator-line[`+ separatorMiddleData + `] {
         background: #FBBA20;
     }
 
     .provider-redirect-iframe-container[`+ providerRedirectData + `] {
         margin-top: 20px;
         border-radius: 12px;
     }
 
     .show-pending-transactions .header-container span.cancel-all-pending[`+ pendingTransactionData + `] {
         display: none;
     }
 `


    /**********************************
       * Crypto Screen
       *********************************/
    toReturn = toReturn + `\n\n
    .oldCrypto{
        display: none;
    }

    .newCrypto{
        display: block;
    }
`
    toReturn = toReturn + `\n\n

    .provider-iframe-container .provider-iframe[`+ cryptoData + `] {
        background-color: transparent !important;

    }
`
    return toReturn;
}