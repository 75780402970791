/* eslint-disable */
import React, { useEffect, useState } from 'react'
import * as constants from 'newui/constants.js'
import moment from 'moment'
import { displayPage } from 'newui/Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom'
import MemoryList from 'newui/Generic/Inputs/MemoryList'
import Button from 'newui/Generic/Inputs/Button'
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import { momentToFullText } from 'newui/Utils/dateUtil'
import * as backendService from 'newui/Services/Backend/backendService';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from 'newui/Utils/themeUtils'
import { displayGenericErrorNotificationIntl, getImageUrl } from 'newui/Utils/uiUtil';

function InfoPopupList(props) {

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [allPopups, setAllPopups] = useState([]);

  const columnsDefinition = [
    { name: intl.formatMessage({ id: 'newcasino.popups.admin.list.col1', defaultMessage: 'Creation Date' }), width: '34%', listColumn: 'creationDateFormated', canSort: true, sortField: 'creationDateMillis' },
    { name: intl.formatMessage({ id: 'newcasino.popups.admin.list.col2', defaultMessage: 'Popup Name' }), width: '33%', listColumn: 'internalName', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.popups.admin.list.col3', defaultMessage: 'Active' }), width: '33%', listColumn: 'active', canSort: true }
  ];

  useEffect(() => {
    loadPopupsList();
  }, []);

  /*******************************************
   * convertPopupsFromBackend
   *******************************************/
  function convertPopupsFromBackend(res) {

    var toReturn = [];

    for (let i = 0; i < res.length; i++) {
      var currentPopup = res[i];

      toReturn.push(
        {
          'internalName': currentPopup.internalName,
          'id': currentPopup.id,
          'active': currentPopup.active ? intl.formatMessage({ id: 'newcasino.popups.admin.list.yes', defaultMessage: 'Yes' }) : intl.formatMessage({ id: 'newcasino.popups.admin.list.no', defaultMessage: 'No' }),
          'creationDate': currentPopup.creationDate,
          'creationDateMillis': moment(currentPopup.creationDate).format('x'),
          'creationDateFormated': momentToFullText(moment(currentPopup.creationDate))
        }
      )
    }
    return toReturn;
  }


  /*******************************************
   * loadContestList
   *******************************************/
  function loadPopupsList() {
    backendService.getPopupList(successLoadList, failureLoadList, navigate, location.pathname)
    /*
    getData('/api/privates/GET_POPUPS_LIST').then(item => {
      try {
        setAllPopups(convertPopupsFromBackend(item[0]))
      } catch (error) {
        console.log(error.message)
      }
      setLoading(false)
    })
    */
  }

  function successLoadList(data) {
    try {
      setAllPopups(convertPopupsFromBackend(data[0]))
    } catch (error) {
      console.log(error.message)
    }
    setLoading(false)
  }

  function failureLoadList(data) {
    displayGenericErrorNotificationIntl(intl)
    setLoading(false);
  }

  function displayDetail(item) {
    displayPage(constants.ROUTE_INFO_POPUP_DETAIL_BASE + item.id, navigate)
  }

  try {

    return loading === false ? (
      <div className={style.master_page_container}>
        <div className={style.topMainDiv}>
          <div>
            <div className={style.grid100}>
              <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.popups.admin.list.title', defaultMessage: 'Popup Management' })}</div>
              <div className={style.case1TopText3}>
                <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
              </div>
              <div className={style.buttonDiv}>
                <Button value={intl.formatMessage({ id: 'newcasino.popups.admin.list.add.button', defaultMessage: 'Add New Popup' })} onClick={() => displayPage(constants.ROUTE_INFO_POPUP_DETAIL_BASE + 'NEW', navigate)} />
              </div>
              <MemoryList list={allPopups} nbrItemPerPage={10} columns={columnsDefinition} onRowClick={displayDetail} onRowClickParam='id' />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
      </div>
    )
  } catch (error) {
    console.log(error);
  }
}
export default InfoPopupList