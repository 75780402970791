/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import Button from 'newui/Generic/Inputs/Button';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import { dateToYYYYMMDDHHmmss } from 'newui/Utils/dateUtil';
import { displayGenericErrorNotificationIntl, displayInfoNotification } from 'newui/Utils/uiUtil';
import { displayPage } from 'newui/Utils/routerUtil';
import { useIntl } from 'react-intl'
import * as constants from 'newui/constants.js'

function SelfExclusion(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState(null)
    const [activeButtons, setActiveButtons] = useState(false);

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {
        jwtBackendService.getUserResponsibleGamblingInfo(successFetchLossLimitDataJWT, failureFetchLossLimitDataJWT, navigate, location.pathname);
    }

    function successFetchLossLimitDataJWT(data) {
        setLoaded(true);
        setData(data);
    }

    function failureFetchLossLimitDataJWT(data) {
        setLoaded(true);
    }


    function save(days) {
        var cloneData = Object.assign({}, data);
        cloneData.exclusion = days;
        jwtBackendService.updateUserResponsibleGamblingInfo(cloneData, successOnSave, failureOnSave, navigate, location.pathname);
    }

    function toggle() {
        setActiveButtons(!activeButtons);
    }

    function logout() {
        displayPage(constants.ROUTE_LOGOUT, navigate, location.pathname);
    }
    function successOnSave(data) {

        var msg = intl.formatMessage({ id: 'newcasino.rg.self.exclusion.success', defaultMessage: 'Your account is lock until : ' }) + dateToYYYYMMDDHHmmss(new Date(data.breakUntilDate));
        //var msg = 'Your account is lock until : ' + dateToYYYYMMDDHHmmss(new Date(data.breakUntilDate));
        displayInfoNotification('INFO', msg);
        setInterval(logout, 5000);
    }

    function failureOnSave(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    if (loaded) {
        return (
            <div id='contestWinnerPage' className={style.master_page_container}>
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.rg.self.exclusion.page.title"
                        defaultMessage="Self Exclusion"
                    />
                </div>
                <div className={style.mainSubTitle}>
                    <FormattedMessage
                        id="newcasino.rg.self.exclusion.page.subtitle"
                        defaultMessage='AT NUMBERS GAME, WE BELIEVE IN RESPONSIBLE GAMBLING. IF YOU EVER FEEL LIKE YOU ARE PLAYING MORE THAN YOU CAN AFFORD, WE ENCOURAGE YOU TO TAKE A BREAK AND CONSIDER SELF-EXCLUSION. REMEMBER, RESPONSIBLE GAMBLING IS KEY TO ENJOYING ONLINE CASINOS SAFELY.'
                    />
                </div>
                <div className={style.mainDiv}>
                    <div className={style.elementBut}>
                        <Checkbox label={intl.formatMessage({ id: 'newcasino.rg.self.exclusion.page.checkbox', defaultMessage: 'By clicking here I understand that this action will block my account' })} onClick={toggle} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.self.exclusion.page.6', defaultMessage: '6 Months' })} onClick={() => save(182)} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.self.exclusion.page.1', defaultMessage: '1 Year' })} onClick={() => save(365)} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.self.exclusion.page.3', defaultMessage: '3 Years' })} onClick={() => save(1095)} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.self.exclusion.page.5', defaultMessage: '5 Years' })} onClick={() => save(1825)} />
                    </div>
                </div>
            </div >
        );
    }
}

export default SelfExclusion;
