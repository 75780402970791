/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as backendService from 'newui/Services/Backend/backendService';
import TextField from 'newui/Generic/Inputs/TextField';
import BirthDay from 'newui/Generic/Inputs/Birthday';
import { displayErrorNotification, displayInfoNotification, isDateDDMMYYYY, isTextMin2Char } from 'newui/Utils/uiUtil';
import Button from 'newui/Generic/Inputs/Button';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as constants from 'newui/constants.js'
import { moveElementInArray } from 'newui/Utils/jsHelper';
import { displayPage } from 'newui/Utils/routerUtil';
import { setTheme } from 'newui/Utils/themeUtils';
import { getImageUrl } from 'newui/Utils/uiUtil';

function EditContest(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [contest, setContest] = useState();

    const [bullet, setBullet] = useState(constants.STRING_EMPTY);
    const [bulletFr, setBulletFr] = useState(constants.STRING_EMPTY);
    const [bulletPt, setBulletPt] = useState(constants.STRING_EMPTY);
    const [bulletValid, setBulletValid] = useState(false);
    const [bulletFrValid, setBulletFrValid] = useState(false);
    const [bulletPtValid, setBulletPtValid] = useState(false);
    const [bulletKey, setBulletKey] = useState(Math.random());

    const [winName, setWinName] = useState(constants.STRING_EMPTY);
    const [winNameValid, setWinNameValid] = useState(false);
    const [winPrize, setWinPrize] = useState(constants.STRING_EMPTY);
    const [winPrizeValid, setWinPriceValid] = useState(false);
    const [winPrizeFr, setWinPrizeFr] = useState(constants.STRING_EMPTY);
    const [winPrizeFrValid, setWinPriceFrValid] = useState(false);
    const [winPrizePt, setWinPrizePt] = useState(constants.STRING_EMPTY);
    const [winPrizePtValid, setWinPricePtValid] = useState(false);
    const [winDate, setWinDate] = useState(constants.STRING_EMPTY);
    const [winDateValid, setWinDateValid] = useState(false);
    const [winKey, setWinKey] = useState(Math.random());

    const [genStartDate, setGenStartDate] = useState(constants.STRING_EMPTY);
    const [genStartDateValid, setGenStartDateValid] = useState(false);
    const [genEndDate, setGenEndDate] = useState(constants.STRING_EMPTY);
    const [genEndDateValid, setGenEndDateValid] = useState(false);
    const [genTitle, setGenTitle] = useState(constants.STRING_EMPTY);
    const [genTitleValid, setGenTitleValid] = useState(false);
    const [genTitleFr, setGenTitleFr] = useState(constants.STRING_EMPTY);
    const [genTitleFrValid, setGenTitleFrValid] = useState(false);
    const [genTitlePt, setGenTitlePt] = useState(constants.STRING_EMPTY);
    const [genTitlePtValid, setGenTitlePtValid] = useState(false);
    const [genDesc, setGenDesc] = useState(constants.STRING_EMPTY);
    const [genDescValid, setGenDescValid] = useState(false);
    const [genDescFr, setGenDescFr] = useState(constants.STRING_EMPTY);
    const [genDescFrValid, setGenDescFrValid] = useState(false);
    const [genDescPt, setGenDescPt] = useState(constants.STRING_EMPTY);
    const [genDescPtValid, setGenDescPtValid] = useState(false);

    const isNew = (params.id === undefined || params.id === null || params.id === '-1' || params.id === -1 || params.id === 'NEW')
    const intl = useIntl();

    useEffect(() => {
        if (!isNew) {
            let id = params.id
            backendService.getContestInformation(id, successGetData, failureGetData, navigate, location.pathname)
        } else {
            setContest({
                id: -1,
                from: constants.STRING_EMPTY,
                to: constants.STRING_EMPTY,
                name: { en: constants.STRING_EMPTY, fr: constants.STRING_EMPTY },
                description: { en: constants.STRING_EMPTY, fr: constants.STRING_EMPTY },
                bullets: [],
                winners: [],
                active: false,
            })
        }
    }, []);

    function successGetData(data) {

        if (data.result !== 'OK') {
            failureGetData(data.data);
            return;
        }
        data = data.data;


        setContest(data[0]);
    }

    function isWinnerNameValid(isValid, value) {
        setWinNameValid(isValid);
        setWinName(value);
    }

    function isWinnerDateValid(isValid, value) {
        setWinDateValid(isValid);
        setWinDate(value);
    }

    function isWinnerPrizeValid(isValid, value) {
        setWinPriceValid(isValid);
        setWinPrize(value);
    }

    function isWinnerPrizeFrValid(isValid, value) {
        setWinPriceFrValid(isValid);
        setWinPrizeFr(value);
    }

    function isWinnerPrizePtValid(isValid, value) {
        setWinPricePtValid(isValid);
        setWinPrizePt(value);
    }

    function isDateFromValid(isValid, value) {
        setGenStartDateValid(isValid);
        setGenStartDate(value);
    }

    function isDateToValid(isValid, value) {
        setGenEndDateValid(isValid);
        setGenEndDate(value);
    }

    function isTitleValid(isValid, value) {
        setGenTitleValid(isValid);
        setGenTitle(value);
    }

    function isTitleFrValid(isValid, value) {
        setGenTitleFrValid(isValid);
        setGenTitleFr(value);
    }

    function isTitlePtValid(isValid, value) {
        setGenTitlePtValid(isValid);
        setGenTitlePt(value);
    }

    function isDescriptionValid(isValid, value) {
        setGenDescValid(isValid);
        setGenDesc(value);
    }

    function isDescriptionFrValid(isValid, value) {
        setGenDescFrValid(isValid);
        setGenDescFr(value);
    }

    function isDescriptionPtValid(isValid, value) {
        setGenDescPtValid(isValid);
        setGenDescPt(value);
    }

    function setIsActive(value) {
        var contestClone = JSON.parse(JSON.stringify(contest));
        contestClone.active = value;
        setContest(contestClone);
    }

    function isBulletFrValid(isValid, value) {
        setBulletFrValid(isValid)
        setBulletFr(value)
    }

    function isBulletPtValid(isValid, value) {
        setBulletPtValid(isValid)
        setBulletPt(value)
    }

    function isBulletValid(isValid, value) {
        setBulletValid(isValid)
        setBullet(value)
    }

    function addWinner() {
        var contestClone = JSON.parse(JSON.stringify(contest));
        contestClone.winners.push({ name: winName, prize: { en: winPrize, fr: winPrizeFr, pt: winPrizePt }, date: winDate });
        setContest(contestClone);
        setWinName(constants.STRING_EMPTY);
        setWinDate(constants.STRING_EMPTY);
        setWinPrize(constants.STRING_EMPTY);
        setWinPrizeFr(constants.STRING_EMPTY);
        setWinPrizePt(constants.STRING_EMPTY);
        setWinNameValid(false);
        setWinDateValid(false);
        setWinPriceFrValid(false);
        setWinPricePtValid(false);
        setWinPriceValid(false);
        setWinKey(Math.random())
    }


    function addBullet() {
        var contestClone = JSON.parse(JSON.stringify(contest));
        contestClone.bullets.push({ en: bullet, fr: bulletFr, pt: bulletPt });
        setContest(contestClone);
        setBullet(constants.STRING_EMPTY);
        setBulletFr(constants.STRING_EMPTY);
        setBulletFrValid(false);
        setBulletPt(constants.STRING_EMPTY);
        setBulletPtValid(false);
        setBulletValid(false);
        setBulletKey(Math.random())
    }

    function moveUpWinner(id) {
        var contestClone = JSON.parse(JSON.stringify(contest));
        moveElementInArray(contestClone.winners, id, (id - 1));
        setContest(contestClone)
    }

    function moveDownWinner(id) {
        var contestClone = JSON.parse(JSON.stringify(contest));
        moveElementInArray(contestClone.winners, id, (id + 1));
        setContest(contestClone)
    }

    function moveUpBullet(id) {
        var contestClone = JSON.parse(JSON.stringify(contest));
        moveElementInArray(contestClone.bullets, id, (id - 1));
        setContest(contestClone)
    }

    function moveDownBullet(id) {
        var contestClone = JSON.parse(JSON.stringify(contest));
        moveElementInArray(contestClone.bullets, id, (id + 1));
        setContest(contestClone)
    }

    function deleteFromBullet(index) {
        var contestClone = JSON.parse(JSON.stringify(contest));
        contestClone.bullets.splice(index, 1);
        setContest(contestClone)
    }

    function deleteFromWinners(index) {
        var contestClone = JSON.parse(JSON.stringify(contest));
        contestClone.winners.splice(index, 1);
        setContest(contestClone)
    }

    function failureGetData(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.fetching.contest', defaultMessage: 'Error trying to get contests information' }));
    }

    function deleteContest() {
        var contestClone = JSON.parse(JSON.stringify(contest));
        backendService.updateContest(true, contestClone, successDelete, failureDelete, navigate, location.pathname);
    }

    function successDelete() {
        displayPage(constants.ROUTE_CONTEST_WINNERS, navigate)
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.success.delete.contest', defaultMessage: 'Error trying to get contests information' }));
    }

    function failureDelete() {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.delete.contest', defaultMessage: 'Operation Failed' }));
    }

    function save() {
        var contestClone = JSON.parse(JSON.stringify(contest));
        contestClone.from = genStartDate;
        contestClone.to = genEndDate;
        contestClone.name.en = genTitle;
        contestClone.name.fr = genTitleFr;
        contestClone.name.pt = genTitlePt;
        contestClone.description.en = genDesc;
        contestClone.description.fr = genDescFr;
        contestClone.description.pt = genDescPt;
        backendService.updateContest(false, contestClone, successSave, failureSave, navigate, location.pathname);
    }

    function successSave(data) {
        displayPage(constants.ROUTE_CONTEST_WINNERS, navigate)
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.success.save.contest', defaultMessage: 'Contest saved' }));
    }

    function failureSave(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.save.contest', defaultMessage: 'Operation Failed' }));
    }

    if (contest) {
        return (
            <>
                <div className={style.master_page_container}>

                    <div className={style.topMainDiv}>
                        <div>
                            <div className={style.grid100}>
                                <div className={style.case1TopText2}>{genTitle ? genTitle : intl.formatMessage({ id: 'newcasino.contests.admin.detail.header.title.missing', defaultMessage: '???' })}</div>
                                <div className={style.case1TopText3}>
                                    <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.grid25_25_auto}>
                                    <BirthDay validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.date.start', defaultMessage: 'Start Date' })} validator={isDateDDMMYYYY} validatorInformer={isDateFromValid} values={contest.from ? [contest.from] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.date.start.hint', defaultMessage: 'Ex: dd/mm/yyyy' })} />
                                    <BirthDay validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.date.end', defaultMessage: 'End Date' })} validator={isDateDDMMYYYY} validatorInformer={isDateToValid} values={contest.to ? [contest.to] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.date.end.hint', defaultMessage: 'Ex: dd/mm/yyyy' })} />
                                    <Checkbox marginTop='24px' label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.active', defaultMessage: 'Active' })} checked={contest.active} onClick={setIsActive} />
                                </div>
                                <div className={style.grid50_50}>
                                    <TextField validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.title.en', defaultMessage: 'Title English' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitleValid} values={contest.name.en ? [contest.name.en] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.title.en.hint', defaultMessage: 'Type the title in English' })} />
                                    <TextField validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.title.fr', defaultMessage: 'Title French' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitleFrValid} values={contest.name.fr ? [contest.name.fr] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.title.fr.hint', defaultMessage: 'Type the title in French' })} />
                                    <TextField validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.title.pt', defaultMessage: 'Title Portuguese' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitlePtValid} values={contest.name.pt ? [contest.name.pt] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.title.pt.hint', defaultMessage: 'Type the title in Portuguese' })} />
                                </div>
                                <div className={style.grid50_50}>
                                    <TextField validateOnStart={!isNew} rows='5' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isDescriptionValid} values={contest.description.en ? [contest.description.en] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.desc.en', defaultMessage: 'Description English' })} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.desc.en.hint', defaultMessage: 'Type the description in English' })} />
                                    <TextField validateOnStart={!isNew} rows='5' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isDescriptionFrValid} values={contest.description.fr ? [contest.description.fr] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.desc.fr', defaultMessage: 'Description French' })} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.desc.fr.hint', defaultMessage: 'Type the description in French' })} />
                                    <TextField validateOnStart={!isNew} rows='5' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isDescriptionPtValid} values={contest.description.pt ? [contest.description.pt] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.desc.pt', defaultMessage: 'Description Portuguese' })} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.desc.pt.hint', defaultMessage: 'Type the description in Portuguese' })} />
                                </div>
                                <div className={style.grid50_50_24p}>
                                    <TextField key={'bul1_' + bulletKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.bullet.en', defaultMessage: 'Bullet English' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBulletValid} values={[bullet]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.bullet.en.hint', defaultMessage: 'English' })} />
                                    <TextField key={'bul2_' + bulletKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.bullet.fr', defaultMessage: 'Bullet French' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBulletFrValid} values={[bulletFr]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.bullet.fr.hint', defaultMessage: 'French' })} />
                                    <TextField key={'bul3_' + bulletKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.bullet.pt', defaultMessage: 'Bullet Portuguese' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBulletPtValid} values={[bulletPt]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.bullet.pt.hint', defaultMessage: 'Portuguese' })} />
                                    <Button active={(bulletPtValid && bulletFrValid && bulletValid)} width='40px' value={intl.formatMessage({ id: 'newcasino.contests.admin.detail.button.add', defaultMessage: '+' })} marginTop='24px' onClick={addBullet} />
                                </div>

                                {contest.bullets.map((item, index) => (
                                    <div key={'bullet_' + index} className={style.grid_20p_20p_20p_20p_100}>
                                        {(index > 0) ?
                                            <div className={style.bullet2} onClick={() => moveUpBullet(index)}><i className="fa fa-arrow-up" aria-hidden="true"></i></div>
                                            : <div />
                                        }
                                        {(index + 1 < contest.bullets.length) ?
                                            <div className={style.bullet2} onClick={() => moveDownBullet(index)}><i className="fa fa-arrow-down" aria-hidden="true"></i></div>
                                            : <div />
                                        }
                                        <div className={style.bullet2} onClick={() => deleteFromBullet(index)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                                        <div className={style.bullet1}>{(index + 1)}. </div>
                                        <div className={style.bullet2}>{contest.bullets[index].en + ' / ' + contest.bullets[index].fr + ' / ' + contest.bullets[index].pt}</div>
                                    </div>))}

                                <div className={style.case1TopText3}><br /><br />
                                    <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.contests.admin.detail.header.winners', defaultMessage: 'The Winners' })}</div>

                                <div className={style.grid50_50}>
                                    <TextField key={'win1_' + winKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.name', defaultMessage: 'Winner\'s name' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isWinnerNameValid} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.name.hint', defaultMessage: 'Type Winner\'s Name' })} />
                                    <div />
                                </div>
                                <div className={style.grid50_50}>
                                    <TextField key={'win2_' + winKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.prize.en', defaultMessage: 'Prize English' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isWinnerPrizeValid} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.prize.en.hint', defaultMessage: 'English' })} />
                                    <TextField key={'win3_' + winKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.prize.fr', defaultMessage: 'Prize French' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isWinnerPrizeFrValid} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.prize.fr.hint', defaultMessage: 'French' })} />
                                    <TextField key={'win33_' + winKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.prize.pt', defaultMessage: 'Prize Portuguese' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isWinnerPrizePtValid} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.prize.pt.hint', defaultMessage: 'Portuguese' })} />
                                </div>
                                <div className={style.grid50_50b}>
                                    <BirthDay key={'win4_' + winKey} label={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.date', defaultMessage: 'Date' })} validator={isDateDDMMYYYY} validatorInformer={isWinnerDateValid} values={[constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.date.hint', defaultMessage: 'Ex: dd/mm/yyyy' })} />
                                    <Button active={(winNameValid && winPrizeValid && winPrizeFrValid && winPrizePtValid && winDateValid)} width='40px' value={intl.formatMessage({ id: 'newcasino.contests.admin.detail.win.button.add', defaultMessage: '+' })} marginTop='24px' onClick={addWinner} />
                                </div>


                                {contest.winners.map((item, index) => (
                                    <div key={'bullet_' + index} className={style.grid_20p_20p_20p_20p_100}>
                                        {(index > 0) ?
                                            <div className={style.bullet2} onClick={() => moveUpWinner(index)}><i className="fa fa-arrow-up" aria-hidden="true"></i></div>
                                            : <div />
                                        }
                                        {(index + 1 < contest.winners.length) ?
                                            <div className={style.bullet2} onClick={() => moveDownWinner(index)}><i className="fa fa-arrow-down" aria-hidden="true"></i></div>
                                            : <div />
                                        }
                                        <div className={style.bullet2} onClick={() => deleteFromWinners(index)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                                        <div className={style.bullet1}>{(index + 1)}. </div>
                                        <div className={style.bullet2}>{contest.winners[index].name + ' : ' + contest.winners[index].date + ' (' + contest.winners[index].prize.en + ' / ' + contest.winners[index].prize.fr + ' / ' + contest.winners[index].prize.pt + ')'}</div>
                                    </div>))}
                                <div className={style.grid50_50b}>
                                    <div className={style.grid50_50b}>
                                        <Button colorStyle='light' active={(genStartDateValid && genEndDateValid && genTitleValid && genTitleFrValid && genDescValid && genDescFrValid)} width='120px' value={intl.formatMessage({ id: 'newcasino.contests.admin.detail.button.save', defaultMessage: 'Save' })} marginTop='24px' onClick={save} />
                                        {!isNew &&
                                            <Button colorStyle='dark' active={!isNew} width='100px' value={intl.formatMessage({ id: 'newcasino.contests.admin.detail.button.delete', defaultMessage: 'Delete' })} marginTop='24px' onClick={deleteContest} />
                                        }
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>
                        <div className={style.case}>
                            <img className={style.caseImg} src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/IMG_Contest2.png'))} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default EditContest;