import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import Button from 'newui/Generic/Inputs/Button';
import * as constants from 'newui/constants';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import { useLocation, useNavigate } from 'react-router-dom'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from 'newui/Utils/themeUtils'
import { displayGenericErrorNotificationIntl, getImageUrl } from 'newui/Utils/uiUtil';
import { displayPage } from 'newui/Utils/routerUtil'
import MemoryList from 'newui/Generic/Inputs/MemoryList'
import moment from 'moment'

function Promotions(props) {

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [allPromotions, setAllPromotions] = useState([]);

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col0', defaultMessage: 'In Progress' }), width: '150px', listColumn: 'inProgress', canSort: true, transformer: booleanFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col1', defaultMessage: 'Promotion Name' }), width: '300px', listColumn: 'name', canSort: true },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col2', defaultMessage: 'Active' }), width: '100px', listColumn: 'active', canSort: true, transformer: booleanFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col3', defaultMessage: 'Start Date' }), width: '300px', listColumn: 'startDate', canSort: false, transformer: dateFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col4', defaultMessage: 'End Date' }), width: '300px', listColumn: 'endDate', canSort: false, transformer: dateFormater },
    ];

    function dateFormater(data) {
        try {
            if (data.length === 'Invalid date') {
                throw new Error();
            }
            return moment(data).format('DD MMMM YYYY - HH:mm');
        } catch (err) {
            return '-';
        }

    }

    function booleanFormater(data) {
        if (data === 1) {
            return intl.formatMessage({ id: 'newcasino.general.true', defaultMessage: 'True' })
        }

        return intl.formatMessage({ id: 'newcasino.general.false', defaultMessage: 'False' })

    }


    function displayDetail(item) {
        displayPage(constants.ROUTE_ADMIN_PROMOTION_DETAIL_BASE + item.id, navigate)
    }

    useEffect(() => {
        getPromotionsInformation();
    },// eslint-disable-next-line
        [])

    function getPromotionsInformation() {
        jwtBackendService.getPromotionsList(onSuccessPromotionList, onFailurePromotionList, navigate, location.pathname);
    }

    function onSuccessPromotionList(data) {
        try {
            setAllPromotions(data[0])
        } catch (error) {
            console.log(error.message)
        }
        setLoading(false)

    }

    function onFailurePromotionList(data) {
        //TODO
        displayGenericErrorNotificationIntl(intl)
        setLoading(false);
    }

    function newPromotion() {
        displayPage(constants.ROUTE_ADMIN_PROMOTION_NEW, navigate, location.pathname)
    }

    try {

        return loading === false ? (
            <div className={style.master_page_container}>
                <div className={style.topMainDiv}>
                    <div>
                        <div className={style.grid100}>
                            <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.promotions.admin.list.title', defaultMessage: 'Promotions' })}</div>
                            <div className={style.case1TopText3}>
                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                            </div>
                            <div className={style.buttonDiv}>
                                <Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.list.add.button', defaultMessage: 'Add New Promotion' })} onClick={newPromotion} />
                            </div>
                            <MemoryList list={allPromotions} nbrItemPerPage={10} columns={columnsDefinition} onRowClick={displayDetail} onRowClickParam='id' />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
            </div>
        )
    } catch (error) {
        console.log(error);
    }
}


export default Promotions;
