import React, { useState } from 'react'
import style from './style.module.scss'
import TextField from 'newui/Generic/Inputs/TextField';
import { isEmail } from 'newui/Utils/uiUtil';
import { useIntl } from 'react-intl';
import DivClick from 'newui/Generic/Inputs/DivClick';

export const FORGET_PASSWORD_STEP_EMAIL = 'email';

function ScreenForgetPassword(props) {

  const intl = useIntl()
  const sourceData = props.value;
  const sourceEmail = sourceData.fields.get(FORGET_PASSWORD_STEP_EMAIL) ? sourceData.fields.get(FORGET_PASSWORD_STEP_EMAIL) : {};
  const [email, setEmail] = useState(sourceEmail.value ? sourceEmail.value : '');
  const [emailValid, setEmailValid] = useState((sourceEmail.valid !== undefined) ? sourceEmail.valid : null);


  const message = props.message;

  function emailValidCallBack(isValid, value) {
    var data = new Map();
    data.set(FORGET_PASSWORD_STEP_EMAIL, { value: value, valid: isValid });
    setEmailValid(isValid);
    setEmail(value)
    props.validatorInformer((isValid), data);
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            {(message.text && message.action) &&
              <DivClick className={style.errorLink} onClick={() => props.msgCallback(message.action)}>
                {message.text}
              </DivClick>
            }
            {(message.text && !message.action) &&
              <div className={style.error}>
                {message.text}
              </div>
            }
            <div className={style.test2}>
              <TextField maxLength='100' autofocus='true' values={[email, emailValid]} hint={intl.formatMessage({ id: 'newcasino.auth.login.type.email', defaultMessage: 'Type your email here' })} label={intl.formatMessage({ id: 'newcasino.auth.login.email', defaultMessage: 'Email' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.login.email.err', defaultMessage: 'Invalid Email' })} />
            </div>
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ScreenForgetPassword