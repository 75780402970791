import React, { useState } from 'react'
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import TextField from '../TextField';
/*
noResultTxt
*/
function NonMemoryList(props) {

  const intl = useIntl();

  const [list] = useState(props.list)

  const currentPage = props.currentPage;
  const nbrPage = props.nbrPage;

  const rowCellLight = props.customRowCellLight ? props.customRowCellLight : style.rowCellLight;
  const rowCellDark = props.customRowCellDark ? props.customRowCellDark : style.rowCellDark;
  const headerSorted = props.customHeaderSorted ? props.customHeaderSorted : style.headerSorted;
  const header = props.customHeader ? props.customHeader : style.header;

  function onSort(column) {
    props.onSort(column);
  }

  function onRowClick(value) {
    if (props.onRowClick) {
      props.onRowClick(value);
    }
  }

  function canBeSorted(col) {
    return (col.canSort === true);
  }

  function isSorted(col) {
    return col.sortValue === props.sortedColumn;
  }

  function previousPage() {
    props.changePage(currentPage - 1);
  }
  function nextPage() {
    props.changePage(currentPage + 1);
  }

  function specificPage(data) {
    if (data > 0 && data <= nbrPage) {
      props.changePage(data);
    }

  }

  function getValueQte() {
    let toReturn = 0;
    if (nbrPage < 5) {
      toReturn = 0
    }
    else {
      toReturn = nbrPage;
    }

    return toReturn;
  }
  /*
    function getValues() {
      if (getValueQte() === 0) {
        return [];
      }
      else {
        let toReturn = [];
        for (let x = 1; x <= nbrPage; x++) {
          toReturn.push({ key: x, value: x });
        }
        return toReturn;
      }
    }
  */
  function getPagination() {
    var previous = (currentPage > 1) ? <span className={style.page} onClick={previousPage}>{intl.formatMessage({ id: 'newcasino.memorylist.previous', defaultMessage: 'Previous Page' })} - </span> : ''
    var pageTxt = <>{intl.formatMessage({ id: 'newcasino.memorylist.page', defaultMessage: 'Page' })} {currentPage} {intl.formatMessage({ id: 'newcasino.memorylist.of', defaultMessage: 'of' })} {nbrPage}</>
    var next = (currentPage < nbrPage) ? <span className={style.page} onClick={nextPage}> - {intl.formatMessage({ id: 'newcasino.memorylist.next', defaultMessage: 'Next Page' })}</span> : ''

    //<ComboBox className={style.combo} height='34px' styleInput={style.comboInput} values={[currentPage]} valueList={{ list: getValues(), key: 'key', value: 'value' }} onChange={specificPage} />
    return (
      <div className={(getValueQte() > 0) ? style.paginationMain : ''}>
        <div className={style.pagination}>
          {previous}
          {pageTxt}
          {next}
        </div>
        {(getValueQte() > 0) &&
          <div className={style.paginator}><TextField styleBaseUI={style.paginationBaseUI} styleCustom={style.paginatorTxtField} height='30px' type='digit' values={[currentPage]} onEnterPress={specificPage} /></div>
        }
      </div>
    );
  }

  function getRowCellStyle(index) {
    var toReturn = rowCellLight;

    if (index % 2 === 0) {
      toReturn = rowCellDark;
    }

    return toReturn;
  }
  /*
    function getValue(data, definition) {
      if (definition.transformer) {
        return definition.transformer(data, intl);
      } else {
        return data;
      }
  
    }
  */

  function getValue(data, colDef) {
    if (colDef.transformer) {
      if (colDef.transformerObj === true) {
        return colDef.transformer(data[colDef.listColumn], data);
      } else {
        return colDef.transformer(data[colDef.listColumn], intl);
      }

    } else {
      return data[colDef.listColumn];
    }

  }


  function getField(elem) {
    if (props.onRowClickParam) {
      return elem[props.onRowClickParam];
    }

    return elem;
  }

  function getGridTemplateColumns() {
    var toReturn = '';
    for (let i = 0; i < props.columns.length; i++) {
      toReturn = toReturn + ' ' + props.columns[i].width;
    }

    return toReturn.trim();
  }

  try {

    return (
      <>
        {(props.nbrPage > 0) &&
          <div className={style.mainGrid} style={{ 'gridTemplateColumns': getGridTemplateColumns() }}>
            {props.columns.map((col, index) => (
              <div key={'col_' + index} onClick={() => onSort(col)} style={canBeSorted(col) ? { cursor: 'pointer' } : { cursor: 'none' }} className={isSorted(col) ? headerSorted : header}>{col.name}&nbsp;
                {canBeSorted(col) ? (isSorted(col) ? (props.sortedOrder === 0 ? <i className="fa fa-arrow-down" aria-hidden="true"></i> : <i className="fa fa-arrow-up" aria-hidden="true"></i>) : '') : ''}
              </div>
            ))}
            {list.map((item, index) => (
              <React.Fragment key={'colRow_' + index}>
                <>
                  {
                    props.columns.map((col, index2) => (
                      (col.condition != null && ((item[col.listColumn] === col.conditionValue) || (Array.isArray(col.conditionValue) && col.conditionValue.includes(item[col.listColumn])))) ?
                        <div key={'colRow_' + index + '_row_' + index2} title={col.hintColumn ? item[col.hintColumn] : ''} className={getRowCellStyle(index)}><div onClick={() => col.function(item)}><i className="fa fa-sliders" aria-hidden="true"></i></div></div>
                        :
                        (col.condition != null) ? <div key={'colRow_' + index + '_row_' + index2} title={col.hintColumn ? item[col.hintColumn] : ''} className={getRowCellStyle(index)}></div> : <div key={'colRow_' + index + '_row_' + index2} title={col.hintColumn ? item[col.hintColumn] : ''} className={getRowCellStyle(index)} onClick={() => onRowClick(getField(item))}><div className={col.txtStyle ? col.txtStyle(item) : ''}>{getValue(item, col)}</div></div>
                    ))
                  }
                </></React.Fragment>))}
            <div style={{ gridColumn: 'span ' + props.columns.length }}>
              {getPagination()}
            </div>
          </div>
        }
        {(props.nbrPage <= 0) &&
          <div className={style.noResult}>
            {props.noResultTxt ? props.noResultTxt : 'No Result'}
          </div>

        }
      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default NonMemoryList

