import React from 'react'
import style from './style.module.scss'
import DivClick from 'newui/Generic/Inputs/DivClick';

function RadioButton(props) {

  //<RadioButton selected={true} values={'RAF'} img='/ressources/newui/register/IMG_High-Five.png' title='Refer-a-friend' subTitle='Deposit and play a minimum of 30$ and both you and your friend will b e awarded 15 free spins!' selectionInformer={promotionSelectedCallBack} />

  const subTitle = (props.subTitle) ? props.subTitle : null;
  const title = (props.title) ? props.title : null;
  const image = (props.img) ? props.img : null;
  const value = (props.value) ? props.value : '-1';

  const selected = (props.selected === true) ? true : false;

  const switchfont = (props.switchfont === true) ? true : false;


  /*************************************************************
   * newValue(newValue)
   *************************************************************/
  function click() {
    if (!selected) {
      props.selectionInformer(true, value)
    }
  }

  function getGridStyle() {
    var nbrElement = 1;

    if (image !== null) {
      nbrElement++;
    }

    if (title !== null) {
      nbrElement++;
    }

    if (nbrElement === 1) {
      if (selected) {
        return style.baseGrid1
      } else {
        return style.baseGrid1_noSel
      }

    }

    if (nbrElement === 2) {
      if (selected) {
        return style.baseGrid2
      } else {
        return style.baseGrid2_noSel
      }
    }

    if (selected) {
      return style.baseGrid3
    } else {
      return style.baseGrid3_noSel
    }

  }

  function getTextStyle() {
    var toReturn = [style.textTitle, style.textSubTitle]

    if (switchfont) {
      toReturn = [style.textSubTitle, style.textTitle]
    }


    if (subTitle === null) {
      toReturn = [style.textSubTitle, style.textSubTitle]
    }

    return toReturn;
  }

  function getCheck() {
    if (selected) {
      return props.imageCheck;
    }
    else {
      return props.imageNotCheck;
    }
  }

  try {
    return (
      <DivClick className={getGridStyle()} onClick={click}>
        {(image !== null) &&
          <div className={style.imgDiv}>
            <img className={style.img} src={image} alt='' />
          </div>
        }
        {(title !== null) &&
          <div className={style.textDiv}>
            <div className={getTextStyle()[0]}>
              {title}
            </div>
            <div className={getTextStyle()[1]}>
              {subTitle}
            </div>
          </div>
        }
        <div className={style.checkDiv}>
          <img src={getCheck()} alt='' />
        </div>

      </DivClick >
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default RadioButton

