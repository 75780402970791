import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import SpanClick from '../SpanClick';

/*

checked
label
onClick
*/
function Checkbox(props) {

  const [selected, setSelected] = useState(props.checked ? props.checked : false);
  const [readOnly] = useState(props.readOnly ? true : false);

  const isArray = props.label ? (Array.isArray(props.label) ? true : false) : false

  useEffect(() => {
    if (props.validateOnStart === true) {
      props.onClick(selected);
    }
  }, // eslint-disable-next-line
    []);

  function handleClick() {
    if (readOnly) {
      return false;
    }
    var newValue = !selected;
    setSelected(newValue);
    if (props.onClick) {
      props.onClick(newValue);
    }
  }

  function createLabelFromArray(data) {
    var toReturn = [];
    for (let i = 0; i < data.length; i++) {
      var theStyle = (i === 0) ? style.theLabel : style.theLabelOthers
      var theActionStyle = (i === 0) ? style.theLabelAction : style.theLabelOthersAction

      var current = data[i];
      if (current.action === undefined) {
        toReturn.push(<span key={Math.random()} className={theStyle}>{current.label}</span>)
      } else {
        toReturn.push(<SpanClick key={Math.random()} onClick={current.action} className={theActionStyle}>{current.label}</SpanClick>)
      }
    }

    return toReturn;
  }

  try {
    return (
      <div className={style.baseGrid} style={{ marginTop: props.marginTop ? props.marginTop : '0px' }}>
        <div className={style.centerizeVerticallyCheckBox}>
          <input className={props.checkStyle ? props.checkStyle : style.checkbox} type="checkbox" checked={selected} onChange={handleClick} />
        </div>
        {(props.label && !isArray) &&
          <div className={style.centerizeVertically}>
            <div className={style.alignLeft}>
              <span className={props.labelStyle ? props.labelStyle : style.theLabel}>{props.label}</span>
            </div>
          </div>
        }
        {(props.label && isArray) &&
          <div className={style.centerizeVertically}>
            <div className={style.alignLeft}>
              {createLabelFromArray(props.label)}
            </div>
          </div>
        }
      </div >
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Checkbox

