import React, { useState } from 'react'
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl'
import { canAccess } from 'newui/Utils/authorizationUtils'
import { displayPage } from 'newui/Utils/routerUtil'
import { useNavigate, useLocation } from 'react-router-dom'
import ApiPersistence from 'newui/Utils/ApiPersistence'
import DivClick from 'newui/Generic/Inputs/DivClick'

function MobileBottomMenu(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedElement, setSelectedElement] = useState(figureOutShowedTab(location.pathname, props.definition));

  const methodCalls = new Map();
  const user = ApiPersistence.getInstance().getUser()

  methodCalls.set('toggleLeftMenu', props.toggleLeftMenu)
  methodCalls.set('requestHomeSpecificDisplay', props.requestHomeSpecificDisplay)

  const menuElements = props.definition.elements;

  function figureOutShowedTab(currentRoute, definition, defaultValue) {


    for (let i = 0; i < definition.elements.length; i++) {
      if (definition.elements[i].action === currentRoute) {
        return i + '';
      }
    }

    return '-1';
  }

  function isSelected(elemIndexParam) {
    const elemIndex = elemIndexParam.toString();

    var toReturn = false;
    if (selectedElement === elemIndex) {
      toReturn = true;
    }

    return toReturn
  }

  function getStyle(index) {
    var toReturn = [style.mobileMenu_grid_element, style.mobileMenu_image, style.mobileMenu_text];

    if (isSelected(index)) {
      toReturn = [style.mobileMenu_grid_element_sel, style.mobileMenu_image_sel, style.mobileMenu_text_sel];
    }

    return toReturn;
  }

  function changeTab(index) {
    setSelectedElement('' + index);

    if (props.toggleUserMenu) {
      props.toggleUserMenu(false);
    }

    if (menuElements[index].action.startsWith('DYNAMIC_FUNCTION')) {
      methodCalls.get(menuElements[index].action.split('::')[1])();
    } else {
      displayPage(menuElements[index].action, navigate, location.pathname)
      props.toggleLeftMenu(false);
    }
  }

  try {
    if (props.forceHide === false) {
      return (
        <div className={`${style.mobileMenuMainDivSpacer}`}>

          <div className={`${style.mobileMenuMainDiv} ${style.showOnlyOnMobile}`}>


            <div className={`${style.mobileMenu_grid}`}>
              {(menuElements.map((item, index) => (((item.visible !== false) && canAccess(user, item)) ? (
                <DivClick key={'mbm_elem' + index} className={getStyle(index)[0]} onClick={() => changeTab(index)}>
                  <div className={`${getStyle(index)[1]}`} style={{
                    backgroundImage: item.image
                  }}>
                  </div>
                  <div className={`${getStyle(index)[2]}`}>
                    <FormattedMessage
                      id={item.intl}
                      defaultMessage={item.default}
                    />
                  </div>
                </DivClick>
              ) : ''))
              )}

            </div>

          </div>
        </div>
      );
    } else {
      return (
        <></>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default MobileBottomMenu

