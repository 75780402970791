import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import RadioButton from '../../RadioButton'
import { useIntl } from 'react-intl';
import { setTheme } from 'newui/Utils/themeUtils';
import { getImageUrl } from 'newui/Utils/uiUtil';

export const REGISTRATION_STEP1_PROMOTION = 'promotion';
//export const REFER_A_FRIEND = 'RAF';
export const PROMO_CODE = 'PROMO_CODE';
export const WELCOME_BONUS_3X = 'WELCOME_BONUS_3X';
export const DEFAULT_VALUE = 'NONE';


function ChooseBonus(props) {
  const intl = useIntl();

  //This feature has been deactivated because now the new user does not receive any bonus when he is refered.
  //Only the referer receive automatically the bonus. I leave the code here in case of it come back some day
  //const featureReferedByFriendAction = false;

  const sourceData = props.value;
  const sourcePromotion = sourceData.fields.get(REGISTRATION_STEP1_PROMOTION) ? sourceData.fields.get(REGISTRATION_STEP1_PROMOTION) : {};
  const [promotion, setPromotion] = useState(sourcePromotion.value ? sourcePromotion.value : DEFAULT_VALUE);
  const [hash] = useState(props.hash);

  useEffect(() => {
    if (promotion === DEFAULT_VALUE) {
      var defaultValue = new Map();
      defaultValue.set(REGISTRATION_STEP1_PROMOTION, { value: DEFAULT_VALUE, valid: true, hash: hash });
      props.validatorInformer(true, defaultValue)
    }
  }, // eslint-disable-next-line
    [])

  function promotionSelectedCallBack(isValid, value) {
    var data = new Map();
    data.set(REGISTRATION_STEP1_PROMOTION, { value: value, valid: isValid, hash: hash });
    setPromotion(value)
    props.validatorInformer(true, data);
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            <div className={style.test2}>
            </div>
            <RadioButton selected={promotion === WELCOME_BONUS_3X} value={WELCOME_BONUS_3X} switchfont={true} img={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Happiness' + (promotion === WELCOME_BONUS_3X ? '-sel' : '') + '.png'))} title={intl.formatMessage({ id: 'newcasino.auth.registration.bonus.cashback', defaultMessage: 'Get 3X your first deposit up to 1500$ in Bonus' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.registration.bonus.cashback.subtitle', defaultMessage: 'Cashback up to $350' })} selectionInformer={promotionSelectedCallBack} imageCheck={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Circle-Check.svg'))} imageNotCheck={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Circle.png'))} />
            <RadioButton selected={promotion === PROMO_CODE} value={PROMO_CODE} img={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Coupon' + (promotion === PROMO_CODE ? '-sel' : '') + '.png'))} title={intl.formatMessage({ id: 'newcasino.auth.registration.bonus.promocode', defaultMessage: 'I have promo code!' })} selectionInformer={promotionSelectedCallBack} imageCheck={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Circle-Check.svg'))} imageNotCheck={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Circle.png'))} />
            <RadioButton selected={promotion === DEFAULT_VALUE} value={DEFAULT_VALUE} img={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_No' + (promotion === DEFAULT_VALUE ? '-sel' : '') + '.png'))} title={intl.formatMessage({ id: 'newcasino.auth.registration.bonus.none', defaultMessage: 'No Bonuses' })} selectionInformer={promotionSelectedCallBack} imageCheck={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Circle-Check.svg'))} imageNotCheck={setTheme(getImageUrl('newui/themes/{THEME}/Register/IMG_Circle.png'))} />
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ChooseBonus