export function canAccess(user, authElement) {
    var toReturn = false;

    if (authElement.authorization) {
        if (authElement.authorization.access) {
            if (authElement.authorization.access.toUpperCase().trim() === 'PUBLIC') {
                toReturn = true;
            }
            else if (authElement.authorization.access.toUpperCase().trim() === 'PRIVATE') {
                //If the user is logged (email not empty)
                if (user && user.email.trim().length > 0) {

                    var typeAccountOk = true;
                    if (authElement.authorization.typeAccount) {
                        if (authElement.authorization.typeAccount.length > 0) {
                            //Must check TypeAccount
                            typeAccountOk = authElement.authorization.typeAccount.includes(user.typeAccount);
                        }
                    }

                    var rolsOk = true;
                    if (authElement.authorization.rols) {
                        if (authElement.authorization.rols.length > 0) {
                            var found = false;
                            for (let i = 0; i < authElement.authorization.rols.length; i++) {
                                var currentRole = '' + authElement.authorization.rols[i];
                                if (user.rols.includes(currentRole)) {
                                    found = true;
                                    break;
                                }
                            }
                            rolsOk = found;
                        }
                    }

                    toReturn = (typeAccountOk && rolsOk);
                }

            }
        }
    } else {
        toReturn = true;
    }
    return toReturn;
}