/* eslint-disable */
import React, { useRef, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import TextField from 'newui/Generic/Inputs/TextField';
import Button from 'newui/Generic/Inputs/Button';
import { useIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha';
import { displayErrorNotificationIntl, displayInfoNotificationIntl, isEmail, isTextMin2Char } from 'newui/Utils/uiUtil';
import DivClick from 'newui/Generic/Inputs/DivClick';
import * as constants from 'newui/constants.js'
import { displayPage } from 'newui/Utils/routerUtil';
import { getImageUrl } from 'newui/Utils/uiUtil';

function ContactUs(props) {
    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [fullNameValid, setFullNameValid] = useState(null);
    const [emailValid, setEmailValid] = useState(null);
    const [subjectValid, setSubjectValid] = useState(null);
    const [messageValid, setMessageValid] = useState(null);

    const captchaRef = useRef(null)

    function nameValidCallBack(isValid, value) {
        setFullNameValid(isValid);
        setFullName(value);
    }

    function emailValidCallBack(isValid, value) {
        setEmailValid(isValid);
        setEmail(value);
    }

    function subjectValidCallBack(isValid, value) {
        setSubjectValid(isValid);
        setSubject(value);
    }

    function messageValidCallBack(isValid, value) {
        setMessageValid(isValid);
        setMessage(value);
    }

    function canSend() {
        return fullNameValid && emailValid && subjectValid && messageValid;
    }

    function sendContactUsMsg(data) {
        let captchaOk = false;
        if (captchaRef && captchaRef.current) {
            if (captchaRef.current.getValue() !== '') {
                captchaOk = true;
            }
        }
        if (captchaOk) {
            sendContactUsMsgJWT(data)
        } else {
            displayErrorNotificationIntl(intl, 'newcasino.contactus.captcha');
        }

    }

    function sendContactUsMsgJWT(data) {
        //setloading(true)

        var theData = {
            fullname: fullName,
            email: email,
            subject: subject,
            message: message,
        }

        jwtBackendService.sendContactUsMsg(theData, successSendContactUsMsgJWT, failureSendContactUsMsgJWT, navigate, location.pathname);
    }

    function successSendContactUsMsgJWT(data) {
        displayInfoNotificationIntl(intl, 'newcasino.contactus.success');
    }
    function openFAQ() {
        displayPage(constants.ROUTE_FAQ, navigate, location.pathname)
    }

    function failureSendContactUsMsgJWT(data) {
        displayErrorNotificationIntl(intl, 'newcasino.contactus.failure');
    }

    return (
        <div className={style.master_page_container}>
            <div className={style.mainTitle}>
                <FormattedMessage id="newcasino.contactus.title" defaultMessage="Contact Us" />
            </div>
            <div className={style.mainDiv}>
                <div className={style.grid50}>
                    <div className={style.width90}>
                        <div className={style.colTitle}>
                            <FormattedMessage id="newcasino.contactus.question" defaultMessage="HAVE A QUESTION? CONTACT US!" />
                        </div>
                        <div><img src={getImageUrl("newui/general/sep.png")} /></div>
                        <div className={style.inputField}><TextField maxLength='100' autofocus='true' values={[fullName, fullNameValid]} hint={intl.formatMessage({ id: 'newcasino.contactus.name.hint', defaultMessage: 'Type your name here' })} label={intl.formatMessage({ id: 'newcasino.contactus.name', defaultMessage: 'Full Name' })} validator={isTextMin2Char} validatorInformer={nameValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.contactus.name.error', defaultMessage: 'Your name is too short' })} /></div>
                        <div className={style.inputField}><TextField maxLength='100' autofocus='false' values={[email, emailValid]} hint={intl.formatMessage({ id: 'newcasino.contactus.name.email.hint', defaultMessage: 'Type your email here' })} label={intl.formatMessage({ id: 'newcasino.contactus.name.email', defaultMessage: 'Email' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.contactus.email.error', defaultMessage: 'Invalid Email address' })} /></div>
                        <div className={style.inputField}><TextField maxLength='100' autofocus='false' values={[subject, subjectValid]} hint={intl.formatMessage({ id: 'newcasino.contactus.subject.hint', defaultMessage: 'Subject' })} label={intl.formatMessage({ id: 'newcasino.contactus.subject', defaultMessage: 'Subject' })} validator={isTextMin2Char} validatorInformer={subjectValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.contactus.subject.error', defaultMessage: 'Subject is too short' })} /></div>
                        <div className={style.inputField}><TextField type='textarea' maxLength='1000' autofocus='false' values={[message, messageValid]} hint={intl.formatMessage({ id: 'newcasino.contactus.message.hint', defaultMessage: 'Message' })} label={intl.formatMessage({ id: 'newcasino.contactus.message', defaultMessage: 'Message' })} validator={isTextMin2Char} validatorInformer={messageValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.contactus.message.error', defaultMessage: 'Message is too short' })} /></div>
                        <div className={style.inputField}><ReCAPTCHA ref={captchaRef} sitekey="6LerDUIhAAAAAH1g40NNYHsebp5m8Le8e1y2H7Dh" /></div>
                        <div className={style.buttCenter}><Button width='100%' active={canSend()} value={intl.formatMessage({ id: 'newcasino.contactus.button.send' })} onClick={sendContactUsMsg} /></div>
                    </div>
                    <div className={style.width90}>
                        <div className={style.colTitle}>
                            <FormattedMessage id="newcasino.contactus.where" defaultMessage="WHERE TO FIND US?" />
                        </div>
                        <div><img src={getImageUrl("newui/general/sep.png")} /></div>


                        <div className={style.findGrid}>
                            <div className={style.boximg2}>
                                <img src={getImageUrl("newui/general/img-location@2x.png")} height="35px" />
                            </div>
                            <div className={style.findGridText}>
                                First Floor, Millennium House,<br />Victoria Road, Douglas, IM24RW, <br />Isle of Man
                            </div>
                        </div>

                        <div className={style.findGrid}>
                            <div className={style.boximg2}>
                                <img src={getImageUrl("newui/general/img-faq@2x.png")} height="35px" />
                            </div>
                            <DivClick className={style.findGridTextPointer} onClick={openFAQ}>
                                Your question may have already
                                been answered, check out our FAQ
                            </DivClick>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    );

}

export default ContactUs;
