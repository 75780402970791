import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
/* 
label
mandatory
values
valueList
selectLabel
showCheck
showTitleError
validator
validatorInformer
labelPosition
onChange
*/

function ComboBox(props) {

  const TRUE = 'true';
  const EMPTY = '';

  const label = props.label ? props.label : null;
  const isMandatory = (props.mandatory === TRUE) ? true : false;

  const [hasError, setHasError] = useState((props.values && props.values[1] != null) ? !props.values[1] : null);
  const [value, setValue] = useState(props.values ? props.values[0] + '' : EMPTY);
  const [options] = useState(createOptions(props.valueList, props.selectLabel));

  const [displayCheck] = useState(props.showCheck === TRUE ? true : false);
  const [displayTitleError] = useState(props.showTitleError === TRUE ? true : false);

  const height = props.height ? props.height : '40px';
  const checkMarginTop = props.checkMarginTop ? props.checkMarginTop : '5px';
  const border = props.border ? props.border : '1px solid #707070';

  useEffect(() => {
    if (props.validateOnStart === true) {
      performValidation(value);
    }
    // eslint-disable-next-line
  }, []);

  function performValidation(newValue) {
    if (props.validator) {
      var isValid = validateValue(newValue);
      props.validatorInformer(isValid, newValue)
      setHasError(!isValid)
    }
  }

  /*************************************************************
   * newValue(newValue)
   *************************************************************/
  function newValue(newValue) {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue)
    }
    performValidation(newValue)
  }

  /*************************************************************
   * validateValue(toCheck) {
   *************************************************************/
  function validateValue(toCheck) {
    var trimmedValue = toCheck.trim();

    var toReturn = true;
    if (props.validator) {
      if (trimmedValue.length > 0) {
        if (!props.validator(trimmedValue)) {
          toReturn = false;
        }
      } else {
        toReturn = !isMandatory;
      }
    }

    return toReturn;
  }


  function getTopTitleStyle() {
    var toReturn = style.topTitle;
    if (props.labelPosition === 'center') {
      toReturn = style.topTitleCenter;
    }
    return toReturn;
  }

  function createOptions(theRawList, selectLabel) {
    var toReturn = [];

    if (selectLabel !== undefined) {
      toReturn.push(<option key={Math.random() + '_theList_sel'} value={selectLabel.value}>{selectLabel.label}</option>)
    }

    for (let i = 0; i < theRawList.list.length; i++) {
      var currentValue = theRawList.list[i];
      toReturn.push(<option key={Math.random() + '_theList_' + i} value={currentValue[theRawList.key]}>{currentValue[theRawList.value]}</option>)
    }
    return toReturn;
  }

  function getGridStyle() {
    if (displayCheck) {
      return style.borderMenu_search_grid_2
    } else {
      return style.borderMenu_search_grid_1
    }
  }

  try {
    return (
      <div className={props.className ? props.className : ''}>
        <div className={style.baseGrid}>
          {label &&
            <div className={`${getTopTitleStyle()} ${props.topTitleStyle ? props.topTitleStyle : null}`}>
              {label} <span className={style.mandatory}>{(isMandatory) ? ('*') : EMPTY}</span> {(hasError && displayTitleError) ? (' - ') : EMPTY}<span className={style.error}>{(hasError && displayTitleError) ? (props.validationMsg) : EMPTY}</span>
            </div>
          }
          <div className={`${style.borderMenu_search_grid2}`}>
            <div style={{ height: height, border: border }} className={style.baseUI}>
              <div className={`${getGridStyle()}`}>
                <div className={props.styleInput ? props.styleInput : style.input}>
                  <select className={style.input_tag} onChange={(e) => newValue(e.target.value)} value={value}>
                    {options}
                  </select>
                </div>
                {(displayCheck && hasError !== null && hasError === true) &&
                  <div style={{ marginTop: checkMarginTop }} className={style.checkNo}>
                    <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                  </div>
                }
                {(displayCheck && ((hasError !== null) && (hasError === false))) &&
                  <div style={{ marginTop: checkMarginTop }} className={style.check}>
                    <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                  </div>
                }
              </div>
            </div>
          </div>
        </div >
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ComboBox

