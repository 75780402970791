import React, { useState } from 'react'
import style from './style.module.scss'
import background from "./noimg.png";
import DivClick from '../DivClick';
import { getImageUrl } from 'newui/Utils/uiUtil';

/*
Image Loader : Display a single image once loaded. It simply use a hidden image
               to load the image and once it is loaded set it as background image

PARAMS: image : (String)     Url of the image (ex: 'http://whatever.com/img.png')
        width : (String)     Width of the image default 100% (ex: '200px')
        disabled : (boolean) Disable the image (grayout and not onClick action)

        onClick : (function)        : Call when image is clicked
        onClickDisabled (function)  : Call when image is clicked and is disabled
*/

function ImageLoader(props) {

  const image = props.image;
  const width = props.width;
  const disabled = (props.disabled === true) ? true : false;
  const styleBase = style.imageLoader_bgContainer;
  const styleBaseLoaded = style.imageLoader_bgContainer_loaded;
  const styleBaseLoadedClick = style.imageLoader_bgContainer_loaded_click;
  const styleBaseLoadedDisabled = style.imageLoader_bgContainer_disabled

  const [bgImage, setbgImage] = useState(background);
  const [bgStyle, setBgStyle] = useState(styleBase);

  function imageLoaded() {
    setbgImage(image);
    setBgStyle(disabled ? styleBaseLoadedDisabled : (props.onClick ? styleBaseLoadedClick : styleBaseLoaded))
  }

  function onClick() {
    if (disabled) {
      if (props.onClickDisabled) {
        props.onClickDisabled();
      }
    } else {

      if (props.onClick) {
        props.onClick();
      }
    }
  }

  function getPriorityLoading(index) {
    return 'auto';
  }

  try {
    return (
      <div className={`${style.imageLoader_div}`} style={{ width: width ? width : '100%' }}>
        <div className={`${style.imageLoader_centerize}`}>
          <DivClick className={`${bgStyle}`} onClick={onClick} style={{ backgroundImage: `url(${bgImage})` }}>
            <img onLoad={imageLoaded} fetchpriority={getPriorityLoading(1)} className={`${style.imageLoader_image}`} src={image} alt='' onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = getImageUrl('newui/ManageGames/nogameImage.png');
              currentTarget.style = "visibility='visible' !important;"
            }} />
          </DivClick>
        </div>
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ImageLoader

