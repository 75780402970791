/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import Header from 'newui/Casino/General/Modal/Header';
import Footer from 'newui/Casino/General/Modal/Footer';
import TextField from 'newui/Generic/Inputs/TextField';
import { displayGenericErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, getAmountTxt, getAmountTxtWithCoin } from 'newui/Utils/uiUtil';


function AddMoney(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [amount, setAmount] = useState('');

    function addMoneyAction() {
        jwtBackendService.manageBalance(2, 0, amount, 0, '', props.userId, successManageBalanceJWT, failureManageBalanceJWT, navigate, location.pathname);
    }


    function successManageBalanceJWT(data) {

        if (amount > 0) {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title' }), intl.formatMessage({ id: 'newcasino.admin.money.add.success' }, { AMOUNT: (getAmountTxtWithCoin(amount, props.coin)) }));
        } else {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title' }), intl.formatMessage({ id: 'newcasino.admin.money.remove.success' }, { AMOUNT: (getAmountTxtWithCoin(Math.abs(amount), props.coin)) }));
        }

        props.reload();
        props.closeModal();
    }

    function failureManageBalanceJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    try {
        var header = null;
        var footer = null;

        header = <Header title={intl.formatMessage({ id: 'newcasino.admin.user.popup.cash.title', defaultMessage: 'Add Real Money' })} subTitle={intl.formatMessage({ id: 'newcasino.admin.user.popup.cash.subtitle', defaultMessage: 'Add Real Money To Wallet' })} closeModal={props.closeModal} />
        footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.cash.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.cash.button.add', defaultMessage: 'Add' }), action: addMoneyAction, canProcess: true }} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    <div className={style.grid50}>
                        <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.popup.cash.amount.label', defaultMessage: 'Amount' })} hint={intl.formatMessage({ id: 'newcasino.admin.user.popup.cash.amount.hint', defaultMessage: 'Amount' })} values={[amount]} onChange={setAmount} type='2decimalsWithMinus' />
                    </div>
                    <div>
                        {footer}
                    </div>
                </div>
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default AddMoney;
