import * as jwtRestService from './jwtRestService';
import * as rsaService from './crypto/rsaService';

/*******************************************************************************************
* LOAD CONFIGURATIONS
********************************************************************************************/
export function loadConfigurations(jsonConfigurationLocation, handleSuccessGetConfiguration, handleErrorGetConfiguration) {
  jwtRestService.asyncGetPlainJson(jsonConfigurationLocation, handleSuccessGetConfiguration, handleErrorGetConfiguration);

}

/*******************************************************************************************
* LOGIN
********************************************************************************************/
export async function login(email, password, stayConnected, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      var keyPair = rsaService.generateKeyPair();

      var data = {
        "email": email,
        "password": password,
        "keepSessionActive": stayConnected,
        "nbg": keyPair.PublicKey64
      }
      jwtRestService.asyncPost('/api/public/LOGIN', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname, keyPair);
    }, 100);
  });

}

/*******************************************************************************************
* CONTACT US
********************************************************************************************/
export function sendContactUsMsg(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/CONTACT', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* RESET PASSWORD
********************************************************************************************/
export function passwordReset(email, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/PASSWORD_RECOVERY_SEND', email, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* RESEND VERIFICATION EMAIL
********************************************************************************************/
export function resendVerificationEmail(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/RESEND_VERIFICATION_EMAIL', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* RESEND VERIFICATION SMS
********************************************************************************************/
export function resendVerificationSms(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/RESEND_VERIFICATION_SMS', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}



/*******************************************************************************************
* Account Verification
********************************************************************************************/
export function accountVerification(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/VERIFY_ACCOUNT', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* EMAIL CHANGE VERIFY
********************************************************************************************/
export function changeEmailVerify(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/EMAIL_CHANGE_VERIFY', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* RESET PASSWORD WITH HASH AND PASSWORD
********************************************************************************************/
export function resetPasswordWithHash(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/PASSWORD_RECOVERY_VERIFY', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* RESET PASSWORD VERIFY HASH
********************************************************************************************/
export function resetPasswordVerifyHash(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/PASSWORD_RECOVERY_FIND_HASH', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* REGISTRATION
********************************************************************************************/
export function createAccount(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/REG_STEP_1', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function addInfoToAccount(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/REG_STEP_2', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function validateMobileCode(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/REG_STEP_3', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function validateEmailCode(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/public/REG_STEP_4', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}


/*******************************************************************************************
* LOGOUT
********************************************************************************************/
export function logout(navigate, currentPathname) {
  //Tell backend that the user logout
  jwtRestService.asyncGet('/api/public/LOGOUT', true, false, doNothingCallBack, doNothingCallBack, navigate, currentPathname);

  //Remove Session object
  jwtRestService.cleanUp();

}

/*******************************************************************************************
* TOP MENU
********************************************************************************************/
export function casino_get_wallet_total(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  //GET_WALLET_TOTAL
  jwtRestService.asyncGet('/api/private/NB_GET_WALLETS', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}


/*******************************************************************************************
* SWITCH WALLET
********************************************************************************************/
export function switchWallet(walletId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = { walletId: walletId }
  jwtRestService.asyncPost('/api/private/NB_SWITCH_WALLET', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* HOME PAGE
********************************************************************************************/
export function casino_get_popup(isLogged, data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var url = '/api/public/GET_POPUP'

  if (isLogged) {
    url = '/api/private/GET_POPUP'
    jwtRestService.asyncPost(url, data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
  } else {
    jwtRestService.asyncPost(url, data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
  }
}

export function closePopup(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/ACTION_POPUP', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getDataCasino(isLogged, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var url = '/api/public/GET_DATA_CASINO'

  if (isLogged) {
    url = '/api/private/GET_DATA_CASINO'
    jwtRestService.asyncPost(url, { type: 'all' }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
  } else {
    jwtRestService.asyncPost(url, { type: 'all' }, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
  }
}

/*******************************************************************************************
* POPUP MANAGEMENT
********************************************************************************************/
export function getPopupList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_POPUPS_LIST', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getPopupData(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/GET_POPUP_DATA', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updatePopup(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/ADD_EDIT_POPUP', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* MAINTENANCE
********************************************************************************************/
/*
export function getMaintenanceInformation(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/MAINTENANCE_STATUS', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function toggleMaintenance(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/MAINTENANCE_TOGGLE', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}
*/

/*******************************************************************************************
* Play Game
********************************************************************************************/
export function casino_get_gameData(url, data, isPrivate, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost(url, data, isPrivate, isPrivate, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* REFER A FRIEND WAGER (OLD)
********************************************************************************************/
export function getReferFriendInfo(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/REFER_FRIEND_WAGER', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function claimReferAFriendWager(idList, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = { dataPayload: idList }
  jwtRestService.asyncPost('/api/private/REFER_FRIEND_PROCESS_WAGER_REQUEST', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* REFER A FRIEND CASH
********************************************************************************************/
export function getReferFriendInfoCash(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/REFER_FRIEND_CASH', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function claimReferAFriendCash(idList, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = { dataPayload: idList }
  jwtRestService.asyncPost('/api/private/REFER_FRIEND_PROCESS_CASH_REQUEST', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* COUNTRY LIST
********************************************************************************************/
export function casino_get_country_list(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/public/COUNTRIES_GET', false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* PNG SHOP
********************************************************************************************/
export function loadShop(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/SHOP_LOAD', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function buyElementFromShop(elementId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/SHOP_BUY', { elementId: elementId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* CONTEST WINNERS
********************************************************************************************/
export function getContestInformation(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/GET_CONTEST_DATA', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getContestList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_CONTEST_LIST', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateContest(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/ADD_EDIT_CONTEST', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}


/*******************************************************************************************
* STREAMERS ADMIN
********************************************************************************************/
export function getStreamerList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_ALL_STREAMERS', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function resetAllVault(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/RESET_ALL_VAULT', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getStreamerDeals(streamerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = { streamerID: streamerId }
  jwtRestService.asyncPost('/api/private/GET_STREAMER_DEALS', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateStreamerDeals(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/UPDATE_STREAMER_DEALS', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* STREAMERS
********************************************************************************************/
export function getStreamingInfo(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_STREAMER_INFO', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getBalance(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/NB_REQUEST_WAGER_MONEY', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function giveAwayMoney(giveAwayEmail, giveAwayAmount, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var theData = {
    beneficiaryEmail: giveAwayEmail,
    amount: giveAwayAmount,
  }
  jwtRestService.asyncPost('/api/private/CREATE_DATA_GIVEAWAYS', theData, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}


/*******************************************************************************************
* PROMOTIONS
********************************************************************************************/
export function getPromotionsList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/NB_PROMOTION_LIST', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getPromotion(id, pageNumber, sortColumn, sortAsc, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let theData = {
    promId: id,
    pageNumber: pageNumber,
    elemPerPage: 10,
    sortColumn: sortColumn,
    sortAsc: sortAsc
  }
  jwtRestService.asyncPost('/api/private/NB_PROMOTION_GET', theData, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function deletePromotion(id, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let theData = {
    promId: id
  }
  jwtRestService.asyncPost('/api/private/NB_PROMOTION_DELETE', theData, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function deactivatePromotion(id, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let theData = {
    promId: id
  }
  jwtRestService.asyncPost('/api/private/NB_PROMOTION_DEACTIVATE', theData, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function activatePromotion(id, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let theData = {
    promId: id
  }
  jwtRestService.asyncPost('/api/private/NB_PROMOTION_ACTIVATE', theData, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function addPromotion(theUserList, data, successCallbackFunction, failureCallbackFunction, navigate, location) {

  var formData = new FormData()

  if (data.audienceType === 2 && theUserList && theUserList.name) {
    var extension = theUserList.name.substr(theUserList.name.lastIndexOf('.')).toLowerCase();
    formData.append("document", theUserList);
    formData.append("extension", extension);
    formData.append("type", theUserList.type);
    formData.append("uploadType", 'csv');
  }

  formData.append("promo", JSON.stringify(data));

  jwtRestService.asyncUploadFile('/api/private/NB_PROMOTION_ADD', formData, true, false, successCallbackFunction, failureCallbackFunction, navigate, location);
}

/*******************************************************************************************
* GIVEAWAY
********************************************************************************************/
export function getNonApprovedGiveaways(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_NON_APPROVED_GIVEAWAYS', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function saveGiveawaysApproval(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/SAVE_NON_APPROVED_GIVEAWAYS', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateGiveAwayStatus(giveAwayId, status, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = {
    giveAwayId: giveAwayId,
    status: status
  }

  jwtRestService.asyncPost('/api/private/UPDATE_DATA_GIVEAWAYS', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* PROFILE
********************************************************************************************/
export function getImageFile(type, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_USER_DOC_IMAGE?type=' + type, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}


export function uploadProofImageFile(theImage, docType, successCallbackFunction, failureCallbackFunction, navigate, location) {

  var formData = new FormData()
  var extension = theImage.name.substr(theImage.name.lastIndexOf('.') + 1).toLowerCase();
  formData.append("document", theImage);
  formData.append("extension", extension);
  formData.append("type", theImage.type);
  formData.append("uploadType", docType);

  jwtRestService.asyncUploadFile('/api/private/UPLOAD_PROOF_IMAGE', formData, true, false, successCallbackFunction, failureCallbackFunction, navigate, location);

}

export function loadPersonalData(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_PERSONAL_DATA', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updatePersonalData(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/CHANGE_PERSONAL_DATA', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateMyPersonalData(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/CHANGE_PERSONAL_DATA_USER', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function changePassword(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/CHANGE_PASSWORD', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function sendSmsVerificationCode(phoneNumber, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  const data = {
    phoneNumber: phoneNumber
  }
  jwtRestService.asyncPost('/api/private/SEND_SMS_VERIFICATION_CODE', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function verifySmsVerificationCode(phoneNumber, code, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  const data = {
    phoneNumber: phoneNumber,
    code: code
  }
  jwtRestService.asyncPost('/api/private/VERIFY_SMS_VERIFICATION_CODE', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function changeEmail(newEmail, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  const data = {
    newEmail: newEmail
  }

  jwtRestService.asyncPost('/api/private/EMAIL_CHANGE_SEND', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function uploadUserFile(formData, successCallbackFunction, failureCallbackFunction, navigate, location) {
  jwtRestService.asyncUploadFile('/api/private/UPLOAD_IF_PROOF', formData, true, false, successCallbackFunction, failureCallbackFunction, navigate, location);
}

/*******************************************************************************************
* User Management
********************************************************************************************/
export function getUserImageFile(userId, type, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_USER_DOC_ADMIN_IMAGE?type=' + type + '&userid=' + userId, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getUserListCSVFile(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncDownload('/api/private/GET_USER_LIST_CSV', true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function resetPhoneVerification(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/RESET_PHONE_VERIFICATION', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateUserPersonalData(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/CHANGE_USER_PERSONAL_DATA_USER', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function approveKyc(userId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    userId: userId
  }

  jwtRestService.asyncPost('/api/private/APPROVE_KYC', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function rejectKyc(userId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    userId: userId
  }

  jwtRestService.asyncPost('/api/private/REJECT_KYC', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getUserListWithFilter(pageNumber, nbrElements, userType, onlyBanned, kycNotApproved, filter, orderColumn, desc, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    pageNumber: pageNumber,
    nbrElements: nbrElements,
    userType: userType,
    onlyBanned: onlyBanned,
    kycNotApproved: kycNotApproved,
    filter: filter,
    orderColumn: orderColumn,
    desc: desc,
    exportCsv: 0
  }

  jwtRestService.asyncPost('/api/private/NB_USER_LIST', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function exportPromotionCSV(promId, field, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    promId: promId,
    field: field,
  }

  //jwtRestService.asyncSecurePostDownload('/api/private/NB_USER_LIST', data, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
  jwtRestService.asyncSecurePostDownload('/api/private/NB_PROMOTION_CSV', data, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function exportUserListWithFilter(userType, onlyBanned, kycNotApproved, filter, orderColumn, desc, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    pageNumber: -1,
    nbrElements: -1,
    userType: userType,
    onlyBanned: onlyBanned,
    kycNotApproved: kycNotApproved,
    filter: filter,
    orderColumn: orderColumn,
    desc: desc,
    exportCsv: 1
  }

  jwtRestService.asyncSecurePostDownload('/api/private/NB_USER_LIST', data, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getWalletDetailsWithFilter(walletId, walletType, pageNumber, nbrElements, filter, orderColumn, desc, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    walletId: walletId,
    walletType: walletType,
    pageNumber: pageNumber,
    nbrElements: nbrElements,
    filter: filter,
    orderColumn: orderColumn,
    desc: desc
  }

  jwtRestService.asyncPost('/api/private/NB_WALLET_DETAIL', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getTransactionInformation(txId, walletId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    txId: txId,
    walletId: walletId
  }

  jwtRestService.asyncPost('/api/private/NB_WALLET_TRANSACTION_INFO', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getUserInformation(userId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data = {
    userId: userId
  }

  jwtRestService.asyncPost('/api/private/NB_USER_INFO', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getUserList(pagination, filters, sorter, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  const data = {
    pagination: pagination,
    filters: filters,
    sorter: sorter,
  }
  jwtRestService.asyncPost('/api/private/NB_GET_USER_LIST', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getAllUsersList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_ALL_USER_LIST', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getSpecificUserInformation(idUser, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_GET_USER_DATA', { idUser }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getSpecificUserInformationWithFilter(idUser, pagination, filters, sorter, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_GET_USER_DATA_WITH_FILTER', { idUser: idUser, pagination: pagination, filters: filters, sorter: sorter }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getDeleteManuallyEntry(values, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/DELETE_MANUALLY_ENTRY', { id: values }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function addPngPoints(nbr, walletId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_ADD_PNGPOINTS', { nbr: nbr, walletId: walletId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function addFreeSpins(nbr, walletId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_ADD_FREESPINS', { nbr: nbr, walletId: walletId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function infoFreeSpins(userId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_INFO_FREESPINS', { userId: userId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function useFreeSpins(gameId, nbrFreeSpins, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_USE_FREESPINS', { gameId: gameId, nbrFreeSpins: nbrFreeSpins }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function manageBalance(typeId, xWager, balanceAmount, max, expiration, playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_MANAGE_BALANCE', { maxWin: max, expirationDate: expiration, typeId: typeId, xWager: xWager, balanceAmount: balanceAmount, playerId: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function wagerBatch(xWager, balanceAmount, max, expiration, userList, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_BATCH_WAGER', { maxWin: max, expirationDate: expiration, xWager: xWager, balanceAmount: balanceAmount, userList: userList }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function smsAnswer(phoneNumber, answer, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/NB_USER_SMS_ANSWER', { phoneNumber: phoneNumber, answer: answer }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function deactivateTakeBreak(playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/DEACTIVATED_TAKE_A_BREAK', { playerId: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function deactivateSelfExclusion(playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/DEACTIVATED_SELF_EXCLUSION', { playerId: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function switchUserActivation(playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/ACTIVE_OR_DEACTIVATED_USER', { playerId: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateUserType(userType, playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/UPDATE_USER_TYPE', { userType: userType, idUser: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updatePhoneStatus(userStatus, playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/UPDATE_PHONE_STATUS', { userStatus: userStatus, idUser: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateUserStatus(userStatus, playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/UPDATE_USER_STATUS', { userStatus: userStatus, idUser: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function fullKycAction(status, playerId, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/FULL_KYC_ACTION', { status: status, playerId: playerId }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* CASHIER
********************************************************************************************/
export function getDepositInformation(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/NB_GET_DATA_DEPOSIT', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* Responsable Gaming
********************************************************************************************/
export function getUserResponsibleGamblingInfo(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_USER_RESPONSIBLE_GAMING_INFO', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateUserResponsibleGamblingInfo(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/UPDATE_USER_RESPONSIBLE_GAMING_INFO', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getActivityCheckData(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_ACTIVITY_CHECK_DATA', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function changeActivityCheck(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/CHANGE_ACTIVITY_CHECK_DATA', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getSelfExclusionData(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_SELF_EXCLUSION_DATA', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function changeSelfExclusionData(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/CHANGE_SELF_EXCLUSION_DATA', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getTakeABreakData(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_TAKE_BREAK_DATA', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function updateTakeABreakData(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/CHANGE_TAKE_BREAK_DATA', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* Game Management Section
********************************************************************************************/
export function getManageGamesInformation(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncPost('/api/private/GET_MANAGE_CASINO', { type: 'all' }, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function changeGameOrder(sourceId, targetId, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: { source: sourceId, target: targetId } }
  jwtRestService.asyncPost('/api/private/NB_ORDER_GAMES', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function addModifyProvider(newProvider, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: newProvider }
  jwtRestService.asyncPost('/api/private/ADD_EDIT_PROVIDER', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function addModifyCategory(newCategory, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: newCategory }
  jwtRestService.asyncPost('/api/private/ADD_EDIT_CATEGORY', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function addModifyType(newType, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: newType }
  jwtRestService.asyncPost('/api/private/ADD_EDIT_TYPE', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function addModifySubType(newSubType, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: newSubType }
  jwtRestService.asyncPost('/api/private/ADD_EDIT_SUBTYPE', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function addModifyGame(newGame, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: newGame }
  jwtRestService.asyncPost('/api/private/ADD_EDIT_GAME', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function deleteGame(game, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: game }
  jwtRestService.asyncPost('/api/private/DELETE_GAME', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function deleteProvider(provider, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: provider }
  jwtRestService.asyncPost('/api/private/DELETE_PROVIDER', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function deleteCategory(category, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: category }
  jwtRestService.asyncPost('/api/private/DELETE_CATEGORY', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function deleteType(type, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: type }
  jwtRestService.asyncPost('/api/private/DELETE_TYPE', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function deleteSubType(subType, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = { object: subType }
  jwtRestService.asyncPost('/api/private/DELETE_SUBTYPE', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function uploadGameImageFile(formData, successCallbackFunction, failureCallbackFunction, navigate, location) {
  jwtRestService.asyncUploadFile('/api/private/UPLOAD_GAME_IMAGE', formData, true, false, successCallbackFunction, failureCallbackFunction, navigate, location);
}


/*******************************************************************************************
 * LOYALTY
 *******************************************************************************************/
export function getLoyaltyInformation(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/LOYALTY', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function loyaltyClaimDaily(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/LOYALTY_DAILY_CLAIM', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

/*******************************************************************************************
* AFFILIATES
********************************************************************************************/
export function getAffiliateDashboard(period, theUserEmail, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var theUrl = '/api/private/AFF_DASHBOARD?period=' + period;

  if (theUserEmail) {
    theUrl = theUrl + '&email=' + theUserEmail;
  }

  jwtRestService.asyncGet(theUrl, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function payStreamer(trashed, streamerId, amount, currency, month, year, history, successCallbackFunction, failureCallbackFunction, navigate, location) {
  const data = {
    trashed: trashed,
    streamerId: streamerId,
    amount: amount,
    currency: currency,
    month: month,
    year: year,
    history: history
  }

  jwtRestService.asyncPost('/api/private/AFF_PAY', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, location);
}

export function getAffiliateCommissions(year, period, theUserEmail, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var theUrl = '/api/private/AFF_COMMISSIONS?year=' + year + '&period=' + period;

  if (theUserEmail) {
    theUrl = theUrl + '&email=' + theUserEmail;
  }

  jwtRestService.asyncGet(theUrl, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function registrationAffiliate(fullName, cieName, email, currency, country, dob, password, webSite, streamPage, avgViews, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = {
    fullName: fullName,
    cieName: cieName,
    email: email,
    currency: currency,
    country: country,
    dob: dob,
    password: password,
    webSite: webSite,
    streamPage: streamPage,
    avgViews: avgViews
  }
  jwtRestService.asyncPost('/api/public/AFF_REGISTER', data, false, false, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function getAffiliates(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtRestService.asyncGet('/api/private/GET_USER_LIST_AFFILIATES', true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}


export function approveAffiliate(email, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = {
    email: email,
    userType: 5,
    commissionType: 1
  }

  jwtRestService.asyncPost('/api/private/AFF_APPROVE', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function rejectAffiliate(email, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var data = {
    email: email
  }

  jwtRestService.asyncPost('/api/private/AFF_REJECT', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}


/*******************************************************************************************
* test
********************************************************************************************/
export function test(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {

  var data = {
    "txt": 'Hello Server'
  }

  jwtRestService.asyncPost('/api/private/TEST', data, true, true, successCallbackFunction, failureCallbackFunction, navigate, currentPathname);
}

export function doNothingCallBack() {

}