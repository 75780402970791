import React from 'react'
import style from './style.module.scss'
import LoyaltyHow from '../LoyaltyHow';

function LoyaltyHowPopup(props) {

  try {
    return (
      <div className={style.mainDiv}>
        <LoyaltyHow />
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}
export default LoyaltyHowPopup