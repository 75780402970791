import React, { useState } from 'react'
import style from './style.module.scss'
import DivClick from '../DivClick';
/* 
chevron
colorStyle
active
value
onClick
icon
iconTextAlign
*/
function Switch(props) {
  const [value, setValue] = useState(props.initialValue);

  function toggleButton() {
    var newValue = !value;
    setValue(newValue);
    props.onClick(newValue);
  }

  try {
    return (
      <div className={style.main}>
        <DivClick onClick={toggleButton}>
          <div className={style.design}></div>
          <div className={value ? style.ballOn : style.ballOff}></div>
          <div className={value ? style.theTextOn : style.theTextOff}>{value ? props.onValue : props.offValue}</div>
        </DivClick >
      </div>

    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Switch

