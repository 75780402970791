import React from 'react'
import { createRoot } from 'react-dom/client';
import AffiliatesPage from 'newui/Affiliates';
import ApiPersistence from 'newui/Utils/ApiPersistence';
import HomePage from 'newui/Casino';

const container = document.getElementById('root');
const root = createRoot(container);
var forceAffiliate = false;

if (window.location.href === 'https://test.playnumbersgame.com/forceAff') {
  ApiPersistence.getInstance().set('test', '2');
  window.location = 'https://test.playnumbersgame.com/';
} else if (window.location.href === 'https://test.playnumbersgame.com/forceCas') {
  ApiPersistence.getInstance().delete('test')
  window.location = 'https://test.playnumbersgame.com/';
}

if (document.location.hostname === 'test.playnumbersgame.com') {
  if (ApiPersistence.getInstance().get('test') === '2') {
    forceAffiliate = true;
  }
}

if (document.location.hostname === 'affiliate.local' || document.location.hostname === 'affiliatepng.com' || document.location.hostname === 'www.affiliatepng.com' || forceAffiliate) {
  document.documentElement.setAttribute('data-theme', 'affiliates'); //FOR NEW UI  
  root.render(
    <AffiliatesPage />
  );
} else {
  document.documentElement.setAttribute('data-theme', ApiPersistence.getInstance().getTheme()); //FOR NEW UI
  root.render(
    <HomePage />
  );
}