export default class ApiCache {

    static createTime = Date.now();
    static LIFETIME = 14400000 // 4 hours

    static defaultInstance = null;

    resultMap = new Map();

    /**
    * @returns {ApiCache}
    */
    static getInstance() {
        if (ApiCache.defaultInstance == null) {
            ApiCache.defaultInstance = new ApiCache();
        }

        if (ApiCache.defaultInstance.isExpired()) {
            ApiCache.createTime = Date.now();
            ApiCache.defaultInstance = new ApiCache();
        }

        return ApiCache.defaultInstance;
    }

    isExpired() {
        if (Date.now() - ApiCache.LIFETIME > ApiCache.createTime) {
            return true;
        }

        return false;
    }

    removeFromCache(key) {
        this.resultMap.delete(key);
    }
    setInCache(key, value) {
        this.resultMap.set(key, value);
    }

    getFromCache(key) {
        return this.resultMap.get(key);
    }

    isInCache(key) {
        return (this.resultMap.get(key) !== null && this.resultMap.get(key) !== undefined);
    }
}