/* eslint-disable */
import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';

function BettingRules(props) {
    return (
        <div className={style.master_page_container}>
            {props.header !== false &&
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.tochange"
                        defaultMessage="Betting Rules"
                    />
                </div>
            }
            <div className={style.mainDiv}>
                <div className={style.header1}>Sportsbook Rules</div>
                <div className={style.header2}>1.Introduction</div>
                <div className={style.parag}>
                    1.1. This set of terms and conditions govern the use of the
                    Sportsbook platform. When placing a bet with Sportsbook
                    platform, the Account Holder is therefore agreeing that the
                    Account Holder has read, understood and will be adhering to
                    these Terms and Conditions including the general Terms and
                    Conditions at any time applicable to Sportsbook platform.
                </div>
                <div className={style.parag}>
                    1.2. The use of this Sportsbook platform is subject to the
                    regulations imposed by the (лицензия).
                </div>
                <div className={style.parag}>
                    1.3. Any dispute relating in any way to the use of this
                    Sportsbook platform should be emailed to (почта саппорта)
                    Should the reply not be considered satisfactory, a request for
                    confidential arbitration can be sent to the (лицензия). Their
                    decision shall be binding and may be entered as a judgment in
                    any court of competent jurisdiction.
                </div>
                <div className={style.parag}>
                    1.4. Sportsbook platform reserves the right to make changes to
                    the site, betting limits, payout limits and offerings.
                </div>
                <div className={style.parag}>
                    1.5. Sportsbook platform may update, amend, edit and
                    supplement these Terms and Conditions at any time.
                </div>
                <div className={style.parag}>
                    1.6. Any reference in these Terms and Conditions to
                    words/objects that appear in singular also applies to plural.
                    References to gender are non-binding and to be treated for
                    information purposes only.
                </div>

                <div className={style.header2}>2. Definition.</div>
                <div className={style.parag}>
                    2.1. Sportsbook platform – legal entity engaged in betting
                    activities in accordance with the licensing and legislative
                    requirements of the country.
                </div>
                <div className={style.parag}>
                    2.2. Client - an individual who has agreed to the Rules for
                    accepting bets, registered on the site (сайт) (and other
                    subdomain versions of the site).
                </div>
                <div className={style.parag}>
                    2.3. Bet – risk-based agreement concluded between the Client
                    and the Betting Company on the outcome of an event in which
                    they do not participate, involving a win. Bets are made on
                    terms previously proposed by the Sportsbook platform.
                </div>
                <div className={style.parag}>
                    2.4. Stake - the amount of money transferred by the client to
                    Sportsbook platform and which are the main condition for
                    participation in a bet in accordance with these Rules.
                </div>
                <div className={style.parag}>
                    2.5. The outcome is the result of the event on which the
                    Sportsbook platform is invited to make a bet.
                </div>
                <div className={style.parag}>
                    2.6. Odd — the number by which the bet amount of the
                    stakeholder is multiplied when determining the payout amount
                    if bet win.
                </div>
                <div className={style.parag}>
                    2.7. Winnings - cash to be paid to the Client upon the
                    outcome, on which a bet was made.
                </div>
                <div className={style.header2}>2.8. Bonuses:</div>
                <div className={style.parag}>
                    2.8.1. Freebet - free bet, client get just won amount on his
                    account. For example, freebet 5 on odd 3.5 client get 5*3.5 -
                    5 = 12.50
                </div>
                <div className={style.parag}>
                    2.8.2. Free money - client get bet amount and won on his
                    account. For example, freebet 5 on odd 3.5 client get 5*3.5 =
                    18.50
                </div>
                <div className={style.parag}>
                    2.8.3. Bet without risk - player use his money for bet, but if
                    bet lost he get his money back.
                </div>

                <div className={style.header2}>3. Betting rules:</div>
                <div className={style.parag}>
                    3.1. Sportsbook platform reserves the right to cancel any bet
                    made on obviously “bad” odds, switched odds or a bet made
                    after an event has started.
                </div>
                <div className={style.parag}>
                    3.2. All bets accepted by Sportsbook platform are subject to
                    these rules, as well as to applicable licence conditions.
                </div>
                <div className={style.parag}>
                    3.3. Sportsbook platform reserves the right to refuse,
                    restrict, cancel or limit any bet.
                </div>
                <div className={style.parag}>
                    3.4. Sportsbook platform reserves the right to settled after
                    the contest is final or with official results.
                </div>
                <div className={style.parag}>
                    3.5. The winner of an event will be determined on the date of
                    the event’s conclusion. Sportsbook platform does not recognize
                    protested or overturned decisions for betting purposes. The
                    settlement of an event suspended after the start of
                    competition will be decided according to the betting rules
                    specified for that sport by Sportsbook platform.
                </div>
                <div className={style.parag}>
                    3.6. No one under the age of 18 is permitted to make a bet.
                </div>
                <div className={style.parag}>
                    3.7. All rules contained herein are subject to changes and
                    revisions by Sportsbook platform without prior written notice.
                    All changes and revisions to our rules will be posted on the
                    Sportsbook platform website.
                </div>
                <div className={style.parag}>
                    3.8. Maximum bet amounts on all sporting events will be
                    determined by Sportsbook platform and are subject to change
                    without prior written notice. Sportsbook platform also
                    reserves the right to adjust limits on individual accounts as
                    well.
                </div>

                <div className={style.parag}>
                    3.9. For accounts with minus balances, Sportsbook platform
                    reserves the right to cancel any pending plays, whether placed
                    with funds resulting from the error or not.
                </div>
                <div className={style.parag}>
                    3.10. Members are solely responsible for their own account
                    transactions. Please be sure to review and confirm your bets
                    for any mistakes before sending them in. Once a transaction is
                    complete, it cannot be changed. Sportsbook platform does not
                    take responsibility for missing or duplicate bets made by the
                    client and will not entertain requests for alterations because
                    a play is missing or duplicated. Clients may review their
                    transactions in the “My Bets” of the site after each session
                    to ensure all requested bets were accepted.
                </div>
                <div className={style.parag}>
                    3.11. Disputes must be lodged within seven (7) days from the
                    date the bet in question has been decided. No claims will be
                    honoured after this period. The client is solely responsible
                    for their account transactions.
                </div>
                <div className={style.parag}>
                    3.12. Winnings will always be calculated using Decimal Odds.
                    Please note, that when converting odds into the British
                    standard, round-off errors may occur, since some odds don’t
                    have an exact translation into British-style fractions. Here,
                    we’ll show the nearest fractional odds.
                </div>
                <div className={style.parag}>
                    3.13. Sportsbook platform reserves the right to suspend a
                    client account without prior notice.
                </div>
                <div className={style.parag}>
                    3.14. In the event of there being a discrepancy between the
                    English language version of these rules and any other language
                    version, the English language version will be deemed to be
                    correct.
                </div>
                <div className={style.parag}>
                    3.15. Combo (accumulators, parlays, multis). If certain
                    outcomes are related example: place bet on Barcelona to win
                    the La Liga combined with a Barcelona win in the deciding
                    game, these bets will be void.
                </div>
                <div className={style.parag}>
                    3.16. Any bets placed with a system bet will not count for the
                    wagering requirement in Bonus.
                </div>
                <div className={style.parag}>
                    3.17. Live Score Update is for guidance only. Sportsbook
                    platform is not responsible for any errors. Sportsbook
                    platform reserves the right to cancel any bets if the outcome
                    is already known or if the odds have not been updated
                    correctly due to technical issues.
                </div>
                <div className={style.parag}>
                    3.18. Outright bets are considered all in run or not and so
                    will be settled as a loss if the selection does not take part
                    in the event, unless otherwise stated. Dead heat rules apply
                    where there is more than one winner. Bettors stakes are first
                    divided by the number of selections who tied and then this
                    portion of their stakes is settled as a winner and the rest
                    settled as a loser.
                </div>
                <div className={style.parag}>
                    3.19. Sportsbook platform reserves the right to void or cancel
                    any bets where the outcome has been altered by the imposition
                    of penalty points, enforced relegations or any other measure
                    enforced as a result of anything other than the normal results
                    of the games/competitions in question.
                </div>
                <div className={style.parag}>
                    3.20. All bets are settled using the information provided by
                    the official body running the competition at the time of the
                    result. In the case of any events outside of official
                    competitions then bets are settled using the information
                    provided.
                </div>
                <div className={style.parag}>
                    3.21. If one of competitors didn't start Sportsbook platform
                    cancel this head to head market.
                </div>
                <div className={style.parag}>
                    3.22. If both competitors didn't finish, winner will be
                    competitors who's have more laps. If both competitors out in
                    the same lap, Sportsbook platform cancel this head to head
                    market.
                </div>
                <div className={style.parag}>
                    3.23. If competitors in the same position, Sportsbook platform
                    cancel bets on this head to heat market.
                </div>
                <div className={style.parag}>
                    3.24. Sportsbook platform don't responsible for the damage
                    incurred by the client as a result of a system malfunction,
                    defects, delays, manipulations or errors in data transfer.
                </div>
                <div className={style.parag}>
                    3.25. Clients' claims are considered by Sportsbook platform
                    within thirty days from the moment the Client submits a
                    written application to Sportsbook platform. After making a
                    decision, Sportsbook platform notifies the client by means of
                    an e-mail linked to the game account.
                </div>
                <div className={style.parag}>
                    3.26. In case of suspicion of unfair wrestling, Sportsbook
                    platform reserves the right to refuse any bet in general or
                    any part of it, thus making the questionable bet invalid (in
                    these cases, the payment is made with a factor equal to "1")
                    or completion of proceedings or up to 31 calendar days.
                </div>
                <div className={style.parag}>
                    3.27. Clients are allowed to bet only as individuals, group
                    bets are not allowed. Repeated bets on the same results /
                    winners from the same or different customers may subsequently
                    be declared invalid. Even after the official result of the
                    competition / athletes is already known, Sportsbook platform
                    may consider the indicated bets invalid if it considers that
                    the Clients act in collusion or as a syndicate, or the bets
                    considered were made by one or more Clients within a short
                    period of time. The betting company also has the right to
                    refuse to accept bets or to count already made bets as invalid
                    if they are made from different game accounts from the same IP
                    address.
                </div>
                <div className={style.parag}>
                    3.28. LIVE bets: If the match is interrupted or postponed and
                    does not continue in 48 hours after the scheduled time, the
                    bets will be canceled (except for those outcomes that are
                    clearly defined when the game was stopped).
                </div>
                <div className={style.parag}>
                    3.29. Statistics or editorial text published at the Sportsbook
                    platform site are to be considered as added information but
                    Sportsbook platform does not acknowledge or accept any
                    liability whatsoever if the information is not correct. At all
                    times it is the Account Holder’s responsibility to be aware
                    about circumstances relating to an event.
                </div>
                <div className={style.parag}>
                    3.30. It is forbidden to use automatically system (any kind of
                    scanners or robots) on Sportsbook. Sportsbook platform
                    reserves the right to cancel any bet which made using
                    automatic systems.
                </div>
                <div className={style.parag}>
                    3.31. It is forbidden to use accounts owned by others people
                    or registered accounts on other people. Sportsbook platform
                    reserves the right to cancel any bet which made not an owner
                    of an account.
                </div>

                <div className={style.header2}>4.Bets types.</div>
                <div className={style.parag}>
                    4.1. Single (Ordinary) - bet on a separate outcome of the
                    event. Single bet payout equal to the product of the bid
                    amount set for the outcome price.
                </div>
                <div className={style.parag}>
                    4.2. Combo - bet on several independent outcomes of events. To
                    win on express it is necessary that none of the outcomes that
                    are included in the express, there was no loss. Losing one of
                    the results of the combo means losing all over the combo.
                    Combo payment is equal to the product the amount of the bet on
                    the odds of all outcomes included in the combo.
                </div>
                <div className={style.parag}>
                    4.3. System - a set of combos, which is a complete search
                    variants of combos of the same size from a fixed set of
                    outcomes. It is characterized by the same stake for each
                    express (option system) and the same number of outcomes in
                    each express. Betting the system must specify the total number
                    of outcomes and number of combos (system option). Payment on
                    the system is equal to the amount of payments on combo
                    included in the system.
                </div>
                <div className={style.parag}>
                    4.4. A ‘Trixie’ is a combination, which includes one treble
                    and three doubles from a selection of three matches.
                </div>
                <div className={style.parag}>
                    4.5. A ‘Patent’ is a combination, which includes one treble,
                    three doubles and three singles from a selection of three
                    matches.
                </div>
                <div className={style.parag}>
                    4.6. A ‘Yankee’ is a combination, which includes one fourfold,
                    four trebles and six doubles from a selection of four matches.
                </div>
                <div className={style.parag}>
                    4.7. A ‘Canadian’ (also known as ‘Super Yankee’) is a
                    combination, which includes one fivefold, five fourfolds, ten
                    trebles and ten doubles from a selection of five matches.
                </div>
                <div className={style.parag}>
                    4.8. A ‘Heinz’ is a combination, which includes one sixfold,
                    six fivefolds, fifteen fourfolds, twenty trebles and fifteen
                    doubles from a selection of six matches.
                </div>
                <div className={style.parag}>
                    4.9. A ‘Super Heinz’ is a combination, which includes one
                    sevenfold, seven sixfolds, twenty-one fivefolds, thirty-five
                    fourfolds, thirty-five trebles and twenty-one doubles from a
                    selection of seven matches.
                </div>
                <div className={style.parag}>
                    4.10. A ‘Goliath’ is a combination, which includes one
                    eightfold, eight sevenfolds, twenty-eight sixfolds, fifty-six
                    fivefolds, seventy fourfolds, fifty-six trebles and
                    twenty-eight doubles from a selection of eight matches.
                </div>
                <div className={style.parag}>
                    4.11. If odds consider more than 2 digits after decimal point,
                    the payout will be rounded for second digit after decimal
                    point.
                </div>
                <div className={style.parag}>
                    4.12. “Cash out” is an individual offer initiated by
                    Sportsbook platform, addressed to a bet participant, aimed at
                    changing one or several essential betting conditions
                    (coefficient, event calculation time, etc.) in order to fix a
                    new result and complete the bet at the current time ( further
                    - Cash out). The offer to redeem a bet can be both accepted
                    and rejected by the participant of the bet. By selecting “Cash
                    out” the participant of the bet confirms his acceptance of the
                    new essential conditions of the bet. Cash out rates can be
                    offered for both prematch and for live betting. The
                    bookmaker's office reserves the right to change the offer to
                    repurchase the bid over time, or not to form a bid to
                    repurchase the bet without giving a reason.
                </div>

                <div className={style.header2}>5. Markets</div>
                <div className={style.parag}>
                    5.1. "Match" (1X2) is where it is possible to bet on the
                    (partial or definite) outcome of a match or event. The options
                    are: "1" = Home team, or team listed to the left side of the
                    offer; "X" = Draw, or the selection in the middle; "2" = Away
                    team, or team listed to the right side of the offer.
                </div>
                <div className={style.parag}>
                    5.2. "Correct Score" ( is where it is possible to bet on the
                    (partial or definite) exact score of a match or event.
                </div>
                <div className={style.parag}>
                    5.3. "Over/Under" (Totals) is where it is possible to bet on
                    the (partial or definite) amount of a predefined occurrence
                    (e.g. goals, points, corners, rebounds, penalty minutes,
                    etc.). Should the total amount of the listed occurrences be
                    exactly equal to the betting line, then all bets on this offer
                    will be declared void. Example: an offer where the betting
                    line is 128.0 points and the match ends with the result 64-64
                    will be declared void.
                </div>
                <div className={style.parag}>
                    5.4. "Odd/Even" is where it is possible to bet on the (partial
                    or definite) amount of a predefined occurrence (e.g. goals,
                    points, corners, rebounds, penalty minutes, etc.)."Odd" is
                    1,3,5 etc.; "Even" is 0,2,4 etc.
                </div>
                <div className={style.parag}>
                    5.5. A "Head-to-Head" and/or "Triple-Head" is a competition
                    between two or three participants/outcomes, originating from
                    either an officially organised event, or else, as virtually
                    defined by Sportsbook platfrorm.
                </div>
                <div className={style.parag}>
                    5.6. "Half time/Full time" is where it is possible to bet on
                    the result in half time and the final outcome of the match.
                    E.g. if at Half time the score is 1-0 and the match ends 1-1,
                    the winning outcome is 1/X. The bet is void if the regular
                    time of the match is played in a different time format than
                    those listed in the bet (i.e. other than two halves).
                </div>
                <div className={style.parag}>
                    5.7. "Period betting" is where it is possible to bet on the
                    outcome of each separate period within a match/event.
                </div>
                <div className={style.parag}>
                    5.8. "Draw No Bet" is where it is possible to bet on either
                    "1" or "2" as defined in . It is also common practice to refer
                    to "Draw No Bet" in cases where no draw odds are offered.
                    Should the specific match contain no winner (E.g. match ends
                    as a draw), or the particular occurrence not happen (E.g. Draw
                    No Bet and match ends 0-0) the stakes will be refunded.
                </div>
                <div className={style.parag}>
                    5.9. "Handicap" is where it is possible to bet on whether the
                    chosen outcome will be victorious once the listed handicap is
                    added/subtracted (as applicable) to the match/period/total
                    score to which the bet refers to. In those circumstances where
                    the result after the adjustment of the handicap line is
                    exactly equal to the betting line, then all bets on this offer
                    will be declared void. Example: a bet on -3.0 goals will be
                    declared void if the team chosen wins the match by exactly 3
                    goals difference (3-0,4-1, 5-2, etc). Asian Handicap: Home
                    team (-1.75) vs Away team (+1.75). This means that the stake
                    is divided into 2 equal bets and placed on the outcomes -1.5
                    and -2.0. For the bet to be fully paid out at the listed odds,
                    Team A must win the match with a bigger margin than both of
                    their listed handicaps (ie. 3 goals or more margin). In the
                    eventuality that Team A wins with only a 2 goal margin, the
                    bet will be considered as partially won with a full payout on
                    the -1.5 part of the bet and a refund on the -2.0 side since
                    the outcome on that part of the bet would be considered a
                    “tie”. Should the match produce any other outcome, including a
                    Team A victory with only 1 goal of margin, the whole stake
                    would be lost. Away team is given a +1.75 goal advantage in
                    the match. This means that the stake is divided into 2 equal
                    bets and placed on the outcomes +1.5 and +2.0.
                </div>
                <div className={style.parag}>
                    5.10. "Double Chance" is where it is possible to bet
                    simultaneously on two (partial or definite) outcomes of a
                    match or event. The options are: 1X, 12 and X2 with "1", "X"
                    and "2" as defined in.
                </div>
                <div className={style.parag}>
                    5.11. "Outright" or "Place" betting is where it is possible to
                    choose from a list of alternatives and bet on the eventuality
                    that a participant wins or places within a specified position
                    in the classification of the listed event/competition.
                </div>
                <div className={style.parag}>
                    5.12. Bets on "Quarter / Half / Period X" refer to the
                    result/score achieved in the relevant timeframe and does not
                    include any other points/goals/events tallied from other parts
                    of the event/match. Bets will be voided if the match is played
                    in any other format but the one stipulated in the offer.
                </div>
                <div className={style.parag}>
                    5.13. Bets on "Result at end of Quarter / Half / Period X"
                    refer to the result of the match/event after termination of
                    the stipulated timeframe and will take into account all other
                    points/goals/events tallied from previous parts of the
                    event/match.
                </div>
                <div className={style.parag}>
                    5.14. Bets on "Race to X Points / Race to X Goals..." and
                    similar offers refer to the team/participant reaching the
                    earliest the particular tally of points/goals/events. If the
                    offer lists a timeframe (or any other period restriction) it
                    will not include any other points/goals/events tallied from
                    other parts of the event/match which are not related to the
                    mentioned timeframe. Should the listed score not be reached
                    within the stipulated timeframe (if any), all bets will be
                    declared void, unless otherwise stated.
                </div>
                <div className={style.parag}>
                    5.15. Bets on "Winner of Point X / Scorer of Goal X" and
                    similar offers refer to the team/participant scoring/winning
                    the listed occurrence. For the settlement of these offers, no
                    reference to events happening prior to the listed occurrence
                    will be taken into consideration. Should the listed event not
                    be scored/won within the stipulated timeframe (if any), all
                    bets will be declared void, unless otherwise stated.
                </div>
                <div className={style.parag}>
                    5.16. Bets referring to the happening of a particular
                    occurrence in a pre-defined time order, such as “First Card”,
                    or “Next Team to receive penalty minutes” will be settled as
                    void should it not be possible, without any reasonable doubt,
                    to decide the winning outcome, for example in case of players
                    from different teams which are shown a card in the same
                    interruption of play.
                </div>
                <div className={style.parag}>
                    5.17. "Team to score first and win" refer to the listed team
                    scoring the first goal in the match and going on to win the
                    match. Should there be no goals in the match all bets will be
                    settled as void.
                </div>
                <div className={style.parag}>
                    5.18. Any reference to "clean sheet" indicates that the listed
                    team must not concede any goal during the match.
                </div>
                <div className={style.parag}>
                    5.19. "Team to win from behind" refers to the listed team
                    winning the match after having been at least 1 goal down at
                    any point in the match.
                </div>
                <div className={style.parag}>
                    5.20. Any reference for a team to win all halves/periods (e.g.
                    Team to win both halves) means that the listed team must score
                    more goals than its opponent during all the stipulated
                    halves/periods of the match.
                </div>
                <div className={style.parag}>
                    5.21. Any reference to "Injury Time" refers to the amount
                    displayed by the designated official and not to the actual
                    amount played.
                </div>
                <div className={style.parag}>
                    5.22. Settlement of bets on offers such as "Man of the Match",
                    "Most Valuable Player" etc. will be based on the competition's
                    organisers’ decision, unless otherwise stated.
                </div>

                <div className={style.header2}>6. Special rules for sports.</div>
                <h3>6.1. Soccer:</h3>
                <div className={style.parag}>
                    6.1.1. Bets on the outcome of a match will be decided based on
                    two halves of the scheduled number of minutes each and any
                    time the referee adds to compensate for injuries and other
                    stoppages. It does not include periods of extra time nor
                    penalty shootouts if not stated.
                </div>
                <div className={style.parag}>6.1.2. Corners awarded but not taken are not considered.</div>
                <div className={style.parag}>
                    6.1.3. Own goals will not be considered for Anytime Goalscorer
                    or Player to score X or Next Goalscorer or more settlement
                    purposes and are ignored.
                </div>
                <div className={style.parag}>
                    6.1.4. All players who took part in the match since kick off
                    or previous goal are considered as runners.
                </div>
                <div className={style.parag}>
                    6.1.5. All players who are currently taking part are listed.
                </div>
                <div className={style.parag}>
                    6.1.6. If for any reason an unlisted player scores a goal all
                    bets on listed players stand.
                </div>
                <div className={style.parag}>
                    6.1.7. Anytime Goalscorer or Player to score X or Next
                    Goalscorer market will be settled based on TV insert and
                    statistics provided by Press Association unless there is clear
                    evidence that these statistics are not correct
                </div>

                <div className={style.parag}>
                    6.1.8. Interval markets will be settled based on the goal time
                    announced by TV. If this is not available, the time according
                    to the match clock is considered.
                </div>
                <div className={style.parag}>
                    6.1.9. Interval goal markets are settled based on the time the
                    ball crosses the line, and not the time the kick is made.
                </div>
                <div className={style.parag}>
                    6.1.10. Corner interval markets are settled based on the time
                    the corner kick is taken and not the time the corner is
                    conceded or awarded.
                </div>
                <div className={style.parag}>
                    6.1.11. Booking interval markets are settled based on the time
                    the card is shown and not the time the infringement is made.
                </div>
                <div className={style.parag}>
                    6.1.12. Offsides will be settled based on the time when the
                    referee gives the decision. This rule will be applied on any
                    video assistant referee (VAR) situation.
                </div>
                <div className={style.parag}>
                    6.1.13. Penalty markets will be settled based on the time when
                    the referee gives the decision. This rule will be applied on
                    any video assistant referee (VAR) situation.
                </div>
                <div className={style.parag}>
                    6.1.14. Penalties awarded but not taken are not considered
                </div>
                <div className={style.parag}>
                    6.1.15. Next scoring type.Freekick: The goal has to be scored
                    directly from the freekick or corner to qualify as a goal by
                    freekick. Deflected shots count as long as the freekick or
                    corner taker is awarded the goal. Penalty: Goal must be scored
                    directly from the penalty. Goals after a rebound of a missed
                    penalty do not count. Own Goal: If goal is declared as an own
                    goal. Header: The scorers last touch has to be with the head.
                    Shot: Goal has to be with any other part of the body than the
                    head and the other types do not apply. No Goal.
                </div>
                <div className={style.parag}>
                    6.1.16. If the market was opened with a missing or incorrect
                    red card, we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.1.17. If odds were offered with an incorrect match time
                    (more than 5 minutes), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.1.18. If a wrong score is entered, all markets will be
                    cancelled for the time when the incorrect score was displayed.
                </div>
                <div className={style.parag}>
                    6.1.19. If the team names or category are displayed
                    incorrectly, we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.1.20. Yellow card counts as 1 card and red or yellow-red
                    card as 2. The 2nd yellow for one player which leads to a
                    yellow red card is not considered. As a consequence one player
                    cannot cause more than 3 cards.
                </div>
                <div className={style.parag}>
                    6.1.21. Settlement will be made according to all available
                    evidence of cards shown during the regular 90 minutes play.
                </div>
                <div className={style.parag}>6.1.22. Cards shown after the match are not considered.</div>
                <div className={style.parag}>
                    6.1.23. Cards for non-players (already substituted players,
                    managers, players on bench) are not considered.
                </div>
                <div className={style.parag}>
                    6.1.24. Yellow card counts as 10 points and red or yellow red
                    cards as 25. The 2nd yellow for one player which leads to a
                    yellow red card is not considered. As a consequence one player
                    cannot cause more than 35 booking points.
                </div>
                <div className={style.parag}>
                    6.1.25. Settlement will be made according to all available
                    evidence for cards shown during the regular 90 minutes play.
                </div>
                <div className={style.parag}>6.1.26. Cards shown after the match are not considered.</div>
                <div className={style.parag}>
                    6.1.27. Cards for non-players (already substituted players,
                    managers, players on bench) are not considered.
                </div>

                <h3>6.2. Tennis.</h3>
                <div className={style.parag}>
                    6.2.1. In case of a retirement and walk over of any player all
                    undecided bets are considered void.
                </div>
                <div className={style.parag}>
                    6.2.2. In case of any delay (rain, darkness...) all markets
                    remain unsettled and the trading will be continued as soon as
                    the match continues.
                </div>
                <div className={style.parag}>
                    6.2.3. If penalty point(s) are awarded by the umpire, all bets
                    on that game will stand.
                </div>
                <div className={style.parag}>
                    6.2.4. In case of a match is finished before certain
                    points/games were finished, all affected point/game related
                    markets are considered void.
                </div>
                <div className={style.parag}>
                    6.2.5. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.2.6. If the players/teams are displayed incorrectly, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.2.7. If a player retires all undecided markets are
                    considered void.
                </div>
                <div className={style.parag}>
                    6.2.8. If a match is decided by a Match tie-break then it will
                    be considered to be the 3rd set.
                </div>
                <div className={style.parag}>
                    6.2.9. Every tie-break or Match tie-break counts as 1 game.
                </div>

                <h3>6.3. Basketball.</h3>
                <div className={style.parag}>
                    6.3.1. Markets do not consider overtime unless otherwise
                    stated.
                </div>
                <div className={style.parag}>
                    6.3.2. If odds were offered with an incorrect match time(more
                    than 2 minutes), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.3.3. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.3.4. In the event that a match does not finish in a tie, but
                    overtime is played for qualification purposes, the markets
                    will be settled according to the result at the end of regular
                    time.
                </div>
                <div className={style.parag}>
                    6.3.5. If a match ends before the Xth is reached, this market
                    is considered void (cancelled). Who scores Xth point? (incl.
                    ot), Which team will win race to x points? (incl. ot)
                </div>
                <div className={style.parag}>
                    6.3.6. Market (Will there be overtime?) will be settled as yes
                    if at the end of regular time the match finishes in a draw,
                    regardless of whether or not overtime is played.
                </div>
                <h3>6.4. American football</h3>
                <div className={style.parag}>
                    6.4.1. In case of any delay (rain, darkness...) all markets
                    remain unsettled and the trading will be continued as soon as
                    the match continues.
                </div>
                <div className={style.parag}>
                    6.4.2. Markets do not consider overtime unless otherwise
                    stated.
                </div>
                <div className={style.parag}>
                    6.4.3. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.4.4. If odds were offered with an incorrect match time (more
                    than 89 seconds), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.4.5. If a wrong score is displayed we reserve the right to
                    void betting for this timeframe
                </div>
                <div className={style.parag}>
                    6.4.6. In case of abandoned or postponed matches all markets
                    are considered void unless the match continues in the same NFL
                    weekly schedule (Thursday - Wednesday local stadium time).
                </div>
                <div className={style.parag}>
                    6.4.7. If the teams are displayed incorrectly, we reserve the
                    right to void betting.
                </div>
                <div className={style.parag}>6.4.8. All offered players are considered as runners.</div>
                <div className={style.parag}>
                    6.4.9. If no further touchdown is scored, the market (Next
                    touchdown scorer (incl. overtime)) will be voided.
                </div>
                <div className={style.parag}>
                    6.4.10. Players which are not listed are considered as
                    “Competitor 1 other player” or “Competitor2 other player” for
                    settlement purposes. Note this does not include players which
                    are listed without an active price.
                </div>
                <div className={style.parag}>
                    6.4.11. Players of the Defense- or Special team are considered
                    as “Competitor1 d/st player” or “Competitor2 d/st player” for
                    settlement purposes, even if the player is listed as dedicated
                    outcome.
                </div>
                <div className={style.parag}>
                    6.4.12. Market will be settled based on TV insert and
                    statistics provided by official associations unless there is
                    clear evidence that statistics are not correct.
                </div>
                <h3>6.5. Ice Hockey</h3>
                <div className={style.parag}>
                    6.5.1. All markets (except period, overtime and penalty
                    shootout markets) are considered for regular time only unless
                    it is mentioned in the market.
                </div>
                <div className={style.parag}>
                    6.5.2. In the event of a game being decided by a penalty
                    shootout, then one goal will be added to the winning team's
                    score and the game total for settlement purposes. This applies
                    to all markets including overtime and penalty shootout
                </div>
                <div className={style.parag}>
                    6.5.3. If the market remains open when the following events
                    have already taken place: goals and penalties, we reserve the
                    right to void betting.
                </div>
                <div className={style.parag}>
                    6.5.4. If odds were offered with an incorrect match time (more
                    than 2 minutes), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.5.5. If a wrong score is entered all markets will be
                    cancelled for the time when the incorrect score was displayed.
                </div>

                <h3>6.6. Baseball</h3>
                <div className={style.parag}>
                    6.6.1. If an inning ends before the Xth point is reached
                    (incl. extra innings), this market (Which team wins race to x
                    points?, Who scores the Xth point (incl. ot)) is considered
                    void (cancelled).
                </div>
                <div className={style.parag}>
                    6.6.2. Market (When will the match be decided?) will be
                    settled as “Any extra inning” if at the end of regular time
                    (After a full 9 Innings) the match finishes in a draw,
                    regardless of whether or not overtime (Extra innings) is
                    played.
                </div>
                <div className={style.parag}>
                    6.6.3. Market (Will there be overtime?) will be settled as
                    “Yes” if at the end of regular time (After full 9 Innings) the
                    match finishes in a draw, regardless of whether or not
                    overtime (Extra innings) is played.
                </div>
                <div className={style.parag}>
                    6.6.4. Possible extra innings are not considered in any market
                    unless otherwise stated.
                </div>
                <div className={style.parag}>
                    6.6.5. All markets will be cleared according the final result
                    after 9 innings (8 1⁄2 innings if home team is leading at this
                    point)
                </div>
                <div className={style.parag}>
                    6.6.6. If a match is interrupted or cancelled and won’t be
                    continued on the same day, all undecided markets are
                    considered void.
                </div>
                <div className={style.parag}>
                    6.6.7. If markets remain open with an incorrect score or
                    incorrect match status which has a significant impact on the
                    prices, we reserve the right to void betting.
                </div>

                <h3>6.7. Handball</h3>
                <div className={style.parag}>
                    6.7.1. If a match ends before the Xth is reached, this market
                    (Who scores Xth point? (incl. ot)) is considered void
                    (cancelled).
                </div>
                <div className={style.parag}>
                    6.7.2. If a match ends before the Xth is reached, this market
                    (Which team will win race to x points? (incl. ot)) is
                    considered void (cancelled).
                </div>
                <div className={style.parag}>
                    6.7.3. All markets (except Who scores the Xth point and Which
                    team will win race to X points) are considered for regular
                    time only.
                </div>
                <div className={style.parag}>
                    6.7.4. If the match goes to a 7-metre shootout; the markets
                    “Who scores Xth point?” and “Which team will win race to X
                    points?” will be voided.
                </div>
                <div className={style.parag}>
                    6.7.5. If the match goes to a 7-metre shootout; the markets
                    “Who scores Xth point?” and “Which team will win race to X
                    points?” will be voided.
                </div>
                <div className={style.parag}>
                    6.7.6. If odds were offered with an incorrect match time (more
                    than 3 minutes), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.7.7. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>

                <h3>6.8. Volleyball</h3>
                <div className={style.parag}>
                    6.8.1. If a set ends before the Xth point is reached, this
                    market (Who scores [Xth] point in set [y]) is considered void
                    (cancelled).
                </div>
                <div className={style.parag}>
                    6.8.2. In the case of a match not being finished all undecided
                    markets are considered void.
                </div>
                <div className={style.parag}>
                    6.8.3. Golden set is not considered in any of the mentioned
                    markets.
                </div>
                <div className={style.parag}>
                    6.8.4. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.8.5. Official points deductions will be taken into account
                    for all undetermined markets. Markets which have already been
                    determined will not take deductions into account.
                </div>

                <h3>6.9. Beach volleyball</h3>
                <div className={style.parag}>
                    6.9.1. if a set ends before the Xth point is reached, this
                    market (Who scores [Xth] point in set [y]) is considered void
                    (cancelled).
                </div>
                <div className={style.parag}>
                    6.9.2. In the case of a match not being finished all undecided
                    markets are considered void.
                </div>
                <div className={style.parag}>
                    6.9.3. Golden set is not considered in any of the mentioned
                    markets.
                </div>
                <div className={style.parag}>
                    6.9.4. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.9.5. If a team retires all undecided markets are considered
                    void.
                </div>
                <div className={style.parag}>
                    6.9.6. Official points deductions will be taken into account
                    for all undetermined markets. Markets which have already been
                    determined will not take deductions into account.
                </div>

                <h3>6.10. Futsal</h3>
                <div className={style.parag}>
                    6.10.1. All markets (except halftime, first half markets,
                    overtime and penalty shoot out) are considered for regular
                    time only.
                </div>
                <div className={style.parag}>
                    6.10.2. If a match is interrupted and continued within 48h
                    after initial kick-off date, all open bets will be settled
                    with the final result. Otherwise all undecided bets are
                    considered void.
                </div>
                <div className={style.parag}>
                    6.10.3. If the market remains open when the following events
                    have already taken place: goals, red or yellow-red cards and
                    penalties, we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.10.4. If the market was opened with a missing or incorrect
                    red card, we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.10.5. If odds were offered with an incorrect match time
                    (more than 2 minutes), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.10.6 If a wrong score is entered, all markets will be
                    cancelled for the time when the incorrect score was displayed.
                </div>

                <div className={style.parag}>
                    6.10.7. If the team names or category are displayed
                    incorrectly, we reserve the right to void betting.
                </div>

                <h3>6.11. Badminton</h3>
                <div className={style.parag}>
                    6.11.1. If a set ends before the Xth point is reached, this
                    market (Who scores [Xth] point in [Nth] set) is considered
                    void (cancelled).
                </div>
                <div className={style.parag}>
                    6.11.2. In the case of a match not being finished, all
                    undecided markets are considered void.
                </div>
                <div className={style.parag}>
                    6.11.3. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.11.4. If a team retires all undecided markets are considered
                    void.
                </div>
                <div className={style.parag}>
                    6.11.5. If the players/teams are displayed incorrectly, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.11.6. Official points deductions will be taken into account
                    for all undetermined markets. Markets which have already been
                    determined will not take deductions into account.
                </div>

                <h3>6.12. Rugby union and rugby league</h3>
                <div className={style.parag}>
                    6.12.1. All markets (except halftime, first half markets,
                    overtime and penalty shoot out) are considered for regular
                    time only.
                </div>
                <div className={style.parag}>
                    6.12.2. Regular 80 Minutes: Markets are based on the result at
                    the end of a scheduled 80 minutes play unless otherwise
                    stated. This includes any added injury or stoppage time but
                    does not include extra-time, time allocated for a penalty
                    shootout or sudden death.
                </div>
                <div className={style.parag}>
                    6.12.3. If the market remains open when the following events
                    have already taken place: score changes or red cards, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.12.4. If the market was opened with a missing or incorrect
                    red card, we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.12.5. If odds were offered with an incorrect match time
                    (more than 2 minutes), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.12.6. If the team names or category are displayed
                    incorrectly, we reserve the right to void betting.
                </div>

                <h3>6.13. Rugby Sevens</h3>
                <div className={style.parag}>
                    6.13.1. All markets (except halftime, first half markets,
                    overtime and penalty shoot out) are considered for regular
                    time only.
                </div>
                <div className={style.parag}>
                    6.13.2. Regular 14 / 20 Minutes: Markets are based on the
                    result at the end of a scheduled 14 / 20 minutes play unless
                    otherwise stated. This includes any added injury or stoppage
                    time but does not include extra-time, time allocated for a
                    penalty shootout or sudden death.
                </div>
                <div className={style.parag}>
                    6.13.3. If the market remains open when the following events
                    have already taken place: score changes or red cards, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.13.4. If the market was opened with a missing or incorrect
                    red card, we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.13.5. If odds were offered with an incorrect match time
                    (more than 1 minute), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.13.6. If the team names or categories are displayed
                    incorrectly, we reserve the right to void betting.
                </div>

                <h3>6.14. Darts</h3>
                <div className={style.parag}>
                    6.14.1. In the case of a match not being finished all
                    undecided markets are considered void.
                </div>
                <div className={style.parag}>
                    6.14.2. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>6.14.3. If the players/teams are displayed.</div>
                <div className={style.parag}>
                    6.14.4. incorrectly, we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.14.5. If a match is not completed all undecided markets are
                    considered void.
                </div>
                <div className={style.parag}>6.14.6. Bullseye counts as red check out colour.</div>

                <h3>6.15. Snooker</h3>
                <div className={style.parag}>
                    6.15.1. In the case of a retirement of a player or
                    disqualification all undecided markets are considered void.
                </div>
                <div className={style.parag}>
                    6.15.2. In case of a re-rack settlement stays if the outcome
                    was determined before the re-rack.
                </div>
                <div className={style.parag}>
                    6.15.3. No fouls or free balls are considered for settlement
                    of any Potted- Colour market.
                </div>
                <div className={style.parag}>
                    6.15.4. In case of a frame starting but not being completed,
                    all frame related markets will be voided unless the outcome
                    has already been determined
                </div>
                <div className={style.parag}>
                    6.15.5. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.15.6. If the players/teams are displayed incorrectly, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.15.7. If a match is not completed all undecided markets are
                    considered void.
                </div>

                <h3>6.16. Table tennis</h3>
                <div className={style.parag}>
                    6.16.1. If a set ends before the Xth point is reached, this
                    market (Who scores [Xth] point in set [y]) is considered void
                    (cancelled).
                </div>
                <div className={style.parag}>
                    6.16.2. In the case of a match not being finished all
                    undecided markets are considered void.
                </div>
                <div className={style.parag}>
                    6.16.3. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.16.4. If the players/teams are displayed incorrectly, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.16.5. If a player retires all undecided markets are
                    considered void.
                </div>
                <div className={style.parag}>
                    6.16.6. Official points deductions will be taken into account
                    for all undetermined markets. Markets which have already been
                    determined will not take deductions into account.
                </div>

                <h3>6.17. Bowls</h3>
                <div className={style.parag}>
                    6.17.1. If a set ends before the Xth point is reached, this
                    market (Xth set - which team wins race to x points, Xth set -
                    which team scores Xth point) is considered void (cancelled).
                </div>
                <div className={style.parag}>
                    6.17.2. In case of a retirement and walk over of any player
                    all undecided bets are considered void.
                </div>
                <div className={style.parag}>
                    6.17.3. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.17.4. If the players/teams are displayed incorrectly, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.17.5. If a player retires all undecided markets are
                    considered void.
                </div>

                <h3>6.18. Cricket</h3>
                <div className={style.parag}>
                    6.18.1. All match betting will be settled in accordance with
                    official competition rules. In matches affected by adverse
                    weather, bets will be settled according to the official
                    result.
                </div>
                <div className={style.parag}>
                    6.18.2. All markets do not consider super overs unless
                    otherwise mentioned.
                </div>
                <div className={style.parag}>
                    6.18.3. Penalty runs are not considered in any over or
                    delivery market (markets for multiple overs are not considered
                    for this rule).
                </div>
                <div className={style.parag}>
                    6.18.4. Twenty 20: all scheduled overs must be played for
                    undecided markets to be settled unless the innings has reached
                    its natural conclusion.
                </div>
                <div className={style.parag}>
                    6.18.5. ODIs: a minimum of 90 % of the entire overs allocated
                    for an innings must be played at the time the bet was struck
                    for markets to be settled unless the innings has reached its
                    natural conclusion.
                </div>
                <div className={style.parag}>
                    6.18.6. If the match is tied and the official competition
                    rules do not determine a winner; or if the competition rules
                    determine the winner by a coin toss or drawing of lots, then
                    all undecided markets are considered void.
                </div>
                <div className={style.parag}>
                    6.18.7. In the event of an over not being completed, all
                    undecided markets on this specific over are considered void
                    unless the innings has reached its natural conclusion e.g.
                    declaration, team all out, etc.
                </div>
                <div className={style.parag}>
                    6.18.8. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>

                <h3>6.19. Squash</h3>
                <div className={style.parag}>
                    6.19.1. If a set ends before the Xth point is reached, this
                    market (Who scores [Xth] point in set [y]) is considered void
                    (cancelled).
                </div>
                <div className={style.parag}>
                    6.19.2. If markets remain open with an incorrect score which
                    has a significant impact on the prices, we reserve the right
                    to void betting.
                </div>
                <div className={style.parag}>
                    6.19.3. If the players/teams are displayed incorrectly, we
                    reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.19.4. If a player retires, forfeits the match or is
                    disqualified all undecided markets are considered void.
                </div>
                <div className={style.parag}>
                    6.19.5. Official points deductions will be taken into account
                    for all undetermined markets. Markets which have already been
                    determined will not take deductions into account.
                </div>
                <div className={style.parag}>
                    6.19.6. If penalty point(s) are awarded by the umpire, all
                    bets on that game will stand.
                </div>

                <h3>6.20. Aussie rules</h3>
                <div className={style.parag}>
                    6.20.1. All markets exclude overtime unless otherwise stated.
                </div>
                <div className={style.parag}>
                    6.20.2. Regular 80 Minutes: Markets are based on the result at
                    the end of a scheduled 80 minutes play unless otherwise
                    stated. This includes any added injury or stoppage time but
                    does not include extra-time.
                </div>
                <div className={style.parag}>
                    6.20.3. If odds were offered with an incorrect match time
                    (more than 2 minutes), we reserve the right to void betting.
                </div>
                <div className={style.parag}>
                    6.20.4. If the team names or category are displayed
                    incorrectly, we reserve the right to void betting.
                </div>

                <h3>6.21. Counter-Strike: Global Offensive</h3>
                <div className={style.parag}>
                    6.21.1. If no bomb is planted, market ([mapNr!] Map [roundNr!]
                    Round - Bomb defused) will be considered void.
                </div>
                <div className={style.parag}>
                    6.21.2. Markets do not consider overtime unless otherwise
                    stated.
                </div>
                <div className={style.parag}>
                    6.21.3. Markets will be settled based on officially published
                    results.
                </div>
                <div className={style.parag}>
                    6.21.4. If the fixture is listed incorrectly, we reserve the
                    right to void betting.
                </div>
                <div className={style.parag}>
                    6.21.5. In case of a walkover or retirement, all undecided
                    markets are void.
                </div>
                <div className={style.parag}>
                    6.21.6. If a match or map is replayed due to a disconnection,
                    or technical issues which are not player-related, all
                    undecided markets will be void. The replayed match or map will
                    be handled separately.
                </div>
                <div className={style.parag}>
                    6.21.7. If the standard number of maps is changed or differs
                    from those offered for betting purposes, we reserve the right
                    to void betting.
                </div>

                <h3>6.22. Dota 2</h3>
                <div className={style.parag}>
                    6.22.1. Xth map – 1st aegis: Settlement is determined by the
                    team which picks up the Aegis of the Immortal, and not who
                    slays Roshan.
                </div>
                <div className={style.parag}>
                    6.22.2. Xth map – 1st tower and Xth map – 1st barracks: For
                    settlement purposes every method of tower destruction will be
                    taken into account (Opponent & Creep destroy; destroy by
                    Deny).
                </div>
                <div className={style.parag}>
                    6.22.3. Markets will be settled based on officially published
                    results.
                </div>
                <div className={style.parag}>
                    6.22.4. If the fixture is listed incorrectly, we reserve the
                    right to void betting.
                </div>
                <div className={style.parag}>
                    6.22.5. In case of a walkover or retirement, all undecided
                    markets are void.
                </div>
                <div className={style.parag}>
                    6.22.6. If a match or map is replayed due to a disconnection,
                    or technical issues which are not player-related, all
                    undecided markets will be void. The replayed match or map will
                    be handled separately.
                </div>
                <div className={style.parag}>
                    6.22.7. If the standard number of maps is changed or differs
                    from those offered for betting purposes, we reserve the right
                    to void betting.
                </div>

                <h3>6.23. League of Legends</h3>
                <div className={style.parag}>
                    6.23.1. Xth map – 1st inhibitor and Xth map – 1st tower: For
                    settlement purposes every method of destruction will be taken
                    into account.
                </div>

                <div className={style.parag}>
                    6.23.2. Markets do not consider overtime unless otherwise
                    stated.
                </div>
                <div className={style.parag}>
                    6.23.3. Markets will be settled based on officially published
                    results.
                </div>
                <div className={style.parag}>
                    6.23.4. If the fixture is listed incorrectly, we reserve the
                    right to void betting.
                </div>
                <div className={style.parag}>
                    6.23.5. In case of a walkover or retirement, all undecided
                    markets are void.
                </div>
                <div className={style.parag}>
                    6.23.6. If a match or map is replayed due to a disconnection,
                    or technical issues which are not player-related, all
                    undecided markets will be void. The replayed match or map will
                    be handled separately.
                </div>
                <div className={style.parag}>
                    6.23.7. If the standard number of maps is changed or differs
                    from those offered for betting purposes, we reserve the right
                    to void betting.
                </div>
            </div>

        </div >
    );
}

export default BettingRules;
