import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from 'newui/Utils/themeUtils'
import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl, getImageUrl, inNotMinusOne, isDateDDMMYYYY, isHourHHMM, isNumeric, isTextMin2Char } from 'newui/Utils/uiUtil';
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'

import DivClick from 'newui/Generic/Inputs/DivClick';
import { displayPage } from 'newui/Utils/routerUtil';
import * as constants from 'newui/constants.js'
import TextField from 'newui/Generic/Inputs/TextField';
import Button from 'newui/Generic/Inputs/Button';
import BirthDay from 'newui/Generic/Inputs/Birthday';
import Clock from 'newui/Generic/Inputs/Clock';

function NewPromotion(props) {

    const STEP_INITIAL = -1;
    const STEP_GIFT_TYPE = 1;
    const STEP_AUDIDENCE = 2;
    const STEP_DEPOSIT_BONUS_DETAIL = 3;

    const PROMO_TYPE_NONE = -1;
    const PROMO_TYPE_DEPOSIT_BONUS = 1;
    const PROMO_TYPE_INSTANT_GIFT = 2;

    const GIFT_TYPE_NONE = -1;
    const GIFT_TYPE_CASH = 1;
    const GIFT_TYPE_PNG = 2;
    const GIFT_TYPE_FREESPIN = 3;
    const GIFT_TYPE_WAGER = 4;

    const AUDIENCE_NONE = -1;
    const AUDIENCE_ALL = 1;
    const AUDIENCE_SPECIFIC = 2;

    const PROMO_TYPE_DEPOSIT_XTIME_NONE = -1;
    const PROMO_TYPE_DEPOSIT_XTIME_1 = 1;
    const PROMO_TYPE_INSTANT_XTIME_2 = 2;

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [currentStep, setCurrentStep] = useState(STEP_INITIAL);
    const [promoType, setPromoType] = useState(PROMO_TYPE_NONE);
    const [giftType, setGiftType] = useState(GIFT_TYPE_NONE);
    const [audienceType, setAudienceType] = useState(AUDIENCE_NONE);

    const [inputPromName, setInputPromName] = useState('');
    const [isPromNameValidMaster, setIsPromNameValidMaster] = useState(false);

    const [expirationFreeSpin, setExpirationFreeSpin] = useState('0');

    const [amount, setAmount] = useState(null);

    const [wagerX, setWagerX] = useState(null);
    const [wagerMaxWin, setWagerMaxWin] = useState(null);
    const [wagerExpirationDate, setWagerExpirationDate] = useState('');
    const [wagerExpirationTime, setWagerExpirationTime] = useState('');

    const [executionDate, setExecutionDate] = useState('');
    const [executionTime, setExecutionTime] = useState('');

    const [file, setFile] = useState(null);

    const [isExpirationFreeSpinValidMaster, setIsExpirationFreeSpinValidMaster] = useState(false);

    const [isAmountValidMaster, setIsAmountValidMaster] = useState(false);
    const [isWagerXTimesValidMaster, setIsWagerXTimesValidMaster] = useState(false);
    const [isWagerMaxWinValidMaster, setIsWagerMaxWinValidMaster] = useState(false);

    const [isExecutionDateValidMaster, setIsExecutionDateValidMaster] = useState(false);
    const [isExecutionTimeValidMaster, setIsExecutionTimeValidMaster] = useState(false);

    const [isWagerExpirationDateValidMaster, setIsWagerExpirationStartDateValidMaster] = useState(false);
    const [isWagerExpirationTimeValidMaster, setIsWagerExpirationStartTimeValidMaster] = useState(false);

    const [depositBonusStartDate, setDepositBonusStartDate] = useState('');
    const [depositBonusStartTime, setDepositBonusStartTime] = useState('');
    const [depositBonusEndDate, setDepositBonusEndDate] = useState('');
    const [depositBonusEndTime, setDepositBonusEndTime] = useState('');
    const [depositBonusAction, setDepositBonusAction] = useState(PROMO_TYPE_DEPOSIT_XTIME_NONE);
    const [depositBonusChance, setDepositBonusChance] = useState(PROMO_TYPE_DEPOSIT_XTIME_NONE);
    const [depositBonusMaxWinPerDeposit, setDepositBonusMaxWinPerDeposit] = useState('');
    const [depositBonusTotal, setDepositBonusTotal] = useState('');
    const [isDepositBonusStartDateValidMaster, setIsDepositBonusStartDateValidMaster] = useState(false);
    const [isDepositBonusStartTimeValidMaster, setIsDepositBonusStartTimeValidMaster] = useState(false);
    const [isDepositBonusEndDateValidMaster, setIsDepositBonusEndDateValidMaster] = useState(false);
    const [isDepositBonusEndTimeValidMaster, setIsDepositBonusEndTimeValidMaster] = useState(false);
    const [isDepositBonusActionValidMaster, setIsDepositBonusActionValidMaster] = useState(false);
    const [isDepositBonusChanceValidMaster, setIsDepositBonusChanceValidMaster] = useState(false);
    const [isDepositBonusMaxAmountPerDepositValidMaster, setIsDepositBonusMaxAmountPerDepositValidMaster] = useState(false);
    const [isDepositBonusTotalAmountGiftValidMaster, setIsDepositBonusTotalAmountGiftValidMaster] = useState(false);



    const promoTypeChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type.select', defaultMessage: 'Choose one...' }), key: PROMO_TYPE_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type.deposit', defaultMessage: 'Deposit Bonus' }), key: PROMO_TYPE_DEPOSIT_BONUS },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type.instant', defaultMessage: 'Instant Gift' }), key: PROMO_TYPE_INSTANT_GIFT }
        ]
    }

    const audienceGiftChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.select', defaultMessage: 'Choose one...' }), key: AUDIENCE_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.all', defaultMessage: 'All (Except banned)' }), key: AUDIENCE_ALL },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.specific', defaultMessage: 'Upload user list' }), key: AUDIENCE_SPECIFIC }
        ]
    }

    const giftTypeChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.select', defaultMessage: 'Choose one...' }), key: GIFT_TYPE_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash', defaultMessage: 'Cash' }), key: GIFT_TYPE_CASH },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.png', defaultMessage: 'Png Points' }), key: GIFT_TYPE_PNG },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin', defaultMessage: 'FreeSpins' }), key: GIFT_TYPE_FREESPIN },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager', defaultMessage: 'Wager' }), key: GIFT_TYPE_WAGER }
        ]
    }


    const depositXTimeChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.select', defaultMessage: 'Choose one...' }), key: PROMO_TYPE_DEPOSIT_XTIME_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.double', defaultMessage: 'Double' }), key: PROMO_TYPE_DEPOSIT_XTIME_1 },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.triple', defaultMessage: 'Triple' }), key: PROMO_TYPE_INSTANT_XTIME_2 }
        ]
    }

    const chancesToWinChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.select', defaultMessage: 'Choose one...' }), key: PROMO_TYPE_DEPOSIT_XTIME_NONE },
            { value: '5%', key: 20 },
            { value: '10%', key: 10 },
            { value: '20%', key: 5 },
            { value: '33%', key: 3 },
            { value: '50%', key: 2 },
            { value: '100%', key: 1 },
        ]
    }

    function clearDetails() {
        setFile(null);
        setAmount('');
        setExpirationFreeSpin('0');
        setWagerX('');
        setWagerMaxWin('');
        setWagerExpirationDate('');
        setWagerExpirationTime('');

        setIsAmountValidMaster(false);
        setIsExpirationFreeSpinValidMaster(false);
        setIsWagerXTimesValidMaster(false);
        setIsWagerMaxWinValidMaster(false);
        setIsWagerExpirationStartDateValidMaster(false);
        setIsWagerExpirationStartTimeValidMaster(false);
    }

    function isFreeSpinOk() {
        return isAmountOk() && isExpirationFreeSpinValidMaster;
    }

    function isAmountOk() {
        return isAmountValidMaster && isExecutionDateValidMaster && isExecutionTimeValidMaster;
    }

    function isWagerOk() {
        return isAmountOk() && isWagerXTimesValidMaster && isWagerMaxWinValidMaster && isWagerExpirationDateValidMaster && isWagerExpirationTimeValidMaster;
    }


    function switchAudienceType(data) {
        setAudienceType(Number(data));
    }

    function switchGiftType(data) {
        setGiftType(Number(data));
        clearDetails();
    }

    function switchPromoType(data) {
        setPromoType(Number(data));
    }

    function isNameValid(isValid, value) {
        setIsPromNameValidMaster(isValid);
        setInputPromName(value);
    }


    function isExpirationFreeSpinValid(isValid, value) {
        setIsExpirationFreeSpinValidMaster(isValid);
        setExpirationFreeSpin(value);
    }

    function isAmountValid(isValid, value) {
        setIsAmountValidMaster(isValid);
        setAmount(value);
    }

    function isXTimesValid(isValid, value) {
        setIsWagerXTimesValidMaster(isValid);
        setWagerX(value);
    }

    function isMaxWinValid(isValid, value) {
        setIsWagerMaxWinValidMaster(isValid);
        setWagerMaxWin(value);
    }

    function canProcessDepositBonus() {

        let toReturn =
            isDepositBonusStartDateValidMaster && isDepositBonusStartTimeValidMaster && isDepositBonusEndDateValidMaster && isDepositBonusEndTimeValidMaster &&
            isDepositBonusActionValidMaster && isDepositBonusChanceValidMaster && isDepositBonusMaxAmountPerDepositValidMaster && isDepositBonusTotalAmountGiftValidMaster;

        return toReturn;
    }

    function canProcessAudience() {
        if (audienceType === AUDIENCE_SPECIFIC) {
            return file != null;
        }

        let toReturn =
            audienceType > AUDIENCE_NONE;

        return toReturn;
    }


    function isExecutionDateValid(isValid, value) {
        setIsExecutionDateValidMaster(isValid);
        setExecutionDate(value);
    }

    function isExecutionTimeValid(isValid, value) {
        setIsExecutionTimeValidMaster(isValid);
        setExecutionTime(value);
    }

    function isWagerExpirationDateValid(isValid, value) {
        setIsWagerExpirationStartDateValidMaster(isValid);
        setWagerExpirationDate(value);
    }

    function isWagerExpirationTimeValid(isValid, value) {
        setIsWagerExpirationStartTimeValidMaster(isValid);
        setWagerExpirationTime(value);
    }

    function canProcessNextStep() {
        let toReturn =
            isPromNameValidMaster && promoType > PROMO_TYPE_NONE;
        return toReturn;
    }

    function back() {
        displayPage(constants.ROUTE_ADMIN_PROMOTIONS, navigate, location.pathname);
    }

    function nextStep() {
        if (currentStep === STEP_INITIAL && promoType === PROMO_TYPE_INSTANT_GIFT) {
            setCurrentStep(STEP_GIFT_TYPE);
        }

        else if (currentStep === STEP_INITIAL && promoType === PROMO_TYPE_DEPOSIT_BONUS) {
            setCurrentStep(STEP_DEPOSIT_BONUS_DETAIL);
        }
        else if (currentStep === STEP_GIFT_TYPE) {
            setCurrentStep(STEP_AUDIDENCE);
        }

    }

    function uploadSuccess(data) {
        displayInfoNotificationIntl(intl, 'newcasino.promotions.admin.prom.add.success');
        back();
    }

    function uploadFailure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function handleFileUpload(event) {

        event.preventDefault();

        if (event.dataTransfer && event.dataTransfer.items) {
            if (event.dataTransfer.items.length > 1) {
                console.log('More than one file');
                return;
            }

            if (event.dataTransfer.items[0].kind === 'file') {
                const file = event.dataTransfer.items[0];
                setFile(file)
            }
        } else if (event.target.files) {
            console.log(event.target.files[0])
            if (event.target.files.length > 1) {
                console.log('More than one file');
                return;
            }

            const file = event.target.files[0];
            setFile(file)
        }
    }

    function isDepositBonusStartDateValid(isValid, value) {
        setIsDepositBonusStartDateValidMaster(isValid);
        setDepositBonusStartDate(value);
    }

    function isDepositBonusStartTimeValid(isValid, value) {
        setIsDepositBonusStartTimeValidMaster(isValid);
        setDepositBonusStartTime(value);
    }

    function isDepositBonusEndDateValid(isValid, value) {
        setIsDepositBonusEndDateValidMaster(isValid);
        setDepositBonusEndDate(value);
    }

    function isDepositBonusEndTimeValid(isValid, value) {
        setIsDepositBonusEndTimeValidMaster(isValid);
        setDepositBonusEndTime(value);
    }

    function isComboActionOk(isValid, value) {
        setIsDepositBonusActionValidMaster(isValid);
        setDepositBonusAction(value)
    }

    function isChanceOk(isValid, value) {
        setIsDepositBonusChanceValidMaster(isValid);
        setDepositBonusChance(value);
    }

    function switchChanceToWin(data) {
        setDepositBonusChance(data);
    }

    function switchXTime(data) {
        setDepositBonusAction(data);
    }

    function maxWinPerDepositInformer(isValid, value) {
        setIsDepositBonusMaxAmountPerDepositValidMaster(isValid);
        setDepositBonusMaxWinPerDeposit(value);
    }

    function maxAmountInformer(isValid, value) {
        setIsDepositBonusTotalAmountGiftValidMaster(isValid);
        setDepositBonusTotal(value);
    }

    function sendRequest() {
        const data = {
            promoType: promoType,
            giftType: giftType,
            action: 1, //No Other choice for now (Deposit Action)
            audienceType: audienceType,
            promoName: inputPromName,
            amount: amount,
            expirationFreespin: expirationFreeSpin,
            wagerX: wagerX,
            wagerMaxWin: wagerMaxWin,
            active: 1,
            executionDate: executionDate,
            executionTime: executionTime,
            wagerExpirationDate: wagerExpirationDate,
            wagerExpirationTime: wagerExpirationTime,
            depositBonusStartDate: depositBonusStartDate,
            depositBonusStartTime: depositBonusStartTime,
            depositBonusEndDate: depositBonusEndDate,
            depositBonusEndTime: depositBonusEndTime,
            depositBonusAction: depositBonusAction,
            depositBonusChance: depositBonusChance,
            depositBonusMaxWinPerDeposit: depositBonusMaxWinPerDeposit,
            depositBonusTotal: depositBonusTotal
        }

        jwtBackendService.addPromotion(file, data, uploadSuccess, uploadFailure, navigate, location.pathname);
    }

    try {

        return (
            <div className={style.master_page_container}>
                <div className={style.topMainDiv}>
                    <div>
                        <div className={style.grid100}>
                            <div className={style.gridBack}>
                                <DivClick className={style.arrow} onClick={back}>
                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                                </DivClick>

                                <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.title', defaultMessage: 'New Promotion' })}</div>
                            </div>
                            <div className={style.case1TopText3}>
                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                            </div>
                            {currentStep === STEP_INITIAL &&
                                <>
                                    <div className={style.bottom}><TextField maxLength='100' autofocus='true' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.name', defaultMessage: 'Promotion Name' })} values={[inputPromName]} validator={isTextMin2Char} validatorInformer={isNameValid} mandatory='true' showCheck='true' /></div>
                                    <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type', defaultMessage: 'Choose A Promotion Type' })} values={[promoType]} valueList={promoTypeChoices} onChange={switchPromoType} /></div>
                                    <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={canProcessNextStep()} onClick={nextStep} /></div>
                                </>
                            }

                            {currentStep === STEP_GIFT_TYPE &&
                                <>
                                    <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.title', defaultMessage: 'Choose a gift' })} values={[giftType]} valueList={giftTypeChoices} onChange={switchGiftType} /></div>

                                    <div className={style.lineDates}>
                                        <div className={style.bottom}><BirthDay label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cron.date', defaultMessage: 'Execution Date' })} values={[executionDate]} validator={isDateDDMMYYYY} validatorInformer={isExecutionDateValid} hint={'DD/MM/YYYY'} /></div>
                                        <div className={style.bottom}><Clock label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cron.time', defaultMessage: 'Execution Time' })} values={[executionTime]} validator={isHourHHMM} validatorInformer={isExecutionTimeValid} hint={'HH:MM'} /></div>
                                    </div>


                                    {giftType === GIFT_TYPE_CASH &&
                                        <>
                                            <div className={style.case1TopText3}>
                                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                            </div>
                                            <div className={style.onefield}>
                                                <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash.amount', defaultMessage: 'Amount In Euro' })} values={['']} validator={isNumeric} validatorInformer={isAmountValid} mandatory='true' showCheck='true' /></div>
                                            </div>
                                            <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={isAmountOk()} onClick={nextStep} /></div>
                                        </>
                                    }

                                    {giftType === GIFT_TYPE_FREESPIN &&
                                        <>
                                            <div className={style.case1TopText3}>
                                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                            </div>
                                            <div className={style.onefield}>
                                                <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin.quantity', defaultMessage: 'Quantity' })} values={['']} validator={isNumeric} validatorInformer={isAmountValid} mandatory='true' showCheck='true' /></div>
                                            </div>
                                            <div className={style.onefield}>
                                                <div className={style.bottom}><TextField maxLength='5' autofocus='false' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin.expiration.days', defaultMessage: 'Expiry in days' })} values={[expirationFreeSpin]} validator={isNumeric} validatorInformer={isExpirationFreeSpinValid} mandatory='true' showCheck='true' /></div>
                                            </div>
                                            <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={isFreeSpinOk()} onClick={nextStep} /></div>
                                        </>
                                    }

                                    {giftType === GIFT_TYPE_PNG &&
                                        <>
                                            <div className={style.case1TopText3}>
                                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                            </div>
                                            <div className={style.onefield}>
                                                <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash.quantity', defaultMessage: 'Quantity' })} values={['']} validator={isNumeric} validatorInformer={isAmountValid} mandatory='true' showCheck='true' /></div>
                                            </div>
                                            <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={isAmountOk()} onClick={nextStep} /></div>
                                        </>
                                    }

                                    {giftType === GIFT_TYPE_WAGER &&
                                        <>
                                            <div className={style.case1TopText3}>
                                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                            </div>
                                            <div className={style.threefields}>
                                                <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.amount', defaultMessage: 'Amount In Euro' })} values={['']} validator={isNumeric} validatorInformer={isAmountValid} mandatory='true' showCheck='true' /></div>
                                                <div className={style.bottom}><TextField maxLength='100' autofocus='false' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.xtime', defaultMessage: 'X times' })} values={['']} validator={isNumeric} validatorInformer={isXTimesValid} mandatory='true' showCheck='true' /></div>
                                                <div className={style.bottom}><TextField maxLength='100' autofocus='false' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.maxwin', defaultMessage: 'Max Win' })} values={['']} validator={isNumeric} validatorInformer={isMaxWinValid} mandatory='true' showCheck='true' /></div>
                                            </div>
                                            <div className={style.lineDates}>
                                                <div className={style.bottom}><BirthDay label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.expiration.date', defaultMessage: 'Expiration Date' })} values={[wagerExpirationDate]} validator={isDateDDMMYYYY} validatorInformer={isWagerExpirationDateValid} hint={'DD/MM/YYYY'} /></div>
                                                <div className={style.bottom}><Clock label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.expiration.time', defaultMessage: 'Expiration Time' })} values={[wagerExpirationTime]} validator={isHourHHMM} validatorInformer={isWagerExpirationTimeValid} hint={'HH:MM'} /></div>
                                            </div>
                                            <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={isWagerOk()} onClick={nextStep} /></div>
                                        </>
                                    }

                                </>
                            }

                            {currentStep === STEP_DEPOSIT_BONUS_DETAIL &&
                                <>
                                    <div className={style.lineDates}>
                                        <div className={style.bottom}><BirthDay label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.startdate', defaultMessage: 'Start Date' })} values={[depositBonusStartDate]} validator={isDateDDMMYYYY} validatorInformer={isDepositBonusStartDateValid} hint={'DD/MM/YYYY'} /></div>
                                        <div className={style.bottom}><Clock label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.starttime', defaultMessage: 'Start Time' })} values={[depositBonusStartTime]} validator={isHourHHMM} validatorInformer={isDepositBonusStartTimeValid} hint={'HH:MM'} /></div>
                                    </div>
                                    <div className={style.lineDates}>
                                        <div className={style.bottom}><BirthDay label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.enddate', defaultMessage: 'End Date' })} values={[depositBonusEndDate]} validator={isDateDDMMYYYY} validatorInformer={isDepositBonusEndDateValid} hint={'DD/MM/YYYY'} /></div>
                                        <div className={style.bottom}><Clock label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.endtime', defaultMessage: 'End Time' })} values={[depositBonusEndTime]} validator={isHourHHMM} validatorInformer={isDepositBonusEndTimeValid} hint={'HH:MM'} /></div>
                                    </div>
                                    <div className={style.lineDetails}>
                                        <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.action', defaultMessage: 'Action on deposit amount' })} values={[depositBonusAction]} valueList={depositXTimeChoices} onChange={switchXTime} validator={inNotMinusOne} validatorInformer={isComboActionOk} /></div>
                                        <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.chance', defaultMessage: 'Chance to win' })} values={[depositBonusChance]} valueList={chancesToWinChoices} onChange={switchChanceToWin} validator={inNotMinusOne} validatorInformer={isChanceOk} /></div>

                                    </div>
                                    <div className={style.lineDetailsEnd}>
                                        <div className={style.bottom}><TextField type='digit' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.maxwin', defaultMessage: 'Max Win Per Deposit (EUR)' })} showCheck='true' validator={isTextMin2Char} validatorInformer={maxWinPerDepositInformer} values={[depositBonusMaxWinPerDeposit]} hint={''} /></div>
                                        <div className={style.bottom}><TextField type='digit' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.total', defaultMessage: 'Total Amount Gift (EUR)' })} showCheck='true' validator={isTextMin2Char} validatorInformer={maxAmountInformer} values={[depositBonusTotal]} hint={''} /></div>
                                    </div>
                                    <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={canProcessDepositBonus()} onClick={sendRequest} /></div>
                                </>
                            }

                            {currentStep === STEP_AUDIDENCE && promoType === PROMO_TYPE_INSTANT_GIFT &&
                                <>
                                    <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.select', defaultMessage: 'Choose recipients' })} values={[audienceType]} valueList={audienceGiftChoices} onChange={switchAudienceType} /></div>


                                    {audienceType === AUDIENCE_ALL &&
                                        <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={canProcessAudience()} onClick={sendRequest} /></div>
                                    }

                                    {audienceType === AUDIENCE_SPECIFIC &&
                                        <>
                                            <input className={style.fileInput} type="file" id="uploadMyFile" name="myfile1" onChange={(e) => handleFileUpload(e, 1)} />
                                            <div className={style.bottom}><Button width='250px' colorStyle='light' value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.upload', defaultMessage: 'Select File To Upload' })} onClick={() => document.getElementById('uploadMyFile').click()} /></div>
                                            <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={canProcessAudience()} onClick={sendRequest} /></div>
                                        </>
                                    }

                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    } catch (error) {
        console.log(error);
    }
}


export default NewPromotion;
