import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as manageGames from 'newui/Services/Backend/manageGames';
import * as constants from 'newui/constants.js'
import style from './style.module.scss'
import { urlToBackgroundUrl } from 'newui/Utils/uiUtil'
import { displayPage } from 'newui/Utils/routerUtil';
import GameEditor from './GameEditor';
import CategoryEditor from './CategoryEditor';
import SubTypeEditor from './SubTypeEditor';
import TypeEditor from './TypeEditor';
import ProviderEditor from './ProviderEditor';
import SpanClick from 'newui/Generic/Inputs/SpanClick';
import DivClick from 'newui/Generic/Inputs/DivClick';
import { createManagePageGamesDescription } from 'newui/Utils/uiUtil';
import { getImageUrl } from 'newui/Utils/uiUtil';

function ManageGames(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState(null);
  const [subTypes, setSubTypes] = useState([]);
  const [games, setGames] = useState([]);

  const [tabSelectedCat, setTabSelectedCat] = useState(-1);
  const [tabSelectedType, setTabSelectedType] = useState(-1);
  const [tabSelectedSubType, setTabSelectedSubType] = useState(-1);
  const [tabSelectedProv, setTabSelectedProv] = useState(-1);

  const dragItem = useRef();
  const dragOverItem = useRef();


  let isDownCat = false;
  let startXCat;
  let scrollLeftCat;

  let isDownType = false;
  let startXType;
  let scrollLeftType;

  let isDownSubType = false;
  let startXSubType;
  let scrollLeftSubType;

  let isDownProv = false;
  let startXProv;
  let scrollLeftProv;

  let moveInProgressCat = 0;
  let moveInProgressType = 0;
  let moveInProgressSubType = 0;
  let moveInProgressProv = 0;

  useEffect(() => {
    getAllGamesInformation()
  },// eslint-disable-next-line
    [])

  useEffect(() => {
    if (data != null) {
      doSelection(tabSelectedProv, tabSelectedCat, tabSelectedType, tabSelectedSubType);
    }
  },// eslint-disable-next-line
    [data])




  function changeTabProv(newTab) {
    if (newTab === tabSelectedProv) {

      setTabSelectedProv(-1);
      doSelection(-1, tabSelectedCat, tabSelectedType, tabSelectedSubType);
    } else {

      setTabSelectedProv(newTab);
      doSelection(newTab, tabSelectedCat, tabSelectedType, tabSelectedSubType);
    }

  }


  function changeTabCat(newTab) {
    if (newTab === tabSelectedCat) {

      setTabSelectedCat(-1);
      doSelection(tabSelectedProv, -1, tabSelectedType, tabSelectedSubType);
    } else {

      setTabSelectedCat(newTab);
      doSelection(tabSelectedProv, newTab, tabSelectedType, tabSelectedSubType);
    }

  }

  function dragStart(e, id) {
    dragItem.current = id;
  };

  function dragEnter(e, id) {
    dragOverItem.current = id;
  };

  function drop(e, id) {
    const target = dragOverItem.current;
    manageGames.changeGameOrder(id, target, successCallBack, failureCallBack)
  }

  function resetSubTypes(theData, typeIndex) {
    var newSubTypes = [];
    if (typeIndex !== -1) {
      for (let i = 0; i < theData.gamesSubTypes.length; i++) {
        if (theData.gamesTypes[typeIndex] !== undefined) {
          if (theData.gamesSubTypes[i].type === theData.gamesTypes[typeIndex].id) {
            newSubTypes.push(theData.gamesSubTypes[i]);
          }
        }
      }
    }
    setSubTypes(newSubTypes);


  }
  function changeTabType(newTab) {

    if (newTab === tabSelectedType) {
      setTabSelectedType(-1);
      setTabSelectedSubType(-1);
      resetSubTypes(data, -1)
      doSelection(tabSelectedProv, tabSelectedCat, -1, -1);
    } else {
      setTabSelectedType(newTab);
      setTabSelectedSubType(-1);
      resetSubTypes(data, newTab)

      doSelection(tabSelectedProv, tabSelectedCat, newTab, -1);
    }

  }

  function changeTabSubType(newTab) {
    if (newTab === tabSelectedSubType) {
      setTabSelectedSubType(-1);
      doSelection(tabSelectedProv, tabSelectedCat, tabSelectedType, -1);
    } else {
      setTabSelectedSubType(newTab);
      doSelection(tabSelectedProv, tabSelectedCat, tabSelectedType, newTab);
    }
  }

  function reorderGames(gamelist) {
    return gamelist;
  }

  function doSelection(provParam, catParam, typeParam, subTypeParam) {
    var newArray = reorderGames([...data.games]);
    var gameList = [];

    var catId = (catParam === -1) ? -1 : data.gamesCategory[catParam].id;
    var provId = (provParam === -1) ? -1 : (data.providers[provParam] ? data.providers[provParam].id : -1);
    var typeId = (typeParam === -1) ? -1 : (data.gamesTypes[typeParam] ? data.gamesTypes[typeParam].id : -1);
    var subTypeId = (subTypeParam === -1) ? -1 : subTypes[subTypeParam].id;

    for (let i = 0; i < newArray.length; i++) {
      var mustShow = true;
      if (provId !== -1) {
        if (newArray[i].provider_id !== provId) {
          mustShow = false;
        }
      }

      if (catId !== -1) {
        if (newArray[i].category_id !== catId) {
          mustShow = false;
        }
      }

      if (typeId !== -1) {
        if (newArray[i].typeId !== typeId) {
          mustShow = false;
        }
      }

      if (subTypeId !== -1) {
        if (newArray[i].subType !== subTypeId) {
          mustShow = false;
        }
      }

      if (mustShow) {
        gameList.push(newArray[i]);
      }
    }
    setGames(gameList);

  }

  function onSuccessGetAllGamesInformation(data) {
    var newData = data;

    newData = {
      gamesCategory: data[0],
      gamesTypes: data[1],
      gamesSubTypes: data[2],
      games: data[3],
      providers: data[4],
      subTypePossiblities: null
    }
    newData = createManagePageGamesDescription(newData);

    setData(newData);
    resetSubTypes(newData, tabSelectedType);
  }

  function onFailureGetAllGamesInformation(error) {
    console.log(error);
    displayPage(constants.ROUTE_500, navigate);
  }

  function getAllGamesInformation() {
    return manageGames.getManageGamesInformation(onSuccessGetAllGamesInformation, onFailureGetAllGamesInformation, navigate, location.pathname)
  }




  function newTabSelectedFromTabsListProv(index) {
    if (props.isMobile || ((Date.now() - moveInProgressProv) < 200)) {
      changeTabProv(index)
    }
  }

  function newTabSelectedFromTabsListCat(index) {
    if (props.isMobile || ((Date.now() - moveInProgressCat) < 200)) {
      changeTabCat(index)
    }
  }

  function newTabSelectedFromTabsListType(index) {
    if (props.isMobile || ((Date.now() - moveInProgressType) < 200)) {
      changeTabType(index)
    }
  }


  function newTabSelectedFromTabsListSubType(index) {
    if (props.isMobile || ((Date.now() - moveInProgressSubType) < 200)) {
      changeTabSubType(index)
    }
  }

  function getTabStyleProv(index) {
    var toReturn = style.GameSelection_tab_notSelected;

    if (tabSelectedProv === index) {
      toReturn = style.GameSelection_tab_selected;
    }

    return toReturn;
  }

  function getTabStyleCat(index) {
    var toReturn = style.GameSelection_tab_notSelected;

    if (tabSelectedCat === index) {
      toReturn = style.GameSelection_tab_selected;
    }

    return toReturn;
  }

  function getTabStyleSubType(index) {
    var toReturn = style.GameSelection_tab_notSelected;

    if (tabSelectedSubType === index) {
      toReturn = style.GameSelection_tab_selected;
    }

    return toReturn;
  }

  function isGameActive(item) {
    if (item.active === 1) {
      return '';
    }

    return style.notActive;
  }
  function getTabStyleType(index) {
    var toReturn = style.GameSelection_tab_notSelected;

    if (tabSelectedType === index) {
      toReturn = style.GameSelection_tab_selected;
    }

    return toReturn;
  }



  function sliderMouseDown(e, divId) {
    if (props.isMobile) { return; }
    var slider = document.getElementById(divId)//e.target;


    if (divId === 'scrollbarCategories') {
      isDownCat = true;
      slider.classList.add('active');
      startXCat = e.pageX - slider.offsetLeft;
      scrollLeftCat = slider.scrollLeft;
      moveInProgressCat = Date.now();
    }
    else if (divId === 'scrollbarTypes') {
      isDownType = true;
      slider.classList.add('active');
      startXType = e.pageX - slider.offsetLeft;
      scrollLeftType = slider.scrollLeft;
      moveInProgressType = Date.now();
    }
    else if (divId === 'scrollbarSubTypes') {
      isDownSubType = true;
      slider.classList.add('active');
      startXSubType = e.pageX - slider.offsetLeft;
      scrollLeftSubType = slider.scrollLeft;
      moveInProgressSubType = Date.now();
    }
    else if (divId === 'scrollbarProviders') {
      isDownProv = true;
      slider.classList.add('active');
      startXProv = e.pageX - slider.offsetLeft;
      scrollLeftProv = slider.scrollLeft;
      moveInProgressProv = Date.now();
    }
  }

  function successCallBack(resp) {
    getAllGamesInformation();
  }

  function failureCallBack(title, msg) {
  }

  function editGameCallBack(game, index, action) {
    if (action === 'ADD' || action === 'MODIFY') {
      manageGames.addModifyGame(game, successCallBack, failureCallBack);
    } else if (action === 'DELETE_GAME') {
      manageGames.deleteGame(game, successCallBack, failureCallBack);
    }
  }

  function editProviderCallBack(provider, index, action) {
    if (action === 'ADD' || action === 'MODIFY') {
      manageGames.addModifyProvider(provider, successCallBack, failureCallBack);
    } else if (action === 'DELETE_PROV') {
      setTabSelectedProv(-1)
      manageGames.deleteProvider(provider, successCallBack, failureCallBack);
    }

  }

  function editTypeCallBack(type, index, action) {
    if (action === 'ADD' || action === 'MODIFY') {
      manageGames.addModifyType(type, successCallBack, failureCallBack);
    } else if (action === 'DELETE_TYPE') {
      setTabSelectedSubType(-1);
      setTabSelectedType(-1);
      manageGames.deleteType(type, successCallBack, failureCallBack);
    }

  }

  function editSubTypeCallBack(subType, index, action) {
    if (action === 'ADD' || action === 'MODIFY') {
      manageGames.addModifySubType(subType, successCallBack, failureCallBack);
    } else if (action === 'DELETE_SUBTYPE') {
      setTabSelectedSubType(-1);
      manageGames.deleteSubType(subType, successCallBack, failureCallBack);
    }
  }

  function editCategoryCallBack(category, index, action) {
    if (action === 'ADD' || action === 'MODIFY') {
      manageGames.addModifyCategory(category, successCallBack, failureCallBack);
    } else if (action === 'DELETE_CAT') {
      setTabSelectedCat(-1);
      manageGames.deleteCategory(category, successCallBack, failureCallBack);
    }
  }



  function sliderMouseLeave(e, divId) {
    if (props.isMobile) { return; }
    var slider = document.getElementById(divId)

    if (divId === 'scrollbarCategories') {
      isDownCat = false;
      slider.classList.remove('active');

    }
    else if (divId === 'scrollbarTypes') {
      isDownType = false;
      slider.classList.remove('active');

    }
    else if (divId === 'scrollbarSubTypes') {
      isDownSubType = false;
      slider.classList.remove('active');
    }
    else if (divId === 'scrollbarProviders') {
      isDownProv = false;
      slider.classList.remove('active');
    }


  }



  function handleClickGameEditor(game, index) {
    props.displayComponent(
      <GameEditor
        key={Math.random()}
        game={game}
        currentCategory={(tabSelectedCat !== -1) ? data.gamesCategory[tabSelectedCat] : null}
        currentProvider={(tabSelectedProv !== -1) ? data.providers[tabSelectedProv] : null}
        currentType={(tabSelectedType !== -1) ? data.gamesTypes[tabSelectedType] : null}
        currentSubType={(tabSelectedSubType !== -1) ? data.subTypePossiblities.get('' + data.gamesTypes[tabSelectedType].id)[tabSelectedSubType] : null}
        subTypes={data.subTypePossiblities}
        providers={data.providers}
        categories={data.gamesCategory}
        types={data.gamesTypes}
        index={index}
        callback={editGameCallBack} />);
  }

  function handleClickProviderEditor(provider, index) {
    props.displayComponent(<ProviderEditor key={Math.random()} provider={provider} index={index} callback={editProviderCallBack} />);
  }

  function handleClickTypeEditor(type, index) {
    props.displayComponent(<TypeEditor key={Math.random()} type={type} index={index} callback={editTypeCallBack} />);
  }

  function handleClickSubTypeEditor(subType, index) {
    props.displayComponent(<SubTypeEditor key={Math.random()} currentType={(tabSelectedType !== -1) ? data.gamesTypes[tabSelectedType] : null} types={data.gamesTypes} subType={subType} index={index} callback={editSubTypeCallBack} />);
  }

  function handleClickCategoryEditor(category, index) {
    props.displayComponent(<CategoryEditor key={Math.random()} category={category} index={index} callback={editCategoryCallBack} />);
  }


  function sliderMouseUp(e, divId) {
    if (props.isMobile) { return; }
    var slider = document.getElementById(divId)

    if (divId === 'scrollbarCategories') {
      isDownCat = false;
      slider.classList.remove('active');

    }
    else if (divId === 'scrollbarTypes') {
      isDownType = false;
      slider.classList.remove('active');

    }
    else if (divId === 'scrollbarSubTypes') {
      isDownSubType = false;
      slider.classList.remove('active');
    }
    else if (divId === 'scrollbarProviders') {
      isDownProv = false;
      slider.classList.remove('active');
    }





  }

  function sliderMouseMove(e, divId) {
    if (props.isMobile) { return; }
    pauseEvent(e)
    var slider = document.getElementById(divId)

    if (divId === 'scrollbarCategories') {
      if (!isDownCat) return;
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startXCat) * 3; //scroll-fast
      slider.scrollLeft = scrollLeftCat - walk;

    }
    else if (divId === 'scrollbarTypes') {
      if (!isDownType) return;
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startXType) * 3; //scroll-fast
      slider.scrollLeft = scrollLeftType - walk;

    }
    else if (divId === 'scrollbarSubTypes') {
      if (!isDownSubType) return;
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startXSubType) * 3; //scroll-fast
      slider.scrollLeft = scrollLeftSubType - walk;
    }
    else if (divId === 'scrollbarProviders') {
      if (!isDownProv) return;
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startXProv) * 3; //scroll-fast
      slider.scrollLeft = scrollLeftProv - walk;
    }

  }

  function pauseEvent(e, divId) {
    if (props.isMobile) { return; }
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }


  try {
    if (data) {
      return (
        <>
          <div className={`${style.GameSelection_mainDiv}`}>
            <div className={`${style.GameSelection_mainDiv2}`}>
              <div className={`${style.theTitle}`}>
                Provider <SpanClick className={`${style.pointer}`} onClick={() => handleClickProviderEditor(null, -1)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" aria-hidden="true"></i></SpanClick>
                {(tabSelectedProv !== -1) &&
                  <SpanClick className={`${style.pointer}`} onClick={() => handleClickProviderEditor(data.providers[tabSelectedProv], tabSelectedProv)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-pencil" aria-hidden="true"></i></SpanClick>
                }
              </div>

              <div id={'scrollbarProviders'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_nopaddingbottom}`} onMouseUp={(e) => sliderMouseUp(e, 'scrollbarProviders')} onMouseMove={(e) => sliderMouseMove(e, 'scrollbarProviders')} onMouseLeave={(e) => sliderMouseLeave(e, 'scrollbarProviders')} onMouseDown={(e) => sliderMouseDown(e, 'scrollbarProviders')}>
                <ul className={`${style.GameSelection_ul}`}>

                  {data.providers.map((item, index) => (
                    <li key={'providers_' + index}>
                      <DivClick key={'gameProv_' + index} className={`${getTabStyleProv(index)}`} onClick={() => newTabSelectedFromTabsListProv(index)} >
                        <i>
                          {item.name}
                        </i>
                      </DivClick>
                    </li>
                  ))}

                </ul>
              </div>
              {/********************************************************/}
              <div className={`${style.theTitle}`}>
                Categories <SpanClick className={`${style.pointer}`} onClick={() => handleClickCategoryEditor(null, -1)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" aria-hidden="true"></i></SpanClick>
                {(tabSelectedCat !== -1) &&
                  <SpanClick className={`${style.pointer}`} onClick={() => handleClickCategoryEditor(data.gamesCategory[tabSelectedCat], tabSelectedCat)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-pencil" aria-hidden="true"></i></SpanClick>
                }
              </div>

              <div id={'scrollbarCategories'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_nopaddingbottom}`} onMouseUp={(e) => sliderMouseUp(e, 'scrollbarCategories')} onMouseMove={(e) => sliderMouseMove(e, 'scrollbarCategories')} onMouseLeave={(e) => sliderMouseLeave(e, 'scrollbarCategories')} onMouseDown={(e) => sliderMouseDown(e, 'scrollbarCategories')}>
                <ul className={`${style.GameSelection_ul}`}>

                  {data.gamesCategory.map((item, index) => (
                    <li key={'categories_' + index}>
                      <DivClick key={'gameCat_' + index} className={`${getTabStyleCat(index)}`} onClick={() => newTabSelectedFromTabsListCat(index)} >
                        <i>
                          {item.category}
                        </i>
                      </DivClick>
                    </li>
                  ))}

                </ul>
              </div>
              <div className={`${style.theTitle}`}>
                Game Types <SpanClick className={`${style.pointer}`} onClick={() => handleClickTypeEditor(null, -1)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" aria-hidden="true"></i></SpanClick>
                {(tabSelectedType !== -1) &&
                  <SpanClick className={`${style.pointer}`} onClick={() => handleClickTypeEditor(data.gamesTypes[tabSelectedType], tabSelectedType)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-pencil" aria-hidden="true"></i></SpanClick>
                }
              </div>
              {/********************************************************/}
              <div id={'scrollbarTypes'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_nopaddingbottom}`} onMouseUp={(e) => sliderMouseUp(e, 'scrollbarTypes')} onMouseMove={(e) => sliderMouseMove(e, 'scrollbarTypes')} onMouseLeave={(e) => sliderMouseLeave(e, 'scrollbarTypes')} onMouseDown={(e) => sliderMouseDown(e, 'scrollbarTypes')}>
                <ul className={`${style.GameSelection_ul}`}>

                  {data.gamesTypes.map((item, index) => (
                    <li key={'types_' + index}>
                      <DivClick key={'gameTypes_' + index} className={`${getTabStyleType(index)}`} onClick={() => newTabSelectedFromTabsListType(index)} >
                        <i>
                          {item.name}
                        </i>
                      </DivClick>
                    </li>
                  ))}

                </ul>
              </div>

              {(tabSelectedType > -1 &&
                <>
                  <div className={`${style.theTitle}`}>
                    Game SubType <SpanClick className={`${style.pointer}`} onClick={() => handleClickSubTypeEditor(null, -1)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" aria-hidden="true"></i></SpanClick>
                    {(tabSelectedSubType !== -1) &&
                      <SpanClick className={`${style.pointer}`} onClick={() => handleClickSubTypeEditor(subTypes[tabSelectedSubType], tabSelectedSubType)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-pencil" aria-hidden="true"></i></SpanClick>
                    }
                  </div>

                  {/********************************************************/}
                  <div id={'scrollbarSubTypes'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_nopaddingbottom}`} onMouseUp={(e) => sliderMouseUp(e, 'scrollbarSubTypes')} onMouseMove={(e) => sliderMouseMove(e, 'scrollbarSubTypes')} onMouseLeave={(e) => sliderMouseLeave(e, 'scrollbarSubTypes')} onMouseDown={(e) => sliderMouseDown(e, 'scrollbarSubTypes')}>
                    <ul className={`${style.GameSelection_ul}`}>

                      {subTypes.map((item, index) => (
                        <li key={'subTypes_' + index}>
                          <DivClick key={'gameSubTypes_' + index} className={`${getTabStyleSubType(index)}`} onClick={() => newTabSelectedFromTabsListSubType(index)} >
                            <i>
                              {item.name}
                            </i>
                          </DivClick>
                        </li>
                      ))}

                    </ul>
                  </div>
                  {/****************************************************************/}
                </>)}

              <div className={`${style.theTitle}`}>
                Games <SpanClick className={`${style.pointer}`} onClick={() => handleClickGameEditor(null, -1)}>&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" aria-hidden="true"></i></SpanClick>
              </div>
              {(games.length > 0) && (
                <>
                  < div className={`${style.GameSelection_showAllGrid}`} >
                    {games.map((item, index) => (
                      <DivClick key={'tabSpecificShowAlls_' + index} className={`${style.GameSelection_showAllGridElement_div}`} onClick={() => handleClickGameEditor(item, index)}>
                        <div onDragEnd={(e) => drop(e, item.id)} onDragStart={(e) => dragStart(e, item.id)} onDragEnter={(e) => dragEnter(e, item.id)} draggable>
                          <div className={`${style.GameSelection_showAllGridElement} ${isGameActive(item)}`} style={{
                            backgroundImage: urlToBackgroundUrl(item.game_image_url)
                          }}>
                            <img className={`${style.GameSelection_showAllGridElement_img}`} src={item.game_image_url} alt='' onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = getImageUrl('newui/ManageGames/nogameImage.png');
                              currentTarget.style = "visibility='visible' !important;"
                            }} />
                          </div>
                        </div>
                      </DivClick>
                    ))}
                  </div>
                </>
              )}


            </div>

          </div >
        </>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ManageGames