import React, { useState } from 'react';

import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl, inNotMinusOne, isTextMin2Char, nullToEmpty, nullToMinusOne } from 'newui/Utils/uiUtil';

import Button from 'newui/Generic/Inputs/Button';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import TextField from 'newui/Generic/Inputs/TextField';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import ApiPersistence from 'newui/Utils/ApiPersistence';
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import ChangePhoneNumber from './ChangePhoneNumber';
import * as constants from 'newui/constants.js'

function PersoInfo(props) {
    const [countries] = useState(props.countries);
    const [personalData] = useState(props.personalData);

    var user = ApiPersistence.getInstance().getUser();

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    const [address, setAddress] = useState(props.personalData.kycAddressLine1);
    const [addressValid, setAddressValid] = useState(isTextMin2Char(props.personalData.kycAddressLine1));

    const [country, setCountry] = useState(props.personalData.kycCountry);
    const [countryValid, setCountryValid] = useState(inNotMinusOne(props.personalData.kycCountry));

    const [state, setState] = useState(props.personalData.kycState);
    const [stateValid, setStateValid] = useState(isTextMin2Char(props.personalData.kycState));

    const [city, setCity] = useState(props.personalData.kycCity);
    const [cityValid, setCityValid] = useState(isTextMin2Char(props.personalData.kycCity));

    const [zipcode, setZipcode] = useState(props.personalData.kycZip);
    const [zipcodeValid, setZipcodeValid] = useState(isTextMin2Char(props.personalData.kycZip));

    function canUpdate() {
        return countryValid && addressValid && cityValid && stateValid && zipcodeValid;
    }

    function addressValidCallBack(isValid, value) {
        setAddressValid(isValid);
        setAddress(value)
    }

    function countryValidCallBack(isValid, value) {
        setCountryValid(isValid);
        setCountry(value);
    }

    function cityValidCallBack(isValid, value) {
        setCityValid(isValid);
        setCity(value);
    }

    function zipcodeValidCallBack(isValid, value) {
        setZipcodeValid(isValid);
        setZipcode(value);
    }


    function stateValidCallBack(isValid, value) {
        setStateValid(isValid);
        setState(value);
    }

    function updateInfo() {
        var data = {
            AddressLine1Profile: address,
            CityProfile: city,
            StateProfile: state,
            CountryProfile: country,
            ZipProfile: zipcode,
        }
        jwtBackendService.updateMyPersonalData(data, successUpdatePersonalDataJWT, failureUpdatePersonalDataJWT, navigate, location.pathname);
    }

    function successUpdatePersonalDataJWT(data) {
        if (data.result === 'OK') {
            displayInfoNotificationIntl(intl, 'newcasino.profile.info.success')
        } else {
            displayGenericErrorNotificationIntl(intl)
        }
    }

    function failureUpdatePersonalDataJWT(data) {
        displayGenericErrorNotificationIntl(intl)
    }



    function changePhone() {
        const theMsg = {
            component: <ChangePhoneNumber reload={props.reload} key={Math.random()} personalData={props.personalData} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    return (
        <>

            <div className={style.desktop2Cell}>
                <TextField label={intl.formatMessage({ id: 'newcasino.profile.info.ln.label', defaultMessage: 'Last Name' })} readOnly={true} values={[nullToEmpty(personalData.kycLastName)]} />
                <TextField label={intl.formatMessage({ id: 'newcasino.profile.info.fn.label', defaultMessage: 'First Name' })} readOnly={true} values={[nullToEmpty(personalData.kycFirstName)]} />
            </div>
            <div className={style.desktop2Cell}>
                <TextField label={intl.formatMessage({ id: 'newcasino.profile.info.email.label', defaultMessage: 'Email' })} readOnly={true} values={[user.email]} />
                <div className={style.desktop2Cell2}>
                    <TextField label={intl.formatMessage({ id: 'newcasino.profile.info.phone.label', defaultMessage: 'Phone Number' })} readOnly={true} values={[nullToEmpty(personalData.phoneVerify_phone)]} />
                    <div className={style.butSpacer}><Button value={intl.formatMessage({ id: 'newcasino.profile.info.button.change', defaultMessage: 'Change' })} onClick={changePhone} /></div>
                </div>
            </div>
            <div className={style.desktop2Cell}>
                <TextField maxLength='100' autofocus='true' values={[nullToEmpty(address), addressValid]} hint={intl.formatMessage({ id: 'newcasino.profile.info.address.address.hint', defaultMessage: 'Type your address here' })} label={intl.formatMessage({ id: 'newcasino.profile.info.address.address.label', defaultMessage: 'Address' })} validator={isTextMin2Char} validatorInformer={addressValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.profile.info.address.address.error', defaultMessage: 'Your address is too short' })} />
                <TextField maxLength='100' values={[city, cityValid]} hint={intl.formatMessage({ id: 'newcasino.profile.info.address.city.hint', defaultMessage: 'Type your city here' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.profile.info.address.city.label', defaultMessage: 'City' })} validator={isTextMin2Char} showTitleError='true' showCheck='true' validatorInformer={cityValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.profile.info.address.city.error', defaultMessage: 'Your city is too short' })} />
            </div>
            <div className={style.desktop3Cell}>
                <TextField maxLength='100' autofocus='true' values={[nullToEmpty(state), stateValid]} hint={intl.formatMessage({ id: 'newcasino.profile.info.address.state.hint', defaultMessage: 'Type your State here' })} label={intl.formatMessage({ id: 'newcasino.profile.info.address.state.label', defaultMessage: 'State/Province' })} validator={isTextMin2Char} validatorInformer={stateValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.profile.info.address.state.error', defaultMessage: 'Your state is too short' })} />
                <ComboBox autofocus='true' values={[nullToMinusOne(country), countryValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.profile.info.address.country.label', defaultMessage: 'Select your country...' }) }} valueList={{ list: countries, key: 'id', value: 'name' }} hint={intl.formatMessage({ id: 'newcasino.profile.info.address.country.hint', defaultMessage: 'Country' })} label={intl.formatMessage({ id: 'newcasino.profile.info.address.country.label', defaultMessage: 'Country' })} showCheck='true' validator={inNotMinusOne} validatorInformer={countryValidCallBack} mandatory='true' />
                <TextField maxLength='100' uppercase={true} values={[nullToEmpty(zipcode), zipcodeValid]} hint={intl.formatMessage({ id: 'newcasino.profile.info.address.zip.hint', defaultMessage: 'Postal Code' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.profile.info.address.zip.label', defaultMessage: 'Postal Code' })} validator={isTextMin2Char} showTitleError='false' showCheck='true' validatorInformer={zipcodeValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.profile.info.address.zip.error', defaultMessage: 'Your Postal Code is too short' })} />
            </div>
            <div className={style.buttCenter}>
                <Button active={canUpdate()} value={intl.formatMessage({ id: 'newcasino.profile.info.button.update', defaultMessage: 'Update' })} onClick={updateInfo} />
            </div>
        </>
    );
}

export default PersoInfo;