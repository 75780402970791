import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl'
import Question from './Question';
import Button from 'newui/Generic/Inputs/Button';
import { displayPage } from 'newui/Utils/routerUtil';
import * as constants from 'newui/constants.js'
import { useLocation, useNavigate } from 'react-router-dom';
import { getImageUrl } from 'newui/Utils/uiUtil';

function FAQ(props) {
    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    function openContact() {
        displayPage(constants.ROUTE_CONTACT, navigate, location.pathname)
    }

    return (
        <div className={style.master_page_container}>
            <div className={style.mainTitle}>
                <FormattedMessage id="newcasino.faq.title" defaultMessage="FAQ" />
            </div>
            <div className={style.mainDiv}>
                <Question question={intl.formatMessage({ id: 'newcasino.faq.q1' })} answer={intl.formatMessage({ id: 'newcasino.faq.a1' })} open={true} />
                <Question question={intl.formatMessage({ id: 'newcasino.faq.q2' })} answer={intl.formatMessage({ id: 'newcasino.faq.a2' })} />
                <Question question={intl.formatMessage({ id: 'newcasino.faq.q3' })} answer={intl.formatMessage({ id: 'newcasino.faq.a3' })} />
                <Question question={intl.formatMessage({ id: 'newcasino.faq.q4' })} answer={intl.formatMessage({ id: 'newcasino.faq.a4' })} />
                <Question question={intl.formatMessage({ id: 'newcasino.faq.q5' })} answer={intl.formatMessage({ id: 'newcasino.faq.a5' })} />

                <div className={style.moreDiv}>
                    <div className={style.header1}><FormattedMessage id="newcasino.faq.more" defaultMessage="MORE QUESTIONS?" /></div>
                    <img src={getImageUrl("newui/general/sep.png")} alt='' />
                    <div className={style.header2}><FormattedMessage id="newcasino.faq.hesitate" defaultMessage="Do not hesitate to contact us for further answers." /></div>
                    <div className={style.buttCenter}><Button value={intl.formatMessage({ id: 'newcasino.faq.contact' })} onClick={openContact} /></div>
                </div>
            </div>
        </div >
    );
}

export default FAQ;
