import React from 'react'
//import style from './style.module.scss'




function HorizontalSlider(props) {
  try {
    return (
      <>
        {props.children}
      </>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default HorizontalSlider

