import React from 'react'

function DivClick(props) {

  var startTime = -1;
  var startX = -1;
  var startY = -1;


  function touchEnd(evt) {
    if (props.onClick) {
      var deltaX = Math.abs(startX - evt.changedTouches[0].pageX);
      var deltaY = Math.abs(startY - evt.changedTouches[0].pageY);
      var start = startTime;
      startTime = -1;

      if (evt.cancelable) {
        evt.preventDefault()
      }

      if ((Date.now() - start < 200) && (deltaX < 10) && (deltaY < 10)) {
        props.onClick();
      }


    }
  }

  function touchStart(evt) {
    let touch = (evt.touches && evt.touches[0]) || (evt.pointerType && evt.pointerType === 'touch' && evt);
    startX = (touch || evt).pageX;
    startY = (touch || evt).pageY;

    var start = Date.now();
    startTime = start;
  }

  try {
    return (
      <div style={props.style} className={props.className} onTouchStart={(e) => touchStart(e)} onClick={props.onClick} onTouchEnd={(e) => touchEnd(e)}>
        {props.children}
      </div >
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default DivClick

