/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import DivClick from 'newui/Generic/Inputs/DivClick';
import * as constants from 'newui/constants.js'
import { displayPage } from 'newui/Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom';
import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper'
import ApiPersistence from 'newui/Utils/ApiPersistence';
import { getCashierCSS, getCashierColorPerTheme } from 'newui/Utils/uiUtil';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import moment from 'moment';

const uuid = require('uuid');

function PaymentIQ(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [cashierInstance, setCashierInstance] = useState(null);
    const [sessionId] = useState(uuid.v4());

    useEffect(() => {
        loadPaymentIQ()
    }, []);

    function loadFinish() {
        loadCashierHistory(ApiPersistence.getInstance().getUser().idUser, sessionId)
    }

    function reloadBalance() {
        props.reloadBalance();
    }

    function loadCashierHistory(userId, sessionId) {

        try {
            let data = null;
            let theDate = moment().add(-30, 'days').format("YYYY-MM-DD");

            let url = 'https://api.paymentiq.io/user/transaction/' + process.env.REACT_APP_BAMBORA_MID + '/' + userId + '?sessionId=' + sessionId + '&minDate=' + theDate + '+00:00:00';

            const request = new XMLHttpRequest();
            request.open("GET", url, false); // `false` makes the request synchronous
            request.send(null);

            if (request.status === 200) {
                data = JSON.parse(request.responseText);
                props.setTxHistory({
                    status: 'OK',
                    transactions: data.transactions
                })
            } else {
                console.log("Impossible to load Transaction History");
            }
        } catch (e) {
            console.log("Impossible to load Transaction History");
        }
    }


    function loadPaymentIQ() {

        const CashierInstance = new _PaymentIQCashier('#cashier',
            {
                merchantId: process.env.REACT_APP_BAMBORA_MID,
                userId: ApiPersistence.getInstance().getUser().idUser,
                sessionId: sessionId,
                "environment": process.env.REACT_APP_ENVIROMENT,
                "containerHeight": props.isMobile === true ? "1100px" : "850px",
                "containerWidth": "100%",
                "layout": "horizontal",
                "accountDelete": false,
                "allowCancelPendingWithdrawal": true,
                "alwaysShowSubmitBtn": true,
                "font": "Montserrat,sans-serif",
                "country": "",
                "showHeader": false,
                "history": false,
                "showAccounts": false,
                "locale": props.locale,
                "pmListLimit": "5",
                "predefinedAmounts": "50,100,250,500",
                "showAmountLimits": true,
                "receiptBackBtn": true,
                "tabs": true,
                "dropdownTabs": false,
                "theme": {
                    "input": {
                        "borderRadius": "15px"
                    },
                    "loader": {
                        "color": "#C28E42"
                    },
                    "buttons": {
                        "color": "#fbba20"
                    },
                    "headerbackground": {
                        "color": "#fbba20"
                    },
                    "border": {
                        "radius": "15px"
                    },
                    "cashierbackground": {
                        "color": getCashierColorPerTheme('614D86')
                    }
                }
            },
            (api) => {
                api.on({
                    //cashierInitLoad: () => loadFinish(sessionId),
                    update: data => console.log('The passed in data was set', data),
                    success: data => {
                        console.log('Transaction was completed successfully', data)
                        reloadBalance();
                    },
                    failure: data => console.log('Transaction failed', data),
                    isLoading: data => console.log('Data is loading', data),
                    doneLoading: data => {
                        console.log('Data has been successfully downloaded', data)
                        loadFinish(sessionId)
                        //this.setState({ loading: false });
                    },
                    newProviderWindow: data => console.log('A new window / iframe has opened', data),
                    paymentMethodSelect: data => console.log('Payment method was selected', data),
                    paymentMethodPageEntered: data => console.log('New payment method page was opened', data),
                    navigate: data => console.log('Path navigation triggered', data)
                })
                api.css(getCashierCSS(props.isMobile == true, ApiPersistence.getInstance().getLanguage()))
            }
        )

    }


    //
    return (
        <div id='cashier'></div>
    );
}

export default PaymentIQ;


/*


<div className={style.crypto_grid5050}>
                <div>
                    <div className={style.crypto_title}>
                        Private Depositing Address
                    </div>
                    <div className={style.crypto_subTitle}>
                        This is your private depositing address. Any transactions you make to this address will be shown in your balance after one confirmation. Kindly note the minimum deposit limit stated, as a deposit below this limit cannot be processed.
                    </div>
                    <div className={style.crypto_editLabel}>
                        Copy the following address or follow the link to open your wallet.
                    </div>
                    <div className={style.crypto_readonly_edit_box}>
                        <div className={style.crypto_editTxt}>dsfdsfsdfsd</div>
                        <div className={style.crypto_copy}></div>
                    </div>
                    <div className={style.crypto_editLabel}>
                        Tag
                    </div>
                    <div className={style.crypto_readonly_edit_box}>
                        <div className={style.crypto_editTxt}>dsfdsfsdfsd</div>
                        <div className={style.crypto_copy}></div>
                    </div>
                </div>
                <div className={style.crypto_centerize}>
                    <div className={style.crypto_qrcode}>
                        test
                    </div>
                </div>
            </div>

            */
