import moment from "moment";

export function patternToMoment(theDate, pattern) {
    return moment(theDate, pattern)
}

export function momentToFullText(theMoment) {
    if (moment.locale() === 'fr' || moment.locale() === 'pt') {
        return theMoment.format('Do MMMM YYYY');
    } else {

        return theMoment.format('MMMM Do YYYY');
    }

}

export function patternToFullText(theDate, pattern) {
    return momentToFullText(patternToMoment(theDate, pattern));
}

export function dateToYYYYMMDDHHmmss(theDate) {
    return moment(theDate).format('YYYY-MM-DD HH:mm:ss');
}