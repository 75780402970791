import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import Tab from './Tab';
import DivClick from '../DivClick';
import ValidationCheck from '../ValidationCheck';


function TabGroup(props) {
  const [tabs, setTabs] = useState([])
  const [displayedTab, setDisplayedTab] = useState(-1)

  useEffect(() => {
    getTabsFromChildren()
  }, // eslint-disable-next-line
    [])

  function getTabsFromChildren() {

    var theTabs = [];
    for (let i = 0; i < props.children.length; i++) {

      if (props.children[i].type === Tab) {
        theTabs.push(props.children[i]);
        if (props.children[i].props.internalId === props.value) {
          setDisplayedTab(i);
        }
      }
    }

    setTabs(theTabs);
  }


  function changeTab(index, internalIdText) {
    props.onChangeTab(internalIdText);
  }


  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.tabsDiv}>
          {tabs.map((item, index) => (
            <div key={'TAB_' + index} className={(index === displayedTab) ? style.tabDivSel : style.tabDiv}>
              <div className={style.tabsDivCheck}>
                <div className={style.textVal}>
                  <DivClick onClick={() => changeTab(index, item.props.internalId)}>{item.props.label}</DivClick>
                </div>
                <div className={style.checkVal}><ValidationCheck opaque={(index === displayedTab) ? false : true} check={props.tabsValidation[index]} display={(props.tabsValidation[index] !== undefined) ? true : false} /></div>
              </div>
            </div>
          ))}
        </div>
        {(displayedTab > -1) &&
          <>
            {tabs.map((item, index) => (
              <React.Fragment key={'TAB_GROUP_' + index}>
                {(displayedTab === index) &&
                  <>
                    {tabs[index]}
                  </>
                }
                {(displayedTab !== index) &&
                  <div className={style.hideIt}>
                    {tabs[index]}
                  </div>
                }
              </React.Fragment>
            ))}
          </>
        }
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default TabGroup

