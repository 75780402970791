
import moment from 'moment';
import { txToText } from './uiUtil';

export function convertWalletTypeToText(value, intl) {
    if (value === 0) {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.type.realmoney', defaultMessage: 'Real Money' });
    }

    if (value === 1) {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.type.wager', defaultMessage: 'Wager' });
    }

    if (value === 2) {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.type.streamer.wager', defaultMessage: 'Streamer Wager' });
    }

    else {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.type.unknow', defaultMessage: 'Unknown' });
    }
}

//NOT NULL DEFAULT 1,    #0 INACTIVE(NOT SEEN BY USER), 1-ACTIVE, 2-NOT PLAYABLE(STREAMER WIN WAGER - VAULT), 20-future3xBonus(WAITING FOR 1st DEPOSIT to be activate)
export function convertWalletStateToText(value, intl) {
    if (value === 0) {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.state.inactive', defaultMessage: 'Inactive' });
    }

    if (value === 1) {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.state.active', defaultMessage: 'Active' });
    }

    if (value === 2) {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.state.unplayable', defaultMessage: 'Not Playable' });
    }

    if (value === 20) {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.state.3xpending', defaultMessage: 'Pending 3x Bonus' });
    }

    else {
        return intl.formatMessage({ id: 'newcasino.admin.user.wallet.state.unknow', defaultMessage: 'Unknow' });
    }
}

export function convertToMoney(value, coin) {
    try {
        if (value === null || value === undefined) {
            return '';
        }

        value = Number(value);

        let sign = getCurrencySign(coin, 'sign');
        return sign + ' ' + value.toFixed(2);
    } catch (err) { }

    return '';
}

export function toTwoDecimal(value) {
    let toReturn = '';

    try {
        if (!(value === null || value === undefined)) {
            toReturn = Number(value).toFixed(2);
        }
    } catch (err) { }

    return toReturn;
}

export function convertTransactionTypeToText(value, intl) {
    return txToText(value, intl);
}
export function convertTDate(value, coin) {
    try {
        const date = new Date(value); // {object Date}
        const dateFormatted = moment(date).format('yyyy/MM/DD HH:mm:ss');
        return dateFormatted;
    } catch (err) {
        return '';
    }

}

export function convertTDateOnlyDate(value, coin) {
    try {
        const date = new Date(value); // {object Date}
        const dateFormatted = moment(date).format('yyyy/MM/DD');
        return dateFormatted;
    } catch (err) {
        return '';
    }

}

export function getCurrencySign(selected, type) {
    //type: signo, texto
    try {

        let coins = [
            { sign: '€', text: 'EUR' },
            { sign: '$', text: 'USD' },
            { sign: 'R$', text: 'BRL' },
            { sign: 'A$', text: 'AUD' },
            { sign: '$', text: 'CAD' },
        ]

        let coinselected = coins.filter(item => {
            return item.text === selected
        })

        if (type === 'sign') return coinselected[0].sign
        else return coinselected[0].text
    } catch (error) {
        console.log('error signo');
    }
    return '';
}
