/* eslint-disable */
import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';

function PrivacyPolicy(props) {

    return (
        <div className={style.master_page_container}>
            {props.header !== false &&
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.tochange"
                        defaultMessage="Privacy Policy"
                    />
                </div>
            }
            <div className={style.mainDiv}>
                <div className={style.header2}>Privacy Policy</div>
                <div className={style.parag}>
                    Please note that this Privacy Policy will be agreed between
                    you and GAC Group Limited (‘We’, ‘Us’ or ‘Our’, as
                    appropriate).
                </div>
                <div className={style.parag}>
                    At the GAC Group we are dedicated to safeguarding and
                    preserving your privacy when visiting our website
                    www.playnumbersgame.com utilising our online gaming and draw
                    offerings, products or communicating electronically with us
                    (collectively “Services”).
                </div>

                <div className={style.parag}>
                    This Privacy Policy, together with our End User Licence
                    Agreement provides an explanation as to what happens to any
                    personal data that you provide to us, or that we collect from
                    you.
                </div>

                <div className={style.header2}>Information we collect</div>

                <div className={style.parag}>
                    In operating our website and providing services we may collect
                    and process the following data about you:
                </div>

                <ul>
                    <li>
                        I. Details of your visits to our website and the resources
                        that you access, including, but not limited to, traffic
                        data, location data, weblogs and other communication data.
                    </li>
                    <li>
                        II. Information that you provide by filling in forms on our
                        website, such as when you registered to access or receive
                        our services.
                    </li>
                    <li>
                        III. Information provided to us when you communicate with us
                        for any reason.
                    </li>
                    <li>IV. Request technical support.</li>
                </ul>

                <div className={style.parag}>
                    The information we collect generally is restricted to your
                    full name, home address, email and payment details. We
                    endeavour to only capture information that is necessary to
                    provide our services in a practical and legally compliant way.
                </div>

                <div className={style.header2}>Use of cookies</div>

                <div className={style.parag}>
                    On occasion, we may gather information about your computer to
                    facilitate usage of our services and to provide statistical
                    information regarding the use of our website.
                </div>

                <div className={style.parag}>
                    Such information will not identify you personally, it is
                    statistical data about our visitors and their use of our
                    website. This statistical data does not identify any personal
                    details whatsoever.
                </div>

                <div className={style.parag}>
                    Similarly to the above, we may gather information about your
                    general internet use by using a cookie file. Where used, these
                    cookies are downloaded to your computer automatically. This
                    cookie file is stored on the hard drive of your computer as a
                    cookie and contains information that is transferred to your
                    computer’s hard drive. They help us to improve our website and
                    the services that we provide you.
                </div>

                <div className={style.parag}>
                    All computers have the ability to decline cookies. This can be
                    done by activating the settings on your browser which enables
                    you to decline cookies. Please note that should you choose to
                    decline cookies, you may be unable to access particular parts
                    of our website.
                </div>

                <div className={style.header2}>Use of your information</div>

                <div className={style.parag}>
                    The information that we collect and store relating to you is
                    primarily used to enable us to provide services to you. In
                    addition, we may use the information for the following
                    purposes:
                </div>

                <ul>
                    <li>
                        I. To provide you with information requested from us,
                        relating to our services and to provide information on other
                        services which we feel may be of interest to you where you
                        have consented to receive such information.
                    </li>
                    <li>II. To meet our contractual commitments to you.</li>
                    <li>
                        III. To notify you about any changes to our website, such as
                        improvements to our services.
                    </li>
                </ul>

                <div className={style.parag}>
                    If you are a new client, we will only contact you when you
                    have provided consent and only by those means you provided
                    consent for.
                </div>

                <div className={style.parag}>
                    On occasions, GAC may retain the services of a third party
                    contractor for the purposes of obtaining external expertise.
                    In such circumstances we may permit (if deemed absolutely
                    necessary) access to certain of our client’s personal
                    information, but only once they have agreed to treat such
                    client information with the strictest levels of security and
                    confidentiality.
                </div>

                <div className={style.header2}>Storing your personal data</div>

                <div className={style.parag}>
                    Data that is provided to us is stored on our secure servers.
                    Details relating to any transactions entered into on our
                    website will be encrypted to ensure its safety.
                </div>

                <div className={style.parag}>
                    The transmission of information via the internet is not
                    completely secure and therefore we cannot guarantee the
                    security of data sent to us electronically and transmission of
                    such data is therefore entirely at your own risk. Where we
                    have given you (or where you have chosen) a password so that
                    you can access certain parts of our website or usage of our
                    services, you are responsible for keeping this password
                    confidential.
                </div>

                <div className={style.parag}>
                    We take all appropriate security measures in line with market
                    driven best practice to protect your personal information.
                </div>

                <div className={style.header2}>Third party links</div>

                <div className={style.parag}>
                    You may find links to third party websites on our website.
                    These websites should have their own privacy policies which
                    you should check. We do not accept any responsibility or
                    liability for their policies whatsoever as we have no control
                    over them.
                </div>

                <div className={style.header2}>OPT-OUT</div>
                <div className={style.parag}>
                    You may ‘opt out’ of receiving any promotional communications
                    either by choosing to opt out via your account settings
                    available on our sites or the services or in an email you
                    receive from us, or at any time by sending an email, or by
                    writing to us at Customer Service.
                </div>

                <div className={style.header2}>Contacting us</div>

                <div className={style.parag}>
                    We welcome any queries, comments or requests you may have
                    regarding this Privacy Policy. Please do not hesitate to
                    contact us at info@playnumbersgame.com.
                </div>
            </div>

        </div >
    );
}

export default PrivacyPolicy;
