import React, { useState } from 'react';
import style from './style.module.scss'
import Checkbox from 'newui/Generic/Inputs/Checkbox';

function ReceiverLine(props) {

    const [accept, setAccept] = useState(null);
    const [refresh, setRefresh] = useState(Math.random());

    function actionCheck1(data) {
        let newData = null;
        if (data === false) {
            setAccept(null);
        } else {
            setAccept(data)
            newData = data;
            setRefresh(Math.random())
        }

        props.alert(props.index, newData);
    }

    function actionCheck2(data) {
        let newData = null;
        if (data === false) {
            setAccept(null);
        } else {
            setAccept(!data)
            newData = !data;
            setRefresh(Math.random())
        }

        props.alert(props.index, newData);
    }

    return (
        <div className={style.gridReceiver}>
            <div></div>
            <div>
                <Checkbox key={'1-' + refresh} checked={accept === true} checkStyle={style.checkboxGreen} onClick={actionCheck1} />
            </div>
            <div>
                <Checkbox key={'2-' + refresh} checked={accept === false} checkStyle={style.checkboxRed} onClick={actionCheck2} />
            </div>
            <div className={style.receiver}>
                {props.amount}
            </div>
            <div className={style.receiverHelp} title={props.hint}>
                {props.name} ({props.email})
            </div>
        </div>
    );
}

export default ReceiverLine;
