import React, { useState } from 'react'
import style from './style.module.scss'
import ImageLoader from '../ImageLoader';


function ImagesGroup(props) {
  const [images] = useState(props.images ? props.images : []);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(((images.length - 1) > startIndex + 5) ? (startIndex + 5) : (images.length - 1));
  const [theKey] = useState('IMG_GROUP_' + Math.random)

  var loaded = 0;

  function onImageClick(imgIndex) {
    if (props.onImageClick && props.onImageClickParam === undefined) {
      props.onImageClick();
    } else if (props.onImageClick && props.onImageClickParam !== undefined) {
      if (props.onImageClickParam === '*') {
        props.onImageClick(images[imgIndex]);
      } else {
        props.onImageClick(images[imgIndex][props.onImageClickParam]);
      }
    }
  }

  function imgLoaded(index) {
    loaded++;

    if (loaded === 5) {
      const newStart = startIndex + 5;
      setStartIndex(newStart);
      setEndIndex(((images.length - 1) > newStart + 5) ? (newStart + 5) : (images.length - 1));
      loaded = 0;
    }
  }

  let rows = [];
  let rowsImages = [];
  for (let i = startIndex; i <= endIndex; i++) {
    rows.push(<div key={i}><img width='25px' className={style.invisible} key={theKey + '_' + Math.random()} src={images[i].image} alt='' onLoad={() => imgLoaded(i)} onError={() => imgLoaded(i)} /></div>);
  }

  for (let j = 0; j <= endIndex; j++) {
    rowsImages.push(<li key={j}><div className={`${props.divStyle}`}><div><ImageLoader onClick={() => onImageClick(j)} key={j} width={props.imgSize ? props.imgSize : '100%'} image={images[j].image} /></div></div></li>);
  }

  return (
    <>
      {rows}
      {rowsImages}
    </>
  );
}

export default ImagesGroup

