import React, { useState } from 'react'
import style from './style.module.scss'
import TextField from 'newui/Generic/Inputs/TextField';
import { inNotMinusOne, isTextMin2Char } from 'newui/Utils/uiUtil';
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import { useIntl } from 'react-intl';
import { countryPhoneCodes } from 'newui/constants';

export const DATA_USERADDRESS_ADDRESS = 'address';
export const DATA_USERADDRESS_COUNTRY = 'country';
export const DATA_USERADDRESS_STATE = 'state';
export const DATA_USERADDRESS_CURRENCY = 'currency';
export const DATA_USERADDRESS_CITY = 'city';
export const DATA_USERADDRESS_ZIPCODE = 'zipcode';
export const DATA_USERADDRESS_PHONE = 'phone';
export const DATA_USERADDRESS_PHONECODE = 'phonecode';

function UserAddress(props) {
  const intl = useIntl();
  const sourceData = props.value;

  const sourceCity = sourceData.fields.get(DATA_USERADDRESS_CITY) ? sourceData.fields.get(DATA_USERADDRESS_CITY) : {};
  const sourceAddress = sourceData.fields.get(DATA_USERADDRESS_ADDRESS) ? sourceData.fields.get(DATA_USERADDRESS_ADDRESS) : {};
  const sourceCountry = sourceData.fields.get(DATA_USERADDRESS_COUNTRY) ? sourceData.fields.get(DATA_USERADDRESS_COUNTRY) : {};
  const sourceState = sourceData.fields.get(DATA_USERADDRESS_STATE) ? sourceData.fields.get(DATA_USERADDRESS_STATE) : {};
  const sourceCurrency = sourceData.fields.get(DATA_USERADDRESS_CURRENCY) ? sourceData.fields.get(DATA_USERADDRESS_CURRENCY) : {};

  const sourceZipcode = sourceData.fields.get(DATA_USERADDRESS_ZIPCODE) ? sourceData.fields.get(DATA_USERADDRESS_ZIPCODE) : {};
  const sourcePhone = sourceData.fields.get(DATA_USERADDRESS_PHONE) ? sourceData.fields.get(DATA_USERADDRESS_PHONE) : {};
  const sourcePhoneCode = sourceData.fields.get(DATA_USERADDRESS_PHONECODE) ? sourceData.fields.get(DATA_USERADDRESS_PHONECODE) : {};

  const [address, setAddress] = useState(sourceAddress.value ? sourceAddress.value : '');
  const [addressValid, setAddressValid] = useState((sourceAddress.valid !== undefined) ? sourceAddress.valid : null);

  const [country, setCountry] = useState(sourceCountry.value ? sourceCountry.value : '');
  const [countryValid, setCountryValid] = useState((sourceCountry.valid !== undefined) ? sourceCountry.valid : null);

  const [state, setState] = useState(sourceState.value ? sourceState.value : '');
  const [stateValid, setStateValid] = useState((sourceState.valid !== undefined) ? sourceState.valid : null);

  const [currency, setCurrency] = useState(sourceCurrency.value ? sourceCurrency.value : '');
  const [currencyValid, setCurrencyValid] = useState((sourceCurrency.valid !== undefined) ? sourceCurrency.valid : null);

  const [city, setCity] = useState(sourceCity.value ? sourceCity.value : '');
  const [cityValid, setCityValid] = useState((sourceCity.valid !== undefined) ? sourceCity.valid : null);

  const [zipcode, setZipcode] = useState(sourceZipcode.value ? sourceZipcode.value : '');
  const [zipcodeValid, setZipcodeValid] = useState((sourceZipcode.valid !== undefined) ? sourceZipcode.valid : null);

  const [phone, setPhone] = useState(sourcePhone.value ? sourcePhone.value : '');
  const [phoneValid, setPhoneValid] = useState((sourcePhone.valid !== undefined) ? sourcePhone.valid : null);

  const [phoneCode, setPhoneCode] = useState(sourcePhoneCode.value ? sourcePhoneCode.value : '');
  const [phoneCodeValid, setPhoneCodeValid] = useState((sourcePhoneCode.valid !== undefined) ? sourcePhoneCode.valid : null);



  function addressValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: value, valid: isValid });
    data.set(DATA_USERADDRESS_CITY, { value: city, valid: cityValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: zipcode, valid: zipcodeValid });
    data.set(DATA_USERADDRESS_PHONE, { value: phone, valid: phoneValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: country, valid: countryValid });
    data.set(DATA_USERADDRESS_CURRENCY, { value: currency, valid: currencyValid });
    data.set(DATA_USERADDRESS_PHONECODE, { value: phoneCode, valid: phoneCodeValid });
    data.set(DATA_USERADDRESS_STATE, { value: state, valid: stateValid });
    setAddressValid(isValid);
    setAddress(value)
    props.validatorInformer((stateValid && phoneCodeValid && currencyValid && countryValid && phoneValid && isValid && cityValid && zipcodeValid), data);
  }

  function cityValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: address, valid: addressValid });
    data.set(DATA_USERADDRESS_CITY, { value: value, valid: isValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: zipcode, valid: zipcodeValid });
    data.set(DATA_USERADDRESS_PHONE, { value: phone, valid: phoneValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: country, valid: countryValid });
    data.set(DATA_USERADDRESS_CURRENCY, { value: currency, valid: currencyValid });
    data.set(DATA_USERADDRESS_PHONECODE, { value: phoneCode, valid: phoneCodeValid });
    data.set(DATA_USERADDRESS_STATE, { value: state, valid: stateValid });
    setCityValid(isValid);
    setCity(value);

    props.validatorInformer((stateValid && phoneCodeValid && currencyValid && countryValid && phoneValid && addressValid && isValid && zipcodeValid), data);
  }

  function zipcodeValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: address, valid: addressValid });
    data.set(DATA_USERADDRESS_CITY, { value: city, valid: cityValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: value, valid: isValid });
    data.set(DATA_USERADDRESS_PHONE, { value: phone, valid: phoneValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: country, valid: countryValid });
    data.set(DATA_USERADDRESS_CURRENCY, { value: currency, valid: currencyValid });
    data.set(DATA_USERADDRESS_PHONECODE, { value: phoneCode, valid: phoneCodeValid });
    data.set(DATA_USERADDRESS_STATE, { value: state, valid: stateValid });
    setZipcodeValid(isValid);
    setZipcode(value);

    props.validatorInformer((stateValid && phoneCodeValid && currencyValid && countryValid && phoneValid && addressValid && cityValid && isValid), data);
  }

  function phoneValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: address, valid: addressValid });
    data.set(DATA_USERADDRESS_CITY, { value: city, valid: cityValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: zipcode, valid: zipcodeValid });
    data.set(DATA_USERADDRESS_PHONE, { value: value, valid: isValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: country, valid: countryValid });
    data.set(DATA_USERADDRESS_CURRENCY, { value: currency, valid: currencyValid });
    data.set(DATA_USERADDRESS_PHONECODE, { value: phoneCode, valid: phoneCodeValid });
    data.set(DATA_USERADDRESS_STATE, { value: state, valid: stateValid });
    setPhoneValid(isValid);
    setPhone(value);

    props.validatorInformer((stateValid && phoneCodeValid && currencyValid && countryValid && isValid && addressValid && cityValid && zipcodeValid), data);
  }

  function countryValidCallBack(isValid, value) {
    var validity = (value !== '-1')
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: address, valid: addressValid });
    data.set(DATA_USERADDRESS_CITY, { value: city, valid: cityValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: zipcode, valid: zipcodeValid });
    data.set(DATA_USERADDRESS_PHONE, { value: phone, valid: phoneValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: value, valid: validity });
    data.set(DATA_USERADDRESS_CURRENCY, { value: currency, valid: currencyValid });
    data.set(DATA_USERADDRESS_PHONECODE, { value: phoneCode, valid: phoneCodeValid });
    data.set(DATA_USERADDRESS_STATE, { value: state, valid: stateValid });
    setCountryValid(validity);
    setCountry(value);

    props.validatorInformer((stateValid && phoneCodeValid && currencyValid && validity && phoneValid && addressValid && cityValid && zipcodeValid), data);
  }

  function stateValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: address, valid: addressValid });
    data.set(DATA_USERADDRESS_CITY, { value: city, valid: cityValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: zipcode, valid: zipcodeValid });
    data.set(DATA_USERADDRESS_PHONE, { value: phone, valid: phoneValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: country, valid: countryValid });
    data.set(DATA_USERADDRESS_CURRENCY, { value: currency, valid: currencyValid });
    data.set(DATA_USERADDRESS_PHONECODE, { value: phoneCode, valid: phoneCodeValid });
    data.set(DATA_USERADDRESS_STATE, { value: value, valid: isValid });
    setStateValid(isValid);
    setState(value);

    props.validatorInformer((isValid && phoneCodeValid && currencyValid && countryValid && phoneValid && addressValid && cityValid && zipcodeValid), data);
  }

  function currencyValidCallBack(isValid, value) {
    var validity = (value !== '-1')
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: address, valid: addressValid });
    data.set(DATA_USERADDRESS_CITY, { value: city, valid: cityValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: zipcode, valid: zipcodeValid });
    data.set(DATA_USERADDRESS_PHONE, { value: phone, valid: phoneValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: country, valid: countryValid });
    data.set(DATA_USERADDRESS_CURRENCY, { value: value, valid: validity });
    data.set(DATA_USERADDRESS_PHONECODE, { value: phoneCode, valid: phoneCodeValid });
    data.set(DATA_USERADDRESS_STATE, { value: state, valid: stateValid });
    setCurrencyValid(validity);
    setCurrency(value);

    props.validatorInformer((stateValid && phoneCodeValid && validity && countryValid && phoneValid && addressValid && cityValid && zipcodeValid), data);
  }

  function phoneCodeValidCallBack(isValid, value) {
    var validity = (value !== '-1')
    var data = new Map();
    data.set(DATA_USERADDRESS_ADDRESS, { value: address, valid: addressValid });
    data.set(DATA_USERADDRESS_CITY, { value: city, valid: cityValid });
    data.set(DATA_USERADDRESS_ZIPCODE, { value: zipcode, valid: zipcodeValid });
    data.set(DATA_USERADDRESS_PHONE, { value: phone, valid: phoneValid });
    data.set(DATA_USERADDRESS_COUNTRY, { value: country, valid: countryValid });
    data.set(DATA_USERADDRESS_CURRENCY, { value: currency, valid: currencyValid });
    data.set(DATA_USERADDRESS_PHONECODE, { value: value, valid: validity });
    data.set(DATA_USERADDRESS_STATE, { value: state, valid: stateValid });
    setPhoneCodeValid(validity);
    setPhoneCode(value);

    props.validatorInformer((stateValid && validity && currencyValid && countryValid && phoneValid && addressValid && cityValid && zipcodeValid), data);
  }



  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            <div className={style.test2}>
              <TextField maxLength='100' autofocus='true' values={[address, addressValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.address.address.hint', defaultMessage: 'Type your address here' })} label={intl.formatMessage({ id: 'newcasino.auth.registration.address.address.label', defaultMessage: 'Address' })} validator={isTextMin2Char} validatorInformer={addressValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.address.address.error', defaultMessage: 'Your address is too short' })} />
            </div>
            <div className={style.col2}>
              <div className={style.test2}>
                <TextField maxLength='100' values={[city, cityValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.address.city.hint', defaultMessage: 'Type your city here' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.auth.registration.address.city.label', defaultMessage: 'City' })} validator={isTextMin2Char} showTitleError='true' showCheck='true' validatorInformer={cityValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.address.city.error', defaultMessage: 'Your city is too short' })} />
              </div>
              <div className={style.test2}>
                <TextField maxLength='100' uppercase={true} values={[zipcode, zipcodeValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.address.zip.hint', defaultMessage: 'Postal Code' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.auth.registration.address.zip.label', defaultMessage: 'Postal Code' })} validator={isTextMin2Char} showTitleError='false' showCheck='true' validatorInformer={zipcodeValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.address.zip.error', defaultMessage: 'Your Postal Code is too short' })} />
              </div>
            </div>
            <div className={style.col2}>
              <div className={style.test2}>
                <ComboBox autofocus='true' values={[country, countryValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.auth.registration.address.country.hint', defaultMessage: 'Select your country...' }) }} valueList={{ list: props.countryList, key: 'id', value: 'name' }} hint='Select your country' label={intl.formatMessage({ id: 'newcasino.auth.registration.address.country.label', defaultMessage: 'Country' })} showCheck='true' validator={inNotMinusOne} validatorInformer={countryValidCallBack} mandatory='true' />
              </div>
              <div className={style.test2}>
                <TextField maxLength='100' autofocus='true' values={[state, stateValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.address.state.hint', defaultMessage: 'Type your State here' })} label={intl.formatMessage({ id: 'newcasino.auth.registration.address.state.label', defaultMessage: 'State/Province' })} validator={isTextMin2Char} validatorInformer={stateValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.address.state.error', defaultMessage: 'Your state is too short' })} />
              </div>
            </div>
            <div className={style.test2}>
              <ComboBox autofocus='true' values={[currency, currencyValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.auth.registration.address.currency.hint', defaultMessage: 'Select your currency...' }) }} valueList={{ list: props.currencyList, key: 'cod', value: 'name' }} hint='' label={intl.formatMessage({ id: 'newcasino.auth.registration.address.currency.label', defaultMessage: 'Currency' })} showCheck='true' validator={inNotMinusOne} validatorInformer={currencyValidCallBack} mandatory='true' />
            </div>
            <div className={style.col2_50}>
              <div className={style.test2}>
                <ComboBox autofocus='true' values={[phoneCode, phoneCodeValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.auth.registration.address.phonecode.hint', defaultMessage: 'Select Phone Country Code...' }) }} valueList={{ list: countryPhoneCodes, key: 'code', value: 'label' }} hint='' label={intl.formatMessage({ id: 'newcasino.auth.registration.address.phonecode.label', defaultMessage: 'Mobile Phone Number - Country code' })} showCheck='true' validator={inNotMinusOne} validatorInformer={phoneCodeValidCallBack} mandatory='true' />
              </div>
              <div className={style.test2}>
                <TextField maxLength='100' autofocus='true' type='digit' values={[phone, phoneValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.address.phone.hint', defaultMessage: 'Type your phone number here' })} label={intl.formatMessage({ id: 'newcasino.auth.registration.address.phone.label', defaultMessage: 'Phone Number' })} validator={isTextMin2Char} validatorInformer={phoneValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.address.phone.error', defaultMessage: 'Too short' })} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default UserAddress