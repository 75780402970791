import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';

function TermsConditions(props) {
    return (
        <div className={style.master_page_container}>
            {props.header !== false &&
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.tochange"
                        defaultMessage="Terms And Conditions"
                    />
                </div>
            }
            <div className={style.mainDiv}>
                <div className={style.header2}>Account Terms</div>
                <div className={style.parag}>
                    Please read these Account Terms (the “Terms”) carefully as
                    they set out the various rules and procedures that apply when
                    You open an Account and use it to play on playnumbersgame.com
                    draw based game online(including on mobile devices) or for any
                    other reason.
                </div>
                <div className={style.parag}>
                    By accepting these Terms, You agree to be bound by the
                    relevant game rules.
                </div>
                <div className={style.header2}>1. Account Registration</div>
                <div className={style.parag}>1.1 You are only allowed to have one Account.</div>
                <div className={style.parag}>
                    1.2 You can only register for an Account and use that Account
                    if:
                </div>
                <ul>
                    <li>
                        (a) You are of legal gambling age in your jurisdiction;
                    </li>
                    <li>
                        (b) You are physically located in a jurisdiction where laws
                        allow you to at the time;
                    </li>
                    <li>
                        (c) The payment details have not been registered or supplied
                        in relation to another Account; and
                    </li>
                    <li>
                        (d) Where required, You are able to verify, in whatever way
                        GAC decides, that any information supplied to GAC is
                        genuine.
                    </li>
                </ul>
                <div className={style.parag}>
                    1.3 You will need to provide certain information when applying
                    to open an Account to help GAC (or our screening &/or payment
                    partners) establish whether or not You and/or Your payment
                    details meet the criteria set out in Section 1.2 and whether
                    You are eligible to use the website. This information will
                    include as a minimum: Your name, address, date of birth,
                    gender, email address. GAC reserves the right to carry out
                    checks to establish the above and to verify (by ourselves or
                    screening agency to carry out checks to verify) Your age and
                    identity and any other details provided, and to ask You for
                    further documentation to verify the same.
                </div>
                <div className={style.parag}>
                    1.4 GAC may also request information from You or carry out
                    checks after You have opened an Account, to ensure that You
                    and/or Your payment details (where supplied) still meet the
                    criteria set out in Section 1.2.
                </div>
                <div className={style.parag}>
                    1.5 GAC reserves the right to refuse an application to open an
                    Account for any reason.
                </div>
                <div className={style.parag}>
                    1.6 If GAC subsequently discovers that You were not or are no
                    longer eligible to open, hold and use an Account, GAC reserves
                    the right to take all appropriate steps in relation to You and
                    Your Account ( including, without limitation, suspending or
                    terminating Your Account, and Your ability to buy tickets, and
                    investigating any security issues).
                </div>
                <div className={style.parag}>
                    1.7 GAC will not be liable to pay a Prize or amount to anyone
                    that does not meet the criteria set out in Section 1.2 and may
                    (at our discretion) refuse to pay a Prize or amount to anyone
                    that has more than one Account. If a Prize or amount is paid
                    to anyone who does not meet those criteria, that person will
                    be required to repay the Prize or amount immediately if
                    requested by GAC.
                </div>
                <div className={style.header2}>2. Using Your Account</div>
                <div className={style.parag}>
                    2.1 You must only access Your Account when You are physically
                    located in a jurisdiction where laws allow you to do so.
                </div>
                <div className={style.parag}>
                    2.2 As long as You are physically located in a jurisdiction
                    where laws allow, You may purchase tickets as soon as Your
                    Account has been activated.
                </div>
                <div className={style.parag}>
                    2.3 You will only be able to play on the site with Your
                    Account once You have registered with our payment merchant.
                    You must be physically located in a jurisdiction where laws
                    allow when providing these details.
                </div>
                <div className={style.parag}>
                    2.4 You are responsible for making sure all of Your Account
                    information is accurate and up to date. Any updates and
                    changes must be made whilst You are physically located in a
                    jurisdiction where laws allow. The updates can only be done
                    through Your Account.
                </div>
                <div className={style.parag}>
                    2.5 You must keep Your password secure and secret at all times
                    and take steps to prevent it being used without Your
                    permission. You should, for example:
                </div>

                <ul>
                    <li>
                        (a) memorise Your password and never tell it to anyone;
                    </li>
                    <li>
                        (b) never write Your password down (including on Your
                        computer or other electronic device) or record it in a way
                        that can be understood by someone else;
                    </li>
                    <li>
                        (c) destroy any communications from GAC in relation to Your
                        password as soon as You have read them;
                    </li>
                    <li>(d) avoid using a password that is easy to guess; and</li>
                    <li>
                        (e) contact the Customer Support Team immediately if anyone
                        has used Your Account or given any instruction in relation
                        to it without Your permission, or if You think someone else
                        knows Your password.
                    </li>
                </ul>
                <div className={style.parag}>
                    2.6 If You forget Your password, You can reset it by following
                    the instructions on the Site (as long as You can provide the
                    relevant security information).
                </div>

                <div className={style.header2}>3. Play Limits</div>
                <div className={style.parag}>
                    3.1 GAC may, at its discretion, impose limits on the number of
                    times You can play on the site during a defined time period
                    prior to a draw. Any limits imposed under this Section 3.1
                    will be notified to You in an appropriate manner by GAC.
                </div>
                <div className={style.parag}>
                    3.2 Subject to any limits imposed by GAC under Section 3.1,
                    You are also able to permanently exclude Yourself, or
                    temporarily exclude Yourself for a fixed period of time from
                    Your Account.
                </div>
                <div className={style.parag}>
                    3.3 If You think Your limits haven't been applied correctly,
                    contact the Customer Support Team.
                </div>

                <div className={style.header2}>4. Game History and Transaction History</div>
                <div className={style.parag}>
                    You can view up to 180 days’ details relating to Your play
                    history and transaction history, through Your Account on the
                    Site. If You want to view records of Your Game History and/or
                    Transaction History that go further back than this, You should
                    contact the Customer Support Team. GAC will then provide this
                    information to You as soon as is reasonably practicable.
                </div>

                <div className={style.header2}>5. Closing an Account and Termination etc.</div>
                <div className={style.parag}>
                    5.1 You can close Your Account at any time by calling the
                    Customer Support Team.
                </div>
                <div className={style.parag}>
                    5.2 Accounts are limited to one per person. If GAC discovers
                    that You have more than one Account, or believes a third party
                    Account is being used by You or on Your behalf, GAC reserves
                    the right to terminate or suspend some or all of those
                    Accounts (at its discretion) and take whatever action it
                    reasonably considers appropriate.
                </div>
                <div className={style.parag}>
                    5.3 GAC may terminate or suspend the provision of the draws,
                    access to Your Account and/or to the Site, and refuse any and
                    all current or future partaking in the draw by You,
                    immediately and without giving You prior notice:
                </div>

                <ul>
                    <li>
                        (a) if any information provided by You is provided
                        fraudulently or is inaccurate, out-of-date or incomplete;
                    </li>
                    <li>
                        (b) on Your breach of these Terms, the relevant draw Rules
                        and/or the relevant draw Procedures;
                    </li>
                    <li>
                        (c) for repair or maintenance work or in order to upgrade or
                        update the Site and/or GAC’s Computer System;
                    </li>
                    <li>
                        (d) if GAC stops offering the draw, the provision of
                        Accounts, or access to the Site or any part of it to its
                        customers for any reason; or
                    </li>
                    <li>(e) for any other reason at GAC’s sole discretion.</li>
                </ul>

                <div className={style.parag}>5.4</div>
                <ul>
                    <li>
                        (a) Once Your Account has been closed or terminated (or if
                        You permanently exclude Yourself from playing on the site in
                        line with Section 3.2), GAC will return any funds remaining
                        in Your Account to the payment details registered to Your
                        Account.
                    </li>
                    <li>
                        (b) If GAC is not able to return those funds to You, it will
                        keep those funds for six years from the date the Account was
                        closed or terminated (or You permanently excluded Yourself).
                        After this time, the funds will be allocated to the GAC
                        Charitable Trust fund/GAC Group Ltd. The former Player can
                        contact GAC at any time during that 6 year period to claim
                        the funds and, as long as they are able to provide any
                        information GAC reasonably requires, GAC will then refund
                        the funds. No interest will be payable in respect of monies
                        refunded.
                    </li>
                </ul>

                <div className={style.header2}>6. Unauthorised Use of your payment details</div>
                <div className={style.parag}>
                    6.1 If You notify GAC or our payment merchant that Your
                    payment details have been used without Your permission in
                    relation to Your Account, and GAC are asked to return the
                    relevant amount to You ("Chargeback"), GAC may suspend Your
                    Account and ask You to contact the Customer support Team. GAC
                    reserves the right to debit Your Account with the amount of
                    the Chargeback. If that debit results in a negative balance in
                    Your Account, GAC reserves the right to recover that balance
                    from You and You will not be able to buy any more tickets
                    unless and until the balance has been recovered.
                </div>

                <div className={style.header2}>7. Dormant Accounts</div>
                <div className={style.parag}>
                    7.1 If You don’t log into or otherwise access or use Your
                    Account for a continuous period of 6 months, Your Account will
                    become a “Dormant Account”.
                </div>
                <div className={style.parag}>
                    7.2 If there are funds in Your Dormant Account, GAC will use
                    all reasonable efforts to contact You to establish Your wishes
                    in relation to those funds. If GAC are unable to contact You,
                    it may at its discretion, return the funds to the payment
                    details registered to Your Account. If GAC is unable to return
                    the funds to those details, it will keep the funds for six
                    years from the date the Account became a Dormant Account.
                    After this time, the funds will be allocated to the GAC
                    Charitable Trust fund/GAC GroupLtd. The former Player can
                    contact GAC at any time during that 6 year period to claim the
                    funds and, as long as they are able to provide any information
                    GAC reasonably requires, GAC will then refund the funds. No
                    interest will be payable in respect of monies refunded.
                </div>

                <div className={style.header2}>8. Site Availability</div>
                <div className={style.parag}>
                    If You aren’t able to access the Site for any reason for more
                    than 7 consecutive days (other than because of Your equipment
                    or technology, or internet service provider or mobile device
                    network operator, failing or malfunctioning, or because You
                    are not eligible or entitled to do so), GAC will (except with
                    the prior consent of the Gambling Supervision Commission)
                    immediately after that 7 day period return any funds in Your
                    Account to the payment details registered to Your Account.
                </div>

                <div className={style.header2}>9. Use of the Site</div>
                <div className={style.parag}>9.1 You agree that:</div>
                <ul>
                    <li>
                        (a) the Site is for Your own personal, non-commercial use;
                        and
                    </li>
                    <li>
                        (b) You are only allowed to use Your Account and the Site,
                        and partake in the draw via Your Account, as set out in
                        these Terms, the applicable draw Rules and draw Procedures
                        on the draw Details Screen.
                    </li>
                </ul>

                <div className={style.parag}>
                    9.2 You also agree that You will only use Your Account and use
                    the Site in an appropriate and lawful manner. You will not,
                    for example:
                </div>
                <ul>
                    <li>
                        (a) receive, access or transmit any content which is
                        obscene, pornographic, threatening, racist, menacing,
                        offensive, defamatory, in breach of confidence, in breach of
                        any intellectual property rights (including, without limit,
                        copyright) or otherwise objectionable or unlawful; or
                    </li>
                    <li>
                        (b) knowingly or recklessly transmit any content (including,
                        without limit, viruses) through the Site and/or GAC’s
                        Computer System which will cause, or be likely to cause,
                        detriment or harm, in any degree, to computer systems owned
                        by GAC or others; or
                    </li>
                    <li>
                        (c) hack into, make excessive traffic demands on or cause
                        any impairment of the functions of any computer system,
                        deliver or forward chain letters, "junk mail" of any kind,
                        surveys, contests, pyramid schemes or otherwise engage in
                        any behaviour intended to prevent others from partaking in
                        the draw or using the Site or any other website, or which is
                        otherwise likely to damage the reputation or business of GAC
                        or of any third party; or
                    </li>
                    <li>(d) authorise or allow anyone to do the above.</li>
                </ul>

                <div className={style.parag}>
                    9.3 You agree to indemnify GAC against any costs, losses,
                    damages and expenses which GAC may suffer or incur arising out
                    of or in relation to any claim, legal proceeding or demand
                    made by any third party due to or arising out of Your wrongful
                    or negligent use of Your Account, access to the Site and/or
                    GAC’s Computer System, or breach by You of these Terms.
                </div>
                <div className={style.parag}>
                    9.4 There is no guarantee that the Site will display correctly
                    on all devices it can be viewed on.
                </div>
                <div className={style.parag}>
                    9.5 GAC is the owner, licensee or has been granted legal use
                    of all the copyright, trademarks and other intellectual
                    property rights in, to and in respect of the draw and the
                    Site, and You will not acquire any rights in any of these.
                </div>
                <div className={style.parag}>9.6 You must not:</div>
                <ul>
                    <li>
                        (a) copy, disclose, transmit or otherwise make available or
                        remove or change any material available on the Site;
                    </li>
                    <li>
                        (b) reverse engineer or decompile (whether in whole or in
                        part) any software used in connection with the Site and/or
                        the provision of the draw (except to the extent expressly
                        permitted by applicable law); or
                    </li>
                    <li>
                        (c) remove, obscure or change any copyright, trade mark or
                        other intellectual property rights notices in any material
                        obtained from the Site or as a result of partaking in the
                        draw.
                    </li>
                </ul>

                <div className={style.parag}>
                    9.7 You agree that partaking in the draw (including accessing
                    Your Account) whilst located in any jurisdiction other than a
                    jurisdiction where laws allow is strictly prohibited and that
                    GAC will not be liable to pay a Prize or any amount to You in
                    respect of any winnings whilst You were located in any
                    jurisdiction other than a jurisdiction where laws allow. GAC
                    has the right to terminate Your Account and refuse any and all
                    current or future Plays by You or access to the Site by You if
                    it suspects or knows that You have played or are intending to
                    partake in the draw whilst located in any jurisdiction other
                    than a jurisdiction where laws allow.
                </div>

                <div className={style.header2}>10. Your information</div>
                <div className={style.parag}>
                    10.1 If You win on the site, You understand and accept that
                    GAC may, if required by law, provide a third party with
                    details of any Prize claimed by You or on Your behalf of, or
                    paid to You.
                </div>
                <div className={style.parag}>
                    10.2 By passing information to GAC, You agree and consent to
                    GAC holding and processing it in line with this Section 10 and
                    the Privacy Policy. GAC is entitled to hold and process such
                    information in relation to a Prize claim or withdraw and to
                    fulfil its obligations under its licence to operate an online
                    gaming website under the Kahnawake Gaming licence. GAC is
                    required to keep Your information until that licence expires
                    (and to keep any financial or transactional information until
                    6 years have passed from expiry of the licence) but will only
                    disclose it to anyone (other than the Commission) if required
                    by law or with Your consent.
                </div>
                <div className={style.parag}>
                    10.3 GAC is also entitled to pass Your personal information to
                    the Commission in line with the Privacy Policy.
                </div>

                <div className={style.header2}>11. Limitation of Liability</div>

                <div className={style.parag}>
                    11.1 GAC’s only obligation is to pay the Prizes won on the
                    site to the rightful owners of winning tickets (the ticket
                    holder). GAC will not be liable under any circumstances for
                    any loss of whatever nature other than, subject to Section
                    11.2, the non-payment of a Prize or the non-payment of any
                    refund properly due under the draw Rules. In particular, but
                    without limitation, GAC will not be liable for any loss of
                    profits, indirect, special or consequential loss, suffered or
                    incurred by You that arises out of the withdrawal of any draw
                    or from the participation or non-participation of You or any
                    person in any draw. This includes the loss, for whatever
                    reason, of the chance to participate in such draw.
                </div>
                <div className={style.parag}>11.2 GAC will not be liable to any person for:</div>
                <ul>
                    <li>
                        (a) events beyond its reasonable control and expectations
                        (for example war, strike, lockout, industrial action, fire,
                        flood, drought, power cut and/or failure or obstruction of
                        any network, broadcasting or telecommunications service);
                    </li>
                    <li>
                        (b) the failure or destruction of, or damage to, all or part
                        of the computer systems or records of GAC or any third party
                        (including, without limitation, the Site and/or GAC’s
                        Computer System);
                    </li>
                    <li>
                        (c) any mistakes caused by all or part of the computer
                        systems or records of GAC or any third party (including,
                        without limitation, the Site and/or GAC’s Computer System);
                    </li>
                    <li>
                        (d) delays, losses, mistakes or omissions in or made by the
                        postal or other delivery service or by the banking system;
                    </li>
                    <li>
                        (e) any other action or event which prevents or hinders the
                        issue of a valid draw (including without limitation the
                        failure of the Site to display correctly on any device used
                        to view it);
                    </li>
                    <li>
                        (f) the refusal to sell a ticket or the refusal to accept a
                        bet to any person or to allow any person to partake in a
                        draw or bet;
                    </li>
                    <li>
                        (g) any losses caused by You, including the misuse or
                        unauthorised use of passwords, money lost by purchasing
                        tickets, and the failure to keep GAC informed of changes to
                        Your personal contact details;
                    </li>
                    <li>
                        (h) any losses caused by the failure or malfunction of Your
                        equipment or technology or Your internet service provider or
                        mobile phone network operator, or the equipment, technology,
                        internet service provider or mobile phone network operator
                        of any other person or third party.
                    </li>
                </ul>

                <div className={style.parag}>11.3 If:</div>
                <ul>
                    <li>
                        (a) there is a failure or malfunction of GAC’s Computer
                        System which impacts any limits on Your spend or play set by
                        GAC in line with Sections 3.1 or set by You in line with
                        Section 3.2, and has a material adverse effect on You; or
                    </li>
                    <li>
                        (b) any limits You set in line with Section 3.2 are not
                        placed on Your Account and the non-placement has a material
                        adverse effect on You, GAC will (at its sole discretion)
                        either:
                    </li>
                    <li>
                        (c) recover any Prizes paid (or withhold any Prizes won but
                        not yet paid) in respect of those tickets and refund to You
                        the money You spent on buying tickets or bet placed above
                        any limit that should have been set. For the avoidance of
                        doubt, You shall repay to GAC any such Prizes or bet amount
                        paid immediately on demand); or
                    </li>
                    <li>
                        (d) refund to You the money You spent on buying tickets
                        above any limit that should have been set, net of any Prizes
                        paid in respect of those tickets. No interest will be
                        payable in respect of any refunds made.
                    </li>
                </ul>

                <div className={style.parag}>
                    11.4 Nothing in these Terms excludes or limits (a) any
                    person’s liability (i) for fraud; or (ii) for death or
                    personal injury caused by breach of any duty that person may
                    have to take reasonable care or exercise reasonable skill; or
                    (b) any other liability which cannot lawfully be excluded or
                    limited.
                </div>
                <div className={style.parag}>
                    11.5 You acknowledge and agree that in accepting these Terms
                    You do not rely on, and shall have no remedy in respect of,
                    any statement, representation, warranty (in each case whether
                    negligently or innocently made) or understanding of any person
                    whether or not that person is a party to these Terms.
                </div>

                <div className={style.header2}>12. GAC’s Discretion is Binding</div>
                <div className={style.parag}>
                    The reasonable exercise by GAC of any discretion provided for
                    by these Terms will be final and binding.
                </div>

                <div className={style.header2}>13. General</div>
                <div className={style.parag}>
                    13.1 Any person who plays on the site using their Account, or
                    who claims a Prize in whatever capacity, agrees to be bound by
                    the provisions of any applicable legislation, these Terms, the
                    relevant Rules and Procedures (all as amended from time to
                    time) and any other statements or explanations or rules or
                    procedures GAC may issue from time to time in respect of that
                    draw.
                </div>
                <div className={style.parag}>
                    13.2 GAC may change these Terms (including the Privacy Policy)
                    and any Rules or Procedures, and any other rules and
                    procedures issued by GAC from time to time. The changes will
                    be effective from the date of their publication on the Site
                    (or any earlier time GAC states), or on notification to You
                    that the changes have taken place (whichever takes place
                    sooner) and will apply to tickets bought after the date on
                    which the changes become effective, and/or tickets bought
                    before that date if reasonable in the circumstances.
                    Notification will be by email, Account notification, post or
                    any other form of communication reasonably decided by GAC. You
                    agree that You will be bound by the changes when You next
                    purchase a ticket, deposit money on the site, place a bet,
                    access Your Account or claim a Prize after the changes have
                    become effective, or (where relevant) when You expressly
                    accept the changes, whichever takes place first.
                </div>
                <div className={style.parag}>
                    13.3 These Terms, the Privacy Policy (which is incorporated by
                    reference into these Terms), the applicable Rules, Procedures
                    and the statements and explanations appearing on the
                    applicable draw Details Screen (where appropriate) set out the
                    full extent of GAC’s obligations and liabilities to You when
                    You purchase tickets from Your Account and use the Site, and
                    form the contract between GAC and You. If there is any
                    conflict between these documents, they will apply in the
                    following order (unless GAC states otherwise): (a) the
                    applicable draw Procedures; (b) the applicable draw Rules; (c)
                    the applicable betting rules (d) these Terms (excluding, for
                    this purpose, the Privacy Policy); (e) the statements and
                    explanations appearing on the applicable draw Details Screen;
                    (f) the Privacy Policy.
                </div>
                <div className={style.parag}>
                    13.4 If any provision (or part of a provision) in any of the
                    documents mentioned in Section 13.3 is decided by a court of
                    competent jurisdiction to be void and/or unenforceable, that
                    decision will only affect the particular provision (or part of
                    the provision) and will not, in itself, make the other
                    provisions void or unenforceable.
                </div>
                <div className={style.parag}>
                    13.5 You may not assign or otherwise transfer (in whole or in
                    part) Your rights and/or obligations under these Terms. Any
                    breach of this Section may result in the use of Your Account,
                    the provision of the draw and/or access to the Site being
                    terminated immediately by GAC. GAC may assign or otherwise
                    their rights and/or obligations under these Terms in whole or
                    in part to any third party at its sole discretion.
                </div>

                <div className={style.header2}>14. Law</div>
                <div className={style.parag}>
                    The relevant law for these Terms is Manx law. Any legal
                    proceedings will be heard in the Manx courts.
                </div>

                <div className={style.header2}>15. Definitions</div>

                <table id="eula-table">
                    <tr>
                        <td>
                            Account:
                        </td>
                        <td>
                            An account maintained by a Player on GAC’s Computer
                            System.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Computer System:
                        </td>
                        <td>
                            The computer systems used by or on behalf of GAC from time
                            to time to operate draws, administer Accounts and pay
                            Prizes.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Commission:
                        </td>
                        <td>
                            The Kahnawake Gaming Commission (being the body appointed
                            to regulate The Draw) or any other body appointed to
                            regulate The Draw from time to time.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Customer Support Team:
                        </td>
                        <td>
                            GAC’s helpline for Players, contact details of which are
                            set out on the site.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Dormant Account:
                        </td>
                        <td>
                            Has the meaning given to it in Section 7.1.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Draw Game:
                        </td>
                        <td>
                            A game, the result of which is determined by a draw, entry
                            into which You can buy via the Site.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Draw Details Screen:
                        </td>
                        <td>
                            The screen on the Site that display details of a
                            particular draw Game.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Draw History:
                        </td>
                        <td>
                            Information held by GAC which relates to the draws You
                            have played through Your Account, including the outcome of
                            each draw.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Draw Procedures:
                        </td>
                        <td>
                            A written document issued by GAC for the draw in addition
                            to these Terms, which includes, without limitation, the
                            price per Play, how to play, how a Prize is won, the Prize
                            Structure and any other relevant information.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Draw Rules:
                        </td>
                        <td>
                            Any rules or conditions issued by GAC in addition to these
                            Terms which apply to the Draw-Based Game.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Draw Related Information:
                        </td>
                        <td>
                            Your Transaction History, Draw History and any information
                            GAC requires You to submit to it before providing You with
                            access to the draw (which includes Your name, user name,
                            password, phone number(s), debit card details, security
                            information, address, Identification Information, email
                            address, date of birth and gender).
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Identification Information:
                        </td>
                        <td>
                            Information used to establish Your identity, including
                            information required by GAC to confirm the authenticity of
                            that information. Identification Information could include
                            any element of the Draw-Related Information and, if You
                            submit a claim for a Prize payment within the applicable
                            claim period, Your passport, driving licence and utility
                            bills.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Player:
                        </td>
                        <td>
                            Has the same meaning as You.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Privacy Policy:
                        </td>
                        <td>
                            GAC’s policy relating to the use and storage of Your
                            personal information (including, but not limited to,
                            Draw-Related Information), as amended from time to time.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Prize:
                        </td>
                        <td>
                            A prize won by a Player from a Draw.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Prize Structure:
                        </td>
                        <td>
                            The value of Prizes for Player and associated charity
                            determined by GAC and set out in the Draw Procedures for
                            the relevant Game.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Site:
                        </td>
                        <td>
                            The website www.Worldlotto247.com.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Tickets:
                        </td>
                        <td>
                            The number of entries a player may purchase equating to
                            the number of chances the player has to win the draw.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Transaction History:
                        </td>
                        <td>
                            The history of payments into and out of Your Account,
                            shown in the ‘My Account’ section of the Site.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            You:
                        </td>
                        <td>
                            (a) A person intending to open an Account; or (b) the
                            holder of an Account, who is eligible to purchase tickets,
                            and whose Account has not been terminated, cancelled,
                            suspended or closed.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            GAC:
                        </td>
                        <td>
                            GAC Group Limited, a company registered in the Isle of
                            Man, whose registered office is at First Floor, Millennium
                            House, Victoria Rd, Douglas, Isle of Man IM2 4RW. Any
                            references to “we”, “us” and “our” on the Site are
                            references to GAC.
                        </td>
                    </tr>
                </table>
            </div>

        </div >
    );

}

export default TermsConditions;
