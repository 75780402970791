/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import Button from 'newui/Generic/Inputs/Button';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import { displayGenericErrorNotificationIntl, displayInfoNotification } from 'newui/Utils/uiUtil';
import { dateToYYYYMMDDHHmmss } from 'newui/Utils/dateUtil';
import { displayPage } from 'newui/Utils/routerUtil';
import { useIntl } from 'react-intl'
import * as constants from 'newui/constants.js'

function TakeABreak(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState(null)
    const [activeButtons, setActiveButtons] = useState(false);

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {
        jwtBackendService.getUserResponsibleGamblingInfo(successFetchLossLimitDataJWT, failureFetchLossLimitDataJWT, navigate, location.pathname);
    }

    function successFetchLossLimitDataJWT(data) {
        setLoaded(true);
        setData(data);
    }

    function failureFetchLossLimitDataJWT(data) {
        setLoaded(true);
    }


    function save(hours) {
        var cloneData = Object.assign({}, data);
        cloneData.break = hours;
        jwtBackendService.updateUserResponsibleGamblingInfo(cloneData, successOnSave, failureOnSave, navigate, location.pathname);
    }

    function toggle() {
        setActiveButtons(!activeButtons);
    }

    function logout() {
        displayPage(constants.ROUTE_LOGOUT, navigate, location.pathname);
    }
    function successOnSave(data) {
        //var msg = 'Your account is lock until : ' + dateToYYYYMMDDHHmmss(new Date(data.breakUntilDate));
        var msg = intl.formatMessage({ id: 'newcasino.rg.take.break.success', defaultMessage: 'Your account is lock until : ' }) + dateToYYYYMMDDHHmmss(new Date(data.breakUntilDate));
        displayInfoNotification('INFO', msg);
        setInterval(logout, 5000);
    }

    function failureOnSave(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    if (loaded) {
        return (
            <div id='contestWinnerPage' className={style.master_page_container}>
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.rg.take.break.page.title"
                        defaultMessage="Take a break"
                    />
                </div>
                <div className={style.mainSubTitle}>
                    <FormattedMessage
                        id="newcasino.rg.take.break.page.subtitle"
                        defaultMessage='WE PRIORITIZE RESPONSIBLE GAMBLING FOR A SAFE AND ENJOYABLE ONLINE CASINO EXPERIENCE. IF YOU NEED A SHORT BREAK FROM GAMBLING, OUR "TAKE A BREAK" FEATURE ALLOWS YOU TO TEMPORARILY SUSPEND YOUR ACCOUNT. IF YOU REQUIRE A LONGER BREAK, DO NOT HESITATE TO CHECK OUR SELF-EXCLUSION FEATURE.'
                    />
                </div>
                <div className={style.mainDiv}>
                    <div className={style.elementBut}>
                        <Checkbox label={intl.formatMessage({ id: 'newcasino.rg.take.break.page.checkbox', defaultMessage: 'By clicking here I understand that this action will block my account' })} onClick={toggle} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.take.break.page.24', defaultMessage: '24 Hours' })} onClick={() => save(24)} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.take.break.page.7', defaultMessage: '7 Days' })} onClick={() => save(168)} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.take.break.page.30', defaultMessage: '30 Days' })} onClick={() => save(720)} />
                    </div>
                </div>

            </div >
        );
    }
}

export default TakeABreak;
