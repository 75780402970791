
import SingleUserManagement from 'newui/Casino/Administration/UserManagement/SingleUserManagement';
import React from 'react'
import { useParams } from "react-router-dom";

function UserdataWrapper(props) {

    let { id } = useParams();

    return (
        <SingleUserManagement closeModal={props.closeModal} displayGenericModal={props.displayGenericModal} id={id} />
    );
}

export default (UserdataWrapper)
