import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { useIntl } from 'react-intl';

function MemoryList(props) {

  const intl = useIntl();
  const DEFAULT_ITEM_PERPAGE = 10;

  const [list, setList] = useState(null);
  const [currentSortedColumn, setCurrentSortedColumn] = useState('');
  const [currentSortedColumnAsc, setCurrentSortedColumnAsc] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const nbrItemPerPage = props.nbrItemPerPage ? props.nbrItemPerPage : DEFAULT_ITEM_PERPAGE;

  const startIndex = (currentPage - 1) * nbrItemPerPage;
  const lastIndex = startIndex + (nbrItemPerPage - 1);

  useEffect(() => {
    setList(props.list);
  }, // eslint-disable-next-line
    []);

  function getNbrPage() {
    var toReturn = list.length / nbrItemPerPage;
    return Math.ceil(toReturn);
  }

  function onRowClick(value) {
    if (props.onRowClick) {
      props.onRowClick(value);
    }
  }

  function previousPage() {
    setCurrentPage(currentPage - 1);
  }

  //canSort: true, sortField: 'creationDateMillis' },

  function sort(col) {
    var clone = JSON.parse(JSON.stringify(list));
    if (col.canSort === true) {
      var field = col.listColumn;
      if (col.sortField) {
        field = col.sortField;
      }
      var isAsc = true;

      if (currentSortedColumn === field) {
        isAsc = !currentSortedColumnAsc;
      }

      clone.sort(function (a, b) {
        return compareAlpha(a[field], b[field], isAsc);
      })

      setCurrentSortedColumnAsc(isAsc)
      setCurrentSortedColumn(field);
      setList(clone);
    }
  }

  function canBeSorted(col) {
    return (col.canSort === true);
  }

  function isSorted(col) {
    return (col.listColumn === currentSortedColumn || col.sortField === currentSortedColumn)
  }

  function compareAlpha(a, b, isAsc) {
    let x = a ? a.toLowerCase() : '';
    let y = b ? b.toLowerCase() : '';

    var toReturn = 0
    if (x > y) { toReturn = isAsc ? 1 : -1; }
    if (x < y) { toReturn = isAsc ? -1 : 1; }

    return toReturn;
  }


  function nextPage() {
    setCurrentPage(currentPage + 1);
  }

  function getPagination() {
    var previous = (currentPage > 1) ? <span className={style.page} onClick={previousPage}>{intl.formatMessage({ id: 'newcasino.memorylist.previous', defaultMessage: 'Previous Page' })} - </span> : ''
    var pageTxt = <>{intl.formatMessage({ id: 'newcasino.memorylist.page', defaultMessage: 'Page' })} {currentPage} {intl.formatMessage({ id: 'newcasino.memorylist.of', defaultMessage: 'of' })} {getNbrPage()}</>
    var next = (currentPage < getNbrPage()) ? <span className={style.page} onClick={nextPage}> - {intl.formatMessage({ id: 'newcasino.memorylist.next', defaultMessage: 'Next Page' })}</span> : ''
    return (
      <div className={style.pagination}>
        {previous}
        {pageTxt}
        {next}
      </div>
    );
  }

  function getRowCellStyle(index) {
    var toReturn = style.rowCellLight;

    if (index % 2 === 0) {
      toReturn = style.rowCellDark;
    }

    return toReturn;
  }

  function getGridTemplateColumns() {
    var toReturn = '';
    for (let i = 0; i < props.columns.length; i++) {
      toReturn = toReturn + ' ' + props.columns[i].width;
    }

    return toReturn.trim();
  }

  try {

    if (list && list.length > 0) {
      return (
        <div key={Math.random()} className={style.mainGrid} style={{ 'gridTemplateColumns': getGridTemplateColumns() }}>
          {props.columns.map((col, index) => (
            <div key={'col_' + index} onClick={() => sort(col)} style={canBeSorted(col) ? { cursor: 'pointer' } : {}} className={isSorted(col) ? style.headerSorted : style.header}>{col.name}</div>
          ))}
          <div className={style.separator} style={{ 'grid-column': ('span ' + props.columns.length) }} />
          {list.map((item, index) => (
            <React.Fragment key={'colRow_' + index}>
              {(index >= startIndex && index <= lastIndex) &&
                <>
                  {
                    props.columns.map((col, index2) => (
                      <div key={'colRow_' + index + '_row_' + index2} className={getRowCellStyle(index)} onClick={() => onRowClick(item)}>
                        {col.component ? col.component(item) : item[col.listColumn]

                        }
                      </div>
                    ))
                  }
                  <div className={style.separator} style={{ 'grid-column': ('span ' + props.columns.length) }} />
                </>}</React.Fragment>))}
          <div style={{ gridColumn: 'span ' + props.columns.length }}>
            {getPagination()}
          </div>
        </div>

      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default MemoryList

