/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import Header from 'newui/Casino/General/Modal/Header';
import Footer from 'newui/Casino/General/Modal/Footer';
import TextField from 'newui/Generic/Inputs/TextField';
import { countryPhoneCodes } from 'newui/constants';
import { displayErrorNotificationIntl, displayGenericErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, getAmountTxt, getAmountTxtWithCoin, inNotMinusOne, isTextMin2Char } from 'newui/Utils/uiUtil';
import ComboBox from 'newui/Generic/Inputs/ComboBox';


function ChangePhoneNumber(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(null);
    const [phoneCode, setPhoneCode] = useState('');
    const [phoneCodeValid, setPhoneCodeValid] = useState(null);

    const [smsCode, setSmsCode] = useState('');
    const [smsCodeValid, setSmsCodeValid] = useState(null);

    const [currentStep, setCurrentStep] = useState(1);


    function phoneValidCallBack(isValid, value) {
        setPhoneValid(isValid);
        setPhone(value);
    }

    function smsCodeValidCallBack(isValid, value) {
        setSmsCodeValid(isValid);
        setSmsCode(value);
    }

    function phoneCodeValidCallBack(isValid, value) {
        var validity = (value !== '-1')
        setPhoneCodeValid(validity);
        setPhoneCode(value);
    }

    function isFirstStepOk() {
        return (phoneCodeValid && phoneValid)
    }

    function isSecondStepOk() {
        return (phoneCodeValid && phoneValid && smsCodeValid)
    }

    function requestSms() {
        jwtBackendService.sendSmsVerificationCode('+' + phoneCode + '' + phone, successRequestSms, failureRequestSms, navigate, location.pathname);
    }

    function successRequestSms(data) {
        if (data.smsSend === false) {
            displayErrorNotificationIntl(intl, 'newcasino.profile.popup.phone.sms.failure.send')
        } else {
            displayInfoNotificationIntl(intl, 'newcasino.profile.popup.phone.sms.success.send')
            setCurrentStep(2);
        }

    }

    function failureRequestSms(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function requestSmsAnswer() {
        jwtBackendService.verifySmsVerificationCode('+' + phoneCode + '' + phone, smsCode, successSmsAnswer, failureSmsAnswer, navigate, location.pathname);
    }

    function successSmsAnswer(data) {
        if (data.smsVerify === true) {
            displayInfoNotificationIntl(intl, 'newcasino.profile.popup.phone.sms.success.verify')
            props.reload();
            props.closeModal();
        } else {
            displayErrorNotificationIntl(intl, 'newcasino.profile.popup.phone.sms.failure.verify')
        }

    }

    function failureSmsAnswer(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    try {
        var header = null;
        var footer = null;
        header = <Header title={intl.formatMessage({ id: 'newcasino.profile.popup.phone.title', defaultMessage: 'Change your phone number' })} subTitle={intl.formatMessage({ id: 'newcasino.profile.popup.phone.subtitle', defaultMessage: 'To Change your phone number, we have to validate your new phone number with an sms.' })} closeModal={props.closeModal} />

        if (currentStep === 1) {
            footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.profile.popup.phone.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.profile.popup.phone.button.next', defaultMessage: 'Next Step' }), action: requestSms, canProcess: isFirstStepOk() }} />
        } else {
            footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.profile.popup.phone.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.profile.popup.phone.button.end', defaultMessage: 'Validate' }), action: requestSmsAnswer, canProcess: isSecondStepOk() }} />
        }


        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    {(currentStep === 1) &&
                        <div className={style.grid50}>
                            <ComboBox autofocus='false' values={[phoneCode, phoneCodeValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.profile.popup.phone.countrycode.hint', defaultMessage: 'Select Country Code...' }) }} valueList={{ list: countryPhoneCodes, key: 'code', value: 'label' }} hint='' label={intl.formatMessage({ id: 'newcasino.profile.popup.phone.countrycode.label', defaultMessage: 'Country code' })} showCheck='true' validator={inNotMinusOne} validatorInformer={phoneCodeValidCallBack} mandatory='true' />
                            <TextField maxLength='30' autofocus='false' type='digit' values={[phone, phoneValid]} hint={intl.formatMessage({ id: 'newcasino.profile.popup.phone.number.hint', defaultMessage: 'Type your phone number here' })} label={intl.formatMessage({ id: 'newcasino.profile.popup.phone.number.label', defaultMessage: 'Phone Number' })} validator={isTextMin2Char} validatorInformer={phoneValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.address.phone.error', defaultMessage: 'Too short' })} />
                        </div>
                    }
                    {(currentStep === 2) &&
                        <div className={style.grid100}>
                            <TextField maxLength='6' autofocus='true' type='digit' values={[smsCode, smsCodeValid]} hint={intl.formatMessage({ id: 'newcasino.profile.popup.phone.sms.hint', defaultMessage: 'Type the code received' })} label={intl.formatMessage({ id: 'newcasino.profile.popup.phone.sms.label', defaultMessage: 'Received Code' })} validator={isTextMin2Char} validatorInformer={smsCodeValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.address.phone.error', defaultMessage: 'Too short' })} />
                        </div>
                    }
                    <div>
                        {footer}
                    </div>
                </div>
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default ChangePhoneNumber;
