import React from 'react'
import style from './style.module.scss'
import DivClick from 'newui/Generic/Inputs/DivClick';

function Header(props) {
  function closeModal() {
    props.closeModal();
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div>
          <div><span className={props.titleClass ? props.titleClass : style.headerTitle}>{props.title}</span></div>
          <div><span className={style.headerPreSubTitle}>{props.preSubTitle}</span><span className={style.headerSubTitle}>{props.subTitle}</span></div>
        </div>
        <div>
          <DivClick className={style.closeModal}><i className="fa fa-times fa-2x" aria-hidden="true" onClick={closeModal}></i></DivClick>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Header