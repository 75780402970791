import React, { useState } from 'react';
import { displayErrorNotificationIntl, displayGenericErrorNotificationIntl, displayInfoNotificationIntl, isPasswordOk, isTextMin2Char } from 'newui/Utils/uiUtil';
import Button from 'newui/Generic/Inputs/Button';

import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import TextField from 'newui/Generic/Inputs/TextField';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'

function ChangePassword(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    const [refresh, setRefresh] = useState(Math.random());
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(null);

    const [password2, setPassword2] = useState('');
    const [password2Valid, setPassword2Valid] = useState(null);


    function passwordValidCallBack(isValid, value) {
        setPasswordValid(isValid);
        setPassword(value);
    }

    function password2ValidCallBack(isValid, value) {
        setPassword2Valid(isValid);
        setPassword2(value);
    }

    function canChangePassword() {
        return (passwordValid && password2Valid);
    }

    function changePassword() {
        let passData = {
            passwordProfile: password,
            newPasswordProfile: password2,
            confirmNewPasswordProfile: password2
        }

        jwtBackendService.changePassword(passData, successChangePasswordJWT, failureChangePasswordJWT, navigate, location.pathname);
    }

    function successChangePasswordJWT(data) {
        if (data.result === 'OK') {
            displayInfoNotificationIntl(intl, 'newcasino.profile.password.success')
            setPassword('');
            setPasswordValid(null);
            setPassword2('');
            setPassword2Valid(null);
            setRefresh(Math.random());
        } else {
            displayErrorNotificationIntl(intl, 'newcasino.profile.password.failure')
        }
    }

    function failureChangePasswordJWT(data) {
        displayGenericErrorNotificationIntl(intl)
    }

    return (
        <>
            <div className={style.spacer}><TextField key={'P1' + refresh} maxLength='100' values={[password, passwordValid]} hint={intl.formatMessage({ id: 'newcasino.profile.password.input1.hint', defaultMessage: 'Type your current password here' })} type='password' canSee='true' mandatory='true' label={intl.formatMessage({ id: 'newcasino.profile.password.input1.label', defaultMessage: 'Current Password' })} validator={isTextMin2Char} showTitleError='true' showCheck='true' validatorInformer={passwordValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.profile.password.input1.error', defaultMessage: 'Password too short' })} /></div>
            <TextField key={'P2' + refresh} maxLength='100' values={[password2, password2Valid]} hint={intl.formatMessage({ id: 'newcasino.profile.password.input2.hint', defaultMessage: 'Type your new password here' })} type='password' canSee='true' mandatory='true' label={intl.formatMessage({ id: 'newcasino.profile.password.input2.label', defaultMessage: 'New Password' })} validator={isPasswordOk} showTitleError='true' showCheck='true' validatorInformer={password2ValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.profile.password.input2.error', defaultMessage: 'You need at less 8 characters with a mix of uppercase and lowercase' })} />
            <div className={style.buttCenter}><Button active={canChangePassword()} value={intl.formatMessage({ id: 'newcasino.profile.password.button', defaultMessage: 'Update' })} onClick={changePassword} /></div>
        </>
    );
}

export default ChangePassword;
