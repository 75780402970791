import React, { useState } from 'react'
import style from './style.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import TextField from 'newui/Generic/Inputs/TextField';
import Button from 'newui/Generic/Inputs/Button';
import { displayErrorNotificationIntl, inNotMinusOne, isDateStringOver18, isEmail, isPasswordOk, isTextMin1Char, isTextMin2Char, isTextMin5Char } from 'newui/Utils/uiUtil';
import { useIntl } from 'react-intl';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import * as constants from 'newui/constants';
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import BirthDay from 'newui/Generic/Inputs/Birthday';
import SuccessPopup from './SuccessPopup';


function Registration(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl()

  const [resetP2, setResetP2] = useState(Math.random());

  const [fullName, setFullName] = useState('');
  const [fullNameValid, setFullNameValid] = useState(null);

  const [cieName, setCieName] = useState('');
  const [cieNameValid, setCieNameValid] = useState(null);

  const [country, setCountry] = useState('');
  const [countryValid, setCountryValid] = useState(null);

  const [currency, setCurrency] = useState('');
  const [currencyValid, setCurrencyValid] = useState(null);

  const [dob, setDob] = useState('');
  const [dobValid, setDobValid] = useState(null);

  const [webSite, setWebSite] = useState('');
  const [webSiteValid, setWebSiteValid] = useState(null);

  const [streamPage, setStreamPage] = useState('');
  const [streamPageValid, setStreamPageValid] = useState(null);

  const [avgViews, setAvgViews] = useState('');
  const [avgViewsValid, setAvgViewsValid] = useState(null);

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);

  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(null);

  const [password2, setPassword2] = useState('');
  const [password2Valid, setPassword2Valid] = useState(null);

  function processRegister() {
    props.spinner(true);
    jwtBackendService.registrationAffiliate(fullName, cieName, email, currency, country, dob, password, webSite, streamPage, avgViews, successProcessRegister, failureProcessRegister, navigate, location.pathname);
  }

  function getCurrency() {
    return [
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.canada', defaultMessage: 'Canadian Dollar' }), cod: 'CAD' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.usa', defaultMessage: 'American Dollar' }), cod: 'USD' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.australia', defaultMessage: 'Australian Dollar' }), cod: 'AUD' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.brazil', defaultMessage: 'Brazilian Real' }), cod: 'BRL' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.europe', defaultMessage: 'European Euro' }), cod: 'EUR' },
    ]
  }
  function successProcessRegister(data) {
    props.spinner(false);
    props.showLogin();
    props.displayModalComponent(<SuccessPopup closeModal={props.closeModal} />, 'center', '#FFFFFF', '330px');
  }

  function failureProcessRegister(data) {
    props.spinner(false);
    displayErrorNotificationIntl(intl, 'newcasino.aff.auth.reg.err.fail')
  }

  function checkPassConfirm(value) {
    return (password === value)
  }

  function emailValidCallBack(isValid, value) {
    setEmailValid(isValid);
    setEmail(value)
  }

  function passwordValidCallBack(isValid, value) {
    setPasswordValid(isValid);
    setPassword(value);

    if (password2Valid !== null) {
      setPassword2Valid(value === password2)
      setResetP2(Math.random())
    }
  }

  function avgViewsValidCallBack(isValid, value) {
    setAvgViewsValid(isValid);
    setAvgViews(value);
  }

  function webSiteValidCallBack(isValid, value) {
    setWebSiteValid(isValid);
    setWebSite(value);
  }

  function streamPageValidCallBack(isValid, value) {
    setStreamPageValid(isValid)
    setStreamPage(value)
  }

  function cieNameValidCallBack(isValid, value) {
    setCieNameValid(isValid)
    setCieName(value);
  }

  function fullnameValidCallBack(isValid, value) {
    setFullNameValid(isValid)
    setFullName(value)
  }

  function password2ValidCallBack(isValid, value) {
    setPassword2Valid(isValid);
    setPassword2(value);
  }

  function currencyValidCallBack(isValid, value) {
    setCurrencyValid(isValid);
    setCurrency(value);
  }

  function countryValidCallBack(isValid, value) {
    setCountryValid(isValid);
    setCountry(value);
  }

  function dobValidCallBack(isValid, value) {
    setDobValid(isValid);
    setDob(value);
  }

  function canRegister() {
    return fullNameValid && (cieNameValid || cieNameValid === null) && emailValid && currencyValid && countryValid && dobValid && passwordValid && password2Valid && (webSiteValid || webSiteValid === null) && (streamPageValid || streamPageValid === null) && (avgViewsValid || avgViewsValid === null)
  }

  try {
    return (
      <div className={style.reg_mainGrid}>
        <div className={style.reg_title}>{intl.formatMessage({ id: 'newcasino.aff.auth.reg.title', defaultMessage: 'Sign up' })}</div>
        <div className={style.reg_spacer} />
        <div className={style.reg_text}>
          {intl.formatMessage({ id: 'newcasino.aff.auth.reg.sub.title', defaultMessage: 'Enter the required information to register.' })}
        </div>
        <div className={style.reg_subTitle}>{intl.formatMessage({ id: 'newcasino.aff.auth.reg.sub.personnal', defaultMessage: 'Personnal information' })}</div>
        <div className={style.reg_grid50}>
          <div className={style.reg_input}>
            <TextField maxLength='50' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' autofocus='true' topTitleStyle={style.inputTitle} values={[fullName, fullNameValid]} label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.fullname', defaultMessage: 'Full name' })} validator={isTextMin5Char} validatorInformer={fullnameValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.fullname.err', defaultMessage: 'Invalid' })} />
          </div>
          <div className={style.reg_input}>
            <TextField maxLength='40' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[cieName, cieNameValid]} label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.cie', defaultMessage: 'Company name (optional)' })} validator={isTextMin2Char} validatorInformer={cieNameValidCallBack} mandatory='false' showTitleError='false' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.cie.err', defaultMessage: 'Invalid' })} />
          </div>
        </div>
        <div className={style.reg_grid50}>
          <div className={style.reg_input}>
            <TextField maxLength='50' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[email, emailValid]} label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.email', defaultMessage: 'Email address' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.email.err', defaultMessage: 'Invalid Email' })} />
          </div>
          <div className={style.reg_input}>
            <BirthDay height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} maxLength='10' values={[dob, dobValid]} hint={intl.formatMessage({ id: 'newcasino.aff.auth.reg.dob.hint', defaultMessage: 'Ex.: dd/mm/yyyy' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.dob', defaultMessage: 'Date of birth' })} validator={isDateStringOver18} showTitleError='true' showCheck='true' validatorInformer={dobValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.dob.err', defaultMessage: 'Invalid Birthday - 18+' })} />
          </div>
        </div>
        <div className={style.reg_grid50}>
          <div className={style.reg_input}>
            <ComboBox height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} autofocus='true' values={[country, countryValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.aff.auth.reg.country.hint', defaultMessage: 'Select your country...' }) }} valueList={{ list: constants.countries, key: 'id', value: 'name' }} hint='Select your country' label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.country', defaultMessage: 'Country' })} showCheck='true' validator={inNotMinusOne} validatorInformer={countryValidCallBack} mandatory='true' />
          </div>
          <div className={style.reg_input}>
            <ComboBox height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} autofocus='true' values={[currency, currencyValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.aff.auth.reg.currency.hint', defaultMessage: 'Select your currency...' }) }} valueList={{ list: getCurrency(), key: 'cod', value: 'name' }} hint='Select your currency' label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.currency', defaultMessage: 'Currency' })} showCheck='true' validator={inNotMinusOne} validatorInformer={currencyValidCallBack} mandatory='true' />
          </div>
        </div>
        <div className={style.reg_subTitle}>{intl.formatMessage({ id: 'newcasino.aff.auth.reg.sub.connection', defaultMessage: 'Connection information' })}</div>
        <div className={style.reg_grid50}>
          <div className={style.reg_input}>
            <TextField maxLength='20' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[password, passwordValid]} type='password' canSee='false' mandatory='true' label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.password', defaultMessage: 'Password' })} validator={isPasswordOk} showTitleError='true' showCheck='true' validatorInformer={passwordValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.password.err', defaultMessage: 'You need at less 8 characters with a mix of uppercase and lowercase' })} />
          </div>
          <div className={style.reg_input}>
            <TextField key={resetP2} maxLength='20' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} type='password' values={[password2, password2Valid]} label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.password2', defaultMessage: 'Confirm password' })} validator={checkPassConfirm} validatorInformer={password2ValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.password2.err', defaultMessage: 'Does Not match' })} />
          </div>
        </div>
        <div className={style.reg_subTitle}>{intl.formatMessage({ id: 'newcasino.aff.auth.reg.sub.channel', defaultMessage: 'Your channels' })}</div>
        <div className={style.reg_grid50}>
          <div className={style.reg_input}>
            <TextField maxLength='50' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[webSite, webSiteValid]} label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.website', defaultMessage: 'Website (optional)' })} validator={isTextMin5Char} validatorInformer={webSiteValidCallBack} mandatory='false' showTitleError='false' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.website.err', defaultMessage: 'Invalid' })} />
          </div>
          <div className={style.reg_input}>
            <TextField maxLength='50' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[streamPage, streamPageValid]} label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.streaming', defaultMessage: 'Streaming Page (optional)' })} validator={isTextMin5Char} validatorInformer={streamPageValidCallBack} mandatory='false' showTitleError='false' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.streaming.err', defaultMessage: 'Invalid' })} />
          </div>
        </div>
        <div className={style.reg_grid50}>
          <div className={style.reg_input}>
            <TextField maxLength='10' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' type='digit' topTitleStyle={style.inputTitle} values={[avgViews, avgViewsValid]} label={intl.formatMessage({ id: 'newcasino.aff.auth.reg.views', defaultMessage: 'Average views (optional)' })} validator={isTextMin1Char} validatorInformer={avgViewsValidCallBack} mandatory='false' showTitleError='false' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.reg.views.err', defaultMessage: 'Invalid' })} />
          </div>
          <div />
        </div>
        <div className={style.reg_grid50}>
          <div className={style.reg_buttonLine}>
            <Button width='180px' height='36px' paddingTop='8px' font='normal normal 500 12px/15px Montserrat' onClick={props.showLogin} value='Back' colorStyle='white' />
          </div>
          <div className={style.reg_buttonLineRight}>
            <Button width='180px' height='36px' paddingTop='8px' font='normal normal 500 12px/15px Montserrat' active={canRegister()} value='Register' colorStyle='light' onClick={() => processRegister()} />
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}
export default Registration