import React from 'react'

function SpanClick(props) {

  try {
    return (
      <span style={props.style} className={props.className} onTouchStart={props.onClick} onClick={props.onClick} onTouchEnd={(e) => e.preventDefault()}>
        {props.children}
      </span >
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default SpanClick

