import React, { useEffect, useState } from 'react'
import * as backEndService from 'newui/Services/Backend/backendService';
import * as constants from 'newui/constants.js'
import style from './style.module.scss'
import { displayInfoNotification, searchGames, urlToBackgroundUrl } from 'newui/Utils/uiUtil'
import { FormattedMessage, useIntl } from 'react-intl'
import { displayPage } from 'newui/Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom'
import DivClick from 'newui/Generic/Inputs/DivClick';
import ApiPersistence from 'newui/Utils/ApiPersistence';
import ApiCache from 'newui/Utils/ApiCache';
import UseFreeSpin from '../Home/UseFreeSpin';

function GameSearch(props) {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();

  const [games, setGames] = useState([]);

  useEffect(() => {
    getAllGamesInformation()
    // eslint-disable-next-line
  }, [props.keyword])

  function onSuccessSearchJWT(item) {
    backEndService.setInCache(backEndService.CACHE_CASINO_DATA, item, ApiCache.getInstance());
    onSuccessSearch(searchGames(item, props.keyword));
  }

  function onSuccessSearch(gamesToDisplay) {
    if (props.actionOnResult) {
      props.actionOnResult(gamesToDisplay.length)
    }

    setGames(gamesToDisplay);
  }

  function onFailureSearch(error) {
    console.log(error);
    displayPage(constants.ROUTE_500, navigate);
  }

  function getAllGamesInformation() {
    return backEndService.searchGamesByKeyWord(ApiPersistence.getInstance().isLogged(), props.keyword, onSuccessSearch, onSuccessSearchJWT, onFailureSearch, navigate, location.pathname)
  }

  function startGameNow(gameId) {
    displayPage(constants.ROUTE_PLAY_GAME_BASE + gameId, navigate, location.pathname);
  }

  function startGame(game) {

    if (props.isCurrentModal === false) {
      if (game.freeSpinAllowed && props.getFreeSpinAvailable() > 0) {
        props.displayComponent(
          <UseFreeSpin
            gameId={game.gameId}
            nbrFreeSpins={props.getFreeSpinAvailable()}
            key={Math.random()}
            callback={props.useFreeSpin} />);

      } else {
        startGameNow(game.gameId);
      }
    } else {
      if (game.needAccountToPlay && !ApiPersistence.getInstance().isLogged()) {
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.error.need.account', defaultMessage: 'Need an ancount' }), intl.formatMessage({ id: 'newcasino.error.need.account.to.play', defaultMessage: 'You need to login or register to access this game' }))
      } else {

        //if (props.gameStartedAction) {
        if (props.gameStartedAction(game, props.getFreeSpinAvailable())) {
          startGameNow(game.gameId);
        }
        //}

        //displayPage(constants.ROUTE_PLAY_GAME_BASE + game.gameId, navigate, location.pathname)
        //startGameNow(game.gameId);
      }
    }
  }

  try {
    if (games.length > 0) {
      return (
        <div className={`${style.GameSelection_showAllGrid}`} >
          {games.map((item, index) => (
            <div key={'tabSearchAlls_' + index} className={`${style.GameSelection_showAllGridElement_div}`}>
              <div className={`${style.GameSelection_showAllGridElement_center}`}>
                <DivClick className={`${style.GameSelection_showAllGridElement}`} onClick={() => startGame(item)} style={{
                  backgroundImage: urlToBackgroundUrl(item.image)
                }}>
                  <img className={`${style.GameSelection_showAllGridElement_img}`} src={item.image} alt='' />
                </DivClick>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className={`${style.GameSelection_showAllGrid_search_no_result}`} >
          <FormattedMessage id='newcasino.search.noresult' defaultMessage='No Result' />
        </div>
      )
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default GameSearch