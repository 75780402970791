import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';

function BonusTerms(props) {

    return (
        <div className={style.master_page_container}>
            {props.header !== false &&
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.tochange"
                        defaultMessage="Bonus Terms"
                    />
                </div>
            }
            <div className={style.mainDiv}>
                <div className={style.parag}>First, Reload and action deposit bonus</div>
                <div className={style.parag}>Different opportunity may be offered to you from time to time either on a new deposit or if you have completed a specific action. Your deposit bonus, if you accept it, will be matched by a certain percentage up to a certain value that would be indicated on the specific offer.</div>
                <div className={style.parag}>3X or 300% match bonus means that if you deposit $200, you will receive $600 as bonus money for a total of $800 that would be put in your balance automatically.</div>
                <div className={style.parag}>The maximum amount of deposit to claim those type of bonuses is set to $/&euro; 1500, therefore a maximum deposit of $/&euro;500.</div>
                <div className={style.parag}>All deposit bonuses are subject to 35x times wager on deposit + bonus amount.</div>
                <div className={style.parag}>All games contribute to a different percentage towards the wagering requirements. Please see below for all information:</div>
                <div className={style.parag}>All Casino Slots: 100%</div>
                <div className={style.parag}>Tables &amp; Cards: 10%</div>
                <div className={style.parag}>Live Dealer Tables: 10%</div>
                <div className={style.parag}>*Sportsbook: 150%</div>
                <div className={style.parag}>*To count towards the wagering requirements, all bet made in the Sportsbook must be placed at a minimum odd of 1.6 or over.</div>
                <div className={style.parag}>To keep track of the wager requirement you have left before being able to withdraw, you can refer to the cashier section.</div>
                <div className={style.parag}>Bought-in money will have priority over bonus money.</div>
                <div className={style.parag}>Once the wager requirement is complete, bonus money will be transformed into withdrawable money.</div>
                <div className={style.parag}>Bonus money cannot be used to buy lottery tickets.</div>
                <div className={style.parag}>All bonus amount will be forfeited if it goes under $/&euro;1.</div>
                <div className={style.parag}>All offers are only valid once per customer, computer, IP address or household, unless stated otherwise.</div>

                <div className={style.parag}><strong>NO DEPOSIT 30 FREE SPINS (CURRENTLY TIMED OUT FOR BUG ABUSE)</strong></div>
                <div className={style.parag}>THE NO DEPOSIT 30 FREE SPINS BONUS ON <strong>“SWEET BONANZA”</strong> GAME IS ONLY AVAILABLE TO NEW PLAYERS AND EACH SPIN ARE WORTH $0.20.</div>
                <div className={style.parag}>ALL WINNINGS ARE WAGER-FREE AND CAN BE WITHDRAWN AT ANY TIME.</div>
                <div className={style.parag}>ALL OFFERS ARE ONLY VALID ONCE PER CUSTOMER, COMPUTER, IP ADDRESS OR HOUSEHOLD.</div>

                <div className={style.parag}><strong>Free Spins</strong></div>
                <div className={style.parag}>FREE SPINS MIGHT BE OFFERED TO YOU FROM TIME TO TIME.</div>
                <div className={style.parag}>FREE SPINS WILL ALWAYS BE LOCKED TO A SPECIFIC GAME STATED IN THE OFFER.</div>
                <div className={style.parag}>FREE SPINS ARE PLAYED AT $0.20 VALUE PER SPIN UNLESS STATED OTHERWISE IN THE OFFER.</div>
                <div className={style.parag}>FREE SPINS WINNINGS ARE SUBJECT TO A WAGER REQUIREMENT OF 40X.</div>
                <div className={style.parag}>FREE SPINS WINNINGS CAN BE WAGER-FREE AND CAN BE WITHDRAWN AT ANY TIME IF STATED IN THE OFFER, OTHERWISE, ALL FREE SPINS WINNINGS ARE SUBJECT TO THE WAGER REQUIREMENT.</div>
                <div className={style.parag}>IF NO EXPIRATION DATE IS STATED IN THE OFFER, YOU CAN ENJOY YOUR FREE SPINS ANYTIME YOU WANT. OTHERWISE, FREE SPINS WILL EXPIRE AFTER THE SPECIFIC NUMBER OF DAYS STATED IN THE OFFER.</div>
                <div className={style.parag}>ALL OFFERS ARE ONLY VALID ONCE PER CUSTOMER, COMPUTER, IP ADDRESS OR HOUSEHOLD</div>
                <div className={style.parag}>&nbsp;</div>
                <div className={style.parag}><strong>Sports Bonuses</strong></div>
                <div className={style.parag}>Sports Bonuses might be offer to you from time to time. All the following bonuses have an expiration date that will be stated on the offer.</div>
                <div className={style.parag}>Events restriction will be stated on the offer.</div>
                <div className={style.parag}><strong>FREEBET</strong> &ndash; By receiving this type of offer, you will only receive the Won amount on your account. For example, if you place a $/&euro;5 FREE BET on a certain event with odd of 3.5, you will get $/&euro;12.50.</div>
                <div className={style.parag}>(5*3.5 - 5 = 12.50)</div>
                <div className={style.parag}>&nbsp;</div>
                <div className={style.parag}><strong>FREE MONEY</strong> - By receiving this type of offer, you will receive the Bet &amp; Won amount on your account. For example, if you place a $/&euro;5 FREE MONEY on a certain event with odd of 3.5, you will get $/&euro;18.50.</div>
                <div className={style.parag}>(5*3.5 = 18.50)</div>
                <div className={style.parag}><strong>BET WITHOUT RISK</strong> - By receiving this type of offer, player use his money for bet, but if bet is lost, you get your money back.</div>
                <div className={style.parag}>&nbsp;</div>
                <div className={style.parag}><strong>Comboboost</strong></div>
                <div className={style.parag}>By placing a bet on 3 or more sports events, you will receive an extra boost to your current total odd of your combination.</div>
                <div className={style.parag}>Please see the following chart for all boost offer:</div>
                <div className={style.parag}>NUMBER OF EVENTS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; BONUS ODD</div>
                <div className={style.parag}>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.08</div>
                <div className={style.parag}>5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.15</div>
                <div className={style.parag}>7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.20</div>
                <div className={style.parag}>9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.30</div>
                <div className={style.parag}>11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.35</div>
                <div className={style.parag}>13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.45</div>
                <div className={style.parag}>15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.55&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className={style.parag}>18&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.85</div>
                <div className={style.parag}>20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.00</div>
                <div className={style.parag}>Minimum number of events in a combo to take part in the promotion: 3</div>
                <div className={style.parag}>Minimum odds for each bet in the combo: 1.5</div>
                <div className={style.parag}>There is no limit on the amount of time you can use that feature.</div>
                <div className={style.parag}>This feature is available to you at any time.</div>
                <div className={style.parag}>There is no event restriction on this feature unless stated otherwise.</div>
                <div className={style.parag}>&nbsp;</div>
                <div className={style.parag}><strong>General Terms</strong></div>
                <div className={style.parag}>Bonus amounts will be automatically forfeited if the Bonus Money on the account reaches below $1.00.</div>
                <div className={style.parag}>If any rewards are not credited to your account, please contact us immediately.</div>
                <div className={style.parag}>We reserve the right to void any bonuses and/or winnings obtained by breaking the bonus terms and conditions or any other fraudulent behavior, as per the specific offer&rsquo;s terms.</div>
                <div className={style.parag}>We reserve the right to close permanently the account of players who repeatedly attempt fraudulent behavior or breach bonus terms on more than one occasion, as per specific offer&rsquo;s terms.</div>
                <div className={style.parag}>We reserve the right to update these rules at any time.</div>
                <div className={style.parag}>We reserve the right to amend, suspend or cancel any promotion at any time and without notice.</div>
                <div className={style.parag}>Standard Terms and Conditions apply.</div>
            </div>

        </div >
    );
}

export default BonusTerms;
