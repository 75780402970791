import React from 'react'
import style from './style.module.scss'
import { getImageUrl } from 'newui/Utils/uiUtil';

function MaintenancePlayer(props) {

  props.alertMaintenance();
  return (
    <>
      <div className={style.mainDiv2}>
        <center>
          The website is under maintenance<br />It won't be long, come back in a few moments.
        </center>
      </div>
      <div className={style.mainDiv}>
        <img className={style.theImg} alt='Maintenance' src={getImageUrl('newui/maintenance/maintenance.jpg')} />
      </div>
    </>
  );

}

export default MaintenancePlayer