import * as constants from 'newui/constants'
import { get_storage, set_storage } from "./localDataUtil";
import { displayInfoNotification } from './uiUtil';

export default class ApiPersistence {
    static defaultInstance = null;

    resultMap = new Map();

    nextAlarm = null;
    reminderMinutes = null;

    static LOCALE_STORAGE_KEY = 'CONFIG'
    static KEY_REFFERRALUSER = 'referralUser'
    static KEY_USER = 'DataUser'
    static KEY_LANGUAGE = 'Language'
    static KEY_THEME = 'Theme'
    static KEY_REMEMBERME = 'RememberMe'
    static KEY_LASTPOPUPSHOWED = 'LastPopupShowed'
    static KEY_LASTMSG = 'LastMessage'
    static KEY_REMINDERMINUTES = 'ReminderMinutes'
    static KEY_USERSEARCH = 'UserSearch'

    static THEME_NORMAL = 'normal'
    static THEME_DARK = 'dark'




    /**
    * @returns {ApiPersistence}
    */
    static getInstance() {
        if (ApiPersistence.defaultInstance == null) {
            ApiPersistence.defaultInstance = new ApiPersistence();
            var data = get_storage(ApiPersistence.LOCALE_STORAGE_KEY)
            try {
                ApiPersistence.defaultInstance.resultMap = (data ? new Map(data) : new Map());
            } catch (error) {
                ApiPersistence.defaultInstance.resultMap = new Map();
            }
        }

        return ApiPersistence.defaultInstance;
    }

    checkAlarm() {
        if (this.nextAlarm && (new Date().getTime() > this.nextAlarm)) {
            displayInfoNotification('ALARM', 'As requested, this is a reminder that you are playing for more than ' + this.reminderMinutes + ' minutes');
            this.nextAlarm = 9990000000000;
        }
    }

    startAlarm() {
        let minutes = this.getReminderAlarmMinutes();

        let isNew = (this.nextAlarm === null);

        if (minutes && minutes > 0) {
            if (this.nextAlarm === 9990000000000) {
                this.reminderMinutes = minutes;
                this.nextAlarm = ((minutes * 60 * 1000) + new Date().getTime());
            }

            if (isNew) {
                this.reminderMinutes = minutes;
                this.nextAlarm = ((minutes * 60 * 1000) + new Date().getTime());
                setInterval(() => {
                    this.checkAlarm();
                }, 5000);
            }
        } else if (!isNew) {
            this.nextAlarm = 9990000000000;
        }

    }

    getTheme() {
        return this.get(ApiPersistence.KEY_THEME) ? this.get(ApiPersistence.KEY_THEME) : ApiPersistence.THEME_NORMAL
    }

    setTheme(data) {
        this.set(ApiPersistence.KEY_THEME, data)
    }

    isSameAsLastMessage(title, message) {
        var toReturn = false;
        var data = this.get(ApiPersistence.KEY_LASTMSG);

        if (data) {
            if (((data.time + 2000) > Date.now()) && data.title === title && data.msg === message) {
                toReturn = true;
            }
        }

        return toReturn;
    }

    setLastMessage(title, message) {
        var json = {
            time: Date.now(),
            title: title,
            msg: message
        }

        this.set(ApiPersistence.KEY_LASTMSG, json)
    }

    setRememberMe(email, password) {
        this.set(ApiPersistence.KEY_REMEMBERME, [email, password])
    }

    getRememberMe() {
        return this.get(ApiPersistence.KEY_REMEMBERME)
    }

    getUserManagementLastSearch() {
        let toReturn = this.get(ApiPersistence.KEY_USERSEARCH);

        if (toReturn === null || toReturn === undefined) {
            toReturn = {
                userType: -1,
                banned: false,
                kyc: false,
                searchText: '',
                sortedColumn: 1,
                sortedOrder: 0
            }
        }
        return toReturn;
    }

    setUserManagementLastSearch(userType, banned, kyc, searchText, sortedColumn, sortedOrder) {
        let toReturn = {
            userType: userType,
            banned: banned,
            kyc: kyc,
            searchText: searchText,
            sortedColumn: sortedColumn,
            sortedOrder: sortedOrder
        }

        this.set(ApiPersistence.KEY_USERSEARCH, toReturn);

    }




    setReminderAlarmMinutes(nbrMinutes) {
        this.set(ApiPersistence.KEY_REMINDERMINUTES, nbrMinutes)
    }

    getReminderAlarmMinutes() {
        return this.get(ApiPersistence.KEY_REMINDERMINUTES)
    }

    setLastPopupShowed(popupId) {
        this.set(ApiPersistence.KEY_LASTPOPUPSHOWED, popupId)
    }

    getLastPopupShowed() {
        return this.get(ApiPersistence.KEY_LASTPOPUPSHOWED) ? this.get(ApiPersistence.KEY_LASTPOPUPSHOWED) : 0
    }


    deleteRememberMe() {
        this.delete(ApiPersistence.KEY_REMEMBERME)
    }

    getReferralUser() {
        return this.get(ApiPersistence.KEY_REFFERRALUSER)
    }

    setReferralUser(data) {
        this.set(ApiPersistence.KEY_REFFERRALUSER, data)
    }

    deleteReferralUser() {
        this.delete(ApiPersistence.KEY_REFFERRALUSER)
    }

    deleteUser() {
        var toReturn = true;

        if (this.exist(ApiPersistence.KEY_USER)) {
            this.delete(ApiPersistence.KEY_USER)
        } else {
            toReturn = false;
        }

        return toReturn;
    }

    isLogged() {
        return (this.getUser().email.trim().length) > 0
    }

    getUserIdentification() {
        var toReturn = this.getUser().name ? this.getUser().name.trim() : this.getUser().email.trim();

        return toReturn;
    }

    getUser() {
        return this.get(ApiPersistence.KEY_USER) ? this.get(ApiPersistence.KEY_USER) : {
            dateCreate: constants.STRING_EMPTY,
            uniqueId: constants.STRING_EMPTY,
            rols: [],
            sid: constants.STRING_EMPTY,
            status: 0,
            idUser: constants.STRING_EMPTY,
            name: constants.STRING_EMPTY,
            role: constants.STRING_EMPTY,
            email: constants.STRING_EMPTY,
            avatar: constants.STRING_EMPTY,
            time: constants.STRING_EMPTY,
            authorized: false,
            loading: false,
            isPromoCode: false,
            promoCode: constants.STRING_EMPTY
        }
    }

    setUser(value) {
        this.set(ApiPersistence.KEY_USER, value);
    }

    setLanguage(value) {
        this.set(ApiPersistence.KEY_LANGUAGE, value);
    }

    getLanguage() {
        return this.get(ApiPersistence.KEY_LANGUAGE) ? this.get(ApiPersistence.KEY_LANGUAGE) : constants.DEFAULT_LANGUAGE;
    }

    set(key, value) {
        this.resultMap.set(key, value);
        this.persistData();
    }

    get(key) {
        return this.resultMap.get(key);
    }

    delete(key) {
        this.resultMap.delete(key);
        this.persistData();
    }

    exist(key) {
        return (this.resultMap.get(key) !== null && this.resultMap.get(key) !== undefined);
    }

    persistData() {
        set_storage(Array.from(this.resultMap), ApiPersistence.LOCALE_STORAGE_KEY);
    }

}