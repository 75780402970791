var CryptoJS = require('crypto-js')

function get_url() {
    return window.location.href.split('#/')[1].split('/')[0]
}

function gs() {
    let l =
        'Z^JZzsxyp%PD8yGwt=p$HK+-ddGyx9r$RGX63PPps_ccp3jv=qu2z$+VY@8B-ZCb4pD4p7m-P_d5+?rPa7SPjHY9KC$9QFQm&FKJe78nF-=2=9#zz9zJ9n_qr-urM?ka1'
    return l
}

export function get_storage(url) {

    var toReturn = localStorage.getItem(url)
    try {
        var temp = CryptoJS.AES.decrypt(toReturn, gs())
        temp = temp.toString(CryptoJS.enc.Utf8)
        temp = JSON.parse(temp)
        toReturn = temp;
    } catch (e) {
        toReturn = null;
    }

    return toReturn;
}

export function set_storage(data, url) {
    data = CryptoJS.AES.encrypt(JSON.stringify(data), gs()).toString()
    localStorage.setItem(url, data)
}

export function delete_storage(url, callback) {
    let _url = url !== undefined ? url : get_url()
    localStorage.removeItem(_url)
    if (typeof callback == 'function') callback()
}