function Tab(props) {

  try {
    return (
      props.children
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Tab

