/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import Button from 'newui/Generic/Inputs/Button';
import { displayGenericErrorNotificationIntl, displayInfoNotification } from 'newui/Utils/uiUtil';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import ApiPersistence from 'newui/Utils/ApiPersistence';
import { useIntl } from 'react-intl'

function ActivityCheck(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState(null)
    const [activeButtons, setActiveButtons] = useState(false);

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {
        jwtBackendService.getUserResponsibleGamblingInfo(successFetchLossLimitDataJWT, failureFetchLossLimitDataJWT, navigate, location.pathname);
    }

    function successFetchLossLimitDataJWT(data) {
        setLoaded(true);
        setData(data);
    }

    function failureFetchLossLimitDataJWT(data) {
        setLoaded(true);
    }


    function save(minutes) {
        var cloneData = Object.assign({}, data);
        cloneData.activity = minutes;
        jwtBackendService.updateUserResponsibleGamblingInfo(cloneData, successOnSave, failureOnSave, navigate, location.pathname);
    }

    function toggle() {
        setActiveButtons(!activeButtons);
    }

    function successOnSave(data) {
        ApiPersistence.getInstance().setReminderAlarmMinutes(data.alertIntervalMin);

        var msg = intl.formatMessage({ id: 'newcasino.rg.activity.check.success.1', defaultMessage: 'From now on, you will received an alert after ' }) + data.alertIntervalMin + intl.formatMessage({ id: 'newcasino.rg.activity.check.success.2', defaultMessage: ' minutes of playing' });

        displayInfoNotification('INFO', msg);

    }

    function failureOnSave(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    if (loaded) {
        return (
            <div id='contestWinnerPage' className={style.master_page_container}>
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.rg.activity.check.page.title"
                        defaultMessage="Activity Check"
                    />
                </div>
                <div className={style.mainSubTitle}>
                    <FormattedMessage
                        id="newcasino.rg.activity.check.page.subtitle"
                        defaultMessage='WE WANT YOU TO ENJOY PLAYING RESPONSIBLY ON OUR CASINO, WHICH IS WHY WE PROVIDE AN ACTIVITY CHECK FEATURE. IT WILL HELP YOU KEEP TRACK OF YOUR PLAYING TIME BY SENDING ADJUSTABLE NOTIFICATIONS THAT ACT AS YOUR PLAYER ALARM CLOCK, STARTING FROM THE MOMENT YOU LOG IN.'
                    />
                </div>
                <div className={style.mainDiv}>
                    <div className={style.elementBut}>
                        <Checkbox label={intl.formatMessage({ id: 'newcasino.rg.activity.check.page.checkbox', defaultMessage: 'By clicking here I understand that I\'m setting up an automated activity check' })} onClick={toggle} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.activity.check.page.30', defaultMessage: '30 Minutes' })} onClick={() => save(30)} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.activity.check.page.60', defaultMessage: '60 Minutes' })} onClick={() => save(60)} />
                    </div>
                    <div className={style.elementBut}>
                        <Button active={activeButtons} value={intl.formatMessage({ id: 'newcasino.rg.activity.check.page.120', defaultMessage: '120 Minutes' })} onClick={() => save(120)} />
                    </div>
                </div>

            </div >
        );
    }
}

export default ActivityCheck;
