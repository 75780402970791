/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import TextField from 'newui/Generic/Inputs/TextField';
import Button from 'newui/Generic/Inputs/Button';
import { useIntl } from 'react-intl'
import { displayGenericErrorNotificationIntl } from 'newui/Utils/uiUtil';

function LossLimit(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();
    const [loaded, setLoaded] = useState(false)
    const [refresh, setRefresh] = useState(Math.random())

    const [data, setData] = useState(null)
    const [lossDailyAmount, setLossDailyAmount] = useState(0)
    const [lossWeeklyAmount, setLossWeeklyAmount] = useState(0)
    const [lossMonthlyAmount, setLossMonthlyAmount] = useState(0)
    const [dailyOk, setDailyOk] = useState(1)
    const [weeklyOk, setWeeklyOk] = useState(1)
    const [monthlyOk, setMonthlyOk] = useState(1)

    useEffect(() => {
        fetchData()
    }, [])


    function fetchData() {
        jwtBackendService.getUserResponsibleGamblingInfo(successFetchLossLimitDataJWT, failureFetchLossLimitDataJWT, navigate, location.pathname);
    }

    function successFetchLossLimitDataJWT(data) {
        setLoaded(true);
        setData(data);
        setLossDailyAmount(data.lossDailyAmount);
        setLossWeeklyAmount(data.lossWeeklyAmount)
        setLossMonthlyAmount(data.lossMonthlyAmount);
        setDailyOk(data.lossDailyUpdatable);
        setWeeklyOk(data.lossWeeklyUpdatable)
        setMonthlyOk(data.lossMonthlyUpdatable);
    }

    function dailyAmountChange(newValue) {
        setLossDailyAmount(newValue);
    }

    function weeklyAmountChange(newValue) {
        setLossWeeklyAmount(newValue);
    }

    function monthlyAmountChange(newValue) {
        setLossMonthlyAmount(newValue);
    }


    function failureFetchLossLimitDataJWT(data) {
        setLoaded(true);
    }

    function save() {
        var cloneData = Object.assign({}, data);
        cloneData.lossDailyAmount = lossDailyAmount;
        cloneData.lossWeeklyAmount = lossWeeklyAmount;
        cloneData.lossMonthlyAmount = lossMonthlyAmount;
        jwtBackendService.updateUserResponsibleGamblingInfo(cloneData, successOnSave, failureOnSave, navigate, location.pathname);
    }

    function successOnSave(data) {
        setData(data);
        setLossDailyAmount(data.lossDailyAmount);
        setLossWeeklyAmount(data.lossWeeklyAmount)
        setLossMonthlyAmount(data.lossMonthlyAmount);
        setDailyOk(data.lossDailyUpdatable);
        setWeeklyOk(data.lossWeeklyUpdatable)
        setMonthlyOk(data.lossMonthlyUpdatable);
        setRefresh(Math.random());
    }

    function failureOnSave(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    if (loaded) {
        return (
            <div key={refresh} id='contestWinnerPage' className={style.master_page_container}>
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.rg.lost.limit.page.title"
                        defaultMessage="Loss Limit"
                    />
                </div>
                <div className={style.mainSubTitle}>
                    <FormattedMessage
                        id="newcasino.rg.lost.limit.page.subtitle"
                        defaultMessage="* Values ​​can only be changed once every 48 hours"
                    />
                </div>
                <div className={style.mainDiv}>
                    <div className={style.elementDiv}>
                        <div className={style.txt}><FormattedMessage id="newcasino.rg.lost.limit.page.daily" defaultMessage="Your current Daily Loss Limit is:" /></div>
                        <div className={style.field}><TextField key={'a' + refresh} readOnly={dailyOk === 0} type='digit' values={[lossDailyAmount]} onChange={dailyAmountChange} /></div>
                    </div>
                    <div className={style.elementDiv}>
                        <div className={style.txt}><FormattedMessage id="newcasino.rg.lost.limit.page.weekly" defaultMessage="Your current Weekly Loss Limit is:" /></div>
                        <div className={style.field}><TextField key={'b' + refresh} readOnly={weeklyOk === 0} type='digit' values={[lossWeeklyAmount]} onChange={weeklyAmountChange} /></div>
                    </div>
                    <div className={style.elementDiv}>
                        <div className={style.txt}><FormattedMessage id="newcasino.rg.lost.limit.page.monthly" defaultMessage="Your current Monthly Loss Limit is:" /></div>
                        <div className={style.field}><TextField key={'c' + refresh} readOnly={monthlyOk === 0} type='digit' values={[lossMonthlyAmount]} onChange={monthlyAmountChange} /></div>
                    </div>
                    <div className={style.elementBut}>
                        <Button width='200px' value={intl.formatMessage({ id: 'newcasino.rg.lost.limit.page.button', defaultMessage: 'Update' })} onClick={save} />
                    </div>
                </div>

            </div >
        );
    }
}

export default LossLimit;
