/* eslint-disable */
import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';

function HowCell(props) {

    return (
        <>
            <div className={style.splitInTwo}>
                <div className={style.columnOne}>
                    <img alt='' src={props.image} />
                </div>
                <div className={style.columnTwo}>
                    <div className={style.columnTwoTitle}>
                        {props.title}
                    </div>
                    <div className={style.columnTwoText}>
                        {props.txt}
                    </div>
                    {props.txtSmall &&
                        <div className={style.columnTwoTextSmall}>
                            {props.txtSmall}
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default HowCell;
