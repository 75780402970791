import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'

/*******************************************************************************************
* uploadGameImageFile
* Migration to new Sec Status: TODO
********************************************************************************************/
export function uploadGameImageFile(theImage, successCallbackFunction, failureCallbackFunction, navigate, location) {

        var formData = new FormData()
        var extension = theImage.name.substr(theImage.name.lastIndexOf('.')).toLowerCase();
        formData.append("document", theImage);
        formData.append("extension", extension);
        formData.append("type", theImage.type);

        jwtBackendService.uploadGameImageFile(formData, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* getManageGamesInformation
* Migration to new Sec Status: DONE
********************************************************************************************/
export function getManageGamesInformation(successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.getManageGamesInformation(successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* changeGameOrder
* Migration to new Sec Status: TODO
********************************************************************************************/
export function changeGameOrder(sourceId, targetId, successCallbackFunction, failureCallbackFunction, navigate, location) {
        jwtBackendService.changeGameOrder(sourceId, targetId, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* addModifyProvider
* Migration to new Sec Status: TODO
********************************************************************************************/
export function addModifyProvider(newProvider, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.addModifyProvider(newProvider, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* addModifyCategory
* Migration to new Sec Status: TODO
********************************************************************************************/
export function addModifyCategory(newCategory, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.addModifyCategory(newCategory, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* addModifyType
* Migration to new Sec Status: TODO
********************************************************************************************/
export function addModifyType(newType, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.addModifyType(newType, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* addModifySubType
* Migration to new Sec Status: TODO
********************************************************************************************/
export function addModifySubType(newSubType, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.addModifySubType(newSubType, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* addModifyGame
* Migration to new Sec Status: TODO
********************************************************************************************/
export function addModifyGame(newGame, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.addModifyGame(newGame, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* deleteGame
* Migration to new Sec Status: TODO
********************************************************************************************/
export function deleteGame(game, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.deleteGame(game, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* deleteProvider
* Migration to new Sec Status: TODO
********************************************************************************************/
export function deleteProvider(provider, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.deleteProvider(provider, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* deleteCategory
* Migration to new Sec Status: TODO
********************************************************************************************/
export function deleteCategory(category, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.deleteCategory(category, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* deleteType
* Migration to new Sec Status: TODO
********************************************************************************************/
export function deleteType(type, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.deleteType(type, successCallbackFunction, failureCallbackFunction, navigate, location)
}

/*******************************************************************************************
* deleteSubType
* Migration to new Sec Status: TODO
********************************************************************************************/
export function deleteSubType(subType, successCallbackFunction, failureCallbackFunction, navigate, location) {

        jwtBackendService.deleteSubType(subType, successCallbackFunction, failureCallbackFunction, navigate, location)
}


