import React, { useState } from 'react';

import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl, inNotMinusOne, isTextMin2Char } from 'newui/Utils/uiUtil';

import Button from 'newui/Generic/Inputs/Button';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import TextField from 'newui/Generic/Inputs/TextField';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import { convertTDateOnlyDate } from 'newui/Utils/converterUtil';
import SwitchStatus from '../SwitchStatus';

import * as constants from 'newui/constants.js'
import SwitchUserType from '../SwitchUserType';
import SwitchPhone from '../SwitchPhone';

function PersoInfo(props) {
    const [countries] = useState(props.countries);
    const [personalData] = useState(props.personalData);

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    const [ln, setLn] = useState(props.personalData.kycLastName);
    const [lnValid, setLnValid] = useState(isTextMin2Char(props.personalData.kycLastName));

    const [fn, setFn] = useState(props.personalData.kycFirstName);
    const [fnValid, setFnValid] = useState(isTextMin2Char(props.personalData.kycFirstName));

    const [address, setAddress] = useState(props.personalData.kycAddressLine1);
    const [addressValid, setAddressValid] = useState(isTextMin2Char(props.personalData.kycAddressLine1));

    const [country, setCountry] = useState(props.personalData.kycCountry);
    const [countryValid, setCountryValid] = useState(inNotMinusOne(props.personalData.kycCountry));

    const [state, setState] = useState(props.personalData.kycState);
    const [stateValid, setStateValid] = useState(isTextMin2Char(props.personalData.kycState));

    const [note, setNote] = useState(props.personalData.note);

    const [city, setCity] = useState(props.personalData.kycCity);
    const [cityValid, setCityValid] = useState(isTextMin2Char(props.personalData.kycCity));

    const [zipcode, setZipcode] = useState(props.personalData.kycZip);
    const [zipcodeValid, setZipcodeValid] = useState(isTextMin2Char(props.personalData.kycZip));

    function canUpdate() {
        return countryValid && addressValid && cityValid && stateValid && zipcodeValid;
    }

    function lnValidCallBack(isValid, value) {
        setLnValid(isValid);
        setLn(value)
    }

    function fnValidCallBack(isValid, value) {
        setFnValid(isValid);
        setFn(value)
    }

    function addressValidCallBack(isValid, value) {
        setAddressValid(isValid);
        setAddress(value)
    }

    function countryValidCallBack(isValid, value) {
        setCountryValid(isValid);
        setCountry(value);
    }

    function cityValidCallBack(isValid, value) {
        setCityValid(isValid);
        setCity(value);
    }

    function zipcodeValidCallBack(isValid, value) {
        setZipcodeValid(isValid);
        setZipcode(value);
    }


    function stateValidCallBack(isValid, value) {
        setStateValid(isValid);
        setState(value);
    }

    function updateInfo() {
        var data = {
            LastName: ln,
            FirstName: fn,
            AddressLine1Profile: address,
            UserId: props.personalData.id,
            CityProfile: city,
            StateProfile: state,
            CountryProfile: country,
            ZipProfile: zipcode,
            Note: note
        }

        jwtBackendService.updateUserPersonalData(data, successUpdatePersonalDataJWT, failureUpdatePersonalDataJWT, navigate, location.pathname);
    }

    function successUpdatePersonalDataJWT(data) {
        if (data.result === 'OK') {
            displayInfoNotificationIntl(intl, 'newcasino.admin.user.update.success')
            props.reload()
        } else {
            displayGenericErrorNotificationIntl(intl)
        }
    }

    function failureUpdatePersonalDataJWT(data) {
        displayGenericErrorNotificationIntl(intl)
    }

    function getUserStatus() {
        let toReturn = intl.formatMessage({ id: 'newcasino.user.address.ustatus.unknown', defaultMessage: 'Unknown' });

        if (personalData.blockedUntil !== null) {
            toReturn = convertTDateOnlyDate(personalData.blockedUntil);
        } else if (personalData.status === 1) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.ustatus.active', defaultMessage: 'Active' });
        } else if (personalData.status === 0) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.ustatus.inactive', defaultMessage: 'Inactive' });
        }

        return toReturn;
    }

    function getPhoneStatus() {
        let toReturn = intl.formatMessage({ id: 'newcasino.user.address.phonestatus.unknown', defaultMessage: 'Unknown' });

        if ((personalData.phoneVerify_phone === '' || personalData.phoneVerify_phone === null) && personalData.phoneVerify_retries >= 3) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.phonestatus.failed3x', defaultMessage: 'Failed 3x' });
        } else if (personalData.phoneVerify_phone === '' || personalData.phoneVerify_phone === null) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.phonestatus.empty', defaultMessage: 'No Phone' });
        } else
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.phonestatus.verif', defaultMessage: 'Verified' });

        return toReturn;
    }

    function updatePhoneStatus() {
        const theMsg = {
            component: <SwitchPhone key={Math.random()} reload={props.reload} personalData={props.personalData} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function updateStatus() {
        const theMsg = {
            component: <SwitchStatus key={Math.random()} reload={props.reload} personalData={props.personalData} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function getUserType() {
        let toReturn = intl.formatMessage({ id: 'newcasino.user.address.utype.unknown', defaultMessage: 'Unknown' });

        if (personalData.typeAccount === 1) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.utype.player', defaultMessage: 'Player' });
        } else if (personalData.typeAccount === 4) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.utype.admin', defaultMessage: 'Admin' });
        } else if (personalData.typeAccount === 5) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.utype.streamer', defaultMessage: 'Streamer' })
        } else if (personalData.typeAccount === 3) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.utype.partner', defaultMessage: 'Partner' })
        }

        return toReturn;
    }

    function updateUserType() {
        const theMsg = {
            component: <SwitchUserType key={Math.random()} reload={props.reload} personalData={props.personalData} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function setNoteValue(t) {
        setNote(t);
    }

    function getKYCStatus() {
        let toReturn = intl.formatMessage({ id: 'newcasino.user.address.kyc.unknown', defaultMessage: 'Unknown' });
        if (personalData.is_full_kyc_done === 0) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.kyc.nophoto', defaultMessage: 'No Photo' });
        } else if (personalData.is_full_kyc_done === 1) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.kyc.approved', defaultMessage: 'Approved' });
        } else if (personalData.is_full_kyc_done === -1) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.kyc.rejected', defaultMessage: 'Rejected' });
        } else if (personalData.is_full_kyc_done === 2) {
            toReturn = intl.formatMessage({ id: 'newcasino.user.address.kyc.pending', defaultMessage: 'Pending' });
        }

        return toReturn;
    }

    return (
        <>
            <div className={style.desktop4CellIcon}>
                <Button label='User Type' value={getUserType()} onClick={() => updateUserType()} />
                <Button label='Status' value={getUserStatus()} onClick={() => updateStatus()} />
                <Button label='KYC Status' width='160px' iconLarge={true} iconSize={2} icon='fa fa-address-card-o' value={getKYCStatus()} />
                <Button label='Phone Status' iconSize={2} icon='fa fa-mobile' value={getPhoneStatus()} onClick={() => updatePhoneStatus()} />
            </div>
            <div className={style.desktop4Cell}>
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.address.id.label', defaultMessage: 'Id' })} readOnly={true} values={[personalData.id]} />
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.address.sponsorId.label', defaultMessage: 'Sponsor Id' })} readOnly={true} values={[personalData.sponsorId ? personalData.sponsorId : '-']} />
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.address.regdate.label', defaultMessage: 'Registration Date' })} readOnly={true} values={[convertTDateOnlyDate(personalData.dateCreate)]} />
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.address.currency.label', defaultMessage: 'Currency' })} readOnly={true} values={[personalData.coin]} />
            </div>
            <div className={style.desktop2Cell}>
                <TextField maxLength='100' autofocus='true' values={[ln, lnValid]} hint={intl.formatMessage({ id: 'newcasino.user.address.address.ln.hint', defaultMessage: 'Type Last Name' })} label={intl.formatMessage({ id: 'newcasino.user.address.address.ln.label', defaultMessage: 'Last Name' })} validator={isTextMin2Char} validatorInformer={lnValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.user.address.address.ln.error', defaultMessage: 'The last name is too short' })} />
                <TextField maxLength='100' autofocus='true' values={[fn, fnValid]} hint={intl.formatMessage({ id: 'newcasino.user.address.address.fn.hint', defaultMessage: 'Type First Name' })} label={intl.formatMessage({ id: 'newcasino.user.address.address.fn.label', defaultMessage: 'First Name' })} validator={isTextMin2Char} validatorInformer={fnValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.user.address.address.fn.error', defaultMessage: 'The first name is too short' })} />
            </div>
            <div className={style.desktop3Cell}>
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.address.email.label', defaultMessage: 'Email' })} readOnly={true} values={[personalData.email]} />
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.address.dob.label', defaultMessage: 'Date of birth' })} readOnly={true} values={[convertTDateOnlyDate(personalData.kycDateofBirth)]} />
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.address.phone.label', defaultMessage: 'Phone Number' })} readOnly={true} values={[personalData.phoneVerify_phone]} />
            </div>
            <div className={style.desktop2Cell}>
                <TextField maxLength='100' autofocus='true' values={[address, addressValid]} hint={intl.formatMessage({ id: 'newcasino.admin.user.address.address.hint', defaultMessage: 'Type address here' })} label={intl.formatMessage({ id: 'newcasino.admin.user.address.address.label', defaultMessage: 'Address' })} validator={isTextMin2Char} validatorInformer={addressValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.admin.user.address.address.error', defaultMessage: 'Address is too short' })} />
                <TextField maxLength='100' values={[city, cityValid]} hint={intl.formatMessage({ id: 'newcasino.admin.user.address.city.hint', defaultMessage: 'Type city here' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.admin.user.address.city.label', defaultMessage: 'City' })} validator={isTextMin2Char} showTitleError='true' showCheck='true' validatorInformer={cityValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.admin.user.address.city.error', defaultMessage: 'City is too short' })} />
            </div>
            <div className={style.desktop3Cell}>
                <TextField maxLength='100' autofocus='true' values={[state, stateValid]} hint={intl.formatMessage({ id: 'newcasino.admin.user.address.state.hint', defaultMessage: 'Type State here' })} label={intl.formatMessage({ id: 'newcasino.admin.user.address.state.label', defaultMessage: 'State/Province' })} validator={isTextMin2Char} validatorInformer={stateValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.admin.user.address.state.error', defaultMessage: 'State is too short' })} />
                <ComboBox autofocus='true' values={[country, countryValid]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.admin.user.address.country.hint', defaultMessage: 'Select country...' }) }} valueList={{ list: countries, key: 'id', value: 'name' }} hint={intl.formatMessage({ id: 'newcasino.admin.user.address.country.hint', defaultMessage: 'Country' })} label={intl.formatMessage({ id: 'newcasino.admin.user.address.country.label', defaultMessage: 'Country' })} showCheck='true' validator={inNotMinusOne} validatorInformer={countryValidCallBack} mandatory='true' />
                <TextField maxLength='100' uppercase={true} values={[zipcode, zipcodeValid]} hint={intl.formatMessage({ id: 'newcasino.admin.user.address.zip.hint', defaultMessage: 'Postal Code' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.admin.user.address.zip.label', defaultMessage: 'Postal Code' })} validator={isTextMin2Char} showTitleError='false' showCheck='true' validatorInformer={zipcodeValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.admin.user.address.zip.error', defaultMessage: 'Postal Code is too short' })} />
            </div>
            <div className={style.desktop1Cell}>
                <TextField maxLength='512' autofocus='false' type='textarea' values={[note]} onChange={setNoteValue} hint={intl.formatMessage({ id: 'newcasino.admin.user.note.hint', defaultMessage: 'Type Note here' })} label={intl.formatMessage({ id: 'newcasino.admin.user.note.label', defaultMessage: 'Note' })} />
            </div>
            <div className={style.buttCenter}>
                <Button active={canUpdate()} value={intl.formatMessage({ id: 'newcasino.admin.user.button', defaultMessage: 'Update' })} onClick={updateInfo} />
            </div>
        </>
    );
}

export default PersoInfo;