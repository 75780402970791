/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as backendService from 'newui/Services/Backend/backendService';
import TextField from 'newui/Generic/Inputs/TextField';
import BirthDay from 'newui/Generic/Inputs/Birthday';
import { displayErrorNotification, displayInfoNotification, isDateDDMMYYYY, isTextMin2Char } from 'newui/Utils/uiUtil';
import Button from 'newui/Generic/Inputs/Button';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as constants from 'newui/constants.js'
import { displayPage } from 'newui/Utils/routerUtil';
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import TabGroup from 'newui/Generic/Inputs/TabGroup';
import Tab from 'newui/Generic/Inputs/TabGroup/Tab';
import { setTheme } from 'newui/Utils/themeUtils';
import { getImageUrl } from 'newui/Utils/uiUtil';

function EditInfoPopup(props) {
    const DEFAULT_HEIGHT = '100%';
    const TAB_ENGLISH = 'english'
    const TAB_FRENCH = 'french'
    const TAB_PORTUGUESE = 'portuguese'

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [popup, setPopup] = useState();

    const [tabGroupKey, setTabGroupKey] = useState(Math.random());
    const [tabSelected, setTabSelected] = useState(TAB_ENGLISH);
    const [resetTabHeader, setResetTabHeader] = useState(Math.random());


    const [genHtml, setGenHtml] = useState(false);
    const [genActive, setGenActive] = useState(false);
    const [genStartDate, setGenStartDate] = useState(constants.STRING_EMPTY);
    const [genStartDateValid, setGenStartDateValid] = useState(false);
    const [genEndDate, setGenEndDate] = useState(constants.STRING_EMPTY);
    const [genEndDateValid, setGenEndDateValid] = useState(false);


    const [genInternalName, setGenInternalName] = useState(constants.STRING_EMPTY);
    const [genInternalNameValid, setGenInternalNameValid] = useState(false);

    const [genTitle, setGenTitle] = useState(constants.STRING_EMPTY);
    const [genTitleValid, setGenTitleValid] = useState(false);
    const [genTitleFr, setGenTitleFr] = useState(constants.STRING_EMPTY);
    const [genTitleFrValid, setGenTitleFrValid] = useState(false);
    const [genTitlePt, setGenTitlePt] = useState(constants.STRING_EMPTY);
    const [genTitlePtValid, setGenTitlePtValid] = useState(false);

    const [genBody, setGenBody] = useState(constants.STRING_EMPTY);
    const [genBodyValid, setGenBodyValid] = useState(false);
    const [genBodyFr, setGenBodyFr] = useState(constants.STRING_EMPTY);
    const [genBodyFrValid, setGenBodyFrValid] = useState(false);
    const [genBodyPt, setGenBodyPt] = useState(constants.STRING_EMPTY);
    const [genBodyPtValid, setGenBodyPtValid] = useState(false);

    const [genReceiver, setGenReceiver] = useState(constants.STRING_EMPTY);
    const [genReceiverValid, setGenReceiverValid] = useState(false);

    const [genHeight, setGenHeight] = useState(DEFAULT_HEIGHT);
    const [genHeightValid, setGenHeightValid] = useState(false);


    const isNew = (params.id === undefined || params.id === null || params.id === '-1' || params.id === -1 || params.id === 'NEW')
    const intl = useIntl();

    const receiverList = [
        {
            key: '99',
            value: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.all', defaultMessage: 'Everybody' })
        },
        {
            key: '98',
            value: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.logged', defaultMessage: 'All logged users' })
        },
        {
            key: '1',
            value: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.players', defaultMessage: 'Players' })
        },
        {
            key: '5',
            value: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.streamers', defaultMessage: 'Streamers' })
        },
        {
            key: '2',
            value: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.affiliates', defaultMessage: 'Affiliates' })
        },
        {
            key: '3',
            value: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.partners', defaultMessage: 'Partners' })
        },
        {
            key: '4',
            value: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.admin', defaultMessage: 'Admin' })
        }
    ];

    useEffect(() => {
        if (!isNew) {
            let id = params.id
            backendService.getPopupData(id, successGetData, failureGetData, navigate, location.pathname)
        } else {
            setPopup({
                id: -1,
                receiver: -1,
                height: DEFAULT_HEIGHT,
                from: constants.STRING_EMPTY,
                to: constants.STRING_EMPTY,
                internalName: constants.STRING_EMPTY,
                title: { en: constants.STRING_EMPTY, fr: constants.STRING_EMPTY, pt: constants.STRING_EMPTY },
                body: { en: constants.STRING_EMPTY, fr: constants.STRING_EMPTY, pt: constants.STRING_EMPTY },
                html: false,
                active: false,
            })
        }
    }, []);

    function successGetData(data) {

        if (data.result !== 'OK') {
            failureGetData(data.data);
            return;
        }
        data = data.data;


        setGenReceiver(data.receiver);
        if (data.height.endsWith('px')) {
            setGenHeight(data.height.substring(0, (data.height.length - 2)))
        } else {
            setGenHeight(data.height)
        }

        setGenStartDate(data.from)
        setGenEndDate(data.to);
        setGenInternalName(data.internalName);
        setGenTitle(data.title.en);
        setGenTitleFr(data.title.fr);
        setGenTitlePt(data.title.pt);
        setGenBody(data.body.en)
        setGenBodyFr(data.body.fr);
        setGenBodyPt(data.body.pt);
        setGenHtml(data.html);
        setGenActive(data.active);
        setPopup(data);
    }

    function isDateFromValid(isValid, value) {
        setGenStartDateValid(isValid);
        setGenStartDate(value);
    }

    function isDateToValid(isValid, value) {
        setGenEndDateValid(isValid);
        setGenEndDate(value);
    }

    function isInternalNameValid(isValid, value) {
        setGenInternalNameValid(isValid);
        setGenInternalName(value);
    }

    function isTitleValid(isValid, value) {
        setGenTitleValid(isValid);
        setGenTitle(value);
    }

    function isTitleFrValid(isValid, value) {
        setGenTitleFrValid(isValid);
        setGenTitleFr(value);
    }

    function isTitlePtValid(isValid, value) {
        setGenTitlePtValid(isValid);
        setGenTitlePt(value);
    }

    function isBodyValid(isValid, value) {
        setGenBodyValid(isValid);
        setGenBody(value);
    }

    function isBodyFrValid(isValid, value) {
        setGenBodyFrValid(isValid);
        setGenBodyFr(value);
    }

    function isBodyPtValid(isValid, value) {
        setGenBodyPtValid(isValid);
        setGenBodyPt(value);
    }

    function isHeightValid(isValid, value) {
        setGenHeightValid(isValid);

        if (value.trim().length === 0 || value.trim().endsWith('%')) {
            setGenHeight('100%');
        } else {
            setGenHeight(value + 'px');
        }
    }

    function isReceiverValid(isValid, value) {
        setGenReceiverValid(isValid);
        setGenReceiver(value);
    }


    function setIsHtml(value) {
        setGenHtml(value)
        setTabGroupKey(Math.random())
    }

    function setIsActive(value) {
        setGenActive(value)
    }

    function failureGetData(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.fetching.popup', defaultMessage: 'Error trying to get popup information' }));
    }

    function deletePopup() {
        var popupClone = JSON.parse(JSON.stringify(popup));
        backendService.updatePopup(true, popupClone, successDelete, failureDelete, navigate, location.pathname);
    }

    function successDelete() {
        displayPage(constants.ROUTE_INFO_POPUP, navigate)
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.success.delete.popup', defaultMessage: 'Popup deleted' }));
    }

    function failureDelete() {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.delete.popup', defaultMessage: 'Operation Failed' }));
    }

    function save() {

        var popupClone = JSON.parse(JSON.stringify(popup));

        popupClone.receiver = genReceiver;
        popupClone.html = genHtml;
        popupClone.active = genActive;
        popupClone.height = genHeight;
        popupClone.from = genStartDate;
        popupClone.to = genEndDate;
        popupClone.internalName = genInternalName;
        popupClone.title.en = genTitle;
        popupClone.title.fr = genTitleFr;
        popupClone.title.pt = genTitlePt;
        popupClone.body.en = genBody;
        popupClone.body.fr = genBodyFr;
        popupClone.body.pt = genBodyPt;

        backendService.updatePopup(false, popupClone, successSave, failureSave, navigate, location.pathname);
    }

    function successSave(data) {
        displayPage(constants.ROUTE_INFO_POPUP, navigate)
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.success.save.popup', defaultMessage: 'Popup saved' }));
    }

    function failureSave(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.save.popup', defaultMessage: 'Operation Failed' }));
    }

    function closeSample() {
        if (props.closeModal) {
            props.closeModal();
        }
    }

    function testIt() {
        if (props.displayModalMsg) {

            var theTitle = genTitle;
            var theBody = genBody;
            if (tabSelected === TAB_FRENCH) {
                theTitle = genTitleFr;
                theBody = genBodyFr;
            }
            else if (tabSelected === TAB_PORTUGUESE) {
                theTitle = genTitlePt;
                theBody = genBodyPt;
            }

            if (genHtml) {
                props.displayModalMsg(true, '', { height: genHeight, html: theBody }, null, closeSample);
            } else {
                props.displayModalMsg(false, theTitle, theBody, null, closeSample);
            }
        }
    }

    function inNotMinusOne(value) {
        return value !== '-1';
    }

    function isFormValid() {
        var toReturn = genStartDateValid && genEndDateValid && genReceiverValid && genInternalNameValid && genBodyValid && genBodyFrValid;

        if (toReturn) {
            if (!genHtml) {
                toReturn = (genTitleValid && genTitleFrValid && genTitlePtValid);
            } else {
                toReturn = genHeightValid
            }
        }

        return toReturn;
    }

    function onChangeTab(internalId) {
        setTabSelected(internalId)
        setTabGroupKey(Math.random())
    }

    if (popup) {
        return (
            <>
                <div className={style.master_page_container}>

                    <div className={style.topMainDiv}>
                        <div>
                            <div className={style.grid100}>
                                <div className={style.case1TopText2}>{genInternalName ? genInternalName : intl.formatMessage({ id: 'newcasino.popups.admin.detail.header.title.missing', defaultMessage: '???' })}</div>
                                <div className={style.case1TopText3}>
                                    <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.grid25_25_25_auto}>
                                    <BirthDay validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.date.start', defaultMessage: 'Start Date' })} validator={isDateDDMMYYYY} validatorInformer={isDateFromValid} values={genStartDate ? [genStartDate] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.date.start.hint', defaultMessage: 'Ex: dd/mm/yyyy' })} />
                                    <BirthDay validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.date.end', defaultMessage: 'End Date' })} validator={isDateDDMMYYYY} validatorInformer={isDateToValid} values={genEndDate ? [genEndDate] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.date.end.hint', defaultMessage: 'Ex: dd/mm/yyyy' })} />
                                    <ComboBox validateOnStart={!isNew} autofocus='true' values={[genReceiver]} selectLabel={{ value: '-1', label: intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.hint', defaultMessage: 'Select receiver...' }) }} valueList={{ list: receiverList, key: 'key', value: 'value' }} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.hint', defaultMessage: 'Select receiver...' })} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.receiver.label', defaultMessage: 'Receiver' })} showCheck='true' validator={inNotMinusOne} validatorInformer={isReceiverValid} mandatory='true' />
                                    <Checkbox validateOnStart={!isNew} marginTop='24px' label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.active', defaultMessage: 'Active' })} checked={genActive} onClick={setIsActive} />
                                </div>

                                <div className={style.grid50_50}>
                                    <TextField maxLength='100' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.internal.name', defaultMessage: 'Internal Name' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isInternalNameValid} values={genInternalName ? [genInternalName] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.internal.name.hint', defaultMessage: 'Type the Internal Name' })} />
                                    <div />
                                </div>
                                <div className={style.case1TopText4}>
                                    <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.case1TopText5}>{intl.formatMessage({ id: 'newcasino.popups.admin.detail.header.title.popup', defaultMessage: 'Popup editor' })}</div>

                                <div className={style.grid50_50_a}>
                                    <div className={style.grid50_50_c}>
                                        <Checkbox validateOnStart={!isNew} marginTop='24px' label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.html', defaultMessage: 'Html' })} checked={genHtml} onClick={setIsHtml} />
                                        {genHtml &&
                                            <TextField type='digit' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.height', defaultMessage: 'height (px)' })} mandatory='false' showCheck='true' validator={isTextMin2Char} validatorInformer={isHeightValid} values={genHeight ? [genHeight] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.height.hint', defaultMessage: 'Type the height' })} />
                                        }
                                    </div>
                                </div>
                                <div className={style.tabGroup}>
                                    <TabGroup tabsValidation={[(genHtml ? (genBodyValid) : (genBodyValid && genTitleValid)), (genHtml ? (genBodyFrValid) : (genBodyFrValid && genTitleFrValid)), (genHtml ? (genBodyPtValid) : (genBodyPtValid && genTitlePtValid))]} key={tabGroupKey} resetHeader={resetTabHeader} value={tabSelected} onChangeTab={onChangeTab}>
                                        <Tab label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.tab.english', defaultMessage: 'English' })} internalId={TAB_ENGLISH}>
                                            {/*ENGLISH */}
                                            {!genHtml &&
                                                <div className={style.grid50_50}>
                                                    <TextField maxLength='100' key='title_english' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.title.en', defaultMessage: 'Title English' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitleValid} values={genTitle ? [genTitle] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.title.en.hint', defaultMessage: 'Type the title in English' })} />
                                                    <div />
                                                </div>
                                            }
                                            <div className={style.grid100}>
                                                <TextField maxLength='9999' key='body_english' validateOnStart={!isNew} rows='15' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBodyValid} values={genBody ? [genBody] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.body.en', defaultMessage: 'Content in English' })} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.body.en.hint', defaultMessage: 'Type the content in English' })} />
                                            </div>
                                        </Tab>
                                        <Tab label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.tab.french', defaultMessage: 'French' })} internalId={TAB_FRENCH}>
                                            {/*FRENCH */}
                                            {!genHtml &&
                                                <div className={style.grid50_50}>
                                                    <TextField maxLength='100' key='title_french' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.title.fr', defaultMessage: 'Title French' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitleFrValid} values={genTitleFr ? [genTitleFr] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.title.fr.hint', defaultMessage: 'Type the title in French' })} />
                                                    <div />
                                                </div>
                                            }
                                            <div className={style.grid100}>
                                                <TextField maxLength='9999' key='body_french' validateOnStart={!isNew} rows='15' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBodyFrValid} values={genBodyFr ? [genBodyFr] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.body.fr', defaultMessage: 'Content in French' })} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.body.fr.hint', defaultMessage: 'Type the content in French' })} />
                                            </div>
                                            {/*END */}
                                        </Tab>
                                        <Tab label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.tab.portuguese', defaultMessage: 'Portuguese' })} internalId={TAB_PORTUGUESE}>
                                            {/*PORTUGUESE */}
                                            {!genHtml &&
                                                <div className={style.grid50_50}>
                                                    <TextField maxLength='100' key='title_portuguese' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.title.pt', defaultMessage: 'Title Portuguese' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitlePtValid} values={genTitlePt ? [genTitlePt] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.title.pt.hint', defaultMessage: 'Type the title in Portuguese' })} />
                                                    <div />
                                                </div>
                                            }
                                            <div className={style.grid100}>
                                                <TextField maxLength='9999' key='body_portuguese' validateOnStart={!isNew} rows='15' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBodyPtValid} values={genBodyPt ? [genBodyPt] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.popups.admin.detail.body.pt', defaultMessage: 'Content in Portuguese' })} hint={intl.formatMessage({ id: 'newcasino.popups.admin.detail.body.pt.hint', defaultMessage: 'Type the content in Portuguese' })} />
                                            </div>
                                            {/*END */}
                                        </Tab>
                                    </TabGroup>
                                </div>
                                <div className={style.grid100}>
                                    <Button colorStyle='light' width='120px' value={intl.formatMessage({ id: 'newcasino.popups.admin.detail.button.test', defaultMessage: 'Test it' })} marginTop='24px' onClick={testIt} />
                                </div>
                                <div className={style.grid50_50}>
                                    <div className={style.grid50_50}>
                                        <Button colorStyle='light' active={isFormValid()} width='120px' value={intl.formatMessage({ id: 'newcasino.popups.admin.detail.button.save', defaultMessage: 'Save' })} marginTop='24px' onClick={save} />
                                        {!isNew &&
                                            <Button colorStyle='dark' active={!isNew} width='100px' value={intl.formatMessage({ id: 'newcasino.popups.admin.detail.button.delete', defaultMessage: 'Delete' })} marginTop='24px' onClick={deletePopup} />
                                        }
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
export default EditInfoPopup;