import * as constants from 'newui/constants.js'

export function displayPage(path, navigate, theCurrentRoute) {
    var askedRoute = path;
    var currentRoute = theCurrentRoute ? theCurrentRoute : '';

    if (askedRoute.toUpperCase().startsWith('HTTP')) {
        window.open(askedRoute, '_blank').focus();

    }
    else if (askedRoute === 'EVENT_440') {
        navigate(constants.ROUTE_440);
    } else if (askedRoute === 'EVENT_LOGOUT') {
        navigate(constants.ROUTE_USERLOGOUT);
    } else {
        if (askedRoute.startsWith(constants.ROUTE_PLAY_GAME_BASE)) {
            if (currentRoute.startsWith(constants.ROUTE_PLAY_GAME_BASE)) {
                var newRoute = askedRoute.substring(constants.ROUTE_PLAY_GAME_BASE.length);
                newRoute = constants.ROUTE_PLAY_GAME_BACKUP_BASE + newRoute;
                askedRoute = newRoute;
            }
        }

        if (askedRoute === constants.ROUTE_REGISTER) {
            if (currentRoute === constants.ROUTE_REGISTER) {
                askedRoute = constants.ROUTE_REGISTER_BACKUP;
            } else {
                askedRoute = constants.ROUTE_REGISTER;
            }
        }
        else if (askedRoute === constants.ROUTE_VERIFICATION) {
            if (currentRoute === constants.ROUTE_VERIFICATION) {
                askedRoute = constants.ROUTE_VERIFICATION_BACKUP;
            } else {
                askedRoute = constants.ROUTE_VERIFICATION;
            }
        }

        else if (askedRoute === constants.ROUTE_FORGOT_PASSWORD) {
            if (currentRoute === constants.ROUTE_FORGOT_PASSWORD) {
                askedRoute = constants.ROUTE_FORGOT_PASSWORD_BACKUP;
            } else {
                askedRoute = constants.ROUTE_FORGOT_PASSWORD;
            }
        }

        else if (askedRoute === constants.ROUTE_LOGIN) {
            if (currentRoute === constants.ROUTE_LOGIN) {
                askedRoute = constants.ROUTE_LOGIN_BACKUP;
            } else {
                askedRoute = constants.ROUTE_LOGIN;
            }
        }

        else if (askedRoute === constants.ROUTE_HOME) {
            if (currentRoute === constants.ROUTE_HOME) {
                askedRoute = constants.ROUTE_HOME_BACKUP;
            } else {
                askedRoute = constants.ROUTE_HOME;
            }
        }
        navigate(askedRoute);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}