/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import DivClick from 'newui/Generic/Inputs/DivClick';

function GiftConfirmation(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    try {
        return (
            <>
                <div className={style.mainDiv}>
                    <div className={style.headerTitle}>
                        {intl.formatMessage({ id: 'newcasino.loyalty.gift.confirmation.title', defaultMessage: 'Congratulations!' })}
                    </div>
                    <div>
                        <DivClick className={style.closeModal}><i className="fa fa-times fa-2x" aria-hidden="true" onClick={props.closeModal}></i></DivClick>
                    </div>
                </div>
                <div className={style.alignMiddle1}><img alt='' src='https://playnumbersgame.com/images/general/loyalty/IMG_Chips.png' /></div>
                <div className={style.alignMiddle2}>{props.txt}</div>
                {props.imageTxt &&
                    <div className={style.alignMiddle3}>{props.imageTxt}</div>
                }
                {props.image &&
                    <div className={style.alignMiddle2}><img className={style.imgBottom} alt='' src={'https://playnumbersgame.com/images/general/loyalty/' + props.image} /></div>
                }
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default GiftConfirmation;
