import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { getImageUrl } from 'newui/Utils/uiUtil';

function AboutUs(props) {

    return (
        <div className={style.master_page_container}>
            {props.header !== false &&
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.tochange"
                        defaultMessage="About Us"
                    />
                </div>
            }
            <div className={style.mainDiv}>
                <img src={getImageUrl("newui/general/img-mission@2x.png")} width="40px" alt='' />
                <div className={style.header2}>WHO WE ARE</div>

                <img src={getImageUrl("newui/general/sep@2x.png")} width="150px" alt='' />


                <div className={style.parag}>
                    We are Numbers Game, deeply inhabited by uncompromising
                    freedom, we believe in free will and in the relevance of
                    everyone's decisions to have their life as they see fit.
                </div>
                <div className={style.parag}>
                    You are entering the world of back rooms and speakeasies. A
                    journey back in time when everything had to be built,
                    everything was possible and you could find everything on the
                    street. Offering games in this context is to earn your trust,
                    our word of honor is to offer the best rates of return to
                    players available in a fun environment anchored in the future.
                </div>
                <div className={style.parag}>
                    Discretion and trust are the values that elevate us. Place
                    your bets here in cryptocurrencies or with the method of
                    deposit you prefer and enjoy your journey
                </div>
                <div className={style.parag}>We are Numbers Game!</div>
            </div>

        </div >
    );
}

export default AboutUs;
