import React from 'react'
import style from './style.module.scss'

/*
ValidationCheck : Display an input box

PARAMS: check (boolean)       
        display (boolean)
        opaque (boolean)
*/
function ValidationCheck(props) {

  try {
    return (
      <div className={(props.opaque === true) ? style.opaque : ''}>
        {((props.display === true) && (props.check === true)) &&
          <div className={style.check}>
            <i className="fa fa-check fa-2x" aria-hidden="true"></i>
          </div>
        }
        {((props.display === true) && (props.check === false)) &&
          <div className={style.checkNo}>
            <i className="fa fa-times fa-2x" aria-hidden="true"></i>
          </div>
        }
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ValidationCheck

