import React from 'react';

function BankAccount(props) {

    return (
        <>
            BANK ACCOUNT
        </>
    );

}

export default BankAccount;
