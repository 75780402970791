/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import DivClick from 'newui/Generic/Inputs/DivClick';
import * as constants from 'newui/constants.js'
import { displayPage } from 'newui/Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom';
import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper'
import ApiPersistence from 'newui/Utils/ApiPersistence';
import { getCashierCSS, isMobile } from 'newui/Utils/uiUtil';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import MemoryList from 'newui/Generic/Inputs/MemoryList';
import moment from 'moment';

const uuid = require('uuid');

function TxHistory(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [data] = useState(props.data);

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.cashier.history.col1', defaultMessage: 'Date' }), width: '20%', listColumn: 'created', canSort: true, transformer: dateTransformer },
        { name: intl.formatMessage({ id: 'newcasino.cashier.history.col2', defaultMessage: 'Transaction Type' }), width: '20%', listColumn: 'txType', canSort: true, transformer: typeTransformer },
        { name: intl.formatMessage({ id: 'newcasino.cashier.history.col3', defaultMessage: 'Method' }), width: '20%', listColumn: 'pspService', canSort: true },
        { name: intl.formatMessage({ id: 'newcasino.cashier.history.col4', defaultMessage: 'Amount' }), width: '20%', listColumn: 'txAmount', canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.cashier.history.col5', defaultMessage: 'Status' }), width: '20%', listColumn: 'state', canSort: true, transformerHTML: statusTransformer, hideLabelOnMobile: true },
    ];

    function typeTransformer(col, dat) {

        if (col.toUpperCase().indexOf('DEPOSIT') !== -1) {
            return intl.formatMessage({ id: 'newcasino.cashier.history.tx.deposit', defaultMessage: 'Deposit' })
        }

        //To validate
        if (col.toUpperCase().indexOf('WITHDRAWAL') !== -1) {
            return intl.formatMessage({ id: 'newcasino.cashier.history.tx.withdrawal', defaultMessage: 'Withdrawal' })
        }

        return col;
    }


    function dateTransformer(data, colDef) {
        let toReturn = data;
        try {
            var date = moment.utc(data);//.format('YYYY-MM-DD HH:mm:ss');
            toReturn = date.local().format('YYYY/MM/DD HH:mm');
        } catch (erro) {
            console.log(erro.message);
        }
        //2024-05-01 18:02:03

        return toReturn;
    }

    function statusTransformer(data, colDef) {
        let col = data[colDef.listColumn]
        if (col === 'WAITING_INPUT' || col === 'WAITING_APPROVAL') {
            return <div className={style.txPending}>{intl.formatMessage({ id: 'newcasino.cashier.history.tx.pending', defaultMessage: 'Pending' })}</div>
        }

        //To validate
        if (col === 'CANCELLED') {
            return <div className={style.txCancelled}>{intl.formatMessage({ id: 'newcasino.cashier.history.tx.cancelled', defaultMessage: 'Cancelled' })}</div>
        }

        //To validate
        if (col === 'COMPLETED') {
            return <div className={style.txCompleted}>{intl.formatMessage({ id: 'newcasino.cashier.history.tx.completed', defaultMessage: 'Comleted' })}</div>
        }

        return col;
    }

    return (
        <>
            <div className={style.historyTitle}>{intl.formatMessage({ id: 'newcasino.cashier.history.title', defaultMessage: 'Transaction History' })}</div>
            <div className={style.historySubTitle}>{intl.formatMessage({ id: 'newcasino.cashier.history.subTitle', defaultMessage: 'These are all your transactions in the last 30 days' })}</div>
            <div>
                <MemoryList list={data} lineSeparator={true} headerStyle={style.listHeader} rowBackGroundColor={(ApiPersistence.getInstance().getTheme() === 'dark') ? '#262626' : '#614D86'} headerSortArrow='#FBBA20' initSortColumn='created' initSortColumnAsc={false} nbrItemPerPage={10} columns={columnsDefinition} />
            </div>
        </>
    );
}

export default TxHistory;
