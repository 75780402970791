import React, { useState } from 'react'
import TextField from '../TextField';

function BirthDay(props) {

  const [lastValue, setLastValue] = useState('');

  function onChange(newValue) {
    var toReturn = newValue;

    if (newValue.length < lastValue.length) {

    } else {
      newValue = newValue.replace(/[^1234567890/]/g, '');
      if (newValue.endsWith('//')) {
        toReturn = newValue.substring(0, newValue.length - 1)
      } else if (newValue.length === 2) {
        toReturn = (newValue + '/')
      } else if (newValue.length === 5) {
        toReturn = (newValue + '/')
      } else if (newValue.length > 10) {
        toReturn = (newValue.substring(0, 10))
      } else {
        toReturn = (newValue)
      }
    }

    setLastValue(toReturn);

    if (props.onChange) {
      props.onChange(toReturn);
    }
    return toReturn;
  }


  try {
    return (
      <TextField height={props.height} border={props.border} checkMarginTop={props.checkMarginTop} topTitleStyle={props.topTitleStyle} validateOnStart={props.validateOnStart ? props.validateOnStart : false} maxLength='10' autofocus='false' values={props.values} hint={props.hint} label={props.label} onChange={onChange} validator={props.validator} validatorInformer={props.validatorInformer} mandatory='true' showTitleError='true' showCheck='true' validationMsg={props.validationMsg} />
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default BirthDay

