import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import { FormattedMessage, useIntl } from 'react-intl';
import DivClick from 'newui/Generic/Inputs/DivClick';
import ApiPersistence from 'newui/Utils/ApiPersistence';
import { copyToClipboard, displayErrorNotificationIntl, displayInfoNotificationIntl, getAmountTxt, getAmountTxtWithCoin, getLength, isMobile, isNumeric } from 'newui/Utils/uiUtil';
import * as constants from 'newui/constants';
import { displayPage } from 'newui/Utils/routerUtil';
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function Dashboard(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const PERIOD_YESTERDAY = 1;
  const PERIOD_TODAY = 2;
  const PERIOD_THISWEEK = 3;
  const PERIOD_LASTWEEK = 4;
  const PERIOD_THISMONTH = 5;
  const PERIOD_LASTMONTH = 6;
  const PERIOD_THISYEAR = 7;
  const PERIOD_LASTYEAR = 8;

  const mobileCombo = [
    {
      id: PERIOD_YESTERDAY,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.yest', defaultMessage: 'Yesterday' })
    },
    {
      id: PERIOD_TODAY,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.today', defaultMessage: 'Today' })
    },
    {
      id: PERIOD_THISWEEK,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.tw', defaultMessage: 'This week' })
    },
    {
      id: PERIOD_LASTWEEK,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.lw', defaultMessage: 'Last week' })
    },
    {
      id: PERIOD_THISMONTH,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.tm', defaultMessage: 'This Month' })
    },
    {
      id: PERIOD_LASTMONTH,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.lm', defaultMessage: 'Last Month' })
    },
    {
      id: PERIOD_THISYEAR,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.ty', defaultMessage: 'This year' })
    },
    {
      id: PERIOD_LASTYEAR,
      name: intl.formatMessage({ id: 'newcasino.aff.dashboard.period.ly', defaultMessage: 'Last year' })
    }
  ]


  const [period, setPeriod] = useState(PERIOD_TODAY);
  const [infoBullets, setInfoBullets] = useState([]);
  const [referalUrl, setReferalUrl] = useState(null);

  useEffect(() => {
    getData(period, props.user);
  },// eslint-disable-next-line
    [])



  function getData(thePeriod, theUserEmail) {
    if (ApiPersistence.getInstance().isLogged()) {
      props.spinner(true);
      jwtBackendService.getAffiliateDashboard(thePeriod, theUserEmail, successDashboard, failureDashboard, navigate, location.pathname);
    }

  }

  function setSelectedPeriod(thePeriod) {
    setPeriod(thePeriod);
    getData(thePeriod, props.user);
  }

  function getDate(value) {

    let toReturn = '-';

    try {
      toReturn = value.substring(0, 10);
    } catch (err) {

    }
    return toReturn;
  }

  function successDashboard(data) {
    props.spinner(false);
    let newDepositGraphData = [];
    let newDepositSameDayGraphData = [];

    var theStats = data.results;
    var theCommStats = data.commissions;
    var stats = [];

    if (theStats[1][0].referralId !== null && theStats[1][0].referralId !== undefined && theStats[1][0].referralId.trim() !== '') {
      setReferalUrl('https://playnumbersgame.com/streamer/' + theStats[1][0].referralId);
    } else {
      setReferalUrl(null);
    }

    //DEPOSIT TOTAL
    if (theStats[1][0].totalDeposit !== null && theStats[1][0].totalDeposit !== undefined) {
      let toAdd = {
        type: 'NORMAL',
        intl: 'newcasino.aff.dashboard.total.deposit',
        default: 'Total Deposit',
        data: {
          txt1: ApiPersistence.getInstance().getUser().coin,
          txt2: isNumeric(theStats[1][0].totalDeposit) ? getAmountTxt(theStats[1][0].totalDeposit) : theStats[1][0].totalDeposit
        }
      }
      stats.push(toAdd);


      //DEPOSIT GRAPH
      if (theStats.length > 2 && theStats[2].length > 0 && theStats[2][0].graph === 'DEPOSIT_7LASTDAYS') {
        let userCoin = ApiPersistence.getInstance().getUser().coin;
        for (let i = 0; i < theStats[2].length; i++) {
          newDepositGraphData.push({ name: getDate(theStats[2][i].date), value: theStats[2][i]['deposit' + userCoin], fill: "#37225E" })
        }

        if (theStats[1][0].totalDeposit !== null && theStats[1][0].totalDeposit !== undefined) {
          let theVal = parseFloat(theStats[1][0].totalDeposit).toFixed(2)
          newDepositGraphData.push({ name: 'Today', value: theVal, fill: "#FBBA20" })
        }

        let toAddChart = {
          type: 'BARCHART',
          yAxis: formatAmount,
          intl: 'newcasino.aff.dashboard.total.deposit.graph.last7Days',
          default: 'Total Deposit Last Days',
          data: newDepositGraphData
        }

        stats.push(toAddChart);
      }

      //DEPOSIT SAME DAY GRAPH
      if (theStats.length > 3 && theStats[3].length > 0 && theStats[3][0].graph === 'DEPOSIT_7LASTCURRENTDAY') {
        let userCoin = ApiPersistence.getInstance().getUser().coin;
        let day = theStats[3][0].day
        for (let i = 0; i < theStats[3].length; i++) {
          newDepositSameDayGraphData.push({ name: getDate(theStats[3][i].date), value: theStats[3][i]['deposit' + userCoin], fill: "#37225E" })
        }

        if (theStats[1][0].totalDeposit !== null && theStats[1][0].totalDeposit !== undefined) {
          let theVal = parseFloat(theStats[1][0].totalDeposit).toFixed(2)
          newDepositSameDayGraphData.push({ name: 'Today', value: theVal, fill: "#FBBA20" })
        }

        let toAddChart2 = {
          type: 'BARCHART',
          yAxis: formatAmount,
          title: intl.formatMessage({ id: 'newcasino.aff.dashboard.total.deposit.graph.last7SameDay' }, { DAY: intl.formatMessage({ id: 'newcasino.days.' + day }) }),
          data: newDepositSameDayGraphData
        }

        stats.push(toAddChart2);
      }

      //DEPOSIT GRAPH WEEKLY
      if (theStats.length > 2 && theStats[2].length > 0 && theStats[2][0].graph === 'DEPOSIT_7LASTWEEKS') {
        let userCoin = ApiPersistence.getInstance().getUser().coin;
        let currentArray = [];
        for (let i = 0; i < theStats[2].length; i++) {
          currentArray.push({ name: getDate(theStats[2][i].date), value: theStats[2][i]['deposit' + userCoin], fill: "#37225E" })
        }

        if (theStats[1][0].totalDeposit !== null && theStats[1][0].totalDeposit !== undefined) {
          let theVal = parseFloat(theStats[1][0].totalDeposit).toFixed(2)
          currentArray.push({ name: 'Today', value: theVal, fill: "#FBBA20" })
        }

        let toAddChart = {
          type: 'BARCHART',
          yAxis: formatAmount,
          intl: 'newcasino.aff.dashboard.total.deposit.graph.last7Weeks',
          default: 'Total Deposit Last Weeks',
          data: currentArray
        }

        stats.push(toAddChart);
      }

      //DEPOSIT GRAPH MONTHLY
      if (theStats.length > 2 && theStats[2].length > 0 && theStats[2][0].graph === 'DEPOSIT_12LASTMONTHS') {
        let userCoin = ApiPersistence.getInstance().getUser().coin;
        let currentArray = [];
        for (let i = 0; i < theStats[2].length; i++) {
          currentArray.push({ name: getDate(theStats[2][i].date), value: theStats[2][i]['deposit' + userCoin], fill: "#37225E" })
        }

        if (theStats[1][0].totalDeposit !== null && theStats[1][0].totalDeposit !== undefined) {
          let theVal = parseFloat(theStats[1][0].totalDeposit).toFixed(2)
          currentArray.push({ name: 'Today', value: theVal, fill: "#FBBA20" })
        }

        let toAddChart = {
          type: 'BARCHART',
          yAxis: formatAmount,
          intl: 'newcasino.aff.dashboard.total.deposit.graph.last12Months',
          default: 'Total Deposit Last Months',
          data: currentArray
        }

        stats.push(toAddChart);
      }

      if (data.dates.period >= PERIOD_THISMONTH) {
        let theArray = [];

        for (let i = 0; i < theCommStats[0].length; i++) {
          if (theCommStats[0][i].total > 0) {
            theArray.push(theCommStats[0][i]);
          }
        }

        let toAddComm = {
          type: 'COMMISSION',
          intl: 'newcasino.aff.dashboard.total.10.commission',
          default: 'Current commission',
          data: theArray
        }
        stats.push(toAddComm);
      }
    }

    //nbrNewRegistrationTotal
    if (theStats[1][0].nbrNewRegistrationTotal !== null && theStats[1][0].nbrNewRegistrationTotal !== undefined) {
      let toAdd = {
        type: 'MULTILINE',
        data: {
          line1: {
            intl: 'newcasino.aff.dashboard.registration.total.started',
            default: 'Registration - Started/Completed',
            value: theStats[1][0].nbrNewRegistrationStarted + " / " + theStats[1][0].nbrNewRegistrationCompleted, //, nbrNewRegistrationStarted
          },
          line2: {
            intl: 'newcasino.aff.dashboard.registration.total.email.verified',
            default: 'Email Verified',
            value: theStats[1][0].nbrNewRegistrationEmailVerified, //nbrNewRegistrationCompleted, nbrNewRegistrationStarted
          },
          line3: {
            intl: 'newcasino.aff.dashboard.registration.total.player',
            default: 'Total',
            value: theStats[1][0].nbrNewRegistrationTotal
          }
        }
      }
      stats.push(toAdd);
    }


    //Active Player
    if (theStats[1][0].nbrActivePlayer !== null && theStats[1][0].nbrActivePlayer !== undefined) {
      let toAdd = {
        type: 'NORMAL',
        intl: 'newcasino.aff.dashboard.active.player',
        default: 'Active Player',
        data: {
          txt1: '',
          txt2: theStats[1][0].nbrActivePlayer
        }
      }
      stats.push(toAdd);
    }

    //nbrNewFTDPlayer
    if (theStats[1][0].nbrNewFTDPlayer !== null && theStats[1][0].nbrNewFTDPlayer !== undefined) {
      let toAdd = {
        type: 'NORMAL',
        intl: 'newcasino.aff.dashboard.first.time.deposit.player',
        default: 'First Time Deposit',
        data: {
          txt1: '',
          txt2: theStats[1][0].nbrNewFTDPlayer
        }
      }
      stats.push(toAdd);
    }

    //nbrSponseredPlayer
    if (theStats[1][0].nbrSponseredPlayer !== null && theStats[1][0].nbrSponseredPlayer !== undefined && theStats[1][0].nbrSponseredPlayer !== -1) {
      let toAdd = {
        type: 'NORMAL',
        intl: 'newcasino.aff.dashboard.sponsered.players',
        default: 'Total number of sponsered players',
        data: {
          txt1: '',
          txt2: theStats[1][0].nbrSponseredPlayer
        }
      }
      stats.push(toAdd);
    }

    //nbrDailyGiftOpen
    if (theStats[1][0].nbrDailyGiftOpen !== null && theStats[1][0].nbrDailyGiftOpen !== undefined && theStats[1][0].nbrDailyGiftOpen !== -1) {
      let toAdd = {
        type: 'NORMAL',
        intl: 'newcasino.aff.dashboard.dailygift.open',
        default: 'Total number of daily gift openned',
        data: {
          txt1: '',
          txt2: theStats[1][0].nbrDailyGiftOpen
        }
      }
      stats.push(toAdd);
    }
    setInfoBullets(stats);
  }

  function failureDashboard(data) {
    displayErrorNotificationIntl(intl, 'newcasino.aff.dashboard.load.fail')
    props.spinner(false);
  }

  function formatAmount(theValue) {
    try {
      let num = Number(theValue);
      if (num > 1000) {
        return num / 1000 + 'k';
      }
    } catch (error) {

    }

    return theValue;
  }

  function showCommissions() {
    displayPage(constants.ROUTE_AFF_COMMISSIONS, navigate, location.pathname);
  }

  function getLinkFunction(info) {
    if (info.type === 'COMMISSION') {
      return showCommissions;
    }

    return null;
  }

  function copyInClipboard() {
    copyToClipboard(document, referalUrl)
    displayInfoNotificationIntl(intl, 'newcasino.aff.dashboard.refId.copied');
  };

  function mobileComboChange(data) {
    setSelectedPeriod(data)
  }

  function getLink(info) {
    if (info.type === 'COMMISSION') {
      return intl.formatMessage({ id: 'newcasino.aff.dashboard.commission.link', defaultMessage: 'See all commissons' })
    }

    return '';
  }
  try {
    if (infoBullets) {
      return (
        <div className={style.topDiv}>
          <div className={style.title}>
            {intl.formatMessage({ id: 'newcasino.aff.dashboard.title', defaultMessage: 'Dashboard' })}
            {props.user &&
              '  (' + props.user + ')'}

          </div>

          {referalUrl &&
            <>
              <DivClick onClick={copyInClipboard} className={style.link}>
                <span className={style.referal}>{intl.formatMessage({ id: 'newcasino.aff.dashboard.refId', defaultMessage: 'Referal Link' })} - </span> {referalUrl}
              </DivClick>
            </>
          }


          {(!(isMobile())) &&
            <div className={style.menuTop}>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_YESTERDAY)} className={(period === PERIOD_YESTERDAY) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.yest', defaultMessage: 'Yesterday' })}
              </DivClick>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_TODAY)} className={(period === PERIOD_TODAY) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.today', defaultMessage: 'Today' })}
              </DivClick>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_THISWEEK)} className={(period === PERIOD_THISWEEK) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.tw', defaultMessage: 'This week' })}
              </DivClick>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_LASTWEEK)} className={(period === PERIOD_LASTWEEK) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.lw', defaultMessage: 'Last week' })}
              </DivClick>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_THISMONTH)} className={(period === PERIOD_THISMONTH) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.tm', defaultMessage: 'This Month' })}
              </DivClick>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_LASTMONTH)} className={(period === PERIOD_LASTMONTH) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.lm', defaultMessage: 'Last Month' })}
              </DivClick>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_THISYEAR)} className={(period === PERIOD_THISYEAR) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.ty', defaultMessage: 'This year' })}
              </DivClick>
              <DivClick onClick={() => setSelectedPeriod(PERIOD_LASTYEAR)} className={(period === PERIOD_LASTYEAR) ? style.menuTopElemSelected : style.menuTopElem}>
                {intl.formatMessage({ id: 'newcasino.aff.dashboard.period.ly', defaultMessage: 'Last year' })}
              </DivClick>
            </div>
          }
          {(isMobile()) &&
            <ComboBox onChange={mobileComboChange} values={[period]} valueList={{ list: mobileCombo, key: 'id', value: 'name' }} />
          }
          <div className={style.boxes}>
            <div className={style.boxGrid}>

              {infoBullets.map((item, index) => (
                <div key={'bubble_' + index}>
                  {(item.type === 'BARCHART') &&
                    <div>
                      <div key={'BULLET_' + index} className={style.box}>
                        <div className={style.boxContent}>
                          <div className={style.boxContentTable}>
                            <ResponsiveContainer>
                              <BarChart
                                data={item.data}
                                margin={{
                                  top: 0,
                                  right: 0,
                                  left: -10,
                                  bottom: -25,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                {item.yAxis &&
                                  <YAxis tickFormatter={(value) => item.yAxis(value)} />
                                }
                                {!(item.yAxis) &&
                                  <YAxis />
                                }
                                <Tooltip />
                                <Bar dataKey="value" activeBar={<Rectangle fill="#37225E" stroke="#FBBA20" />} />
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                          {item.intl &&
                            <div className={style.boxContentBottom}><FormattedMessage id={item.intl} defaultMessage={item.default} /></div>
                          }
                          {item.title &&
                            <div className={style.boxContentBottom}>
                              {item.title}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  {!(item.type === 'BARCHART') &&
                    <div key={'BULLET_' + index} className={style.box}>
                      <div className={style.boxContent}>

                        <div className={style.boxContentTable}>
                          <div className={style.boxContentTableLine1}>
                            <DivClick onClick={getLinkFunction(item)} className={style.boxContentLink}>
                              {getLink(item)}
                            </DivClick>
                          </div>
                          <div className={style.boxContentTableLine2}>
                            <div className={(item.type === 'COMMISSION') ? style.boxContentTop2 : ((item.type === 'MULTILINE') ? style.boxContentTop3 : style.boxContentTop)}>
                              {item.type === 'COMMISSION' &&
                                <>
                                  {item.data.length > 0 &&
                                    <div className={style.commGrid2}>
                                      {item.data.map((elem, ind) => (
                                        <>
                                          <div className={style.boxContentTopCurrency}>{elem.currency}</div>
                                          <div className={style.boxContentTopNbrSmall}>{getAmountTxtWithCoin(elem.total, elem.currency)}</div>
                                        </>
                                      ))}
                                    </div>
                                  }
                                </>
                              }
                              {item.data.length <= 0 &&
                                <div className={style.commGrid1}>
                                  <div className={style.commGridZero}> -- </div>
                                </div>
                              }

                              {item.type === 'NORMAL' &&
                                <div className={style.boxContentTopCurrency}>{item.data.txt1}</div>
                              }
                              {item.type === 'NORMAL' &&
                                <div className={(getLength(item.data.txt2) < 7) ? style.boxContentTopNbr : style.boxContentTopNbrSmall}>{item.data.txt2}</div>
                              }

                              {item.type === 'MULTILINE' &&
                                <>
                                  <div className={style.boxContentBottom}><FormattedMessage id={item.data.line1.intl} defaultMessage={item.data.line1.default} /></div>
                                  <div className={(getLength(item.data.line1.value) < 7) ? style.boxContentTopNbrCenter : style.boxContentTopNbrSmallCenter}>{item.data.line1.value}</div>
                                </>
                              }
                              {item.type === 'MULTILINE' &&
                                <>
                                  <div className={style.boxContentBottom}><FormattedMessage id={item.data.line2.intl} defaultMessage={item.data.line2.default} /></div>
                                  <div className={(getLength(item.data.line2.value) < 7) ? style.boxContentTopNbrCenter : style.boxContentTopNbrSmallCenter}>{item.data.line2.value}</div>
                                </>
                              }
                              {item.type === 'MULTILINE' &&
                                <>
                                  <div className={style.boxContentBottom}><FormattedMessage id={item.data.line3.intl} defaultMessage={item.data.line3.default} /></div>
                                  <div className={(getLength(item.data.line3.value) < 7) ? style.boxContentTopNbrCenter : style.boxContentTopNbrSmallCenter}>{item.data.line3.value}</div>
                                </>
                              }
                            </div>
                            {item.type !== 'MULTILINE' &&
                              <div className={style.boxContentBottom}><FormattedMessage id={item.intl} defaultMessage={item.default} /></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default Dashboard