import ApiCache from 'newui/Utils/ApiCache';
import { isMobile, searchGames } from 'newui/Utils/uiUtil';
import ApiPersistence from 'newui/Utils/ApiPersistence';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'

export const CACHE_CASINO_DATA = 'CACHE_CASINO_DATA';
export const CACHE_COUNTRY_LIST = 'CACHE_COUNTRY_LIST';
export const CACHE_POPUP_REQUEST = 'CACHE_POPUP_REQUEST';
const POPUP_TIMER = 1800000; //30 minutes

/*******************************************************************************************
* setInCache
********************************************************************************************/
export function setInCache(key, data, cache) {
  if (cache) {
    cache.setInCache(key, data);
  }

  return data;
}

/*******************************************************************************************
*
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function loadConfigurations(jsonConfigurationLocation, handleSuccessGetConfiguration, handleErrorGetConfiguration) {
  jwtBackendService.loadConfigurations(jsonConfigurationLocation, handleSuccessGetConfiguration, handleErrorGetConfiguration)
}



/*******************************************************************************************
* CHECK POPUP
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function checkPopup(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var cacheInstance = ApiCache.getInstance()

  var doRequest = true;

  if (cacheInstance && cacheInstance.isInCache(CACHE_POPUP_REQUEST)) {
    if (Date.now() < Number.parseInt(cacheInstance.getFromCache(CACHE_POPUP_REQUEST) + POPUP_TIMER)) {
      doRequest = false;
    }
  }

  if (doRequest) {
    var lastPopupShowed = ApiPersistence.getInstance().getLastPopupShowed();
    var isLogged = ApiPersistence.getInstance().isLogged();
    cacheInstance.setInCache(CACHE_POPUP_REQUEST, Date.now());

    jwtBackendService.casino_get_popup(isLogged, { lastShowed: lastPopupShowed }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
  }
}
/*******************************************************************************************
* getHomePageGamesInformation
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getHomePageGamesInformation(isLogged, constDefinition, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var cacheInstance = ApiCache.getInstance()

  if (cacheInstance && cacheInstance.isInCache(CACHE_CASINO_DATA)) {
    var item = cacheInstance.getFromCache(CACHE_CASINO_DATA);
    successCallbackFunction(item);

    return;
  }


  jwtBackendService.getDataCasino(isLogged, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
*
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getReferFriendInfo(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  const userEmail = ApiPersistence.getInstance().getUser().email;

  jwtBackendService.getReferFriendInfo({ email: userEmail }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
*
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getReferFriendInfoCash(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  const userEmail = ApiPersistence.getInstance().getUser().email;

  jwtBackendService.getReferFriendInfoCash({ email: userEmail }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
*
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function claimReferAFriendWager(idList, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtBackendService.claimReferAFriendWager(idList, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

export function claimReferAFriendCash(idList, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtBackendService.claimReferAFriendCash(idList, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* getGameData
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getGameData(gameId, lang, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var url = '/api/' + (ApiPersistence.getInstance().isLogged() ? 'private' : 'public') + '/START_GAME'
  var device = isMobile() ? 'mobile' : 'desktop';
  jwtBackendService.casino_get_gameData(url, { id: gameId, device: device, lang: lang }, ApiPersistence.getInstance().isLogged(), successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* createAccount
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function createAccount(email, password, sponsorCode, license, promo, promodata, referByFriendJson, successCallbackFunction, successCallbackFunctionJWT, failureCallbackFunction, navigate, currentPathname) {
  //REG_STEP_1
  var jsonData = {
    email: email,
    password: password,
    license: license,
    promo: promo,
    promodata: promodata,
    sponsorCode: sponsorCode,
    referByFriendStatus: referByFriendJson
  }


  jwtBackendService.createAccount(jsonData, successCallbackFunctionJWT, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* addInfoToAccount
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function addInfoToAccount(email, firstName, lastName, birthday, address, city, zipcode, country, state, currency, phonecode, phone, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  //REG_STEP_2

  var jsonData = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    birthday: birthday,
    address: address,
    city: city,
    zipcode: zipcode,
    country: country,
    state: state,
    currency: currency,
    phonecode: phonecode,
    phone: phone
  }


  jwtBackendService.addInfoToAccount(jsonData, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* validateMobileCode
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function validateMobileCode(email, code, phonecode, phone, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {

  var jsonData = {
    email: email,
    phonecode: phonecode,
    phone: phone,
    code: code
  }

  jwtBackendService.validateMobileCode(jsonData, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* validateEmailCode
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function validateEmailCode(code, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var jsonData = {
    code: code
  }

  jwtBackendService.validateEmailCode(jsonData, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* getCountryList
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getCountryList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {

  var cacheInstance = ApiCache.getInstance()

  if (cacheInstance && cacheInstance.isInCache(CACHE_COUNTRY_LIST)) {
    var data = cacheInstance.getFromCache(CACHE_COUNTRY_LIST);
    successCallbackFunction(data);
    return;
  }


  jwtBackendService.casino_get_country_list(successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* passwordReset
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function passwordReset(email, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtBackendService.passwordReset({ email: email }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* resendVerificationEmail
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function resendVerificationEmail(email, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtBackendService.resendVerificationEmail({ email }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* resendVerificationSms
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function resendVerificationSms(email, phonecode, phone, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  let data =
  {
    email: email,
    phonecode: phonecode,
    phone: phone
  }

  jwtBackendService.resendVerificationSms(data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* getBorderMenuGamesInformation
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getBorderMenuGamesInformation(isLogged, constDefinition, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var cacheInstance = ApiCache.getInstance()

  if (cacheInstance && cacheInstance.isInCache(CACHE_CASINO_DATA)) {
    var item = cacheInstance.getFromCache(CACHE_CASINO_DATA);
    successCallbackFunction(item);
    return;
  }

  jwtBackendService.getDataCasino(isLogged, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* searchGamesByKeyWord
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function searchGamesByKeyWord(isLogged, keyword, successCallbackFunction, successCallbackFunctionJWT, failureCallbackFunction, navigate, currentPathname) {

  var cacheInstance = ApiCache.getInstance()
  if (cacheInstance && cacheInstance.isInCache(CACHE_CASINO_DATA)) {
    var item = cacheInstance.getFromCache(CACHE_CASINO_DATA);
    let data = {
      gamescategory: item[1],
      games: item[2],
      providers: item[3],
      gameTypes: item[4]
    }
    successCallbackFunction(searchGames(data, keyword));
    return;
  }
  jwtBackendService.getDataCasino(isLogged, successCallbackFunctionJWT, failureCallbackFunction, navigate, currentPathname)
}


/*******************************************************************************************
* getContestInformation
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getContestInformation(id, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtBackendService.getContestInformation({ contestId: id }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* getContestList
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getContestList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  jwtBackendService.getContestList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* getContestList
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getPopupList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {

  jwtBackendService.getPopupList(successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}


/*******************************************************************************************
* updateContest
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function updateContest(isDelete, data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var action = 'SAVE';
  if (isDelete) {
    action = 'DELETE';
  }


  jwtBackendService.updateContest({ action: action, data: data }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* getPopupData
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function getPopupData(id, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {

  jwtBackendService.getPopupData({ popupId: id }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}

/*******************************************************************************************
* updateClosedPopup
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function updateClosedPopup(id, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  if (ApiPersistence.getInstance().isLogged()) {

    jwtBackendService.closePopup({ popupId: id }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
  }

  ApiPersistence.getInstance().setLastPopupShowed(id);
}

/*******************************************************************************************
* updatePopup
* MIGRATION NEW SECURITY: DONE
********************************************************************************************/
export function updatePopup(isDelete, data, successCallbackFunction, failureCallbackFunction, navigate, currentPathname) {
  var action = 'SAVE';
  if (isDelete) {
    action = 'DELETE';
  }


  jwtBackendService.updatePopup({ action: action, data: data }, successCallbackFunction, failureCallbackFunction, navigate, currentPathname)
}