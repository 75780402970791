import React, { useState } from 'react';
import style from './style.module.scss'
import DivClick from 'newui/Generic/Inputs/DivClick';

function Question(props) {

    const [showAnswer, setShowAnswer] = useState(props.open ? true : false);

    function toggleAnswer() {
        setShowAnswer(!showAnswer);
    }

    return (
        <>
            <div className={style.boxQuestion}>
                <DivClick onClick={toggleAnswer} >
                    <i class={showAnswer ? "fa fa-chevron-down" : "fa fa-chevron-right"} aria-hidden="true"></i>
                </DivClick>
                <div className={style.question}>{props.question}</div>
            </div >
            {showAnswer &&
                <div className={style.boxAnswer}>
                    <div className={style.answer}>{props.answer}</div>
                </div>
            }
        </>
    );

}

export default Question;
