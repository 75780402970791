/* eslint-disable */
import React from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';

function HowTable(props) {

    return (
        <>
            {props.title &&
                <div className={style.title}>
                    {props.title}
                </div>
            }
            <div className={style.bubble} style={{ gridTemplateColumns: '33% 33% 33%' }}>
                <div className={style.table_title}>{props.rowTitles[0]}</div>
                <div className={style.table_title}>{props.rowTitles[1]}</div>
                <div className={style.table_title}>{props.rowTitles[2]}</div>

                <div className={style.table_separator} style={{ gridColumn: 'span 3' }} />
                {props.rowValues.map((col, index) => (
                    <React.Fragment key={'Row_' + index}>
                        {col.map((col, index2) => (
                            <React.Fragment key={'Row_' + index + 'cell_' + index2}>
                                <div className={(index2 === 0) ? style.table_value : style.table_value_center}>{props.rowValues[index][index2]}</div>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </>
    );
}

export default HowTable;
