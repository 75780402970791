import React from 'react'
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl'
import DivClick from 'newui/Generic/Inputs/DivClick';

function LanguageMenu(props) {

  const menuLanguages = props.languages;

  if (menuLanguages) {
    for (let i = 0; i < menuLanguages.length; i++) {
      var currentLang = menuLanguages[i];
      if (currentLang.code === props.locale) {
        break;
      }
    }
  }

  try {

    return (
      <div className={props.className ? props.className : null} key={Math.random}>
        <div className={style.menuTitle}>
          <FormattedMessage
            id='newcasino.menutop.lang.title'
            defaultMessage='Language'
          />
        </div>
        {menuLanguages.map((item, index) =>
          <DivClick key={'userLang_' + index} className={style.global_DivsHorizontal} onClick={() => props.changeLanguage(index)}>
            <div className={style.flag} style={{ backgroundImage: item.image }} />
            <div>{item.default}</div>
          </DivClick>
        )}
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default LanguageMenu
