import React from 'react'
import style from './style.module.scss'
import DivClick from 'newui/Generic/Inputs/DivClick';

function HeaderList(props) {
  try {

    return (
      <div className={`${style.GameSelection_categories_header}`}>
        <div className={`${style.GameSelection_categories_header_right}`}>
          <div className={`${style.borderMenu_elem_grid}`}>
            <div className={`${style.borderMenu_elemBar_image}`}>
              <div className={`${style.image_trans}`} style={{
                backgroundImage: props.icon
              }} />
            </div>
            <div className={`${style.borderMenu_elemBar_title}`}>
              {props.title}
            </div>
          </div>
        </div>
        <div className={`${style.GameSelection_categories_header_left}`}>
          <DivClick className={`${style.GameSelection_showAll}`} onClick={props.newTabSelected}>
            {props.showall}
          </DivClick>
          <DivClick className={`${style.GameSelection_chevron}`} onClick={props.previousButtonClicked}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </DivClick>
          <DivClick className={`${style.GameSelection_chevron}`} onClick={props.nextButtonClicked}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </DivClick>
        </div>
      </div>


    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default HeaderList