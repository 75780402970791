import React, { useState, useEffect } from 'react'
import { ConfigProvider } from 'antd'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl'
import { canAccess } from 'newui/Utils/authorizationUtils'
import * as constants from 'newui/constants.js'
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';

import english from 'newui/locales/en-US'
import french from 'newui/locales/fr-FR'
import portuguese from 'newui/locales/pt-BR'

import style from './style.module.scss'

import BorderMenu from './BorderMenu'
import TopStickyMenu from './TopStickyMenu'
import ApiPersistence from 'newui/Utils/ApiPersistence';

import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import { closeSnackbar } from 'notistack'

import Auth from './Auth';
import Dashboard from './Dashboard';
import Slash from './Slash';
import Affiliates from './Affiliates';
import UserMenu from 'newui/Casino/General/Menus/UserMenu';
import Logout from 'newui/Casino/General/Authentication/Logout';
import DefaultModal from 'newui/Casino/General/Modal/DefaultModal';
import Commission from './Commission';

function AffiliatesPage(props) {
  const ACCESS_PRIVATE = 'private'
  const USERTYPE_AFFILIATE = 2;
  const USERTYPE_PARTNER = 3;
  const USERTYPE_ADMIN = 4;
  const USERTYPE_STREAMER = 5;

  const [spinner, setSpinner] = useState(false);

  const [displayUserMenu, setDisplayUserMenu] = useState(false);
  const [lang, setLang] = useState(ApiPersistence.getInstance().getLanguage())
  const jsonConfigurationLocation = '/resources/newui/config/affiliates.json';

  const [currentDashboardUser, setCurrentDashboardUser] = useState(null)


  const locales = {
    'en-US': english,
    'fr-FR': french,
    'pt-BR': portuguese,
  }

  var currentLocale = locales[lang];

  const [modalMsg, setModalMsg] = useState(
    {
      id: -1,
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: null
      },
      callback: closeModal
    }
  )

  var onTheFlyMessage = null
  var rendered = false;

  const [configurations, setConfigurations] = useState(null);

  useEffect(() => {
    setMoment();
    jwtBackendService.loadConfigurations(jsonConfigurationLocation, handleSuccessGetConfiguration, handleErrorGetConfiguration);
  }, // eslint-disable-next-line
    []);


  function setMoment(value) {
    var newLang = value;
    if (newLang === undefined) {
      newLang = lang;
    }
    if (newLang.startsWith('fr-')) {
      moment.locale('fr')
    } else {
      moment.locale('en')
    }
  }

  function closeModal() {

    setModalMsg({
      id: -1,
      body: {
        title: constants.STRING_EMPTY,
        message: constants.STRING_EMPTY,
        image: null
      },
      callback: null
    })

  }

  //Handler for Callback of the Text for the UI
  function handleSuccessGetConfiguration(data) {
    setConfigurations(data);
    if (data.debugMode) {
      document.documentElement.setAttribute('debug', 'true');
    }
  }


  function handleErrorGetConfiguration(data) {
    console.log('handleFatalError');
  }

  function showDashBoardFor(user) {
    setCurrentDashboardUser(user)
  }


  function resetToLoggedUser() {
    setCurrentDashboardUser(null)
  }

  useEffect(() => {
    if (onTheFlyMessage) {
      setModalMsg(onTheFlyMessage);
    }
    // eslint-disable-next-line
    rendered = true;
  });

  function doIShowUserMenu() {
    var toReturn = style.noShow;
    if (displayUserMenu) {
      toReturn = style.show;
    }
    return toReturn;
  }

  function toggleUserMenu(force) {

    if (force !== undefined && force !== null) {
      setDisplayUserMenu(force);
    } else {
      setDisplayUserMenu(!displayUserMenu);
    }


  }


  function isMobile() {
    return window.innerWidth <= constants.MOBILE_SCREEN_SIZE_WIDTH_PIXEL;
  }


  function displayModalComponent(component, position, bgColor, width) {

    var body =
    {
      isHtml: false,
      title: '',
      message: '',
      bgColor: bgColor
    }

    const theMsg = {
      id: Math.random(),
      component: component,
      body: body,
      showClose: false,
      width: width,
      callback: closeModal
    };
    displayGenericModal(theMsg)
  }

  function spin(isSpinning) {
    setSpinner(isSpinning);
  }


  function displayGenericModal(theMsg) {
    if (rendered) {
      setModalMsg(theMsg)
    } else {
      onTheFlyMessage = theMsg;
    }
  }

  function changeLanguage(index, value) {
    ApiPersistence.getInstance().setLanguage(value);
    setLang(value);
    setMoment(value);
  }

  function checkAuthorization(authorization) {
    return canAccess(ApiPersistence.getInstance().getUser(), authorization);
  }

  try {
    if (configurations) {
      return (
        <React.Fragment>
          <div className={spinner ? style.coverspinOn : style.coverspinOff} />
          <ConfigProvider locale={lang}>
            <IntlProvider locale={lang} messages={currentLocale.messages}>
              <BrowserRouter>
                <SnackbarProvider
                  action={(snackbarId) => (
                    <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} onClick={() => closeSnackbar(snackbarId)} />
                  )}
                >
                  {ApiPersistence.getInstance().isLogged() &&
                    <>
                      <div />
                      <div className={`${style.test_relative}`}>
                        <div className={`${style.test_fixed} ${doIShowUserMenu()}`}>
                          <UserMenu className={style.userMenu} showTitle={false} definition={configurations.userMenuDefinition} toggleUserMenu={toggleUserMenu} isMobile={isMobile()} />
                        </div>
                      </div>
                      <TopStickyMenu userIdentification={ApiPersistence.getInstance().getUserIdentification()} toggleUserMenu={toggleUserMenu} setLanguage={changeLanguage} languages={configurations.languages} definition={configurations.topMenuDefinition} />
                      <div className={`${style.gamePanel_baseDiv}`}>
                        <div className={style.borderMenuDiv}>
                          <BorderMenu resetToLoggedUser={resetToLoggedUser} definition={configurations.leftMenuDefinition} />
                        </div>
                        <DefaultModal key={Math.random()} msg={modalMsg} />
                        <div className={style.gamePanel_grid_menu}>
                          <div className={`${style.gamePanel_gamesurface}`}>
                            <Routes>
                              <Route path={constants.ROUTE_440} element={<Logout type={440} />} />
                              <Route path={constants.ROUTE_USERLOGOUT} element={<Logout />} />
                              <Route path={constants.ROUTE_SLASH} element={<Slash />} />
                              {/* Need streamer Rights OR AFFILIATE*/}
                              {checkAuthorization({ authorization: { access: ACCESS_PRIVATE, typeAccount: [USERTYPE_STREAMER, USERTYPE_PARTNER, USERTYPE_AFFILIATE, USERTYPE_ADMIN], rols: [] } }) && (
                                <>
                                  <Route path={constants.ROUTE_HOME} element={<Dashboard user={currentDashboardUser} key='HOME' spinner={spin} />} />
                                  <Route path={constants.ROUTE_HOME_BACKUP} element={<Dashboard user={currentDashboardUser} key='HOME2' spinner={spin} />} />
                                  <Route path={constants.ROUTE_AFF_COMMISSIONS} element={<Commission user={currentDashboardUser} spinner={spin} />} />
                                </>
                              )}
                              {/* Need Admin rights*/}
                              {checkAuthorization({ authorization: { access: ACCESS_PRIVATE, typeAccount: [USERTYPE_ADMIN], rols: [] } }) && (
                                <>
                                  <Route path={constants.ROUTE_AFF_USERS} element={<Affiliates showDashBoardFor={showDashBoardFor} spinner={spin} />} />
                                </>
                              )}
                            </Routes>

                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {!(ApiPersistence.getInstance().isLogged()) &&
                    <>
                      <DefaultModal key={Math.random()} msg={modalMsg} />
                      <Auth setLanguage={changeLanguage} spinner={spin} closeModal={closeModal} displayModalComponent={displayModalComponent} />
                    </>
                  }
                </SnackbarProvider>
              </BrowserRouter>
            </IntlProvider>
          </ConfigProvider>
        </React.Fragment >
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }

}

export default (AffiliatesPage)
