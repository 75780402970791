import ApiPersistence from 'newui/Utils/ApiPersistence';
const crypto = require('crypto');

const ENCRYPTIONKEY64 = 'ENCRYPTIONKEY64';
const BASE64 = 'base64';
const UTF8 = 'utf8';
const ALGO = 'aes-256-gcm'

/**********************************************************************
 * cleanUp
 *********************************************************************/
export function cleanUp() {
    ApiPersistence.getInstance().delete(ENCRYPTIONKEY64);
}

/**********************************************************************
 * getEncryptionKey
 *********************************************************************/
export function getEncryptionKey() {
    return ApiPersistence.getInstance().get(ENCRYPTIONKEY64);
}

/**********************************************************************
 * storeEncryptionKey
 *********************************************************************/
export function storeEncryptionKey(keyB64) {
    return ApiPersistence.getInstance().set(ENCRYPTIONKEY64, keyB64);
}


/**********************************************************************
 * encryptJson
 *********************************************************************/
export function encryptJson(json, encKey64) {
    const encKey = Buffer.from(encKey64, BASE64);

    var jsonString = JSON.stringify(json);
    const cipher = crypto.createCipheriv(ALGO, encKey, Buffer.alloc(128 / 8));

    const enc = Buffer.concat([
        cipher.update(jsonString, UTF8),
        cipher.final()
    ]);

    return enc.toString(BASE64);

}

/*********************************************************************************
* decryptJson
*********************************************************************************/
export function decryptJson(encData64, encKey64) {
    const encKey = Buffer.from(encKey64, BASE64);
    const decipher = crypto.createDecipheriv(ALGO, encKey, Buffer.alloc(128 / 8));
    const encData = Buffer.from(encData64, BASE64);
    const data = decipher.update(encData);

    return JSON.parse(data);
}