/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService';
import TextField from 'newui/Generic/Inputs/TextField';
import Button from 'newui/Generic/Inputs/Button';
import { useIntl } from 'react-intl'
import { displayGenericErrorNotificationIntl } from 'newui/Utils/uiUtil';

function DepositLimit(props) {
    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();
    const [loaded, setLoaded] = useState(false)
    const [refresh, setRefresh] = useState(Math.random())

    const [data, setData] = useState(null)
    const [depositDailyAmount, setDepositDailyAmount] = useState(0)
    const [depositWeeklyAmount, setDepositWeeklyAmount] = useState(0)
    const [depositMonthlyAmount, setDepositMonthlyAmount] = useState(0)
    const [dailyOk, setDailyOk] = useState(1)
    const [weeklyOk, setWeeklyOk] = useState(1)
    const [monthlyOk, setMonthlyOk] = useState(1)

    useEffect(() => {
        fetchData()
    }, [])


    function fetchData() {
        jwtBackendService.getUserResponsibleGamblingInfo(successFetchDepositLimitDataJWT, failureFetchDepositLimitDataJWT, navigate, location.pathname);
    }

    function successFetchDepositLimitDataJWT(data) {
        setLoaded(true);
        setData(data);
        setDepositDailyAmount(data.depositDailyAmount);
        setDepositWeeklyAmount(data.depositWeeklyAmount)
        setDepositMonthlyAmount(data.depositMonthlyAmount);
        setDailyOk(data.depositDailyUpdatable);
        setWeeklyOk(data.depositWeeklyUpdatable)
        setMonthlyOk(data.depositMonthlyUpdatable);
    }

    function dailyAmountChange(newValue) {
        setDepositDailyAmount(newValue);
    }

    function weeklyAmountChange(newValue) {
        setDepositWeeklyAmount(newValue);
    }

    function monthlyAmountChange(newValue) {
        setDepositMonthlyAmount(newValue);
    }


    function failureFetchDepositLimitDataJWT(data) {
        setLoaded(true);
    }

    function save() {
        var cloneData = Object.assign({}, data);
        cloneData.depositDailyAmount = depositDailyAmount;
        cloneData.depositWeeklyAmount = depositWeeklyAmount;
        cloneData.depositMonthlyAmount = depositMonthlyAmount;
        jwtBackendService.updateUserResponsibleGamblingInfo(cloneData, successOnSave, failureOnSave, navigate, location.pathname);
    }

    function successOnSave(data) {
        setData(data);
        setDepositDailyAmount(data.depositDailyAmount);
        setDepositWeeklyAmount(data.depositWeeklyAmount)
        setDepositMonthlyAmount(data.depositMonthlyAmount);
        setDailyOk(data.depositDailyUpdatable);
        setWeeklyOk(data.depositWeeklyUpdatable)
        setMonthlyOk(data.depositMonthlyUpdatable);
        setRefresh(Math.random());
    }

    function failureOnSave(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    if (loaded) {
        return (
            <div key={refresh} id='contestWinnerPage' className={style.master_page_container}>
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.rg.deposit.limit.page.title"
                        defaultMessage="Deposit Limit"
                    />
                </div>
                <div className={style.mainSubTitle}>
                    <FormattedMessage id="newcasino.rg.deposit.limit.page.subtitle" defaultMessage="* Values ​​can only be changed once every 48 hours" />
                </div>
                <div className={style.mainDiv}>
                    <div className={style.elementDiv}>
                        <div className={style.txt}><FormattedMessage id="newcasino.rg.deposit.limit.page.daily" defaultMessage="Your current Daily Deposit Limit is:" /></div>
                        <div className={style.field}><TextField key={'a' + refresh} readOnly={dailyOk === 0} type='digit' values={[depositDailyAmount]} onChange={dailyAmountChange} /></div>
                    </div>
                    <div className={style.elementDiv}>
                        <div className={style.txt}><FormattedMessage id="newcasino.rg.deposit.limit.page.weekly" defaultMessage="Your current Weekly Deposit Limit is:" /></div>
                        <div className={style.field}><TextField key={'b' + refresh} readOnly={weeklyOk === 0} type='digit' values={[depositWeeklyAmount]} onChange={weeklyAmountChange} /></div>
                    </div>
                    <div className={style.elementDiv}>
                        <div className={style.txt}><FormattedMessage id="newcasino.rg.deposit.limit.page.monthly" defaultMessage="Your current Monthly Deposit Limit is:" /></div>
                        <div className={style.field}><TextField key={'c' + refresh} readOnly={monthlyOk === 0} type='digit' values={[depositMonthlyAmount]} onChange={monthlyAmountChange} /></div>
                    </div>
                    <div className={style.elementBut}>
                        <Button width='200px' value={intl.formatMessage({ id: 'newcasino.rg.deposit.limit.page.button', defaultMessage: 'Update' })} onClick={save} />
                    </div>
                </div>

            </div >
        );
    }
}

export default DepositLimit;
