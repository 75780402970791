import React from 'react'
import style from './style.module.scss'
import DivClick from '../DivClick';
/* 
chevron
colorStyle
active
value
onClick
icon
iconTextAlign
width
height
iconOnly
*/
function Button(props) {

  const width = props.width ? props.width : '146px';
  const height = props.height ? props.height : '40px';

  const chevronLeft = props.chevron ? (props.chevron === 'left' || props.chevron === 'both') : false
  const chevronRight = props.chevron ? (props.chevron === 'right' || props.chevron === 'both') : false
  const colorStyle = props.colorStyle;
  const icon = props.icon ? props.icon : undefined;
  const active = (props.active !== undefined) ? ((props.active === true) ? true : false) : true
  const label = props.label ? props.label : null;

  function getButStyle() {
    if (props.iconSize === 2) {
      return style.butContent2x;
    } else {
      return props.buttonStyle ? props.buttonStyle : style.butContent;
    }
  }

  function getIconClass() {
    if (props.iconOnly === true) {
      return style.insideIconIconOnly;
    }

    return props.iconLarge === true ? style.insideIconLarge : style.insideIcon;
  }

  function getInsideClass() {
    var toReturn = style.inside1;

    if (chevronLeft && chevronRight) {
      toReturn = style.inside3
    }

    if (chevronLeft || chevronRight) {
      toReturn = style.inside2
    }

    return toReturn;
  }

  function buttonClicked() {
    if (active && props.onClick) {
      props.onClick();
    }
  }

  function getButtonStyle() {
    if (colorStyle === 'light') {
      return props.onClick ? style.lightButton : style.lightButtonNoPointer
    } else if (colorStyle === 'white') {
      return props.onClick ? style.whiteButton : style.whiteButtonNoPointer
    } else {
      return props.onClick ? style.darkButton : style.darkButtonNoPointer
    }
  }

  try {
    return (
      <div className={style.baseGrid}>

        {label &&
          <div className={style.topTitle}>
            {label}
          </div>
        }
        <DivClick style={{ width: width, font: props.font ? props.font : 'normal normal bold 14px/18px Montserrat', paddingTop: props.paddingTop ? props.paddingTop : '0px', height: height, marginTop: props.marginTop ? props.marginTop : '0px' }} className={`${getButtonStyle()} ${(active ? '' : style.notActive)}`} onClick={buttonClicked}>
          <div className={getButStyle()}>
            {icon &&
              <div className={getIconClass()}>
                <div className={props.iconSize === 2 ? '' : ''}>
                  <i className={props.iconSize ? icon + ' fa-2x' : icon}></i>
                </div>
                <div className={props.iconSize === 2 ? style.txtContent2xIcon : ''} style={{ textAlign: props.iconTextAlign ? props.iconTextAlign : 'center' }}>{props.value}</div>
              </div>
            }
            {
              !(icon) &&
              <div className={getInsideClass()}>
                {chevronLeft &&
                  <div>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                }
                <div>{props.value}</div>
                {chevronRight &&
                  <div>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </div>
                }
              </div>
            }
          </div>
        </DivClick >
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Button

